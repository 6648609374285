import React from "react";
import { CollectionModel } from "types/models";
import clsx from "clsx";

import "./FileSystem.scss";
import CollectionIcon from "Components/ERPIcon/CollectionIcon";

interface FileSystemModalCollectionProps {
    collection: CollectionModel;
    disabled: boolean;
}

const FileSystemModalCollection = ({ collection, disabled }: FileSystemModalCollectionProps) => {
    return (
        <div className={clsx("fs-modal-system", { disabled: disabled === true })}>
            <CollectionIcon />

            <span>{collection.name}</span>
        </div>
    );
};

export default FileSystemModalCollection;
