import React from "react";
import clsx from "clsx";

import "./RadioButton.scss";

export type RadioButtonProps = {
    label?: string;
    containerClassName?: string;
    onChecked?: () => void;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">;

const RadioButton = ({ label, checked, onChecked, className, containerClassName, ...rest }: RadioButtonProps) => {
    return (
        <div className={containerClassName}>
            <input
                type="radio"
                className={clsx("erp-radio-button", className)}
                checked={checked}
                onChange={() => {
                    if (onChecked) {
                        onChecked();
                    }
                }}
                {...rest}
            ></input>

            {label && <span className="erp-radio-button--label">{label}</span>}
        </div>
    );
};

export default RadioButton;
