import { KNOWN_HTTP_STATUS_CODES } from "api/base.api";
import { FolderModel, RuleModel, SearchModel, CollectionModel } from "types/models";
import {
    BulkCopyFolderRequest,
    BulkFolderDeleteRequest,
    BulkMoveFolderRequest,
    CopyFolderRequest,
    CreateFolderRequest,
    CreateRuleRequest,
    CreateCollectionRequest,
    FolderDeleteResult,
    MoveFolderRequest,
    UpdateFolderRequest
} from "types/requests";
import API from "../api/data.api";
import { URL } from "../Constants/api";

export default class FolderService {
    public static async createFolder(request: CreateFolderRequest): Promise<FolderModel> {
        const folder = await API.post<FolderModel>(`${URL.FOLDERS}`, request);
        return folder;
    }

    public static async updateFolder(folderId: string, request: UpdateFolderRequest): Promise<FolderModel> {
        const folder = await API.patch<FolderModel>(`${URL.FOLDERS}/${folderId}`, request);
        return folder;
    }

    public static async deleteFolder(id: string): Promise<FolderDeleteResult> {
        try {
            await API.delete<void>(`${URL.FOLDERS}/${id}`);

            return { success: true, failure: null };
        } catch (error) {
            if (API.isAxiosError(error)) {
                if (error.response.status === KNOWN_HTTP_STATUS_CODES.BAD_REQUEST) {
                    const apiError = error.response.data.error;

                    return {
                        success: false,
                        failure: { code: apiError.code, message: apiError.message }
                    };
                }
            } else {
                throw error;
            }
        }
    }

    public static async deleteFolders(ids: string[]): Promise<FolderDeleteResult> {
        try {
            const request: BulkFolderDeleteRequest = {
                folders: ids ?? []
            };

            await API.delete<void>(`${URL.FOLDERS}`, request);

            return { success: true, failure: null };
        } catch (error) {
            if (API.isAxiosError(error)) {
                if (error.response.status === KNOWN_HTTP_STATUS_CODES.BAD_REQUEST) {
                    const apiError = error.response.data.error;

                    return {
                        success: false,
                        failure: { code: apiError.code, message: apiError.message }
                    };
                }
            } else {
                throw error;
            }
        }
    }

    public static async copyFolder(folderId: string, destinationFolderId: string): Promise<FolderModel[]> {
        const request: CopyFolderRequest = {
            folderId: folderId,
            destinationFolderId: destinationFolderId
        };

        const response = await API.post<FolderModel[]>(`${URL.FOLDERS}/copy`, request);

        return response;
    }

    public static async copyFolders(folderIds: string[], destinationFolderId: string): Promise<FolderModel[]> {
        const request: BulkCopyFolderRequest = {
            folderIds: folderIds,
            destinationFolderId: destinationFolderId
        };

        const response = await API.post<FolderModel[]>(`${URL.FOLDERS}/copy/bulk`, request);

        return response;
    }

    public static async moveFolder(id: string, destinationFolderId: string): Promise<FolderModel> {
        const request: MoveFolderRequest = {
            folderId: id,
            destinationFolderId: destinationFolderId
        };

        const movedFolder = await API.post<FolderModel>(`${URL.FOLDERS}/move`, request);

        return movedFolder;
    }

    public static async moveFolders(ids: string[], destinationFolderId: string): Promise<FolderModel[]> {
        const request: BulkMoveFolderRequest = {
            folderIds: ids,
            destinationFolderId: destinationFolderId
        };

        const movedFolders = await API.post<FolderModel[]>(`${URL.FOLDERS}/move/bulk`, request);

        return movedFolders;
    }

    public static async getCollections(folderId: string): Promise<CollectionModel[]> {
        const collections = await API.get<CollectionModel[]>(`${URL.FOLDERS}/${folderId}/collections`);

        return collections;
    }

    public static async createCollection(folderId: string, request: CreateCollectionRequest): Promise<CollectionModel> {
        const collections = await API.post<CollectionModel>(`${URL.FOLDERS}/${folderId}/collections`, request);

        return collections;
    }

    public static async getRules(folderId: string): Promise<RuleModel[]> {
        const rules = await API.get<RuleModel[]>(`${URL.FOLDERS}/${folderId}/rules`);

        return rules;
    }

    public static async createRule(folderId: string, request: CreateRuleRequest): Promise<RuleModel> {
        const rule = await API.post<RuleModel>(`${URL.FOLDERS}/${folderId}/rules`, request);

        return rule;
    }

    public static async searchFolders(searchString: string): Promise<SearchModel[]> {
        const searchResults = await API.get<SearchModel[]>(
            `${URL.FOLDERS}/search`,
            {},
            new URLSearchParams([["name", searchString]])
        );

        return searchResults;
    }
}
