import React, { useState } from "react";
import ERPModal from "Components/Modals/ERPModal";
import { ModalProps } from "Components/Modals/types";
import { DataInfillSettingsModel, DataInfillType, RuleModel, RunPeriodModel, TaskStatus } from "types/models";
import RuleRunModalFooter from "./RuleRunModalFooter";
import { BaseComputationRequest } from "types/requests";
import ComputationService from "Services/computation.service";
import { Toast } from "Components/Toast";
import { isNil } from "lodash";
import TaskStatusDisplay from "Components/TaskStatusDisplay/TaskStatusDisplay";
import { useRule } from "./RuleContext";
import RuleService from "Services/rule.service";
import RuleRunModalRunSettings from "./RuleRunModalRunSettings";

interface RuleRunModalProps extends ModalProps {
    rule: RuleModel;
    readOnly: boolean;
}

const RuleRunModal = ({ rule, show, readOnly, onClose }: RuleRunModalProps) => {
    const { task, updateTask, updateRule } = useRule();

    const [runPeriod, setRunPeriod] = useState<RunPeriodModel>(rule.runPeriod);
    const [dataInfillSettings, setDataInfillSettings] = useState<DataInfillSettingsModel>(
        !isNil(rule.dataInfillSettings)
            ? rule.dataInfillSettings
            : { should_infill_data: false, infill_method: DataInfillType.PREVIOUS_VALUE }
    );
    const [isRunning, setIsRunning] = useState<boolean>(false);

    const handleRun = async () => {
        const parsedRunPeriod = checkNullRunPeriod(runPeriod) ? null : runPeriod;

        const updatedRule = await RuleService.updateRuleRunSettings(rule.id, {
            runPeriod: parsedRunPeriod,
            dataInfillSettings: dataInfillSettings
        });

        updateRule(updatedRule);

        try {
            setIsRunning(true);

            const request: BaseComputationRequest = {
                ruleId: rule.id,
                runPeriod: parsedRunPeriod,
                dataInfillSettings: dataInfillSettings
            };

            const result = await ComputationService.compute(request);

            if (isNil(result)) {
                return;
            }

            if (!result.success) {
                Toast.error(result.failure.message);
                return;
            }

            updateTask(result.task);
        } finally {
            setIsRunning(false);
        }
    };

    const checkNullRunPeriod = (period: RunPeriodModel): boolean => {
        if (isNil(period?.start) && isNil(period?.end)) {
            return true;
        }
        return false;
    };

    const isComputing = task?.status === TaskStatus.RUNNING || isRunning;

    return (
        <ERPModal
            show={show}
            title={`Run - ${rule.name}`}
            onClose={onClose}
            size="lg"
            footer={
                <RuleRunModalFooter canRun={!readOnly} isRunning={isComputing} onRun={handleRun} onCancel={onClose} />
            }
        >
            <div className="run-rule-modal">
                <RuleRunModalRunSettings
                    runPeriod={runPeriod}
                    dataInfillSettings={dataInfillSettings}
                    readOnly={readOnly}
                    handleSetRunPeriod={setRunPeriod}
                    handleSetInfillSettings={setDataInfillSettings}
                />

                {!isNil(task) && (
                    <TaskStatusDisplay
                        label="Computation"
                        task={task}
                        className="computation-status"
                        showProgress={true}
                    />
                )}
            </div>
        </ERPModal>
    );
};

export default RuleRunModal;
