import React from "react";
import { faDownload, faMinusCircle } from "@fortawesome/pro-regular-svg-icons";
import TooltipIcon from "Components/TooltipIcon";
import { BaseDataModel, DataType } from "types/models";
import DataTypeSelect from "Components/DataTypeSelect";
import AzureBlobService from "Services/azure.blob.service";

interface DataPointManualDataFileProps {
    data: BaseDataModel;
    disabled: boolean;
    avaliableDataTypes: DataType[];
    onDataTypeChanged: (data: BaseDataModel, dataType: DataType) => void;
    onDataRemoved: (data: BaseDataModel) => void;
}

const DataPointManualDataFile = ({
    data,
    disabled,
    avaliableDataTypes,
    onDataTypeChanged,
    onDataRemoved
}: DataPointManualDataFileProps) => {
    const handleDataTypeChanged = (dataType: DataType) => {
        onDataTypeChanged(data, dataType);
    };

    const handleRemoved = () => {
        onDataRemoved(data);
    };

    const handleDownload = async () => {
        await AzureBlobService.downloadBlob(data.blobId, data.name);
    };

    return (
        <div className="data-point-file-section-data">
            <div className="data-point-file-section-info">
                <DataTypeSelect
                    allowedDataTypes={avaliableDataTypes}
                    dataType={data.dataType}
                    disabled={disabled}
                    onChanged={handleDataTypeChanged}
                    isClearable
                />

                <span className="data-point-file-section-data-point-name">{data.name}</span>
            </div>

            <div className="data-point-file-section-icons">
                <TooltipIcon
                    className="data-download-icon"
                    icon={faDownload}
                    tooltip="Download file"
                    onClick={handleDownload}
                    size="lg"
                />

                <TooltipIcon
                    className="data-remove-icon"
                    icon={faMinusCircle}
                    tooltip="Remove file"
                    disabled={disabled}
                    onClick={handleRemoved}
                    size="lg"
                />
            </div>
        </div>
    );
};

export default DataPointManualDataFile;
