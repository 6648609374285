import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/pro-solid-svg-icons";
import clsx from "clsx";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";

import "./LoadingIndicator.scss";

interface LoadingIndicatorProps {
    centered?: boolean;
    className?: string;
    size?: SizeProp;
}

const LoadingIndicator = ({ centered = false, size = "lg", className }: LoadingIndicatorProps) => {
    return (
        <div className={clsx("loading-indicator", { centered: centered === true }, className)}>
            <FontAwesomeIcon icon={faCircleNotch} spin size={size} />
        </div>
    );
};

export default LoadingIndicator;
