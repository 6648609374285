import { DataType, RunPeriodModel, TaskModel } from "./models";

export interface PluginSchema {
    id: PluginId;
    name: string;
    version: string;
    definitions: PluginDefinitions;
    definition_groups: string[];
}

export type PluginDefinitions = Record<string, PluginDefinition>;

export interface PluginDefinition {
    id: string;
    name: string;
    scopes: PluginScopes[];
    fields: PluginField[];
}

export interface PluginField {
    id: string;
    name: string;
    required: boolean;
    type: PluginFieldTypes;
    control: PluginControl;
}

export interface PluginControl {
    type: PluginControlTypes;
    unit?: string;
    range?: FieldRange;
    values?: string[] | number[] | PluginDropdownValue[];
    tooltip?: string;
    options?: PluginControlOptions;
    multi?: boolean;
}

type PluginControlOptions =
    | SeasonSelectOptions
    | CurveOptions
    | FlowTypeOptions
    | RiskCategoriesOptions
    | CheckboxInputOptions
    | SeasonTypeOptions
    | DataPointOptions;

export interface PluginDropdownValue {
    label: string;
    value: string;
}

export interface FieldRange {
    min: number;
    max: number;
}

export enum PluginScopes {
    RULE = "rule"
}

export enum PluginControlTypes {
    TEXT_INPUT = "text_input",
    NUMERIC_INPUT = "numeric_input",
    CHECKBOX = "checkbox",
    DROPDOWN = "dropdown",
    MONTH_SELECT = "month_select",
    SEASON_SELECT = "season_select",
    ARI = "ari",
    CURVE = "curve",
    RISK_CATEGORIES = "risk_categories",
    FLOW_TYPE = "flow_type",
    SEASON_TYPE = "season_type",
    VARIABLE_SEASON = "variable_season",
    CHECKBOX_INPUT = "checkbox_input",
    SPELL_TYPE = "spell_type",
    NUMERIC_RANGE = "numeric_range",
    DATA_POINT = "data_point",
    DATA_POINT_MULTI = "data_point_multi",
    LOWFLOW_RULE = "lowflow_rule",
    FRESHES_RULE = "freshes_rule"
}

export enum PluginFieldTypes {
    NUMBER = "number",
    FLOAT = "float",
    BOOLEAN = "boolean",
    ARRAY = "array",
    MONTH = "month",
    SEASON = "season",
    STRING = "string",
    ARI = "ari",
    CURVE = "curve",
    RISK_CATEGORIES = "risk_categories",
    FLOW_TYPE = "flow_type",
    SEASON_TYPE = "season_type",
    VARIABLE_SEASON = "variable_season",
    NUMERIC_RANGE = "numeric_range",
    DATA_POINT = "data_point",
    DATA_POINT_MULTI = "data_point_multi",
    LOWFLOW_RULE = "lowflow_rule",
    FRESHES_RULE = "freshes_rule"
}
export enum ARITypes {
    THRESHOLD = "threshold",
    FLOW = "flow"
}

export enum NumericRangeTypes {
    NUMBER = "number",
    FLOAT = "float"
}

export enum SeasonTypeTypes {
    DATE = "date",
    TEMPERATURE = "temperature"
}

export enum FlowTypeTypes {
    MEDIAN = "median",
    THRESHOLD = "threshold"
}

export enum CriteriaTypes {
    THRESHOLD = "threshold",
    MEDIAN = "median"
}

export enum ComparisonType {
    GREATER_EQUAL = "gte",
    LESS_EQUAL = "lte"
}

export enum Month {
    JANUARY = "january",
    FEBRUARY = "february",
    MARCH = "march",
    APRIL = "april",
    MAY = "may",
    JUNE = "june",
    JULY = "july",
    AUGUST = "august",
    SEPTEMBER = "september",
    OCTOBER = "october",
    NOVEMBER = "november",
    DECEMBER = "december"
}

export enum SpellTypeTypes {
    ABOVE_THRESHOLD = "above_threshold",
    BELOW_THRESHOLD = "below_threshold",
    RANGE = "range"
}

export interface BaseParameter {
    [key: string]: Record<string, PluginFieldValue>;
}

export type PluginFieldValue =
    | string
    | number
    | boolean
    | Record<string, string | number | boolean>
    | PluginFieldArrayValue
    | RunPeriodModel;

export type PluginFieldArrayValue = Array<number | number[] | string | DataPointSection>;

export type PluginParameters = Record<string, Record<string, PluginFieldValue>>;

export interface DataPointSection {
    rule_data_point_id: string;
    lag: number;
    data_type: DataType;
}

export interface ParameterUpdateResult {
    parameters: PluginParameters;
    version: string;
    success: boolean;
    failure: PluginValidationFailure;
}

export interface SetPluginResult {
    parameters: PluginParameters;
    version: string;
}

export interface ComputationResult {
    task: TaskModel;
    success: boolean;
    failure: ComputationFailure;
}

interface ComputationFailure {
    code: string;
    message: string;
}

interface PluginValidationFailure {
    code: string;
    message: string;
}

export interface SeasonSelectOptions {
    day: boolean;
    month: boolean;
    year: boolean;
}

export interface FlowTypeOptions {
    title: string;
}

export interface SeasonTypeOptions {
    allow_partial_seasons: boolean;
    allow_temperature_comparison: boolean;
    allow_temperature_moving_average: boolean;
}

export interface RiskCategoriesOptions {
    high_label: string;
    low_label: string;
}

export interface DataPointOptions {
    default_data_type: DataType;
    allow_lag: boolean;
    required_data_type?: DataType;
}

export interface CurveOptions {
    x_label: string;
    y_label: string;
    disable_add?: boolean;
    disable_remove?: boolean;
    disable_x?: boolean;
    disable_y?: boolean;
    allow_import?: boolean;
}

export interface CheckboxInputOptions {
    checkbox_label: string;
}

export interface ARIThresholdSettings {
    type: ARITypes;
    value: number;
    use_default_data: boolean;
}

export enum PluginId {
    FRESHES = "freshes",
    LOWFLOW = "lowflow",
    MULTIYEAR_FRESHES = "multiyear_freshes",
    OVERSUPPLY = "oversupply"
}

export enum ReportingTiers {
    LEVEL_1 = "level_1",
    LEVEL_2 = "level_2",
    LEVEL_3 = "level_3",
    LEVEL_4 = "level_4"
}

export interface ApiResultFailure {
    code: string;
    message: string;
}

export interface ApiResult<T> {
    data: T;
    success: boolean;
    failure: ApiResultFailure;
}
