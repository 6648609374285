import { isNil } from "lodash";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { PluginDefinition, PluginFieldValue } from "types/plugin";
import PluginParameterField from "./PluginParameterField";
import { PluginForm } from "./types";
import ERPIcon from "Components/ERPIcon";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { isNilOrEmpty } from "utils/utils";
import Transition from "Components/Transition";

interface PluginParameterDefinitionProps {
    /**
     * Unique identifier to make sure this input is uniquely rendered amongst other plugin parameters.
     * This is important to avoid lose of focus on re-render caused by state change during parameter saving.
     */
    id: string;
    definition: PluginDefinition;
    disabled: boolean;
    onValueChanged?: (definition: string, field: string, value: PluginFieldValue) => void;
}

const PluginParameterDefinition = ({ id, definition, disabled, onValueChanged }: PluginParameterDefinitionProps) => {
    const { control, setValue } = useFormContext<PluginForm>();
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

    const handleValueChanged = async (field: string, value: PluginFieldValue) => {
        setValue(`${definition.id}.${field}`, value);

        if (!isNil(onValueChanged)) {
            onValueChanged(definition.id, field, value);
        }
    };

    return (
        <div className="plugin-definition">
            {!isNilOrEmpty(definition.name) && (
                <div className="plugin-definition--header" onClick={() => setIsCollapsed(!isCollapsed)}>
                    {definition.name}

                    <ERPIcon icon={!isCollapsed ? faChevronUp : faChevronDown} className="expand-icon" size="lg" />
                </div>
            )}

            <Transition isShown={!isCollapsed} transitionType="slide-down" key={definition.id}>
                <div className="plugin-definition-fields">
                    {definition.fields.map(field => {
                        const _id = `${id}-${field.id}`;

                        return (
                            <PluginParameterField
                                key={_id}
                                id={_id}
                                definition={definition}
                                field={field}
                                control={control}
                                disabled={disabled}
                                onValueChanged={handleValueChanged}
                            />
                        );
                    })}
                </div>
            </Transition>
        </div>
    );
};

export default PluginParameterDefinition;
