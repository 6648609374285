import clsx from "clsx";
import React, { useMemo } from "react";
import AzureBlobService from "Services/azure.blob.service";
import { Attachment } from "types/models";

interface RuleAttachmentThumbnailProps {
    attachment: Attachment;
}

const RuleAttachmentThumbnail = ({ attachment }: RuleAttachmentThumbnailProps) => {
    const isImage = useMemo(() => {
        const IMAGE_EXTENSIONS = new Set<string>(["jpg", "jpeg", "png", "svg", "gif", "webp"]);

        return IMAGE_EXTENSIONS.has(attachment.extension);
    }, [attachment.extension]);

    return (
        <div className={clsx("attachment-thumbnail", { "image-thumbnail": isImage, "document-thumbnail": !isImage })}>
            {!isImage && <h4>{attachment.extension.toUpperCase()}</h4>}

            {isImage && <img src={AzureBlobService.formatFileDownloadUrl(attachment.blobId)} />}
        </div>
    );
};

export default RuleAttachmentThumbnail;
