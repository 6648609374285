import React from "react";
import PageTitle from "Components/PageTitle";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "Components/LoadingButton";
import { Redirect, useHistory } from "react-router-dom";
import FormInput from "Components/FormInput/FormInput";
import { useQuery } from "../../Hooks/useQuery";
import { isNilOrEmpty } from "utils/utils";
import { RegisterRequest } from "types/requests";
import UserService from "Services/user.service";

import "./Register.scss";

const schema = yup.object().shape({
    email: yup.string().email("Email address is invalid (e.g: something@email.com)").required("Email is required"),
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    password: yup
        .string()
        .required("Please enter your password")
        .matches(
            /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
            "Password must be at least 8 characters and contain at least one of each - uppercase, lowercase, number and special character."
        ),
    confirmPassword: yup.string().oneOf([yup.ref("password"), null], "Passwords must match")
});

interface RegisterForm {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    confirmPassword: string;
}

const Register = () => {
    const query = useQuery();
    const token = query.get("token");
    const email = query.get("user");
    const history = useHistory();

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty, isSubmitting }
    } = useForm<RegisterForm>({
        resolver: yupResolver(schema),
        defaultValues: {
            email: email
        }
    });

    const doRegister = async (data: RegisterForm) => {
        const request: RegisterRequest = {
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            password: data.password,
            token: token
        };

        await UserService.register(request);
        history.push("/");
    };

    if (isNilOrEmpty(email) || isNilOrEmpty(token)) {
        return <Redirect to="/" />;
    }

    return (
        <div className="register">
            <PageTitle title="Register" />

            <div className="register-form">
                <div className="titles">
                    <h1>Eflow Projector</h1>

                    <span>{email}</span>
                </div>

                <form onSubmit={handleSubmit(doRegister)}>
                    <FormInput
                        id="firstName"
                        name="firstName"
                        type="text"
                        placeholder="Your first name"
                        register={register("firstName")}
                        error={errors.firstName}
                        disabled={isSubmitting}
                    />

                    <FormInput
                        id="lastName"
                        name="lastName"
                        type="text"
                        placeholder="Your last name"
                        register={register("lastName")}
                        error={errors.lastName}
                        disabled={isSubmitting}
                    />

                    <FormInput
                        id="password"
                        name="password"
                        type="password"
                        placeholder="Password"
                        register={register("password")}
                        error={errors.password}
                        disabled={isSubmitting}
                    />

                    <FormInput
                        id="confirmPassword"
                        name="confirmPassword"
                        type="password"
                        placeholder="Confirm password"
                        register={register("confirmPassword")}
                        error={errors.confirmPassword}
                        disabled={isSubmitting}
                    />

                    <div className="form--actions">
                        <LoadingButton
                            variant="primary"
                            type="submit"
                            disabled={!isDirty || isSubmitting}
                            isLoading={isSubmitting}
                            rounded
                        >
                            Register
                        </LoadingButton>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Register;
