import React from "react";
import { faCopy, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import TooltipIcon from "Components/TooltipIcon";

interface DataPointRowActionsProps {
    canDelete: boolean;
    onDelete: () => void;
    onCopy: () => void;
}

const DataPointRowActions = ({ canDelete, onDelete, onCopy }: DataPointRowActionsProps) => {
    return (
        <div className="action-container">
            <TooltipIcon icon={faTrashAlt} tooltip="Delete data point" disabled={!canDelete} onClick={onDelete} />
            <TooltipIcon icon={faCopy} tooltip="Copy data point" onClick={onCopy} />
        </div>
    );
};

export default DataPointRowActions;
