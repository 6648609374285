import React from "react";
import ERPIcon from "Components/ERPIcon";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { ResourceLockOwnerModel } from "types/models";

import "./ResourceLockedWarning.scss";
import { fullname } from "utils/utils";

interface ResourceLockedWarningProps {
    resourceType: string;
    owner: ResourceLockOwnerModel;
}

const ResourceLockedWarning = ({ resourceType, owner }: ResourceLockedWarningProps) => {
    return (
        <div className="resource-locked-warning">
            <ERPIcon icon={faExclamationTriangle} size="lg" />
            This {resourceType} is locked by user {fullname(owner)}
        </div>
    );
};

export default ResourceLockedWarning;
