import LoadingButton from "Components/LoadingButton";
import AppProgressBar from "Components/ProgressBar";
import { isNil } from "lodash";
import React from "react";

interface DataPointObservedDataModalFooterProps {
    isLoading: boolean;
    disabled: boolean;
    progress: number;
    onSave: () => void;
}

const DataPointObservedDataModalFooter = ({
    isLoading,
    disabled,
    progress,
    onSave
}: DataPointObservedDataModalFooterProps) => {
    return (
        <div className="data-point-observed-data-modal-footer">
            {!isNil(progress) && <AppProgressBar progress={progress} />}
            {isNil(progress) && <div></div>}

            <LoadingButton className="save-button" onClick={onSave} isLoading={isLoading} disabled={disabled}>
                Save
            </LoadingButton>
        </div>
    );
};

export default DataPointObservedDataModalFooter;
