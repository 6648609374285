import React, { useState, MouseEvent, useEffect } from "react";
import { faPencil, faUniversity } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GroupRowUsers from "./GroupRowUsers";
import { GroupModel, GroupMember, User, GroupPrivilege } from "types/models";
import GroupService from "Services/group.service";
import { fullname, isNilOrEmpty, sleep } from "utils/utils";
import { useConfirmation } from "Components/ConfirmDialog/ConfirmationContext";
import { Toast } from "Components/Toast";

interface GroupRowProps {
    group: GroupModel;
    currentUser: User;
    canEditGroups: boolean;
    onGroupEdit: (group: GroupModel) => void;
    onMemberRemoved: (group: GroupModel, member: GroupMember) => void;
    onMemberAdded: (group: GroupModel, member: GroupMember) => void;
}

const GroupRow = ({
    group,
    currentUser,
    canEditGroups,
    onGroupEdit,
    onMemberAdded,
    onMemberRemoved
}: GroupRowProps) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const [members, setMembers] = useState<GroupMember[]>([]);
    const [loadingMembers, setIsLoadingMembers] = useState<boolean>(false);

    const confirm = useConfirmation();

    useEffect(() => {
        const loadMembers = async () => {
            try {
                setIsLoadingMembers(true);

                const groupMembers = await GroupService.getGroupMembers(group.id);

                setMembers(groupMembers);
            } finally {
                await sleep(200);
                setIsLoadingMembers(false);
            }
        };

        if (expanded && isNilOrEmpty(members)) {
            loadMembers();
        }
    }, [expanded]);

    const handleClick = async () => {
        const next = !expanded;

        setExpanded(next);
    };

    const handleEditClick = (e: MouseEvent) => {
        e.stopPropagation();

        onGroupEdit(group);
    };

    const handleMemberRemoved = async (member: GroupMember) => {
        const displayedUser = member.verified ? fullname(member) : member.email;

        const result = await confirm({
            title: "Remove Member",
            description: `Are you sure you want to remove ${displayedUser} from the group ?`
        });

        if (!result.success) {
            return;
        }

        await GroupService.removeGroupMember(group.id, member.id);

        const next = members.filter(m => m.id !== member.id);

        setMembers(next);

        onMemberRemoved(group, member);

        Toast.success("Successfully removed user from group");
    };

    const handleMemberAdded = async (email: string, privilege: GroupPrivilege) => {
        const member = await GroupService.addUserToGroup(group.id, email, privilege);

        if (members.some(m => m.id === member.id)) {
            const next = members.map(m => {
                if (m.id === member.id) {
                    return member;
                }

                return m;
            });

            setMembers(next);
        } else {
            setMembers([...members, member]);

            onMemberAdded(group, member);
        }

        Toast.success("Successfully added user to group");
    };

    return (
        <>
            <tr className="group-row" onClick={handleClick}>
                <td className="group-name-cell">
                    <FontAwesomeIcon icon={faUniversity} />
                    <span>{group.name}</span>
                </td>

                <td className="edit-cell">
                    {canEditGroups && <FontAwesomeIcon icon={faPencil} onClick={handleEditClick} />}
                </td>
            </tr>

            {expanded && (
                <GroupRowUsers
                    loading={loadingMembers}
                    members={members}
                    currentUser={currentUser}
                    onMemberRemoved={handleMemberRemoved}
                    onMemberAdded={handleMemberAdded}
                />
            )}
        </>
    );
};

export default GroupRow;
