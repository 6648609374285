import LoadingIndicator from "Components/LoadingIndicator";
import React from "react";
import { GroupMember, GroupPrivilege, User, UserRole } from "types/models";
import GroupRowUser from "./GroupRowUser";
import GroupUserInviteInput from "./GroupUserInviteInput";

interface GroupRowUsersProps {
    members: GroupMember[];
    currentUser: User;
    loading: boolean;
    onMemberAdded: (email: string, privilege: GroupPrivilege) => void;
    onMemberRemoved: (member: GroupMember) => void;
}

const GroupRowUsers = ({ members, loading, currentUser, onMemberAdded, onMemberRemoved }: GroupRowUsersProps) => {
    const membership = members.find(m => m.id === currentUser.id);

    const canManageUsers =
        membership?.privilege === GroupPrivilege.CAN_BE_GROUP_ADMIN ||
        membership?.privilege === GroupPrivilege.CAN_MANAGE_USERS;

    return (
        <tr className="group-row-users">
            <td colSpan={3}>
                {loading && <LoadingIndicator centered />}

                {!loading && (
                    <div className="group-users-container">
                        <div className="group-users">
                            {members.map(member => {
                                const isCurrentUser = currentUser.id === member.id;
                                const isRemovable = canManageUsers && !isCurrentUser && member.role === UserRole.USER;

                                return (
                                    <GroupRowUser
                                        key={member.id}
                                        member={member}
                                        isRemovable={isRemovable}
                                        onRemoved={onMemberRemoved}
                                    />
                                );
                            })}
                        </div>

                        <div className="group-users-input">
                            {canManageUsers && <GroupUserInviteInput onMemberAdded={onMemberAdded} />}
                        </div>
                    </div>
                )}
            </td>
        </tr>
    );
};

export default GroupRowUsers;
