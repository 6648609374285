import React from "react";
import { TileLayer } from "react-leaflet";
import Config from "../../Config";

export const BaseLayers = () => {
    const token = Config.map.mapboxToken;

    return (
        <TileLayer
            id="mapbox/light-v11"
            key="mapbox-light-v11"
            url="https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
            attribution="© <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a> <strong><a href='https://www.mapbox.com/map-feedback/' target='_blank'>Improve this map</a></strong>"
            minZoom={4}
            accessToken={token}
        />
    );
};

export default BaseLayers;
