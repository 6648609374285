import React from "react";
import ReactSlider from "react-slider";
import "./Slider.scss";
import { SliderValues } from "./types";
import NumericInput from "Components/NumericInput";
import Label from "Components/Label";

export type ERPSliderProps = {
    values: SliderValues;
    min: number;
    max: number;
    marks: number[];
    containerClassName?: string;
    title: string;
    onChange: (newValues: SliderValues) => void;
};

const ERPSlider = ({ containerClassName, values, min, max, marks, title, onChange }: ERPSliderProps) => {
    const handleValueChange = (newValues: number[]) => {
        const next = {
            from: newValues[0],
            to: newValues[1]
        };
        onChange(next);
    };

    const handleInputValueChange = (newValues: SliderValues) => {
        onChange(newValues);
    };

    return (
        <div className={containerClassName}>
            <div className="slider-holder">
                <Label className="slider-title">{title}</Label>
                <NumericInput
                    style={{ textAlign: "center" }}
                    value={values.from}
                    className="slider-value"
                    onChange={(value: number) => {
                        handleInputValueChange({ ...values, from: value });
                    }}
                    min={min}
                    max={values.to - 1}
                />
                <ReactSlider
                    className="erp-slider"
                    thumbClassName="thumb"
                    trackClassName="track"
                    markClassName="mark"
                    value={[values.from, values.to]}
                    min={min}
                    max={max}
                    marks={marks}
                    renderMark={props => {
                        const value = props.key as number;

                        if (value > values.from && value < values.to) {
                            props.className = "mark mark-active";
                        }
                        return (
                            <div {...props}>
                                <span className="mark-label" style={{ right: value.toString().length * 5 }}>
                                    {value}
                                </span>
                            </div>
                        );
                    }}
                    pearling
                    minDistance={1}
                    onAfterChange={handleValueChange}
                />
                <NumericInput
                    style={{ textAlign: "center" }}
                    value={values.to}
                    className="slider-value"
                    onChange={(value: number) => {
                        handleInputValueChange({ ...values, to: value });
                    }}
                    min={values.from + 1}
                    max={max}
                />
            </div>
        </div>
    );
};

export default ERPSlider;
