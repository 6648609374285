import { useEffect, useRef } from "react";

type PollCallback = () => void;

export function useInterval(callback: PollCallback, delay: number | null) {
    const savedCallback = useRef<PollCallback>();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }

        if (delay !== null) {
            const id = setInterval(tick, delay);

            return () => {
                clearInterval(id);
            };
        }
    }, [delay]);
}
