import React from "react";
import PageTitle from "Components/PageTitle";

import "./DataLibrary.scss";
import DataLibraryContainer from "./DataLibraryContainer";

const DataLibrary = () => {
    return (
        <div className="data-library">
            <PageTitle title="Data Point Library" />

            <DataLibraryContainer allowNewData={true} allowDataActions={true} />
        </div>
    );
};

export default DataLibrary;
