import React from "react";
import { RuleExploreResultsModel, RuleRunModel } from "types/models";
import { ExploreTabType } from "./ExploreTabs";
import RuleExploreSummary from "./RuleExploreSummary";
import RuleExploreIntermediate from "./RuleExploreIntermediate";
import RuleExploreDownloads from "./RuleExploreDownloads";

interface RuleExploreBodyProps {
    results: RuleExploreResultsModel;
    run: RuleRunModel;
    activeTab: ExploreTabType;
}

const RuleExploreBody = ({ results, run, activeTab }: RuleExploreBodyProps) => {
    return (
        <div className="rule-explore-body">
            {activeTab === ExploreTabType.SUMMARY && <RuleExploreSummary results={results} />}
            {activeTab === ExploreTabType.INTERMEDIATE && <RuleExploreIntermediate results={results} />}
            {activeTab === ExploreTabType.DOWNLOADS && <RuleExploreDownloads run={run} />}
        </div>
    );
};

export default RuleExploreBody;
