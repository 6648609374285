import Label from "Components/Label";
import React, { useState } from "react";
import { useDataPoint } from "./DataPointContext";
import Button from "Components/Button";
import { faEye, faPen, faSync } from "@fortawesome/pro-solid-svg-icons";
import DataPointDataPreviewModal from "./DataPointDataPreviewModal";
import DataPointAutomaticDataFile from "./DataPointAutomaticDataFile";
import DataPointService from "Services/data-point.service";
import LoadingButton from "Components/LoadingButton";
import EDSService from "Services/eds.service";
import { BOMConnectionSettings, ObservedDataSourceTypes } from "types/models";
import { isNilOrEmpty } from "utils/utils";
import { Toast } from "Components/Toast";
import PreviewDataService from "Services/preview.data.service";
import Humanize from "humanize-plus";

interface DataPointAutomaticDataProps {
    onDataEdit: () => void;
}

const DataPointAutomaticData = ({ onDataEdit }: DataPointAutomaticDataProps) => {
    const [showDataPreviewModal, setShowDataPreviewModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { dataPoint, dataPointData, readOnly, updateDataPointData } = useDataPoint();

    const handleRefreshAll = async () => {
        try {
            setIsLoading(true);

            const nextData = [];

            for (let i = 0; i < dataPointData.length; i++) {
                const data = dataPointData[i];

                const [_data, _error] = await EDSService.getBOMData(
                    data.dataType,
                    (data.observationSettings.settings as BOMConnectionSettings).gauge
                );

                if (!isNilOrEmpty(_error)) {
                    Toast.error(_error);
                    continue;
                }

                const file = PreviewDataService.formatTimeseriesAsFile(
                    _data.data,
                    `${_data.name}_${ObservedDataSourceTypes.BOM.toUpperCase()}_${Humanize.capitalize(
                        data.dataType
                    )}.csv`,
                    `${_data.name}_${Humanize.capitalize(data.dataType)}_${_data.unit}`
                );

                const dataResult = await DataPointService.updateDataPointDataFile(
                    dataPoint.id,
                    data.id,
                    file,
                    data.dataType,
                    data.isObserved,
                    data.observationSettings
                );

                nextData.push(dataResult.data);
            }

            updateDataPointData(nextData);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="data-point-file-section">
            <div className="data-point-file-section-name">
                <Label>Data</Label>

                <div className="data-point-file-section-name-actions">
                    <LoadingButton
                        icon={faSync}
                        className="refresh-button"
                        disabled={readOnly || dataPointData.length === 0}
                        onClick={handleRefreshAll}
                        isLoading={isLoading}
                        variant="other"
                    >
                        Refresh all data
                    </LoadingButton>

                    <Button
                        icon={faPen}
                        className="edit-button"
                        disabled={readOnly || dataPointData.length === 0}
                        onClick={() => onDataEdit()}
                    >
                        Configure data
                    </Button>

                    <Button
                        icon={faEye}
                        className="preview-button"
                        disabled={dataPointData.length === 0}
                        onClick={() => setShowDataPreviewModal(true)}
                        variant="secondary"
                    >
                        Preview data
                    </Button>
                </div>
            </div>

            {dataPointData.length === 0 && (
                <div className="data-point-file-section-empty-data">
                    <Button className="edit-button" disabled={readOnly} onClick={() => onDataEdit()}>
                        Configure observed data
                    </Button>
                </div>
            )}

            {dataPointData.map(dataFile => {
                return <DataPointAutomaticDataFile key={dataFile.id} data={dataFile} isLoadingAll={isLoading} />;
            })}

            {showDataPreviewModal && (
                <DataPointDataPreviewModal
                    show={showDataPreviewModal}
                    data={dataPointData}
                    dataPoint={dataPoint}
                    onClose={() => setShowDataPreviewModal(false)}
                />
            )}
        </div>
    );
};

export default DataPointAutomaticData;
