import Select, { SelectOption } from "Components/Select/Select";
import { isNil } from "lodash";
import React, { useMemo } from "react";

//FIXME: Ideally this would inherit SelectProps
interface DaySelectProps {
    id: string;
    label?: string;
    value: number;
    className?: string;
    disabled?: boolean;
    isClearable?: boolean;
    onDayChanged: (day: number) => void;
}

const DaySelect = ({ id, label, value, className, disabled, isClearable, onDayChanged }: DaySelectProps) => {
    const days = useMemo(() => {
        const _days: SelectOption[] = [];

        for (let i = 1; i < 32; i++) {
            const day = i.toString();

            _days.push({ label: day, value: day });
        }

        return _days;
    }, []);

    const day = useMemo(() => {
        if (isNil(value)) {
            return null;
        }

        const matching = days.find(m => m.value === value.toString());

        return matching;
    }, [value]);

    return (
        <Select
            id={id}
            labelText={label}
            placeholder="Day"
            value={day}
            values={days}
            onSelected={(option: SelectOption) => {
                const value = !isNil(option?.value) ? Number(option.value) : null;

                if (value !== Number(day?.value)) {
                    onDayChanged(value);
                }
            }}
            isClearable={isClearable}
            isSearchable={false}
            menuClassName={className}
            disabled={disabled}
        />
    );
};

export default DaySelect;
