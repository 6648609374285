import SortableHeader from "Components/SortableHeader";
import { useSort } from "Components/SortableHeader/useSort";
import React from "react";
import { GroupMember, GroupModel, User } from "types/models";
import GroupRow from "./GroupRow";

interface GroupTableProps {
    groups: GroupModel[];
    currentUser: User;
    canEditGroups: boolean;
    onGroupEdit: (group: GroupModel) => void;
    onMemberAdded: (group: GroupModel, member: GroupMember) => void;
    onMemberRemoved: (group: GroupModel, member: GroupMember) => void;
}

const GroupTable = ({
    groups,
    currentUser,
    canEditGroups,
    onGroupEdit,
    onMemberAdded,
    onMemberRemoved
}: GroupTableProps) => {
    const { sortedItems: displayedGroups, handleSort, sortContext } = useSort(groups);

    return (
        <div className="manage-groups--table">
            <table>
                <thead>
                    <tr>
                        <SortableHeader
                            label="Group Name"
                            sortKey="name"
                            context={sortContext}
                            style={{ width: "60%" }}
                            sorter={(g: GroupModel) => g.name?.toLowerCase() ?? ""}
                            onSort={handleSort}
                        />

                        <th style={{ width: 150 }}></th>
                    </tr>
                </thead>

                <tbody>
                    {displayedGroups.map(group => {
                        return (
                            <GroupRow
                                key={group.id}
                                group={group}
                                currentUser={currentUser}
                                canEditGroups={canEditGroups}
                                onGroupEdit={onGroupEdit}
                                onMemberAdded={onMemberAdded}
                                onMemberRemoved={onMemberRemoved}
                            />
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default GroupTable;
