import { Icon } from "leaflet";
import React, { useState } from "react";
import { Marker, Popup } from "react-leaflet";
import { DataPointModel } from "types/models";
import databaseIcon from "Assets/images/database-icon.svg";
import Button from "Components/Button";
import { round } from "lodash";
import { useHistory } from "react-router-dom";
import ERPIcon from "Components/ERPIcon";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-solid-svg-icons";

interface DataLibraryFilterMapGroupMarkerProps {
    id: string;
    latitude: number;
    longitude: number;
    dataPoints: DataPointModel[];
}

const DataLibraryFilterMapGroupMarker = ({
    id,
    latitude,
    longitude,
    dataPoints
}: DataLibraryFilterMapGroupMarkerProps) => {
    const history = useHistory();
    const [selectedDataPointIndex, setSelectedDataPointIndex] = useState<number>(0);

    const handleNavigateRight = () => {
        if (selectedDataPointIndex === dataPoints.length - 1) {
            setSelectedDataPointIndex(0);
            return;
        }

        const next = selectedDataPointIndex + 1;
        setSelectedDataPointIndex(next);
    };

    const handleNavigateLeft = () => {
        if (selectedDataPointIndex === 0) {
            setSelectedDataPointIndex(dataPoints.length - 1);
            return;
        }

        const next = selectedDataPointIndex - 1;
        setSelectedDataPointIndex(next);
    };

    return (
        <Marker
            key={id}
            position={[latitude, longitude]}
            icon={
                new Icon({
                    iconUrl: databaseIcon,
                    iconSize: [20, 30],
                    iconAnchor: [10, 15],
                    tooltipAnchor: [0, 0]
                })
            }
        >
            <Popup>
                <div className="filter-map-group-marker">
                    <ERPIcon
                        icon={faArrowLeft}
                        size="2x"
                        className="filter-map-group-marker-arrow"
                        onClick={handleNavigateLeft}
                    />
                    <div className="filter-map-group-marker-body">
                        {dataPoints[selectedDataPointIndex].name}
                        <br />
                        <br />
                        Lat: {round(dataPoints[selectedDataPointIndex].latitude, 5)}
                        <br />
                        Lng: {round(dataPoints[selectedDataPointIndex].longitude, 5)}
                        <br />
                        <br />
                        <Button
                            className="filter-map-group-marker-body-button"
                            onClick={() => history.push(`/data-point/${dataPoints[selectedDataPointIndex].id}`)}
                        >
                            Open
                        </Button>
                    </div>
                    <ERPIcon
                        icon={faArrowRight}
                        size="2x"
                        className="filter-map-group-marker-arrow"
                        onClick={handleNavigateRight}
                    />
                </div>
            </Popup>
        </Marker>
    );
};

export default DataLibraryFilterMapGroupMarker;
