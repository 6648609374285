import React from "react";
import FormNumericInput from "Components/FormInput/FormNumericInput";
import { Controller } from "react-hook-form";
import { PluginFieldProps } from "./types";

interface PluginNumericInputProps extends PluginFieldProps {
    onChange: (value: number) => void;
}

const PluginNumericInput = ({ id, definition, field, control, disabled, onChange }: PluginNumericInputProps) => {
    return (
        <Controller
            control={control}
            name={`${definition.id}.${field.id}`}
            render={({ field: _field }) => {
                return (
                    <FormNumericInput
                        id={id}
                        labelText={field.name}
                        labelClass="plugin-definition-field-label"
                        value={_field.value}
                        unit={field.control.unit}
                        min={field.control.range?.min}
                        max={field.control.range?.max}
                        onChange={onChange}
                        disabled={disabled}
                    />
                );
            }}
        />
    );
};

export default PluginNumericInput;
