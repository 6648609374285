import ERPModal from "Components/Modals/ERPModal";
import React, { useState } from "react";
import { DataPointDataModel, DataPointModel, DataType } from "types/models";
import DataPointDataPreviewModalBody from "./DataPointDataPreviewModalBody";
import Select from "Components/Select/Select";
import { DataTypeSelectOption } from "Components/DataTypeSelect";

interface DataPointDataPreviewModalProps {
    show: boolean;
    data: DataPointDataModel[];
    dataPoint: DataPointModel;
    onClose: () => void;
}

const DATA_TYPES: DataTypeSelectOption[] = [
    { label: "Flow", value: DataType.FLOW },
    { label: "Depth", value: DataType.DEPTH },
    { label: "Temperature", value: DataType.TEMPERATURE },
    { label: "Rainfall", value: DataType.RAINFALL },
    { label: "Evaporation", value: DataType.EVAPORATION },
    { label: "Salinity", value: DataType.SALINITY },
    { label: "pH", value: DataType.PH },
    { label: "Turbidity", value: DataType.TURBIDITY },
    { label: "Storage Volume", value: DataType.STORAGE_VOLUME },
    { label: "Storage Depth", value: DataType.STORAGE_DEPTH }
];

const DataPointDataPreviewModal = ({ show, data, dataPoint, onClose }: DataPointDataPreviewModalProps) => {
    const [selectedDataType, setSelectedDataType] = useState<DataType>(
        data.some(d => d.dataType === DataType.FLOW) ? DataType.FLOW : data[0].dataType
    );

    return (
        <ERPModal
            show={show}
            title={`Preview data - ${dataPoint?.name}`}
            onClose={onClose}
            size="xl"
            scrollable
            backdrop={true}
            bodyClassName="data-preview-modal-body"
            className="data-preview-modal"
            dialogClassName="data-preview-modal-dialog"
            contentClassName="data-preview-modal-content"
            canClose={true}
        >
            <Select
                labelText="Select a data type"
                menuClassName="select"
                values={DATA_TYPES.filter(d => data.find(dd => dd.dataType === d.value))}
                value={DATA_TYPES.find(d => d.value === selectedDataType)}
                onSelected={(option: DataTypeSelectOption) => {
                    setSelectedDataType(option.value);
                }}
            />

            <DataPointDataPreviewModalBody data={data} selectedDataType={selectedDataType} />
        </ERPModal>
    );
};

export default DataPointDataPreviewModal;
