import clsx from "clsx";
import React from "react";

interface EmptyRowProps {
    message: string;
    colSpan?: number;
    className?: string;
}

const EmptyRow = ({ message, colSpan, className }: EmptyRowProps) => {
    return (
        <tr>
            <td className={clsx("centered-cell", className)} colSpan={colSpan}>
                {message}
            </td>
        </tr>
    );
};

export default EmptyRow;
