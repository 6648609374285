import React from "react";
import { faRulerTriangle } from "@fortawesome/pro-solid-svg-icons";
import ERPIcon, { ERPIconProps } from ".";

type RuleIconProps = Omit<ERPIconProps, "icon">;

const RuleIcon = (props: RuleIconProps) => {
    return <ERPIcon className="rule-icon" icon={faRulerTriangle} {...props} />;
};

export default RuleIcon;
