import ERPModal from "Components/Modals/ERPModal";
import { ModalProps } from "Components/Modals/types";
import React, { useEffect, useState } from "react";
import {
    BOMConnectionSettings,
    DataAvailabilityModel,
    DataPointDataModel,
    DataType,
    ObservedDataSourceTypes
} from "types/models";
import { useDataPoint } from "./DataPointContext";
import LoadingIndicator from "Components/LoadingIndicator";
import DataPointObservedDataModalBody from "./DataPointObservedDataModalBody";
import DataPointObservedDataModalFooter from "./DataPointObservedDataModalFooter";
import { useConfirmation } from "Components/ConfirmDialog/ConfirmationContext";
import DataPointService from "Services/data-point.service";
import { isNilOrEmpty, sleep } from "utils/utils";
import { isNil } from "lodash";
import EDSService from "Services/eds.service";
import PreviewDataService from "Services/preview.data.service";
import { Toast } from "Components/Toast";
import Humanize from "humanize-plus";

interface DataPointObservedDataModalProps extends ModalProps {
    stationId: string;
}

const DataPointObservedDataModal = ({ stationId, show, onClose }: DataPointObservedDataModalProps) => {
    const { dataPoint, dataPointData, readOnly, updateDataPointData } = useDataPoint();
    const [dataAvailabilities, setDataAvailabilities] = useState<DataAvailabilityModel[]>([]);
    const [enabledDataTypes, setEnabledDataTypes] = useState<DataType[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFetchingTraces, setIsFetchingTraces] = useState<boolean>(false);
    const [traceProgress, setTraceProgress] = useState<number>(null);

    const confirm = useConfirmation();

    useEffect(() => {
        const fetchAvailability = async () => {
            try {
                setIsLoading(true);
                const availabilities = await EDSService.getBomAvailabilities(stationId);

                const existingDataTypes =
                    dataPointData.length > 0 ? dataPointData.map(d => d.dataType) : availabilities.map(a => a.dataType);

                setEnabledDataTypes(existingDataTypes);
                setDataAvailabilities(availabilities);
            } finally {
                setIsLoading(false);
            }
        };

        fetchAvailability();
    }, [stationId]);

    const handleSave = async () => {
        const newDataPointDataFiles: DataPointDataModel[] = [];
        const removedDataPointDataFiles: DataPointDataModel[] = [];

        try {
            setIsFetchingTraces(true);
            const newDataTypes = enabledDataTypes.filter(e => !dataPointData.some(d => d.dataType === e));
            const removedDataFiles = dataPointData.filter(d => !enabledDataTypes.some(e => e === d.dataType));

            if (!isNilOrEmpty(newDataTypes)) {
                setTraceProgress(0);
            }

            for (let i = 0; i < newDataTypes.length; i++) {
                const dataType = newDataTypes[i];

                const [_data, _error] = await EDSService.getBOMData(dataType, stationId);

                if (!isNilOrEmpty(_error)) {
                    Toast.error(_error);
                    continue;
                }

                const file = PreviewDataService.formatTimeseriesAsFile(
                    _data.data,
                    `${_data.name}_${ObservedDataSourceTypes.BOM.toUpperCase()}_${Humanize.capitalize(dataType)}.csv`,
                    `${_data.name}_${Humanize.capitalize(dataType)}_${_data.unit}`
                );

                const dataResult = await DataPointService.uploadDataPointData(dataPoint.id, file, dataType, true, {
                    source: ObservedDataSourceTypes.BOM,
                    dataType: dataType,
                    settings: { gauge: stationId } as BOMConnectionSettings
                });

                if (isNil(dataResult)) {
                    return;
                }

                newDataPointDataFiles.push(dataResult.data);
                setTraceProgress((100 / newDataTypes.length) * (i + 1));
            }

            for (let i = 0; i < removedDataFiles.length; i++) {
                const dataPointData = removedDataFiles[i];
                await DataPointService.deleteDataPointData(dataPoint.id, dataPointData.id);
                removedDataPointDataFiles.push(dataPointData);
            }
        } finally {
            const next = [
                ...dataPointData.filter(d => !removedDataPointDataFiles.map(dd => dd.id).includes(d.id)),
                ...newDataPointDataFiles
            ];
            updateDataPointData(next);

            await sleep(500);

            setIsFetchingTraces(false);
            setTraceProgress(null);
            onClose();
        }
    };

    const handleClose = async () => {
        if (!enabledDataTypes.every(e => dataPointData.some(d => d.dataType === e))) {
            const result = await confirm({
                title: "Close without saving",
                description: `You have unsaved changes. Are you sure you want to exit without saving?`
            });

            if (!result.success) {
                return;
            }
        }

        onClose();
    };

    return (
        <ERPModal
            show={show}
            title={`Observed data portal - ${dataPoint?.name}`}
            onClose={handleClose}
            size="xl"
            scrollable
            backdrop={true}
            bodyClassName="data-point-observed-data-modal"
            canClose={true}
            footer={
                <DataPointObservedDataModalFooter
                    onSave={handleSave}
                    isLoading={isFetchingTraces}
                    disabled={readOnly || isLoading}
                    progress={traceProgress}
                />
            }
        >
            {isLoading && <LoadingIndicator centered={true} />}

            {!isLoading && (
                <DataPointObservedDataModalBody
                    dataAvailabilities={dataAvailabilities}
                    enabledDataTypes={enabledDataTypes}
                    setEnabledDataTypes={setEnabledDataTypes}
                />
            )}
        </ERPModal>
    );
};

export default DataPointObservedDataModal;
