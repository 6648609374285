import React from "react";
import ActivableButton from "Components/Button/ActivableButton";
import { guid } from "utils/utils";

export enum ERPChartAxisTabType {
    LINEAR = "linear",
    LOGARITHMIC = "logarithmic"
}

interface ERPChartAxisTabProps {
    type: ERPChartAxisTabType;
    isActive: boolean;
    label: string;
    onSelected: (type: ERPChartAxisTabType) => void;
}

interface ERPChartAxisTabsProps {
    selectedTab: TabSelection;
    onTabSelected: (selection: TabSelection) => void;
}

interface TabInfo {
    type: ERPChartAxisTabType;
    label: string;
}

export interface TabSelection {
    type: ERPChartAxisTabType;
    index: number;
}

const TAB_INFO: TabInfo[] = [
    {
        label: "Log",
        type: ERPChartAxisTabType.LOGARITHMIC
    },
    {
        label: "Linear",
        type: ERPChartAxisTabType.LINEAR
    }
];

const ERPChartAxisTab = ({ type, isActive, label, onSelected }: ERPChartAxisTabProps) => {
    const handleSelected = () => {
        onSelected(type);
    };

    return (
        <div className="timeseries-axis--tab">
            <ActivableButton onClick={handleSelected} isActive={isActive}>
                {label}
            </ActivableButton>
        </div>
    );
};

const ERPChartAxisTabs = ({ selectedTab, onTabSelected }: ERPChartAxisTabsProps) => {
    const handleTabSelected = (selection: TabSelection) => {
        if (selection.type === selectedTab.type) {
            return;
        }

        onTabSelected(selection);
    };

    return (
        <div className="timeseries-axis--navigation">
            <div className="timeseries-axis--tabs">
                {TAB_INFO.map((info, index) => {
                    return (
                        <ERPChartAxisTab
                            key={guid()}
                            type={info.type}
                            label={info.label}
                            isActive={selectedTab.type === info.type}
                            onSelected={type => handleTabSelected({ type: type, index: index })}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default ERPChartAxisTabs;
