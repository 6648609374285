import LinkCell, { LinkCellProps } from "Scenes/Explorer/LinkCell";
import React, { useMemo } from "react";

type CollectionRulesTableLinkCellProps = Omit<LinkCellProps, "to"> & { id: string };

const CollectionRulesTableLinkCell = ({ id, ...rest }: CollectionRulesTableLinkCellProps) => {
    const to = useMemo(() => {
        return `/rule/${id}`;
    }, [id]);

    return <LinkCell to={to} {...rest}></LinkCell>;
};

export default CollectionRulesTableLinkCell;
