import React, { useEffect, useMemo, useState } from "react";
import { LocationModel, RuleModel } from "types/models";
import { PluginId } from "types/plugin";
import CollectionAddHeader from "./CollectionAddHeader";
import Transition from "Components/Transition";
import { isNilOrEmpty } from "utils/utils";
import CollectionAddFilter from "./CollectionAddFilter";
import { useCollection } from "./CollectionContext";
import { useSort } from "Components/SortableHeader/useSort";
import { useSelection } from "./useSelection";
import CollectionRulesTable from "./CollectionRulesTable";
import { SelectionState } from "./types";
import RuleService from "Services/rule.service";
import { useLocations } from "Components/LocationContext";
import CollectionService from "Services/collection.service";
import { Toast } from "Components/Toast";
import CollectionAddSearchView from "./CollectionAddSearchView";

export interface CollectionAddFilterSettings {
    locations: LocationModel[];
    plugins: PluginId[];
}

const DEFAULT_PLUGINS_FILTER = [PluginId.LOWFLOW, PluginId.FRESHES, PluginId.OVERSUPPLY, PluginId.MULTIYEAR_FRESHES];

const CollectionAdd = () => {
    const [rules, setRules] = useState<RuleModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { collection, collectionRules, updateCollectionRules } = useCollection();
    const { locations } = useLocations();

    const { selection, selectAll, selectRule, clearSelection, setAvaliableRules } = useSelection(rules);

    const { sortedItems, handleSort, sortContext } = useSort(rules);

    const [filterSettings, setFilterSettings] = useState<CollectionAddFilterSettings>({
        locations: locations,
        plugins: DEFAULT_PLUGINS_FILTER
    });
    const [search, setSearch] = useState<string>("");

    const isSearching = !isNilOrEmpty(search);

    useEffect(() => {
        const fetchRules = async () => {
            try {
                setIsLoading(true);

                const _rules = await RuleService.getRules();

                setRules(_rules);
            } finally {
                setIsLoading(false);
            }
        };

        fetchRules();
    }, []);

    const filteredRules = useMemo(() => {
        const _filteredRules = sortedItems.filter(r => {
            if (!filterSettings.locations.some(l => l.id === r.location?.id)) {
                return false;
            }

            if (!filterSettings.plugins.includes(r.plugin?.identifier)) {
                return false;
            }

            return true;
        });

        setAvaliableRules(_filteredRules);

        return _filteredRules;
    }, [filterSettings, sortedItems]);

    const handleRuleRemoved = async (rule: RuleModel) => {
        if (!collectionRules.some(r => r.id === rule.id)) {
            Toast.error("This rule isn't in the collection");
            return;
        }

        const nextCollectionRules = await CollectionService.removeCollectionRule(collection.id, rule.id);

        Toast.success(`Sucessfully removed ${rule.name}`);

        updateCollectionRules(nextCollectionRules);
    };

    const handleRuleAdd = async (rule: RuleModel) => {
        if (collectionRules.some(r => r.id === rule.id)) {
            Toast.error("This rule is already in the collection");
            return;
        }

        const nextCollectionRules = await CollectionService.addCollectionRule(collection.id, rule.id);

        Toast.success(`Sucessfully added ${rule.name}`);

        updateCollectionRules(nextCollectionRules);
    };

    const handleBulkAdd = async (state: SelectionState) => {
        const ruleIds = Array.from(state.rules.keys());

        if (isNilOrEmpty(ruleIds)) {
            return;
        }

        const filteredIds = ruleIds.filter(id => !collectionRules.some(r => r.id === id));

        if (isNilOrEmpty(filteredIds)) {
            Toast.error("All selected rules are already in the collection");
            return;
        }

        const nextCollectionRules = await CollectionService.addCollectionRules(collection.id, filteredIds);

        const total = filteredIds.length;
        if (total > 0) {
            Toast.success(`Successfully added ${total} item(s)`);
        }

        updateCollectionRules(nextCollectionRules);
        clearSelection();
    };

    const handleBulkRemove = async (state: SelectionState) => {
        const ruleIds = Array.from(state.rules.keys());

        if (isNilOrEmpty(ruleIds)) {
            return;
        }

        const filteredIds = ruleIds.filter(id => collectionRules.some(r => r.id === id));

        if (isNilOrEmpty(filteredIds)) {
            Toast.error("All selected rules are already not in the collection");
            return;
        }

        const nextCollectionRules = await CollectionService.removeCollectionRules(collection.id, filteredIds);

        const total = filteredIds.length;
        if (total > 0) {
            Toast.success(`Successfully removed ${total} item(s)`);
        }

        updateCollectionRules(nextCollectionRules);
        clearSelection();
    };

    return (
        <div className="collection-add">
            <CollectionAddHeader search={search} onSearch={setSearch} />

            <Transition isShown={!isSearching} transitionType="fade">
                <div className="collection-add-filter-container">
                    <CollectionAddFilter filterSettings={filterSettings} onFilterSettingsChange={setFilterSettings} />

                    <CollectionRulesTable
                        isLoading={isLoading}
                        rules={filteredRules}
                        collectionRules={collectionRules}
                        selection={selection}
                        sortContext={sortContext}
                        allowAdd={true}
                        allowRun={false}
                        noRulesMessage="No rules match the filter"
                        onRuleSelected={selectRule}
                        onRuleRemove={handleRuleRemoved}
                        onRuleAdd={handleRuleAdd}
                        onBulkAdd={handleBulkAdd}
                        onBulkRemove={handleBulkRemove}
                        onAllSelected={selectAll}
                        onSort={handleSort}
                    />
                </div>
            </Transition>

            <Transition isShown={isSearching} transitionType="fade">
                <CollectionAddSearchView
                    search={search}
                    rules={rules}
                    selection={selection}
                    sortContext={sortContext}
                    onRuleSelected={selectRule}
                    onRuleRemove={handleRuleRemoved}
                    onRuleAdd={handleRuleAdd}
                    onBulkAdd={handleBulkAdd}
                    onBulkRemove={handleBulkRemove}
                    onAllSelected={selectAll}
                    onSort={handleSort}
                />
            </Transition>
        </div>
    );
};

export default CollectionAdd;
