import React from "react";
import { Item, Menu } from "react-contexify";

import "./positioners";

interface ChartContextMenuProps {
    menuId: string;
    onDownload: () => void;
}

const ChartContextMenu = ({ menuId, onDownload }: ChartContextMenuProps) => {
    return (
        <Menu id={menuId} animation={false}>
            <Item onClick={onDownload}>Download as image</Item>
        </Menu>
    );
};

export default ChartContextMenu;
