import { ChartOptions } from "chart.js";
import ERPChart from "Components/Charts/ERPChart";
import { forEach, isNil, merge } from "lodash";
import React, { useMemo } from "react";
import {
    EflowChartData,
    ExploreDailyPeriodModel,
    ExplorePeriodModel,
    RuleIntermediateResultDailyTimeseries
} from "types/models";
import { isNilOrEmpty } from "utils/utils";
import RenderIfVisible from "react-render-if-visible";
import { buildYearTimeseriesDates, generateHeatmapColours } from "utils/explorer-charts.utils";

interface RuleExploreIntermediateDailyChartProps {
    result: RuleIntermediateResultDailyTimeseries;
    title: string;
    minValue: number;
    maxValue: number;
    yearlyPeriod: ExplorePeriodModel;
    dailyPeriod: ExploreDailyPeriodModel;
}

const CHART_HEIGHT = 500;

const CHART_OPTIONS: ChartOptions = {
    maintainAspectRatio: false,
    interaction: {
        mode: "index"
    },
    scales: {
        x: {
            grid: { display: false, drawBorder: false },
            stacked: true,
            type: "timeseries",
            time: {
                unit: "month",
                displayFormats: {
                    month: "MMM"
                },
                tooltipFormat: "DD MMMM"
            },
            ticks: {
                minRotation: 0,
                maxRotation: 0,
                sampleSize: 1
            }
        },
        y: {
            grid: { display: false, drawBorder: false },
            display: true,
            stacked: true,
            ticks: {
                stepSize: 1
            }
        }
    },
    plugins: {
        legend: {
            display: false
        },
        title: {
            display: true,
            position: "top",
            align: "start",
            font: {
                size: 20
            },
            padding: {
                bottom: 50
            }
        },
        tooltip: {
            enabled: true,
            intersect: true,
            mode: "point",
            position: "stackedBarPositioner"
        }
    },
    hover: {
        mode: null,
        intersect: false
    },
    animation: false,
    normalized: true
};

const RuleExploreIntermediateDailyChart = ({
    result,
    title,
    minValue,
    maxValue,
    yearlyPeriod,
    dailyPeriod
}: RuleExploreIntermediateDailyChartProps) => {
    const chartData: EflowChartData = useMemo(() => {
        const data = [];
        const dailyPeriodLength = dailyPeriod.endDay - dailyPeriod.startDay + 1;

        forEach(Object.keys(result), year => {
            if (+year < yearlyPeriod.startYear || +year > yearlyPeriod.endYear) {
                return;
            }

            const adjustedYearResult = result[year].slice(dailyPeriod.startDay - 1, dailyPeriod.endDay);

            data.push({
                label: year,
                data: new Array(dailyPeriodLength).fill(1),
                backgroundColor: generateHeatmapColours(adjustedYearResult, minValue, maxValue),
                barPercentage: 1.0,
                categoryPercentage: 1.0
            });
        });

        return {
            labels: buildYearTimeseriesDates()
                .map(d => d.date)
                .slice(dailyPeriod.startDay - 1, dailyPeriod.endDay),
            data: data.reverse()
        };
    }, [result, yearlyPeriod, dailyPeriod]);

    return (
        <div className="chart">
            <RenderIfVisible defaultHeight={CHART_HEIGHT}>
                <ERPChart
                    id={"daily_intermediate_chart"}
                    type="bar"
                    labels={chartData.labels}
                    data={chartData.data}
                    options={merge({}, CHART_OPTIONS, {
                        plugins: {
                            title: {
                                text: title
                            },
                            tooltip: {
                                callbacks: {
                                    title: function (tooltipItems) {
                                        const label = !isNilOrEmpty(tooltipItems) ? tooltipItems[0]?.label : null;
                                        const year = !isNilOrEmpty(tooltipItems)
                                            ? tooltipItems[0]?.dataset?.label
                                            : null;
                                        return `${label} ${year}`;
                                    },
                                    label: function (tooltipItem) {
                                        const year = tooltipItem.dataset.label;
                                        const dayIndex = tooltipItem.dataIndex;

                                        if (isNil(result[year][dayIndex].value)) {
                                            return "Result: None";
                                        }

                                        return `Result: ${result[year][dayIndex].value}%`;
                                    }
                                }
                            }
                        },
                        scales: {
                            y: {
                                ticks: {
                                    callback: function (_, index) {
                                        const adjustedIndex = index - 1;

                                        if (adjustedIndex === chartData.data.length - 1) {
                                            return chartData.data[adjustedIndex]?.label;
                                        }

                                        if (index === 0) {
                                            const year = +chartData.data[index]?.label;
                                            return `${year + 1}`;
                                        }
                                    }
                                }
                            }
                        }
                    })}
                    height={CHART_HEIGHT}
                />
            </RenderIfVisible>
        </div>
    );
};

export default RuleExploreIntermediateDailyChart;
