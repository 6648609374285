import { isNil } from "lodash";
import React from "react";
import { PeriodModel, RunPeriodModel } from "types/models";
import RunPeriodSelect from "./RunPeriodSelect";

import "./RunPeriod.scss";
import clsx from "clsx";
import TooltipIcon from "Components/TooltipIcon";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";

interface RunPeriodProps {
    runPeriod: RunPeriodModel;
    title?: string;
    className?: string;
    includeStart?: boolean;
    includeEnd?: boolean;
    disabled?: boolean;
    onRunPeriodChanged: (runPeriod: RunPeriodModel) => void;
}

const DEFAULT_RUN_PERIOD: PeriodModel = {
    day: null,
    month: null,
    year: null
};

const RunPeriod = ({
    runPeriod,
    title,
    className,
    includeStart = true,
    includeEnd = true,
    disabled,
    onRunPeriodChanged
}: RunPeriodProps) => {
    const checkNullPeriod = (period: PeriodModel): boolean => {
        if (isNil(period?.day) && isNil(period?.month) && isNil(period?.year)) {
            return true;
        }
        return false;
    };

    const handleStartChanged = (period: PeriodModel) => {
        const next: RunPeriodModel = { ...runPeriod, start: checkNullPeriod(period) ? null : period };

        onRunPeriodChanged(next);
    };

    const handleEndChanged = (period: PeriodModel) => {
        const next: RunPeriodModel = { ...runPeriod, end: checkNullPeriod(period) ? null : period };

        onRunPeriodChanged(next);
    };

    return (
        <div className={clsx("run-period", className)}>
            <div className="title-container">
                <h5 className="run-period-title">{title}</h5>

                <TooltipIcon
                    icon={faQuestionCircle}
                    tooltip="If no start/end date is provided the tool will use the record start/end"
                    size="2x"
                />
            </div>

            {includeStart && (
                <RunPeriodSelect
                    id="run-period-select-start"
                    field="Start"
                    period={runPeriod?.start ?? DEFAULT_RUN_PERIOD}
                    disabled={disabled}
                    onChange={handleStartChanged}
                />
            )}

            {includeEnd && (
                <RunPeriodSelect
                    id="run-period-select-end"
                    field="End"
                    period={runPeriod?.end ?? DEFAULT_RUN_PERIOD}
                    disabled={disabled}
                    onChange={handleEndChanged}
                />
            )}
        </div>
    );
};

export default RunPeriod;
