import { faUserPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "Components/Button";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { GroupPrivilege } from "types/models";

interface GroupUserPrivilegePickerProps {
    onPrivilegeSelected: (privilege: GroupPrivilege) => void;
}

const GroupUserPrivilegePicker = ({ onPrivilegeSelected }: GroupUserPrivilegePickerProps) => {
    return (
        <Dropdown>
            <Dropdown.Toggle className="menu-toggle">
                <FontAwesomeIcon icon={faUserPlus} />
            </Dropdown.Toggle>

            <Dropdown.Menu align="right" className="menu-actions">
                <Dropdown.Item
                    as={Button}
                    variant="none"
                    eventKey="1"
                    onClick={() => onPrivilegeSelected(GroupPrivilege.CAN_BE_GROUP_ADMIN)}
                >
                    Group admin
                </Dropdown.Item>

                <Dropdown.Item
                    as={Button}
                    variant="none"
                    eventKey="2"
                    onClick={() => onPrivilegeSelected(GroupPrivilege.CAN_USE)}
                >
                    Group user
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default GroupUserPrivilegePicker;
