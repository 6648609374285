import { LocationModel, Mask } from "../types/models";
import API from "../api/data.api";
import { URL } from "../Constants/api";

export default class LocationService {
    public static async getMask(): Promise<Mask> {
        const mask = await API.get<Mask>(`${URL.LOCATIONS}/mask`);

        return mask;
    }

    public static async getLocations(): Promise<LocationModel[]> {
        const locations = await API.get<LocationModel[]>(`${URL.LOCATIONS}`);

        return locations;
    }
}
