import React from "react";
import { CSSTransition } from "react-transition-group";

import "./Transition.scss";

interface TransitionProps {
    isShown: boolean;
    children: React.ReactNode;
    transitionType: "slide-down" | "slide-right" | "slide-left" | "fade";
}

const TRANSITION_TIME_MS = 300;

const Transition = ({ isShown, children, transitionType }: TransitionProps) => {
    return (
        <CSSTransition in={isShown} timeout={TRANSITION_TIME_MS} classNames={transitionType} unmountOnExit>
            {children}
        </CSSTransition>
    );
};

export default Transition;
