import React, { useEffect, useMemo, useState } from "react";
import { ResourceLockErrors, ResourceLockModel, ResourceType } from "types/models";
import { isNil } from "lodash";
import { useHistory, useParams } from "react-router-dom";
import { useAuth0 } from "auth/react-auth0-spa";
import { useResourceLocker } from "Hooks/useResourceLocker";
import { Toast } from "Components/Toast";
import LoadingIndicator from "Components/LoadingIndicator";

import "./DataPoint.scss";
import DataPointContextProvider from "./DataPointContext";
import DataPointSettings from "./DataPointSettings";
import DataPointHeader from "./DataPointHeader";
import { DataPointFileUploaderModalProvider } from "Components/FileUploader/DataPointFileUploaderContext";
import ResourceLockedWarning from "Components/ResourceLockedWarning";

const DataPoint = () => {
    const { id } = useParams<{ id: string }>();
    const { eflowUser } = useAuth0();
    const { lock, unlock } = useResourceLocker();
    const history = useHistory();
    const [resourceLock, setResourceLock] = useState<ResourceLockModel>(null);

    useEffect(() => {
        const doInit = async (dataPointId: string) => {
            const lock_result = await lock(dataPointId, ResourceType.DATA_POINT);

            if (!lock_result.success) {
                if (lock_result.error?.code === ResourceLockErrors.ENTITY_NOT_FOUND) {
                    Toast.error("Data point could not be found");
                    history.push("/");
                }

                return;
            }

            setResourceLock(lock_result.lock);
        };

        doInit(id);

        return () => {
            unlock(id);
        };
    }, [id]);

    const isLockOwner = useMemo(() => {
        if (isNil(resourceLock?.owner)) {
            return false;
        }

        return resourceLock.owner.id === eflowUser.id;
    }, [resourceLock]);

    if (isNil(resourceLock)) {
        return <LoadingIndicator className="rule-loading" centered />;
    }

    return (
        <DataPointContextProvider dataPointId={id} readOnly={!isLockOwner}>
            <DataPointFileUploaderModalProvider>
                <div className="data-point-container">
                    <DataPointHeader />

                    {!isLockOwner && <ResourceLockedWarning resourceType="Data Point" owner={resourceLock.owner} />}

                    <DataPointSettings />
                </div>
            </DataPointFileUploaderModalProvider>
        </DataPointContextProvider>
    );
};

export default DataPoint;
