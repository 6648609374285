import React, { useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import FormInput from "Components/FormInput/FormInput";
import FormTextArea from "Components/FormTextArea/FormTextArea";
import { debounce } from "lodash";
import { UpdateCollectionRequest } from "types/requests";
import { sleep } from "utils/utils";
import { useCollection } from "./CollectionContext";
import CollectionService from "Services/collection.service";

const schema = yup.object().shape({
    id: yup.string().required("Id is required"),
    name: yup.string().required("Name is required"),
    description: yup.string().optional().nullable()
});

interface CollectionForm {
    id: string;
    name: string;
    description: string;
}

const DEBOUCE_TRIGGER_SAVE = 500;

const CollectionGeneralInfo = () => {
    const { collection, readOnly, setSaving, updateCollection } = useCollection();

    const {
        register,
        watch,
        setValue,
        formState: { errors }
    } = useForm<CollectionForm>({
        resolver: yupResolver(schema),
        mode: "onBlur",
        reValidateMode: "onSubmit",
        defaultValues: {
            id: collection.id,
            name: collection.name ?? "",
            description: collection.description ?? ""
        }
    });

    useEffect(() => {
        setValue("id", collection.id);
        setValue("name", collection.name);
        setValue("description", collection.description);
    }, [collection.id]);

    const saveCollection = async (form: CollectionForm) => {
        try {
            const isValid = await schema.isValid(form);

            if (!isValid) {
                return;
            }

            setSaving(true);

            const request: UpdateCollectionRequest = {
                name: form.name,
                description: form.description
            };

            const updatedCollection = await CollectionService.updateCollection(form.id, request);

            updateCollection(updatedCollection);
        } finally {
            await sleep(250);
            setSaving(false);
        }
    };

    useEffect(() => {
        if (readOnly) {
            return;
        }

        const subscription = watch(debounce(saveCollection, DEBOUCE_TRIGGER_SAVE));

        return () => subscription.unsubscribe();
    }, [watch, readOnly]);

    return (
        <div className="collection-section-content collection-general-info">
            <form>
                <FormInput
                    labelText="Name"
                    id="name"
                    name="name"
                    type="text"
                    register={register("name")}
                    error={errors?.name}
                    disabled={readOnly}
                />

                <FormTextArea
                    labelText="Description"
                    id="description"
                    name="description"
                    rows={6}
                    register={register("description")}
                    error={errors?.description}
                    disabled={readOnly}
                />
            </form>
        </div>
    );
};

export default CollectionGeneralInfo;
