import React from "react";
import Input from "Components/Input";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";

interface CollectionAddHeaderProps {
    search: string;
    onSearch: (value: string) => void;
}

const CollectionAddHeader = ({ search, onSearch }: CollectionAddHeaderProps) => {
    const handleSearch = e => {
        onSearch(e.target.value);
    };

    return (
        <div className="collection-add-header">
            <Input
                className="search-bar"
                placeholder="Search rules"
                onChange={handleSearch}
                value={search}
                icon={faSearch}
            />
        </div>
    );
};

export default CollectionAddHeader;
