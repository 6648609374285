import { faFilter } from "@fortawesome/pro-solid-svg-icons";
import Input from "Components/Input";
import React, { useMemo, useState } from "react";
import { LocationModel } from "types/models";
import { isNilOrEmpty } from "utils/utils";
import { CollectionExploreResultModel } from "./CollectionExploreSingleYear";
import CollectionExploreTableRow from "./CollectionExploreTableRow";

interface CollectionExploreTableProps {
    results: CollectionExploreResultModel[];
    selectedLocations: LocationModel[];
}

const CollectionExploreTable = ({ results, selectedLocations }: CollectionExploreTableProps) => {
    const [searchFilterString, setSearchFilterString] = useState<string>(null);

    const selectedResults: CollectionExploreResultModel[] = useMemo(() => {
        if (!isNilOrEmpty(results) && !isNilOrEmpty(selectedLocations)) {
            const selectedLocationResults = results.filter(r => selectedLocations.some(l => l.id === r.location.id));

            if (isNilOrEmpty(searchFilterString)) {
                return selectedLocationResults;
            }

            return selectedLocationResults
                .map(r => {
                    return {
                        ...r,
                        results: r.results.filter(rr =>
                            rr.rule.toLowerCase().includes(searchFilterString.toLowerCase())
                        )
                    };
                })
                .filter(r => !isNilOrEmpty(r.results));
        }
        return [];
    }, [results, selectedLocations, searchFilterString]);

    return (
        <div className="results-table">
            <table>
                <thead>
                    <tr>
                        <th style={{ width: "30%" }}>Location/rule</th>
                        <th style={{ width: "70%" }} className="centered-cell">
                            % Success
                        </th>
                    </tr>
                </thead>
            </table>
            <table>
                <thead>
                    <tr>
                        <th style={{ width: "30%" }}>
                            <Input
                                placeholder="Filter by rule name"
                                icon={faFilter}
                                value={searchFilterString}
                                onChange={e => setSearchFilterString(e.target.value)}
                            />
                        </th>
                        <th className="centered-cell score-header">Magnitude</th>
                        <th className="centered-cell score-header">Duration</th>
                        <th className="centered-cell score-header">Count</th>
                        <th className="centered-cell score-header">Independence</th>
                        <th className="centered-cell score-header">Summary</th>
                    </tr>
                </thead>

                <tbody>
                    {selectedResults.map(r => {
                        return <CollectionExploreTableRow key={r.location.id} result={r} />;
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default CollectionExploreTable;
