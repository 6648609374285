import { faArrowAltSquareLeft, faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React, { Fragment } from "react";
import { Breadcrumb, Breadcrumbs } from "./types";

import "./FileSystem.scss";

interface ExplorerBreadcrumbsProps {
    breadcrumbs: Breadcrumbs;
    currentViewIndex: number;
    containerClassName?: string;
    onBack: () => void;
    onBreadcrumbSelected: (breadcrumb: Breadcrumb) => void;
}

const ExplorerBreadcrumbs = ({
    breadcrumbs,
    currentViewIndex,
    containerClassName,
    onBack,
    onBreadcrumbSelected
}: ExplorerBreadcrumbsProps) => {
    const handleBreadcrumbSelected = (breadcrumb: Breadcrumb) => {
        if (currentViewIndex === breadcrumb.index) {
            return;
        }

        onBreadcrumbSelected(breadcrumb);
    };

    const canGoBack = currentViewIndex != 0;

    return (
        <div className={clsx("file-system--breadcrumbs", containerClassName)}>
            <FontAwesomeIcon
                className={clsx("file-system--breadcrumbs-icon", { disabled: !canGoBack })}
                icon={faArrowAltSquareLeft}
                onClick={onBack}
            />

            {breadcrumbs.map((breadcrumb, index) => [
                <Fragment key={index}>
                    <span
                        onClick={() => handleBreadcrumbSelected(breadcrumb)}
                        className={clsx("file-system--breadcrumbs-label", {
                            current: currentViewIndex === breadcrumb.index
                        })}
                    >
                        {breadcrumb.label}
                    </span>

                    {index !== breadcrumbs.length - 1 && (
                        <FontAwesomeIcon className="file-system--breadcrumbs-divider" icon={faChevronRight} />
                    )}
                </Fragment>
            ])}
        </div>
    );
};

export default ExplorerBreadcrumbs;
