import React from "react";
import ERPIcon, { ERPIconProps } from ".";
import { faLocationDot } from "@fortawesome/pro-regular-svg-icons";

type DataPointIconProps = Omit<ERPIconProps, "icon">;

const DataPointIcon = (props: DataPointIconProps) => {
    return <ERPIcon className="data-point-icon" icon={faLocationDot} {...props} />;
};

export default DataPointIcon;
