import React, { useMemo } from "react";
import { DataPointModel } from "types/models";
import { fullname, isNilOrEmpty } from "utils/utils";
import SearchResultRow from "./SearchResultRow";
import { useHistory } from "react-router-dom";
import { useSort } from "Components/SortableHeader/useSort";
import SortableHeader from "Components/SortableHeader";
import EmptyRow from "Components/EmptyRow";
import LoadingRow from "Scenes/Explorer/LoadingRow";
import { isNil } from "lodash";

interface SearchResultsTableProps {
    searchResults: DataPointModel[];
    isLoading: boolean;
    onDataPointClicked?: (dataPoint: DataPointModel) => void;
}

const SearchResultsTable = ({ searchResults, isLoading, onDataPointClicked }: SearchResultsTableProps) => {
    const history = useHistory();

    const handleResultNavigation = (result: DataPointModel) => {
        if (!isNil(onDataPointClicked)) {
            onDataPointClicked(result);
            return;
        }

        history.push(`/data-point/${result.id}`);
    };

    const results = useMemo(() => {
        return searchResults.sort((a, b) => a?.location?.name?.localeCompare(b?.location?.name));
    }, [searchResults]);

    const { sortedItems, handleSort, sortContext } = useSort(results);

    const isEmpty = !isLoading && isNilOrEmpty(searchResults);

    return (
        <div className="search--table">
            <table>
                <thead>
                    <tr>
                        <th style={{ width: 50 }}>{/* Row Icon */}</th>

                        <SortableHeader
                            label="Name"
                            sortKey="name"
                            context={sortContext}
                            style={{ width: "30%", textAlign: "left" }}
                            sorter={(d: DataPointModel) => d.name?.toLowerCase() ?? ""}
                            onSort={handleSort}
                        />

                        <SortableHeader
                            label="Type"
                            sortKey="type"
                            context={sortContext}
                            style={{ width: 100 }}
                            sorter={(d: DataPointModel) => d.type?.toLowerCase() ?? ""}
                            onSort={handleSort}
                        />

                        <SortableHeader
                            label="Created by"
                            sortKey="createdBy"
                            context={sortContext}
                            style={{ width: 100 }}
                            sorter={(d: DataPointModel) => fullname(d.createdBy)}
                            onSort={handleSort}
                            centered
                        />

                        <SortableHeader
                            label="Last modified"
                            sortKey="updatedAt"
                            context={sortContext}
                            style={{ width: 100 }}
                            sorter={(d: DataPointModel) => new Date(d.updatedAt).getTime()}
                            onSort={handleSort}
                            centered
                        />

                        <SortableHeader
                            label="Location"
                            sortKey="location"
                            context={sortContext}
                            style={{ width: 210 }}
                            sorter={(d: DataPointModel) => d?.location?.name?.toLowerCase() ?? ""}
                            onSort={handleSort}
                            centered
                        />

                        <th style={{ width: 100 }}>Data types</th>
                    </tr>
                </thead>

                <tbody>
                    {isEmpty && <EmptyRow message="No data points match your search" colSpan={7} />}

                    {isLoading && <LoadingRow collSpan={7} />}

                    {!isLoading &&
                        sortedItems.map(result => {
                            return (
                                <SearchResultRow
                                    key={result.id}
                                    result={result}
                                    onResultNavigation={handleResultNavigation}
                                />
                            );
                        })}
                </tbody>
            </table>
        </div>
    );
};

export default SearchResultsTable;
