import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { PluginFieldProps, PluginForm } from "./types";
import Label from "Components/Label";
import PluginParameterCurveRow from "./PluginParameterCurveRow";
import PluginParameterCurveAddRow from "./PluginParameterCurveAddRow";
import { CurveOptions } from "types/plugin";
import PluginParameterCurveChart from "./PluginParameterCurveChart";
import FileSelectorButton from "Components/FileSelector/FileSelectorButton";
import { CSVParser } from "Services/csv-parser";
import { isNilOrEmpty } from "utils/utils";

interface PluginParameterCurveProps extends PluginFieldProps {
    onChange?: (field: string, value: number[][]) => void;
}

const PluginParameterCurve = ({ id, definition, field, control, disabled, onChange }: PluginParameterCurveProps) => {
    const { getValues } = useFormContext<PluginForm>();

    const handleRowAdded = () => {
        const values: number[][] = getValues(`${definition.id}.${field.id}`);

        const next = [...(values ?? [])];

        next.push([0, 0]);

        onChange(field.id, next);
    };

    const handleValueChanged = (rowIndex: number, index: number, value: number) => {
        const values: number[][] = getValues(`${definition.id}.${field.id}`);

        const next = [...(values ?? [])];

        next[rowIndex][index] = value;

        onChange(field.id, next);
    };

    const handleRowRemoved = (rowIndex: number) => {
        const values: number[][] = getValues(`${definition.id}.${field.id}`);

        const next = [...(values ?? [])];

        next.splice(rowIndex, 1);

        onChange(field.id, next);
    };

    const handleImportCurve = async (file: File) => {
        const data = await file.text();

        const csvParser = new CSVParser();

        const parsedData = csvParser.parseToArray(data, true);

        const rows = parsedData.map(r => [parseFloat(r[0]), parseFloat(r[1])]);

        if (!isNilOrEmpty(rows)) {
            onChange(field.id, rows);
        }
    };

    const options: CurveOptions = field.control.options as CurveOptions;

    return (
        <Controller
            control={control}
            name={`${definition.id}.${field.id}`}
            render={({ field: _field }) => {
                const rows: number[][] = _field.value ?? [];

                return (
                    <div className="curve-parameter-field-container">
                        <div className="curve-parameter-field">
                            <Label className="plugin-definition-field-label">{field.name}</Label>

                            <table className="curve-parameter-field--table">
                                <thead>
                                    <tr>
                                        <td>{options?.x_label ?? "X"}</td>

                                        <td>{options?.y_label ?? "Y"}</td>
                                    </tr>
                                </thead>

                                <tbody>
                                    {rows.map((row, idx) => {
                                        return (
                                            <PluginParameterCurveRow
                                                key={`${id}-curve-row-${idx}`}
                                                id={id}
                                                values={row}
                                                disabled={disabled}
                                                disableRemove={options?.disable_remove ?? false}
                                                disableX={options?.disable_x ?? false}
                                                disableY={options?.disable_y ?? false}
                                                onChanged={(index, val) => handleValueChanged(idx, index, val)}
                                                onRemoved={() => handleRowRemoved(idx)}
                                            />
                                        );
                                    })}

                                    {((!options?.disable_add && !disabled) ?? true) && (
                                        <PluginParameterCurveAddRow onRowAdded={handleRowAdded} />
                                    )}
                                </tbody>
                            </table>

                            {options?.allow_import && !disabled && (
                                <FileSelectorButton
                                    className="curve-parameter-import-button"
                                    label="Import curve from file"
                                    disabled={disabled}
                                    onFileSelected={handleImportCurve}
                                    accept=".csv"
                                />
                            )}
                        </div>

                        <PluginParameterCurveChart options={options} values={rows} />
                    </div>
                );
            }}
        />
    );
};

export default PluginParameterCurve;
