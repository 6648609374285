import Select, { SelectOption } from "Components/Select/Select";
import { isNil } from "lodash";
import React, { useMemo } from "react";

//FIXME: Ideally this would inherit SelectProps
interface YearSelectProps {
    id: string;
    label?: string;
    value: number;
    className?: string;
    disabled?: boolean;
    isClearable?: boolean;
    onYearChanged: (year: number) => void;
}

const YearSelect = ({ id, label, value, className, disabled, isClearable, onYearChanged }: YearSelectProps) => {
    const years = useMemo(() => {
        const _years: SelectOption[] = [];

        const now = new Date();

        const year = now.getFullYear();

        for (let i = year; i >= 1800; i--) {
            const day = i.toString();

            _years.push({ label: day, value: day });
        }

        return _years;
    }, []);

    const year = useMemo(() => {
        if (isNil(value)) {
            return null;
        }

        const matching = years.find(m => m.value === value.toString());

        return matching;
    }, [value]);

    return (
        <Select
            id={id}
            labelText={label}
            placeholder="Year"
            value={year}
            values={years}
            onSelected={(option: SelectOption) => {
                const value = !isNil(option?.value) ? Number(option.value) : null;

                if (value !== Number(year?.value)) {
                    onYearChanged(value);
                }
            }}
            isClearable={isClearable}
            isSearchable={false}
            menuClassName={className}
            disabled={disabled}
        />
    );
};

export default YearSelect;
