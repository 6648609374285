import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Button from "Components/Button";
import React from "react";

interface ResultsInfoProps {
    message: string;
    showButton: boolean;
    buttonText?: string;
    icon?: IconProp;
    onClick?: () => void;
}

const ResultsInfo = ({ message, showButton, buttonText, icon, onClick }: ResultsInfoProps) => {
    return (
        <div className="results-message">
            {message}

            {showButton && (
                <Button variant="secondary" onClick={onClick} icon={icon}>
                    {buttonText}
                </Button>
            )}
        </div>
    );
};

export default ResultsInfo;
