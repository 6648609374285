import React from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import clsx from "clsx";

import "./Input.scss";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { isNil } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type InputProps = {
    register?: UseFormRegisterReturn;
    icon?: IconProp;
    iconClassName?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const Input = (props: InputProps) => {
    const { className, disabled, icon, iconClassName, ...rest } = props;

    return (
        <div className={clsx("form-control", "erp-input-container", { disabled: disabled === true }, className)}>
            {!isNil(icon) && <FontAwesomeIcon icon={icon} className={clsx("btn-icon-before", iconClassName)} />}

            <input className={clsx("erp-input")} disabled={disabled} {...props.register} {...rest} />
        </div>
    );
};

export default Input;
