import React from "react";
import { RuleModel } from "types/models";
import ERPModal from "Components/Modals/ERPModal";
import { ModalProps } from "Components/Modals/types";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import FormActions from "Components/FormActions";
import Button from "Components/Button";
import LoadingButton from "Components/LoadingButton";
import FormInput from "Components/FormInput/FormInput";
import { UpdateRuleNameRequest } from "types/requests";
import { Toast } from "Components/Toast";
import RuleService from "Services/rule.service";

interface RuleModalProps extends ModalProps {
    context: RuleModalContext;
    onRuleUpdated: (rule: RuleModel) => void;
}

export interface RuleModalContext {
    rule: RuleModel;
}

const schema = yup.object().shape({
    name: yup.string().required("Name is required")
});

interface RuleForm {
    name: string;
}

const RuleModal = ({ context, show, onClose, onRuleUpdated }: RuleModalProps) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isDirty, isSubmitting }
    } = useForm<RuleForm>({
        resolver: yupResolver(schema),
        defaultValues: {
            name: context.rule.name ?? ""
        }
    });

    const handleFormSubmit = async (data: RuleForm) => {
        const request: UpdateRuleNameRequest = {
            name: data.name
        };

        const updatedRule = await RuleService.updateRuleName(context.rule.id, request);

        Toast.success(`Successfully updated rule ${data.name}`);

        onRuleUpdated(updatedRule);

        onClose();
    };

    return (
        <ERPModal title="Edit rule" show={show} onClose={onClose}>
            <div>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <FormInput
                        labelText="Name"
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Folder name"
                        register={register("name")}
                        error={errors?.name}
                        disabled={isSubmitting}
                    />

                    <FormActions>
                        <Button variant="secondary" disabled={isSubmitting} onClick={onClose}>
                            Cancel
                        </Button>

                        <LoadingButton disabled={!isDirty} isLoading={isSubmitting} variant="primary" type="submit">
                            Update
                        </LoadingButton>
                    </FormActions>
                </form>
            </div>
        </ERPModal>
    );
};

export default RuleModal;
