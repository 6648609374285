import React from "react";
import MonthSelect from "Components/MonthSelect";
import { Controller, useFormContext } from "react-hook-form";
import { PluginFieldProps, PluginForm } from "./types";
import DaySelect from "Components/DaySelect";
import Label from "Components/Label";
import { SeasonSelectOptions } from "types/plugin";
import YearSelect from "Components/YearSelect";

interface PluginParameterSeasonSelectProps extends PluginFieldProps {
    onChange?: (field: string, value: Record<string, string | number>) => void;
}

const DEFAULT_OPTIONS: SeasonSelectOptions = {
    day: true,
    month: true,
    year: false
};

const PluginParameterSeasonSelect = ({
    id,
    definition,
    field,
    control,
    disabled,
    onChange
}: PluginParameterSeasonSelectProps) => {
    const { getValues } = useFormContext<PluginForm>();

    const handleYearChanged = (year: number) => {
        const currentDay = getValues(`${definition.id}.${field.id}.day`);
        const currentMonth = getValues(`${definition.id}.${field.id}.month`);

        onChange(field.id, { day: currentDay, month: currentMonth, year: year });
    };

    const handleMonthChanged = (month: string) => {
        const currentDay = getValues(`${definition.id}.${field.id}.day`);
        const currentYear = getValues(`${definition.id}.${field.id}.year`);

        onChange(field.id, { day: currentDay, month: month, year: currentYear });
    };

    const handleDayChanged = (day: number) => {
        const currentMonth = getValues(`${definition.id}.${field.id}.month`);
        const currentYear = getValues(`${definition.id}.${field.id}.year`);

        onChange(field.id, { day: day, month: currentMonth, year: currentYear });
    };

    const options: SeasonSelectOptions = (field.control.options as SeasonSelectOptions) ?? DEFAULT_OPTIONS;

    return (
        <div className="season-select-parameter-field">
            <Label className="plugin-definition-field-label">{field.name}</Label>

            <div className="season-select-parameter-field--inputs">
                {options.day && (
                    <Controller
                        control={control}
                        name={`${definition.id}.${field.id}.day`}
                        render={({ field: _field }) => {
                            return (
                                <DaySelect
                                    id={`${id}.day`}
                                    value={_field.value}
                                    className="season-select-day"
                                    disabled={disabled}
                                    onDayChanged={handleDayChanged}
                                />
                            );
                        }}
                    />
                )}

                {options.month && (
                    <Controller
                        control={control}
                        name={`${definition.id}.${field.id}.month`}
                        render={({ field: _field }) => {
                            return (
                                <MonthSelect
                                    id={`${id}.month`}
                                    value={_field.value}
                                    className="season-select-month"
                                    disabled={disabled}
                                    onMonthChanged={handleMonthChanged}
                                />
                            );
                        }}
                    />
                )}

                {options.year && (
                    <Controller
                        control={control}
                        name={`${definition.id}.${field.id}.year`}
                        render={({ field: _field }) => {
                            return (
                                <YearSelect
                                    id={`${id}.year`}
                                    value={_field.value}
                                    className="season-select-year"
                                    disabled={disabled}
                                    onYearChanged={handleYearChanged}
                                />
                            );
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default PluginParameterSeasonSelect;
