import React from "react";
import LoadingIndicator from "Components/LoadingIndicator";

interface LoadingRowProps {
    collSpan: number;
}

const LoadingRow = ({ collSpan }: LoadingRowProps) => {
    return (
        <tr>
            <td className="centered-cell" colSpan={collSpan}>
                <LoadingIndicator centered />
            </td>
        </tr>
    );
};

export default LoadingRow;
