/* eslint-disable react/prop-types */

import React, { useState, useEffect, useContext } from "react";
import createAuth0Client, {
    Auth0Client,
    Auth0ClientOptions,
    getIdTokenClaimsOptions,
    GetTokenSilentlyOptions,
    GetTokenWithPopupOptions,
    IdToken,
    LogoutOptions,
    PopupLoginOptions,
    RedirectLoginOptions,
    RedirectLoginResult
} from "@auth0/auth0-spa-js";
import DataAPI from "../api/data.api";
import { GroupModel, User } from "types/models";
import UserService from "../Services/user.service";
import Config from "Config";
import EFLOWTelemetry from "../telemetry/telemetry";
import { isNil } from "lodash";

export interface Auth0RedirectState {
    targetUrl?: string;
}

export type Auth0User = Omit<IdToken, "__raw">;

export interface AppState {
    backTo: string;
}

interface Auth0ContextProps {
    user?: Auth0User;
    eflowUser?: User;
    isAuthenticated: boolean;
    isPopupOpen: boolean;
    loading: boolean;
    userClaim?: IdToken;
    token: string;
    loginWithPopup(o?: PopupLoginOptions): Promise<void>;
    handleRedirectCallback(): Promise<RedirectLoginResult>;
    getIdTokenClaims(o?: getIdTokenClaimsOptions): Promise<IdToken>;
    loginWithRedirect(o?: RedirectLoginOptions): Promise<void>;
    getTokenSilently(o?: GetTokenSilentlyOptions): Promise<string | undefined>;
    getTokenWithPopup(o?: GetTokenWithPopupOptions): Promise<string | undefined>;
    getLogoutUrl: () => string;
    logout(o?: LogoutOptions): void;
    updateUser: (user: User) => void;
    updateUserGroup: (group: GroupModel) => void;
    addUserGroup: (group: GroupModel) => void;
    setActiveGroup: (group: GroupModel) => Promise<void>;
    askForLogin: (loginHint: string, state: AppState) => Promise<void>;
}

interface Auth0ProviderOptions {
    children: React.ReactElement;
    onRedirectCallback(appState?: AppState): void;
}

const DEFAULT_REDIRECT_CALLBACK = () => {
    window.history.replaceState({}, document.title, window.location.pathname);
};

export const Auth0Context = React.createContext<Auth0ContextProps | null>(null);

export const useAuth0 = () => useContext(Auth0Context);

export const Auth0Provider = ({
    children,
    onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
    ...initOptions
}: Auth0ProviderOptions & Auth0ClientOptions) => {
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [user, setUser] = useState<Auth0User>();
    const [userClaim, setUserClaim] = useState<IdToken>(undefined);
    const [auth0Client, setAuth0] = useState<Auth0Client>();
    const [loading, setLoading] = useState(true);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [eflowUser, seteflowUser] = useState<User>(null);

    async function getMe() {
        try {
            const me = await UserService.me();

            if (isNil(me)) {
                return;
            }

            EFLOWTelemetry.setUser(me.auth0Id);

            const groups = await UserService.getMyGroups();

            me.groups = groups ?? [];

            seteflowUser(me);
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    useEffect(() => {
        const initAuth0 = async () => {
            try {
                const auth0FromHook = await createAuth0Client(initOptions);

                setAuth0(auth0FromHook);

                if (window.location.search.includes("code=") && window.location.search.includes("state=")) {
                    try {
                        const { appState } = await auth0FromHook.handleRedirectCallback();

                        onRedirectCallback(appState);
                    } catch (ex) {
                        console.log("invalid app state", ex);
                    }
                }

                const isAuthenticated = await auth0FromHook.isAuthenticated();

                if (isAuthenticated) {
                    const user = await auth0FromHook.getUser();
                    const claim = await auth0FromHook.getIdTokenClaims();

                    DataAPI.setToken(claim.__raw);

                    setUser(user);
                    setUserClaim(claim);

                    await getMe();
                }

                setIsAuthenticated(isAuthenticated);
            } catch (error) {
                console.log(error);

                throw error;
            } finally {
                setLoading(false);
            }
        };

        initAuth0();
    }, []);

    const updateUser = async (user: User) => {
        seteflowUser(user);
    };

    const updateUserGroup = (group: GroupModel) => {
        const next: User = {
            ...eflowUser,
            groups: eflowUser.groups.map(g => {
                if (g.id === group.id) {
                    return {
                        ...g,
                        name: group.name
                    };
                }

                return g;
            })
        };

        updateUser(next);
    };

    const addUserGroup = (group: GroupModel) => {
        const next: User = {
            ...eflowUser,
            groups: [...eflowUser.groups, group]
        };

        updateUser(next);
    };

    const askForLogin = async (loginHint: string, appState: AppState): Promise<void> => {
        await auth0Client.loginWithRedirect({
            login_hint: loginHint,
            appState: appState,
            prompt: "login"
        });
    };

    const loginWithPopup = async (params = {}) => {
        try {
            setIsPopupOpen(true);

            try {
                await auth0Client.loginWithPopup(params);
            } catch (error) {
                console.error(error);
            } finally {
                setIsPopupOpen(false);
            }

            const user = await auth0Client.getUser();

            setUser(user);
            setIsAuthenticated(true);
        } catch (error) {
            console.log(error);

            throw error;
        }
    };

    const handleRedirectCallback = async () => {
        try {
            setLoading(true);

            const result = await auth0Client.handleRedirectCallback();

            const user = await auth0Client.getUser();

            setLoading(false);
            setIsAuthenticated(true);
            setUser(user);

            return result;
        } catch (error) {
            console.log(error);

            throw error;
        }
    };

    const loginWithRedirect = (options?: RedirectLoginOptions) => auth0Client.loginWithRedirect({ options });

    const getTokenSilently = (options?: GetTokenSilentlyOptions) => auth0Client.getTokenSilently(options);

    const logout = (options?: LogoutOptions) => {
        DataAPI.setToken(null);

        EFLOWTelemetry.clearUser();

        auth0Client.logout({
            ...options,
            client_id: Config.auth0.clientId,
            returnTo: Config.auth0.redirectUri,
            federated: false
        });
    };

    const getIdTokenClaims = (options?: getIdTokenClaimsOptions) => auth0Client.getIdTokenClaims(options);

    const getTokenWithPopup = (options?: GetTokenWithPopupOptions) => auth0Client.getTokenWithPopup(options);

    const getLogoutUrl = () =>
        auth0Client.buildLogoutUrl({
            client_id: Config.auth0.clientId,
            returnTo: Config.auth0.redirectUri,
            federated: false
        });

    const setActiveGroup = async (group: GroupModel) => {
        await UserService.setActiveGroup(group.id);

        const next: User = {
            ...eflowUser,
            currentGroupId: group.id
        };

        seteflowUser(next);
    };

    return (
        <Auth0Context.Provider
            value={{
                isAuthenticated,
                user,
                eflowUser,
                loading,
                isPopupOpen,
                loginWithPopup,
                handleRedirectCallback,
                userClaim,
                token: userClaim?.__raw ?? null,
                getIdTokenClaims: getIdTokenClaims,
                loginWithRedirect: loginWithRedirect,
                getTokenSilently: getTokenSilently,
                getTokenWithPopup,
                logout: logout,
                getLogoutUrl,
                updateUser,
                updateUserGroup,
                addUserGroup,
                setActiveGroup,
                askForLogin
            }}
        >
            {children}
        </Auth0Context.Provider>
    );
};
