import React, { useState } from "react";
import Input from "Components/Input";
import Button from "Components/Button";
import { faPlus, faSearch } from "@fortawesome/pro-solid-svg-icons";
import { CreateDataPointRequest } from "types/requests";
import DataPointService from "Services/data-point.service";
import { useHistory } from "react-router-dom";
import { DataPointType } from "types/models";

interface DataLibraryHeaderProps {
    showNewDataButton: boolean;
    search: string;
    onSearch: (value: string) => void;
}

const DataLibraryHeader = ({ showNewDataButton, search, onSearch }: DataLibraryHeaderProps) => {
    const history = useHistory();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleNewDataPoint = async () => {
        try {
            setIsLoading(true);

            const request: CreateDataPointRequest = {
                name: "New Data Point",
                type: DataPointType.AUTOMATIC
            };

            const dataPoint = await DataPointService.createDataPoint(request);

            history.push(`/data-point/${dataPoint.id}`);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearch = e => {
        onSearch(e.target.value);
    };

    return (
        <div className="data-library-header">
            {showNewDataButton && (
                <Button onClick={handleNewDataPoint} icon={faPlus} className="new-button" disabled={isLoading}>
                    Data
                </Button>
            )}

            {!showNewDataButton && <div></div>}

            <Input
                className="search-bar"
                placeholder="Search data library"
                onChange={handleSearch}
                value={search}
                icon={faSearch}
            />

            <div></div>
        </div>
    );
};

export default DataLibraryHeader;
