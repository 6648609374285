import Explorer from "Scenes/Explorer";
import Rule from "Scenes/Rule";
import ManageAccount from "Scenes/ManageAccount";
import Register from "Scenes/Register";
import DataLibrary from "Scenes/DataLibrary";
import { EflowRouteMap } from "./types";
import DataPoint from "Scenes/DataPoint";
import Collection from "Scenes/Collection";

const AppRoutes: EflowRouteMap = Object.freeze({
    Explorer: {
        route: "/explorer",
        scene: Explorer,
        exact: true,
        authenticated: true,
        admin: false
    },
    DataLibrary: {
        route: "/data-library",
        scene: DataLibrary,
        exact: true,
        authenticated: true,
        admin: false
    },
    DataPoint: {
        route: "/data-point/:id",
        scene: DataPoint,
        exact: true,
        authenticated: true,
        admin: false
    },
    Rule: {
        route: "/rule/:id",
        scene: Rule,
        exact: true,
        authenticated: true,
        admin: false
    },
    Collection: {
        route: "/collection/:id",
        scene: Collection,
        exact: true,
        authenticated: true,
        admin: false
    },
    ManageAccount: {
        route: "/manage-account",
        scene: ManageAccount,
        exact: true,
        authenticated: true,
        admin: false
    },
    Register: {
        route: "/register",
        scene: Register,
        exact: true,
        authenticated: false,
        admin: false
    }
});

export default AppRoutes;
