import React, { useState } from "react";
import { CollectionExploreRuleResultModel, ExplorePeriodModel } from "types/models";
import { PluginId, ReportingTiers } from "types/plugin";
import { getResultPeriod } from "utils/collection-explore.utils";
import CollectionExploreDownloads from "./CollectionExploreDownloads";
import CollectionExploreFilter from "./CollectionExploreFilter";
import CollectionExploreSingleYear from "./CollectionExploreSingleYear";
import { ExploreTabType } from "./ExploreTabs";

interface CollectionExploreBodyProps {
    results: CollectionExploreRuleResultModel[];
    activeTab: ExploreTabType;
}

export interface CollectionExploreFilterModel {
    plugins: PluginId[];
    reportingTiers: ReportingTiers[];
}

export interface CollectionExplorePeriodModel extends ExplorePeriodModel {
    singleYear: number;
}

const DEFAULT_PLUGINS_FILTER = [PluginId.LOWFLOW, PluginId.FRESHES, PluginId.OVERSUPPLY, PluginId.MULTIYEAR_FRESHES];
const DEFAULT_TIERS_FILTER = [
    ReportingTiers.LEVEL_1,
    ReportingTiers.LEVEL_2,
    ReportingTiers.LEVEL_3,
    ReportingTiers.LEVEL_4
];

const CollectionExploreBody = ({ results, activeTab }: CollectionExploreBodyProps) => {
    const [filterSettings, setFilterSettings] = useState<CollectionExploreFilterModel>({
        plugins: DEFAULT_PLUGINS_FILTER,
        reportingTiers: DEFAULT_TIERS_FILTER
    });

    const [selectedResultPeriod, setSelectedResultPeriod] = useState<CollectionExplorePeriodModel>(
        getResultPeriod(results)
    );

    const showFilter = activeTab === ExploreTabType.SINGLE_YEAR || activeTab === ExploreTabType.MULTI_YEAR;

    return (
        <div className="collection-explore-body">
            {showFilter && (
                <CollectionExploreFilter
                    filterSettings={filterSettings}
                    selectedResultPeriod={selectedResultPeriod}
                    showSingleYear={activeTab === ExploreTabType.SINGLE_YEAR}
                    onFilterSettingsChange={setFilterSettings}
                    onSelectedResultsPeriodChange={setSelectedResultPeriod}
                />
            )}

            {activeTab === ExploreTabType.SINGLE_YEAR && (
                <CollectionExploreSingleYear
                    results={results}
                    filterSettings={filterSettings}
                    resultsPeriod={selectedResultPeriod}
                />
            )}

            {activeTab === ExploreTabType.DOWNLOADS && <CollectionExploreDownloads />}
        </div>
    );
};

export default CollectionExploreBody;
