import { useDataPointFileUploader } from "Components/FileUploader/DataPointFileUploaderContext";
import Label from "Components/Label";
import DataPointService from "Services/data-point.service";
import React, { useMemo, useState } from "react";
import { DataPointDataModel, DataType } from "types/models";
import { useDataPoint } from "./DataPointContext";
import FileSelectorDrag from "Components/FileSelector/FileSelectorDrag";
import Button from "Components/Button";
import { faEye } from "@fortawesome/pro-solid-svg-icons";
import DataPointDataPreviewModal from "./DataPointDataPreviewModal";
import DataPointManualDataFile from "./DataPointManualDataFile";
import { useConfirmation } from "Components/ConfirmDialog/ConfirmationContext";

const ALLOWED_DATA_TYPES = [
    DataType.FLOW,
    DataType.DEPTH,
    DataType.TEMPERATURE,
    DataType.RAINFALL,
    DataType.CLIMATE,
    DataType.EVAPORATION,
    DataType.SALINITY,
    DataType.PH,
    DataType.TURBIDITY,
    DataType.STORAGE_VOLUME,
    DataType.STORAGE_DEPTH
];

const DataPointManualData = () => {
    const [showDataPreviewModal, setShowDataPreviewModal] = useState<boolean>(false);

    const { dataPoint, dataPointData, readOnly, updateDataPointData } = useDataPoint();
    const confirm = useConfirmation();

    const uploader = useDataPointFileUploader();

    const handleFileSelected = async (files: File[]) => {
        const result = await uploader({
            dataPointId: dataPoint.id,
            files: files
        });

        if (!result.success) {
            return;
        }

        const next = [...dataPointData, ...result.data];
        updateDataPointData(next);
    };

    const handleDataRemoved = async (dataFile: DataPointDataModel) => {
        const result = await confirm({
            title: "Warning",
            description: "Are you sure you want to permanently delete this data file?"
        });

        if (!result.success) {
            return;
        }

        await DataPointService.deleteDataPointData(dataPoint.id, dataFile.id);

        const next = dataPointData.filter(d => d.id !== dataFile.id);
        updateDataPointData(next);
    };

    const handleDataTypeChanged = async (dataFile: DataPointDataModel, dataType: DataType) => {
        if (dataFile.dataType === dataType) {
            return;
        }

        await DataPointService.updateDataPointDataOptions(dataPoint.id, dataFile.id, dataType);

        const next = dataPointData.map(df => {
            if (df.id === dataFile.id) {
                return {
                    ...df,
                    dataType: dataType
                };
            }

            return df;
        });

        updateDataPointData(next);
    };

    const avaliableDataTypes: DataType[] = useMemo(() => {
        const usedDataTypes = dataPointData.map(d => d.dataType);
        return ALLOWED_DATA_TYPES.filter(d => !usedDataTypes.some(ud => ud === d));
    }, [dataPointData]);

    return (
        <div className="data-point-file-section">
            <div className="data-point-file-section-name">
                <Label>Data</Label>

                <Button
                    icon={faEye}
                    className="preview-button"
                    disabled={dataPointData.length === 0}
                    onClick={() => setShowDataPreviewModal(true)}
                    variant="secondary"
                >
                    Preview data
                </Button>
            </div>

            {dataPointData.map(dataFile => {
                return (
                    <DataPointManualDataFile
                        avaliableDataTypes={avaliableDataTypes}
                        key={dataFile.id}
                        data={dataFile}
                        disabled={readOnly}
                        onDataTypeChanged={handleDataTypeChanged}
                        onDataRemoved={handleDataRemoved}
                    />
                );
            })}

            <FileSelectorDrag
                className="data-point-upload"
                allowMultiple={true}
                disabled={readOnly}
                accept=".csv"
                onFileSelected={handleFileSelected}
            />

            {showDataPreviewModal && (
                <DataPointDataPreviewModal
                    show={showDataPreviewModal}
                    data={dataPointData}
                    dataPoint={dataPoint}
                    onClose={() => setShowDataPreviewModal(false)}
                />
            )}
        </div>
    );
};

export default DataPointManualData;
