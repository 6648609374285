import React from "react";
import Button from "Components/Button";
import { faUpload } from "@fortawesome/pro-solid-svg-icons";
import Dropzone from "react-dropzone";
import clsx from "clsx";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import "./FileSelector.scss";

interface FileSelectorButtonProps {
    label: string;
    accept?: string | string[];
    disabled?: boolean;
    className?: string;
    icon?: IconProp;
    allowMultiple?: boolean;
    onFileSelected: (file: File | File[]) => void;
}

const FileSelectorButton = ({
    label,
    accept,
    disabled,
    className,
    icon = faUpload,
    allowMultiple = false,
    onFileSelected
}: FileSelectorButtonProps) => {
    const handleFileSelected = (files: File[]) => {
        if (files?.length === 0) {
            return;
        }

        if (allowMultiple) {
            onFileSelected(files);
        } else {
            onFileSelected(files[0]);
        }
    };

    return (
        <Dropzone noClick noKeyboard noDrag onDrop={handleFileSelected} accept={accept}>
            {({ getInputProps, open }) => (
                <div>
                    <Button
                        className={clsx("file-selector-btn", className)}
                        disabled={disabled}
                        onClick={open}
                        icon={icon}
                    >
                        {label}
                    </Button>

                    <input {...getInputProps()} />
                </div>
            )}
        </Dropzone>
    );
};

export default FileSelectorButton;
