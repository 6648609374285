import { faDatabase } from "@fortawesome/pro-solid-svg-icons";
import Button from "Components/Button";
import ERPIcon from "Components/ERPIcon";
import Select, { SelectOption } from "Components/Select/Select";
import { isNil } from "lodash";
import React, { useState } from "react";
import { DataPointModel, DataType } from "types/models";
import RuleDataPointSelectModal from "../../Scenes/Rule/RuleDataPointSelectModal";
import Humanize from "humanize-plus";
import { useHistory } from "react-router-dom";
import { useRule } from "../../Scenes/Rule/RuleContext";
import { Toast } from "Components/Toast";
import { useConfirmation } from "Components/ConfirmDialog/ConfirmationContext";
import NumericInput from "Components/NumericInput";

interface DataPointProps {
    dataPoint: DataPointModel;
    dataType: DataType;
    lag: number;
    showWarningOnChange?: boolean;
    disabled: boolean;
    showLag: boolean;
    onDataPointSelected: (nextDataPoint: DataPointModel, _existingDataPoint?: DataPointModel) => void;
    onDataPointDataTypeChanged: (nextDataType: DataType, _existingDataPoint?: DataPointModel) => void;
    onDataPointRemoved: (_existingDataPoint?: DataPointModel) => void;
    onDataPointLagChanged?: (nextLag: number, _existingDataPoint?: DataPointModel) => void;
}

const DataPoint = ({
    dataPoint,
    dataType,
    lag,
    showWarningOnChange,
    disabled,
    showLag,
    onDataPointSelected,
    onDataPointDataTypeChanged,
    onDataPointRemoved,
    onDataPointLagChanged
}: DataPointProps) => {
    const { rule } = useRule();
    const history = useHistory();
    const confirm = useConfirmation();

    const [showDataPointSelectModal, setShowDataPointSelectModal] = useState<boolean>(false);

    const handleDataPointSelected = async (nextDataPoint: DataPointModel) => {
        setShowDataPointSelectModal(false);

        if (nextDataPoint.dataTypes.length === 0) {
            Toast.error("The selected data point has no data");
            return;
        }

        if (isNil(dataPoint)) {
            onDataPointSelected(nextDataPoint);
            return;
        }

        if (dataPoint.id === nextDataPoint.id) {
            return;
        }

        if (showWarningOnChange) {
            const result = await confirm({
                title: "Warning",
                description: "All existing computation results for this rule will be lost. Do you wish to proceed?"
            });

            if (!result.success) {
                return;
            }
        }

        onDataPointSelected(nextDataPoint, dataPoint);
    };

    const hasDataPoint = !isNil(dataPoint) && !isNil(dataType) && !isNil(lag);

    return (
        <div className="rule-data-point">
            {hasDataPoint && (
                <div className="rule-data-point-container">
                    <ERPIcon icon={faDatabase} size="lg" className="data-point-icon" />
                    <table>
                        <thead>
                            <tr>
                                <th style={{ width: "30%", textAlign: "left" }}>Name</th>
                                <th style={{ width: 150 }}>Location</th>
                                <th style={{ width: 150 }}>Data type</th>
                                {showLag && <th style={{ width: 100 }}>Lag</th>}
                                <th style={{ width: 100 }}>Type</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>{dataPoint.name}</td>
                                <td>{dataPoint.location.name}</td>
                                <td>
                                    {
                                        <Select
                                            menuClassName="data-type-select"
                                            values={dataPoint.dataTypes.map(d => {
                                                return {
                                                    label: Humanize.capitalizeAll(d.split("_").join(" ")),
                                                    value: d
                                                };
                                            })}
                                            value={{
                                                label: Humanize.capitalizeAll(dataType.split("_").join(" ")),
                                                value: dataType
                                            }}
                                            onSelected={(d: SelectOption) =>
                                                onDataPointDataTypeChanged(d.value as DataType, dataPoint)
                                            }
                                            disabled={disabled}
                                        />
                                    }
                                </td>
                                {showLag && (
                                    <td>
                                        <NumericInput
                                            value={lag}
                                            onChange={nextValue => onDataPointLagChanged(nextValue, dataPoint)}
                                            unit="days"
                                            containerClassName="data-type-select"
                                        />
                                    </td>
                                )}
                                <td>{Humanize.capitalize(dataPoint.type)}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="data-point-button-group">
                        <Button onClick={() => setShowDataPointSelectModal(true)} disabled={disabled}>
                            Change
                        </Button>
                        <Button variant="other" onClick={() => history.push(`/data-point/${dataPoint.id}`)}>
                            Open
                        </Button>
                        <Button variant="attention" onClick={() => onDataPointRemoved(dataPoint)} disabled={disabled}>
                            Remove
                        </Button>
                    </div>
                </div>
            )}

            {!hasDataPoint && (
                <Button onClick={() => setShowDataPointSelectModal(true)} className="select-data-point-button">
                    Select data point
                </Button>
            )}

            {showDataPointSelectModal && (
                <RuleDataPointSelectModal
                    rule={rule}
                    show={showDataPointSelectModal}
                    onDataPointSelected={handleDataPointSelected}
                    onClose={() => setShowDataPointSelectModal(false)}
                />
            )}
        </div>
    );
};

export default DataPoint;
