import LoadingRow from "Scenes/Explorer/LoadingRow";
import React, { useMemo } from "react";
import { RuleModel } from "types/models";
import SortableHeader, { SortFunction } from "Components/SortableHeader";
import { HeaderSortContext } from "Components/SortableHeader/types";
import Checkbox from "Components/Checkbox";
import { fullname, isNilOrEmpty } from "utils/utils";
import { SelectionState } from "./types";
import CollectionRulesTableBulkActions from "./CollectionRulesTableBulkActions";
import EmptyRow from "Components/EmptyRow";
import CollectionRulesTableRow from "./CollectionRulesTableRow";

interface CollectionRulesTableProps {
    isLoading?: boolean;
    rules: RuleModel[];
    collectionRules: RuleModel[];
    selection: SelectionState;
    sortContext: HeaderSortContext<RuleModel>;
    allowAdd: boolean;
    noRulesMessage: string;
    allowRun: boolean;
    onRuleSelected: (rule: RuleModel, selected: boolean) => void;
    onRuleAdd?: (rule: RuleModel) => void;
    onRuleRemove: (rule: RuleModel) => void;
    onRuleRun?: (rule: RuleModel) => void;
    onBulkRun?: (selection: SelectionState) => void;
    onBulkAdd?: (selection: SelectionState) => void;
    onBulkRemove: (selection: SelectionState) => void;
    onAllSelected: (selected: boolean) => void;
    onSort: SortFunction;
}

const CollectionRulesTable = ({
    isLoading,
    rules,
    collectionRules,
    selection,
    sortContext,
    allowAdd,
    noRulesMessage,
    allowRun,
    onRuleSelected,
    onRuleAdd,
    onRuleRemove,
    onBulkAdd,
    onBulkRemove,
    onAllSelected,
    onSort,
    onRuleRun,
    onBulkRun
}: CollectionRulesTableProps) => {
    const allSelected = useMemo(() => {
        if (isNilOrEmpty(rules)) {
            return false;
        }

        if (rules.every(r => selection.rules.has(r.id))) {
            return true;
        }

        return false;
    }, [rules.length, selection]);

    return (
        <div className="collection-rules-table">
            <CollectionRulesTableBulkActions
                selection={selection}
                onAdd={onBulkAdd}
                onRemove={onBulkRemove}
                onRun={onBulkRun}
                allowAdd={allowAdd}
                allowRun={allowRun}
            />

            <table>
                <thead>
                    <tr>
                        <th style={{ width: 50 }} className="selection-cell">
                            <Checkbox checked={allSelected} onChecked={onAllSelected} />
                        </th>

                        <th style={{ width: 50 }}>{/* Row Icon */}</th>

                        <SortableHeader
                            label="Name"
                            sortKey="name"
                            context={sortContext}
                            style={{ width: "30%", textAlign: "left" }}
                            sorter={(e: RuleModel) => e.name?.toLowerCase() ?? ""}
                            onSort={onSort}
                        />

                        <SortableHeader
                            label="Location"
                            sortKey="location"
                            context={sortContext}
                            style={{ width: 200 }}
                            sorter={(e: RuleModel) => e?.location?.name ?? ""}
                            onSort={onSort}
                        />

                        <SortableHeader
                            label="Created by"
                            sortKey="createdBy"
                            context={sortContext}
                            style={{ width: 200 }}
                            sorter={(e: RuleModel) => fullname(e.createdBy)}
                            onSort={onSort}
                            centered
                        />

                        <SortableHeader
                            label="Last modified"
                            sortKey="updatedAt"
                            context={sortContext}
                            style={{ width: 250 }}
                            sorter={(e: RuleModel) => new Date(e.updatedAt).getTime()}
                            onSort={onSort}
                            centered
                        />

                        {allowRun && (
                            <SortableHeader
                                label="Last run"
                                sortKey="createdAt"
                                context={sortContext}
                                style={{ width: 250 }}
                                sorter={(e: RuleModel) =>
                                    !isNilOrEmpty(e.runs) ? new Date(e.runs[0].createdAt).getTime() : null
                                }
                                onSort={onSort}
                                centered
                            />
                        )}

                        <th style={{ minWidth: 100, maxWidth: 100 }}>{/* Hover Actions */}</th>
                    </tr>
                </thead>

                <tbody>
                    {isLoading && <LoadingRow collSpan={8} />}

                    {!isLoading &&
                        !isNilOrEmpty(rules) &&
                        rules.map(rule => {
                            const isEnabled = collectionRules.some(r => r.id === rule.id);

                            return (
                                <CollectionRulesTableRow
                                    key={rule.id}
                                    rule={rule}
                                    isSelected={selection.rules.has(rule.id)}
                                    isEnabled={isEnabled}
                                    allowRun={allowRun}
                                    onSelected={onRuleSelected}
                                    onRuleAdd={onRuleAdd}
                                    onRuleRemove={onRuleRemove}
                                    onRuleRun={onRuleRun}
                                />
                            );
                        })}

                    {!isLoading && isNilOrEmpty(rules) && <EmptyRow message={noRulesMessage} colSpan={8} />}
                </tbody>
            </table>
        </div>
    );
};

export default CollectionRulesTable;
