import { useState } from "react";
import { RuleModel } from "types/models";
import { SelectionState } from "./types";

export function useSelection(rules: RuleModel[]) {
    const [avaliableRules, setAvaliableRules] = useState<RuleModel[]>(rules);
    const [selection, setSelection] = useState<SelectionState>({
        rules: new Set<string>()
    });

    const selectAll = (selected: boolean) => {
        const state: SelectionState = {
            rules: new Set<string>()
        };

        if (selected) {
            for (let i = 0; i < avaliableRules.length; i++) {
                const rule = avaliableRules[i];

                state.rules.add(rule.id);
            }
        } else {
            state.rules.clear();
        }

        setSelection(state);
    };

    const selectRule = (rule: RuleModel, selected: boolean) => {
        const state: SelectionState = { ...selection };

        if (selected) {
            state.rules.add(rule.id);
        } else {
            state.rules.delete(rule.id);
        }

        setSelection(state);
    };

    const deselectRule = (rule: RuleModel) => {
        if (!selection.rules.has(rule.id)) {
            return;
        }

        selectRule(rule, false);
    };

    const clearSelection = () => {
        setSelection({ rules: new Set<string>() });
    };

    return {
        selection,
        selectAll,
        selectRule,
        deselectRule,
        clearSelection,
        setAvaliableRules
    };
}
