import { MONTHS } from "Constants/months";
import { isNil } from "lodash";
import { ARITypes, PluginField, PluginFieldTypes } from "types/plugin";
import * as yup from "yup";

export function registerField(field: PluginField, definitionSchema: yup.AnyObjectSchema): yup.AnyObjectSchema {
    let schema: yup.BaseSchema;

    switch (field.type) {
        case PluginFieldTypes.NUMBER:
        case PluginFieldTypes.FLOAT:
            schema = registerNumericField(field);
            break;
        case PluginFieldTypes.BOOLEAN:
            schema = registerBooleanField(field);
            break;
        case PluginFieldTypes.MONTH:
            schema = registerMonthField(field);
            break;
        case PluginFieldTypes.ARI:
            schema = registerARIField(field);
            break;
        case PluginFieldTypes.SEASON:
        case PluginFieldTypes.ARRAY:
            break;
        default:
            break;
    }

    if (!isNil(schema)) {
        definitionSchema = definitionSchema.shape({
            [field.id]: schema
        });
    }

    return definitionSchema;
}

export function registerNumericField(field: PluginField): yup.BaseSchema {
    let schema = yup.number().nullable();

    if (!isNil(field.control.range?.min)) {
        schema = schema.min(Number(field.control.range.min));
    }

    if (!isNil(field.control.range?.max)) {
        schema = schema.max(Number(field.control.range.max));
    }

    if (field.required) {
        schema = schema.required();
    }

    return schema;
}

export function registerBooleanField(field: PluginField): yup.BaseSchema {
    let schema = yup.boolean().nullable();

    if (field.required) {
        schema = schema.required();
    }

    return schema;
}

export function registerMonthField(field: PluginField): yup.BaseSchema {
    const lowerCaseMonths = MONTHS.map(m => m.toLowerCase());

    let schema = yup.string().nullable().oneOf(lowerCaseMonths);

    if (field.required) {
        schema = schema.required();
    }

    return schema;
}

export function registerARIField(field: PluginField): yup.BaseSchema {
    let schema = yup
        .object()
        .nullable()
        .shape({
            type: yup.string().nullable().oneOf([ARITypes.THRESHOLD, ARITypes.FLOW]),
            value: yup.number().nullable().min(0)
        });

    if (field.required) {
        schema = schema.required();
    }

    return schema;
}
