import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { PluginFieldProps, PluginForm } from "./types";
import Label from "Components/Label";
import { DataPointModel, DataType } from "types/models";
import DataPoint from "Components/Plugin/DataPoint";
import { DataPointOptions } from "types/plugin";
import { isNil } from "lodash";
import { Toast } from "Components/Toast";
import { useRule } from "Scenes/Rule/RuleContext";

interface PluginParameterDataPointProps extends PluginFieldProps {
    onChange?: (field: string, value: Record<string, string | number | boolean>) => void;
}

const PluginParameterDataPoint = ({
    definition,
    field,
    control,
    disabled,
    onChange
}: PluginParameterDataPointProps) => {
    const { dataPoints, updateDataPoints } = useRule();
    const { getValues } = useFormContext<PluginForm>();

    const options = field.control.options as DataPointOptions;

    const setDataPoint = async (_dataPoint: DataPointModel) => {
        if (!isNil(options?.required_data_type) && !_dataPoint.dataTypes.some(d => d === options.required_data_type)) {
            Toast.error(`This datapoint has no ${options.required_data_type} data`);
            return;
        }

        let dataType = null;
        if (!isNil(options?.required_data_type)) {
            dataType = options.required_data_type;
        } else {
            dataType = _dataPoint.dataTypes.some(d => d === options.default_data_type)
                ? options.default_data_type
                : _dataPoint.dataTypes[0];
        }

        if (!dataPoints.some(d => d.id === _dataPoint.id)) {
            updateDataPoints([...dataPoints, _dataPoint]);
        }

        onChange(field.id, { data_point_id: _dataPoint.id, data_type: dataType, lag: 0 });
    };

    const updateDataPointDataType = async (_dataType: DataType) => {
        if (!isNil(options?.required_data_type) && _dataType !== options.required_data_type) {
            Toast.error(`This datapoint must use ${options.required_data_type} data`);
            return;
        }

        const currentId = getValues(`${definition.id}.${field.id}.data_point_id`);
        const currentLag = getValues(`${definition.id}.${field.id}.lag`);

        onChange(field.id, {
            data_point_id: currentId,
            data_type: _dataType,
            lag: currentLag
        });
    };

    const updateDataPointLag = async (_lag: number) => {
        const currentId = getValues(`${definition.id}.${field.id}.data_point_id`);
        const currentDataType = getValues(`${definition.id}.${field.id}.data_type`);

        onChange(field.id, {
            data_point_id: currentId,
            data_type: currentDataType,
            lag: _lag
        });
    };

    const removeDataPoint = async () => {
        onChange(field.id, null);
    };

    return (
        <div className="data-point-parameter-field">
            <Label className="plugin-definition-field-label">{field.name}</Label>

            <Controller
                control={control}
                name={`${definition.id}.${field.id}`}
                render={({ field: _field }) => {
                    const dataPoint = !isNil(_field.value)
                        ? dataPoints.find(d => d.id === _field.value.data_point_id)
                        : null;

                    return (
                        <DataPoint
                            dataPoint={dataPoint}
                            dataType={_field.value?.data_type}
                            lag={_field.value?.lag}
                            disabled={disabled}
                            showLag={options.allow_lag}
                            onDataPointSelected={setDataPoint}
                            onDataPointDataTypeChanged={updateDataPointDataType}
                            onDataPointRemoved={removeDataPoint}
                            onDataPointLagChanged={updateDataPointLag}
                        />
                    );
                }}
            />
        </div>
    );
};

export default PluginParameterDataPoint;
