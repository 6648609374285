import React, { useEffect, useMemo, useState } from "react";
import { RuleResultType, RuleRunModel } from "types/models";
import AzureBlobService from "Services/azure.blob.service";
import { useCollection } from "./CollectionContext";
import moment from "moment";
import Button from "Components/Button";
import { faDownload } from "@fortawesome/pro-solid-svg-icons";
import { ZipFileInfo } from "Services/zip-generator";
import RuleResultService from "Services/rule-result.service";
import LoadingButton from "Components/LoadingButton";
import { isNilOrEmpty } from "utils/utils";
import { isNil } from "lodash";
import LoadingIndicator from "Components/LoadingIndicator";

const DOWNLOAD_SORT_ORDER = [
    RuleResultType.YEARLY_RESULTS,
    RuleResultType.DAILY_INTERMEDIATE,
    RuleResultType.YEARLY_INTERMEDIATE,
    RuleResultType.SUMMARY_INTERMEDIATE,
    RuleResultType.EVENTS_INTERMEDIATE,
    RuleResultType.CUSTOM_RESULTS,
    RuleResultType.PARAMETER_RESULTS,
    RuleResultType.RUN_SETTINGS_LOG
];

export const mapResultTypeToName = (type: RuleResultType): string | null => {
    switch (type) {
        case RuleResultType.YEARLY_RESULTS:
            return "Yearly results";

        case RuleResultType.DAILY_INTERMEDIATE:
            return "Daily intermediate results";
        case RuleResultType.YEARLY_INTERMEDIATE:
            return "Yearly intermediate results";
        case RuleResultType.SUMMARY_INTERMEDIATE:
            return "Summary intermediate results";
        case RuleResultType.EVENTS_INTERMEDIATE:
            return "Events intermediate results";

        case RuleResultType.RUN_SETTINGS_LOG:
            return "Run settings log";

        case RuleResultType.CUSTOM_RESULTS:
            return "Custom results";

        default:
            return null;
    }
};

const shouldIncludeResult = (resultType: RuleResultType): boolean => {
    switch (resultType) {
        case RuleResultType.RUN_SETTINGS_LOG:
            return false;

        default:
            return true;
    }
};

interface DownloadableResult {
    resultType: RuleResultType;
    blob: Blob;
    extension: string;
}

const CollectionExploreDownloads = () => {
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [results, setResults] = useState<DownloadableResult[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { collection, collectionRules } = useCollection();

    useEffect(() => {
        const fetchResults = async () => {
            setIsLoading(true);

            try {
                const latestRuns = collectionRules
                    .map(result => (!isNilOrEmpty(result.runs) ? result.runs[0] : null))
                    .filter(run => !isNil(run));

                const groupedRuns: Record<string, RuleRunModel[]> = latestRuns.reduce((resultAccumulator, run) => {
                    run.results.forEach(result => {
                        if (!shouldIncludeResult(result.resultType)) {
                            return resultAccumulator;
                        }

                        if (!resultAccumulator[result.resultType]) {
                            resultAccumulator[result.resultType] = [];
                        }

                        resultAccumulator[result.resultType].push(run);
                    });

                    return resultAccumulator;
                }, {});

                const downloadableResults: DownloadableResult[] = await Promise.all(
                    Object.keys(groupedRuns).map(async resultType => {
                        const runs = groupedRuns[resultType];

                        const resultData = await Promise.all(
                            runs.map(async run => {
                                const runResult = run.results.find(r => r.resultType === resultType);

                                return await RuleResultService.getRunResultData(run, runResult);
                            })
                        );

                        return {
                            resultType: resultType as RuleResultType,
                            blob: RuleResultService.combineDataToBlob(resultData),
                            extension: "csv"
                        };
                    })
                );

                setResults(downloadableResults);
            } finally {
                setIsLoading(false);
            }
        };

        fetchResults();
    }, [collectionRules]);

    const sortedResults = useMemo(() => {
        return results.sort((a, b) => {
            const indexA = DOWNLOAD_SORT_ORDER.indexOf(a.resultType);
            const indexB = DOWNLOAD_SORT_ORDER.indexOf(b.resultType);
            return indexA - indexB;
        });
    }, [results]);

    const handleDownload = async (result: DownloadableResult) => {
        const downloadName = formatFileNameForDownload(result.resultType, result.extension);

        AzureBlobService.downloadToDisk(result.blob, downloadName);
    };

    const formatFileNameForDownload = (resultType: RuleResultType, extension: string): string => {
        const fileName = mapResultTypeToName(resultType);
        const timestamp = moment(collection.updatedAt).format("DDMMYYYYHHMM");
        const downloadFileName = `${collection.name}_${timestamp}_${fileName}.${extension}`;

        return downloadFileName;
    };

    const handleDownloadAll = async () => {
        try {
            setIsDownloading(true);

            const files: ZipFileInfo[] = await Promise.all(
                sortedResults.map(async result => {
                    const downloadName = formatFileNameForDownload(result.resultType, result.extension);

                    return {
                        name: downloadName,
                        file: result.blob
                    };
                })
            );

            const blob = await RuleResultService.zipFiles(files);
            const timestamp = moment(collection.updatedAt).format("DDMMYYYYHHMM");

            AzureBlobService.downloadToDisk(blob, `${collection.name}_${timestamp}_results.zip`);
        } finally {
            setIsDownloading(false);
        }
    };

    return (
        <div className="collection-explore-downloads">
            {isLoading && <LoadingIndicator centered={true} />}

            {!isLoading && (
                <>
                    {isNilOrEmpty(sortedResults) && <span>No results</span>}
                    {!isNilOrEmpty(sortedResults) && (
                        <>
                            <div className="file-downloads">
                                {sortedResults.map(r => {
                                    const fileName = mapResultTypeToName(r.resultType);

                                    return (
                                        <Button
                                            key={r.resultType}
                                            variant="none"
                                            icon={faDownload}
                                            iconClassName="result-download-icon"
                                            onClick={() => handleDownload(r)}
                                        >
                                            {fileName}
                                        </Button>
                                    );
                                })}
                            </div>

                            <LoadingButton
                                isLoading={isDownloading}
                                variant="none"
                                icon={faDownload}
                                iconClassName="all-download-icon"
                                onClick={handleDownloadAll}
                            >
                                Download all
                            </LoadingButton>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default CollectionExploreDownloads;
