import React from "react";
import clsx from "clsx";

interface PluginDefinitionsProps {
    name: string;
    useBreak: boolean;
    children: React.ReactNode;
}

const PluginDefinitions = ({ name, children, useBreak }: PluginDefinitionsProps) => {
    return (
        <div
            className={clsx("plugin-definition-groups", {
                "bordered-definition-group": useBreak
            })}
        >
            <h5 className="plugin-definition-groups--header">{name}</h5>

            {children}
        </div>
    );
};

export default PluginDefinitions;
