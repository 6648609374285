import React from "react";
import { LatLngBoundsExpression, LatLngExpression, Map } from "leaflet";
import { MapContainer } from "react-leaflet";
import BaseLayers from "./BaseLayers";
import clsx from "clsx";
import { isNil } from "lodash";

import "./Map.scss";

const ZOOM = 5;
const MAX_ZOOM = 10;
const MIN_ZOOM = 0;

interface AppMapProps {
    id?: string;
    children?: React.ReactNode;
    containerClassName?: string;
    center?: LatLngExpression;
    maxBounds?: LatLngBoundsExpression;
    zoom?: number;
    maxZoom?: number;
    minZoom?: number;
    onMapCreated?: (map: Map) => void;
    onMapReady?: () => void;
}

const AppMap = ({
    id,
    children,
    containerClassName,
    center,
    maxBounds,
    zoom,
    maxZoom,
    minZoom,
    onMapCreated,
    onMapReady
}: AppMapProps) => {
    const handleMapCreated = (map: Map) => {
        if (isNil(map)) {
            return;
        }

        if (onMapCreated) {
            onMapCreated(map);
        }
    };

    return (
        <div className={clsx("map-container", containerClassName)}>
            <MapContainer
                id={id}
                ref={handleMapCreated}
                className="map"
                center={center}
                zoom={zoom ?? ZOOM}
                maxZoom={maxZoom ?? MAX_ZOOM}
                minZoom={minZoom ?? MIN_ZOOM}
                maxBounds={maxBounds}
                scrollWheelZoom={true}
                whenReady={onMapReady}
            >
                <BaseLayers />

                {children && children}
            </MapContainer>
        </div>
    );
};

export default AppMap;
