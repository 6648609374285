import React from "react";
import FormError, { ErrorMessage } from "Components/FormError/FormError";
import TextArea, { TextAreaProps } from "Components/TextArea";
import Label from "Components/Label";
import clsx from "clsx";

type FormTextAreaProps = {
    labelText?: string;
    labelClass?: string;
    error?: ErrorMessage;
    containerClassName?: string;
} & TextAreaProps;

const FormTextArea = (props: FormTextAreaProps) => {
    const { labelText, labelClass, error, name, containerClassName, ...rest } = props;

    return (
        <div className={clsx("form-group", containerClassName)}>
            {labelText && (
                <Label className={labelClass} htmlFor={name}>
                    {labelText}
                </Label>
            )}

            <TextArea name={name} {...rest} />

            <FormError error={error} />
        </div>
    );
};

export default FormTextArea;
