import React, { useMemo } from "react";
import { faFileChartLine, faRulerTriangle, faUsers } from "@fortawesome/pro-solid-svg-icons";
import { useHistory, useLocation } from "react-router-dom";
import LabelHeaderContent from "./LabelHeaderContent";
import NavigationHeaderContent from "./NavigationHeaderContent";
import HeaderIcon from "./HeaderIcon";
import UserMenu from "./UserMenu";
import { startsWith } from "lodash";
import { useAuth0 } from "auth/react-auth0-spa";
import { GroupModel } from "types/models";
import { faLocationDot } from "@fortawesome/pro-regular-svg-icons";

import "./Header.scss";

const Header = () => {
    const { isAuthenticated, eflowUser, logout, setActiveGroup } = useAuth0();

    const history = useHistory();
    const location = useLocation();

    const handleGroupSelected = async (group: GroupModel) => {
        await setActiveGroup(group);

        history.push("/");
    };

    const HeaderContent = useMemo(() => {
        const pathname = location.pathname;

        //Custom Headers
        if (startsWith(pathname, "/collection")) {
            return (
                <LabelHeaderContent label="Compliance Report" icon={faFileChartLine} iconClassName="collection-icon" />
            );
        }

        if (startsWith(pathname, "/rule")) {
            return <LabelHeaderContent label="Rule" icon={faRulerTriangle} iconClassName="rule-icon" />;
        }

        if (startsWith(pathname, "/data-point")) {
            return <LabelHeaderContent label="Data Point" icon={faLocationDot} iconClassName="data-icon" />;
        }

        if (startsWith(pathname, "/manage-account")) {
            return <LabelHeaderContent label="Manage Account" icon={faUsers} iconClassName="manage-account-icon" />;
        }

        //Default Header
        if (startsWith(pathname, "/explorer") || startsWith(pathname, "/data-library")) {
            return <NavigationHeaderContent />;
        }

        //No Header
        return null;
    }, [location.pathname]);

    const headerIconLink = useMemo(() => {
        const pathname = location.pathname;

        if (startsWith(pathname, "/data-point")) {
            return "/data-library";
        }

        return "/explorer";
    }, [location.pathname]);

    if (isAuthenticated) {
        return (
            <div className="eflow-header">
                <HeaderIcon link={headerIconLink} />

                {HeaderContent}

                <UserMenu eflowUser={eflowUser} logout={logout} onGroupSelected={handleGroupSelected} />
            </div>
        );
    } else {
        return null;
    }
};

export default Header;
