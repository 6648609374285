import React from "react";
import HeaderLinks from "./HeaderLinks";

const NavigationHeaderContent = () => {
    return (
        <div className="header-content explorer-header-content">
            <HeaderLinks pathname={location?.pathname} />
        </div>
    );
};

export default NavigationHeaderContent;
