import React, { useMemo, useState } from "react";
import { RuleResultModel, RuleResultType, RuleRunModel } from "types/models";
import AzureBlobService from "Services/azure.blob.service";
import { useRule } from "./RuleContext";
import moment from "moment";
import Button from "Components/Button";
import { faDownload } from "@fortawesome/pro-solid-svg-icons";
import { ZipFileInfo } from "Services/zip-generator";
import RuleResultService from "Services/rule-result.service";
import LoadingButton from "Components/LoadingButton";
import { isNilOrEmpty } from "utils/utils";

interface RuleExploreDownloadsProps {
    run: RuleRunModel;
}

const DOWNLOAD_SORT_ORDER = [
    RuleResultType.YEARLY_RESULTS,
    RuleResultType.DAILY_INTERMEDIATE,
    RuleResultType.YEARLY_INTERMEDIATE,
    RuleResultType.SUMMARY_INTERMEDIATE,
    RuleResultType.EVENTS_INTERMEDIATE,
    RuleResultType.CUSTOM_RESULTS,
    RuleResultType.PARAMETER_RESULTS,
    RuleResultType.RUN_SETTINGS_LOG
];

export const mapResultTypeToName = (type: RuleResultType): string | null => {
    switch (type) {
        case RuleResultType.YEARLY_RESULTS:
            return "Yearly results";

        case RuleResultType.DAILY_INTERMEDIATE:
            return "Daily intermediate results";
        case RuleResultType.YEARLY_INTERMEDIATE:
            return "Yearly intermediate results";
        case RuleResultType.SUMMARY_INTERMEDIATE:
            return "Summary intermediate results";
        case RuleResultType.EVENTS_INTERMEDIATE:
            return "Events intermediate results";

        case RuleResultType.RUN_SETTINGS_LOG:
            return "Run settings log";

        case RuleResultType.CUSTOM_RESULTS:
            return "Custom results";

        default:
            return null;
    }
};

const RuleExploreDownloads = ({ run }: RuleExploreDownloadsProps) => {
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const { rule } = useRule();

    const results = useMemo(() => {
        return run.results.sort((a, b) => {
            const indexA = DOWNLOAD_SORT_ORDER.indexOf(a.resultType);
            const indexB = DOWNLOAD_SORT_ORDER.indexOf(b.resultType);
            return indexA - indexB;
        });
    }, [run]);

    const handleDownload = async (result: RuleResultModel) => {
        const downloadUrl = `${run.id}/${result.blobId}`;
        const downloadName = formatFileNameForDownload(result.resultType, result.extension);

        await AzureBlobService.downloadBlob(downloadUrl, downloadName);
    };

    const formatFileNameForDownload = (resultType: RuleResultType, extension: string): string => {
        const fileName = mapResultTypeToName(resultType);
        const timestamp = moment(run.createdAt).format("DDMMYYYYHHMM");
        const downloadFileName = `${rule.name}_${timestamp}_${fileName}.${extension}`;

        return downloadFileName;
    };

    const handleDownloadAll = async () => {
        try {
            setIsDownloading(true);

            const files: ZipFileInfo[] = await Promise.all(
                results.map(async result => {
                    const downloadUrl = `${run.id}/${result.blobId}`;
                    const downloadName = formatFileNameForDownload(result.resultType, result.extension);

                    return {
                        name: downloadName,
                        file: await AzureBlobService.downloadBlobAsFile(downloadUrl, downloadName)
                    };
                })
            );

            const blob = await RuleResultService.zipFiles(files);
            const timestamp = moment(run.createdAt).format("DDMMYYYYHHMM");

            AzureBlobService.downloadToDisk(blob, `${rule.name}_${timestamp}_results.zip`);
        } finally {
            setIsDownloading(false);
        }
    };

    return (
        <div className="explorer-downloads">
            {isNilOrEmpty(results) && <span>No results</span>}

            {!isNilOrEmpty(results) && (
                <>
                    <div className="file-downloads">
                        {results.map(r => {
                            const fileName = mapResultTypeToName(r.resultType);

                            return (
                                <Button
                                    key={r.id}
                                    variant="none"
                                    icon={faDownload}
                                    iconClassName="result-download-icon"
                                    onClick={() => handleDownload(r)}
                                >
                                    {fileName}
                                </Button>
                            );
                        })}
                    </div>

                    <LoadingButton
                        isLoading={isDownloading}
                        variant="none"
                        icon={faDownload}
                        iconClassName="all-download-icon"
                        onClick={handleDownloadAll}
                    >
                        Download all
                    </LoadingButton>
                </>
            )}
        </div>
    );
};

export default RuleExploreDownloads;
