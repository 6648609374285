import React, { useEffect, useMemo, useState } from "react";
import { EflowTimerseriesChartData, DataPointDataModel, DataType, DataPointDataPreviewData } from "types/models";
import AzureBlobService from "Services/azure.blob.service";
import { CSVParser } from "Services/csv-parser";
import LoadingIndicator from "Components/LoadingIndicator";
import SystemDataPreviewModalTimeseriesChart from "./DataPointDataPreviewModalTimeseriesChart";
import SystemDataPreviewModalMagnitudeDurationChart from "./DataPointDataPreviewModalMagnitudeDurationChart";
import PreviewDataService from "Services/preview.data.service";
import { isNil } from "lodash";

interface DataPointDataPreviewModalBodyProps {
    data: DataPointDataModel[];
    selectedDataType: DataType;
}

const DataPointDataPreviewModalBody = ({ data, selectedDataType }: DataPointDataPreviewModalBodyProps) => {
    const csvParser = new CSVParser();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [parsedData, setParsedData] = useState<DataPointDataPreviewData[]>([]);

    const loadData = async () => {
        try {
            setIsLoading(true);

            const dataFiles: DataPointDataPreviewData[] = await Promise.all(
                data.map(async d => {
                    const dataFile = await AzureBlobService.downloadBlobAsString(d.blobId);
                    const parsedDataFile = csvParser.parseTimeseries(dataFile, true);
                    return { dataType: d.dataType, data: parsedDataFile };
                })
            );

            setParsedData(dataFiles);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const chartData: EflowTimerseriesChartData = useMemo(() => {
        const selectedData = parsedData.find(d => d.dataType === selectedDataType);

        if (isNil(selectedData)) {
            return [];
        }

        return PreviewDataService.buildChartDataSet([
            { name: PreviewDataService.getDataTypeLabel(selectedData.dataType), data: selectedData.data }
        ]);
    }, [parsedData, selectedDataType]);

    return (
        <div>
            {isLoading && <LoadingIndicator centered />}
            {!isLoading && (
                <>
                    {chartData.length > 0 && (
                        <>
                            <SystemDataPreviewModalTimeseriesChart
                                id="timeseries-chart"
                                chartData={chartData}
                                dataType={selectedDataType}
                            />
                            <SystemDataPreviewModalMagnitudeDurationChart
                                id="magnitude-duration-chart"
                                chartData={chartData}
                                dataType={selectedDataType}
                            />
                        </>
                    )}
                    {chartData.length === 0 && (
                        <div className="no-data-message">
                            The selected configuration has no data, add data or change your configuration to preview
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default DataPointDataPreviewModalBody;
