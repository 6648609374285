import React, { useMemo } from "react";
import Checkbox from "Components/Checkbox";
import { FolderModel, RuleModel, CollectionModel, User } from "types/models";
import { fullname, isNilOrEmpty } from "utils/utils";
import FolderRow from "./FolderRow";
import CollectionRow from "./CollectionRow";
import RuleRow from "./RuleRow";
import ExplorerTableBulkActions from "./ExplorerTableBulkActions";
import { ExplorerEntity, ExplorerItem, SelectionState } from "./types";
import { isNil } from "lodash";
import LoadingRow from "./LoadingRow";
import SortableHeader, { SortFunction } from "Components/SortableHeader";
import { HeaderSortContext } from "Components/SortableHeader/types";
import EmptyRow from "Components/EmptyRow";

interface ExplorerTableProps {
    items: ExplorerItem<ExplorerEntity>[];
    selection: SelectionState;
    user: User;
    isLoading?: boolean;
    onSort: SortFunction;
    sortContext: HeaderSortContext<ExplorerItem<ExplorerEntity>>;
    onFolderNavigation: (folder: FolderModel) => void;
    onFolderSelected: (folder: FolderModel, selected: boolean) => void;
    onFolderMove: (folder: FolderModel) => void;
    onFolderEdit: (folder: FolderModel) => void;
    onFolderDelete: (folder: FolderModel) => void;
    onFolderCopy: (folder: FolderModel) => void;
    onCollectionSelected: (collection: CollectionModel, selected: boolean) => void;
    onCollectionMove: (collection: CollectionModel) => void;
    onCollectionDelete: (collection: CollectionModel) => void;
    onCollectionCopy: (collection: CollectionModel) => void;
    onCollectionEdit: (collection: CollectionModel) => void;
    onRuleSelected: (rule: RuleModel, selected: boolean) => void;
    onRuleMove: (rule: RuleModel) => void;
    onRuleDelete: (rule: RuleModel) => void;
    onRuleCopy: (rule: RuleModel) => void;
    onRuleEdit: (rule: RuleModel) => void;
    onAllSelected: (selected: boolean) => void;
    onBulkDelete: (selection: SelectionState) => void;
    onBulkCopy: (selection: SelectionState) => void;
    onBulkMove: (selection: SelectionState) => void;
}

const ExplorerTable = ({
    items,
    selection,
    user,
    isLoading,
    onFolderNavigation,
    onFolderSelected,
    onFolderMove,
    onFolderEdit,
    onFolderDelete,
    onFolderCopy,
    onCollectionSelected,
    onCollectionMove,
    onCollectionDelete,
    onCollectionCopy,
    onCollectionEdit,
    onRuleSelected,
    onRuleMove,
    onRuleDelete,
    onRuleCopy,
    onRuleEdit,
    onAllSelected,
    onBulkDelete,
    onBulkCopy,
    onBulkMove,
    onSort,
    sortContext
}: ExplorerTableProps) => {
    const isEmpty = isNilOrEmpty(items);

    const allSelected = useMemo(() => {
        if (isNilOrEmpty(items)) {
            return false;
        }

        const total = selection.rules.size + selection.collections.size + selection.folders.size;

        return total === items.length;
    }, [items.length, selection]);

    return (
        <div className="explorer--table">
            <ExplorerTableBulkActions
                selection={selection}
                onDelete={onBulkDelete}
                onCopy={onBulkCopy}
                onMove={onBulkMove}
            />

            <table>
                <thead>
                    <tr>
                        <th style={{ width: 50 }} className="selection-cell">
                            <Checkbox checked={allSelected} onChecked={onAllSelected} />
                        </th>

                        <th style={{ width: 50 }}>{/* Row Icon */}</th>

                        <SortableHeader
                            label="Name"
                            sortKey="name"
                            context={sortContext}
                            style={{ width: "30%", textAlign: "left" }}
                            sorter={(e: ExplorerItem<ExplorerEntity>) => e.item.name?.toLowerCase() ?? ""}
                            onSort={onSort}
                        />

                        <th style={{ width: 150 }}>Type</th>

                        <SortableHeader
                            label="Created by"
                            sortKey="createdBy"
                            context={sortContext}
                            style={{ width: 200 }}
                            sorter={(e: ExplorerItem<ExplorerEntity>) => fullname(e.item.createdBy)}
                            onSort={onSort}
                            centered
                        />

                        <SortableHeader
                            label="Last modified"
                            sortKey="updatedAt"
                            context={sortContext}
                            style={{ width: 200 }}
                            sorter={(e: ExplorerItem<ExplorerEntity>) => new Date(e.item.updatedAt).getTime()}
                            onSort={onSort}
                            centered
                        />

                        <th style={{ width: 210 }}>{/* Hover Actions */}</th>
                    </tr>
                </thead>

                <tbody>
                    {isLoading && <LoadingRow collSpan={7} />}

                    {!isLoading && (
                        <>
                            {isEmpty && <EmptyRow message="This folder is empty" colSpan={7} />}

                            {items.map(item => {
                                switch (item.type) {
                                    case "folder": {
                                        const folder = item.item as FolderModel;

                                        return (
                                            <FolderRow
                                                key={folder.id}
                                                folder={folder}
                                                isSelected={selection.folders.has(folder.id)}
                                                onFolderNavigation={onFolderNavigation}
                                                onFolderMove={onFolderMove}
                                                onFolderEdit={onFolderEdit}
                                                onFolderDelete={onFolderDelete}
                                                onFolderCopy={onFolderCopy}
                                                onSelected={onFolderSelected}
                                            />
                                        );
                                    }

                                    case "collection": {
                                        const collection = item.item as CollectionModel;
                                        const isLocked =
                                            !isNil(collection.lock) && collection.lock.owner?.id !== user.id;

                                        return (
                                            <CollectionRow
                                                key={collection.id}
                                                collection={collection}
                                                isSelected={selection.collections.has(collection.id)}
                                                isLocked={isLocked}
                                                onSelected={onCollectionSelected}
                                                onCollectionMove={onCollectionMove}
                                                onCollectionDelete={onCollectionDelete}
                                                onCollectionCopy={onCollectionCopy}
                                                onCollectionEdit={onCollectionEdit}
                                            />
                                        );
                                    }

                                    case "rule": {
                                        const rule = item.item as RuleModel;
                                        const isLocked = !isNil(rule.lock) && rule.lock.owner?.id !== user.id;

                                        return (
                                            <RuleRow
                                                key={rule.id}
                                                rule={rule}
                                                isSelected={selection.rules.has(rule.id)}
                                                isLocked={isLocked}
                                                onSelected={onRuleSelected}
                                                onRuleMove={onRuleMove}
                                                onRuleDelete={onRuleDelete}
                                                onRuleCopy={onRuleCopy}
                                                onRuleEdit={onRuleEdit}
                                            />
                                        );
                                    }

                                    default:
                                        return null;
                                }
                            })}
                        </>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default ExplorerTable;
