import React, { useEffect, useState } from "react";
import { DataPointModel } from "types/models";
import { debounce } from "lodash";
import SearchResultsHeader from "./SearchResultsHeader";
import SearchResultsTable from "./SearchResultsTable";
import DataPointService from "Services/data-point.service";

interface SearchViewProps {
    search: string;
    onDataPointClicked?: (dataPoint: DataPointModel) => void;
}

const DEBOUCE_SEARCH_WAIT_MS = 200;

const SearchView = ({ search, onDataPointClicked }: SearchViewProps) => {
    const [searchResults, setSearchResults] = useState<DataPointModel[]>([]);
    const [searching, setSearching] = useState<boolean>(false);

    const doSearch = async (searchText: string) => {
        try {
            const searchResults = await DataPointService.searchDataPoints(searchText);

            setSearchResults(searchResults);
        } finally {
            setSearching(false);
        }
    };

    const [debouncedSearch] = useState(() => debounce(doSearch, DEBOUCE_SEARCH_WAIT_MS));

    useEffect(() => {
        setSearching(true);
        debouncedSearch(search);
    }, [search]);

    return (
        <div className="search-view">
            <SearchResultsHeader resultsCount={searchResults.length} />

            <SearchResultsTable
                searchResults={searchResults}
                isLoading={searching}
                onDataPointClicked={onDataPointClicked}
            />
        </div>
    );
};

export default SearchView;
