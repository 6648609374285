import React from "react";
import { isNil } from "lodash";
import { useDataPoint } from "./DataPointContext";
import DataPointMap from "./DataPointMap";
import { Control, Controller } from "react-hook-form";
import { DataPointForm } from "./DataPointSettings";
import Label from "Components/Label";
import NumericInput from "Components/NumericInput";
import DataPointManualData from "./DataPointManualData";

interface DataPointManualDetailsProps {
    control: Control<DataPointForm>;
    onSetValue: (field: string, value: any) => void;
    onGetValue: (field: string) => any;
}

const DataPointManualDetails = ({ control, onSetValue, onGetValue }: DataPointManualDetailsProps) => {
    const { readOnly } = useDataPoint();

    return (
        <div className="data-point-details">
            <Controller
                name={"position"}
                control={control}
                render={({ field: _field }) => {
                    return (
                        <>
                            <div className="data-point-map">
                                <DataPointMap
                                    name={onGetValue("name")}
                                    latitude={_field.value?.lat}
                                    longitude={_field.value?.lng}
                                    allowPositionChange={true}
                                    onPositionChange={nextPosition => onSetValue("position", nextPosition)}
                                />
                            </div>

                            {isNil(_field.value) && (
                                <div className="data-point-details-empty">
                                    <h5>Set the location of the data point by clicking the map</h5>
                                </div>
                            )}

                            {!isNil(_field.value) && (
                                <div className="data-point-details-settings">
                                    <div className="data-point-locations">
                                        <div className="data-point-location">
                                            <Label>Latitude</Label>
                                            <NumericInput
                                                disabled={readOnly}
                                                value={_field.value.lat}
                                                onChange={nextValue => onSetValue("position.lat", nextValue)}
                                                allowEmpty={false}
                                            />
                                        </div>

                                        <div className="data-point-location">
                                            <Label>Longitude</Label>
                                            <NumericInput
                                                disabled={readOnly}
                                                value={_field.value.lng}
                                                onChange={nextValue => onSetValue("position.lng", nextValue)}
                                                allowEmpty={false}
                                            />
                                        </div>
                                    </div>

                                    <DataPointManualData />
                                </div>
                            )}
                        </>
                    );
                }}
            />
        </div>
    );
};

export default DataPointManualDetails;
