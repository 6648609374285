import React from "react";
import moment from "moment";

interface DateTimeCellProps {
    dateTime: Date;
    onClick?: () => void;
}

const DateTimeCell = ({ dateTime, onClick }: DateTimeCellProps) => {
    return (
        <td className="centered-cell status-cell" onClick={onClick}>
            {moment(dateTime).format("DD MMM, YYYY hh:mm A")}
        </td>
    );
};

export default DateTimeCell;
