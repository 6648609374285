import { useState } from "react";
import { FolderModel, RuleModel, CollectionModel } from "types/models";
import { SelectionState } from "./types";

export function useSelection(folders: FolderModel[], collections: CollectionModel[], rules: RuleModel[]) {
    const [selection, setSelection] = useState<SelectionState>({
        folders: new Set<string>(),
        collections: new Set<string>(),
        rules: new Set<string>()
    });

    const selectAll = (selected: boolean) => {
        const state: SelectionState = {
            folders: new Set<string>(),
            collections: new Set<string>(),
            rules: new Set<string>()
        };

        if (selected) {
            for (let i = 0; i < folders.length; i++) {
                const folder = folders[i];

                state.folders.add(folder.id);
            }

            for (let i = 0; i < collections.length; i++) {
                const collection = collections[i];

                state.collections.add(collection.id);
            }

            for (let i = 0; i < rules.length; i++) {
                const rule = rules[i];

                state.rules.add(rule.id);
            }
        } else {
            state.folders.clear();
            state.collections.clear();
            state.rules.clear();
        }

        setSelection(state);
    };

    const deselectAll = () => {
        if (selection.folders.size === 0 && selection.collections.size === 0 && selection.rules.size === 0) {
            return;
        }

        selectAll(false);
    };

    const selectFolder = (folder: FolderModel, selected: boolean) => {
        const state: SelectionState = { ...selection };

        if (selected) {
            state.folders.add(folder.id);
        } else {
            state.folders.delete(folder.id);
        }

        setSelection(state);
    };

    const selectCollection = (collection: CollectionModel, selected: boolean) => {
        const state: SelectionState = { ...selection };

        if (selected) {
            state.collections.add(collection.id);
        } else {
            state.collections.delete(collection.id);
        }

        setSelection(state);
    };

    const selectRule = (rule: RuleModel, selected: boolean) => {
        const state: SelectionState = { ...selection };

        if (selected) {
            state.rules.add(rule.id);
        } else {
            state.rules.delete(rule.id);
        }

        setSelection(state);
    };

    const deselectFolder = (folder: FolderModel) => {
        if (!selection.folders.has(folder.id)) {
            return;
        }

        selectFolder(folder, false);
    };

    const deselectCollection = (collection: CollectionModel) => {
        if (!selection.collections.has(collection.id)) {
            return;
        }

        selectCollection(collection, false);
    };

    const deselectRule = (rule: RuleModel) => {
        if (!selection.rules.has(rule.id)) {
            return;
        }

        selectRule(rule, false);
    };

    const clearSelection = () => {
        setSelection({ folders: new Set<string>(), collections: new Set<string>(), rules: new Set<string>() });
    };

    return {
        selection,
        selectAll,
        deselectAll,
        selectFolder,
        deselectFolder,
        selectCollection,
        deselectCollection,
        selectRule,
        deselectRule,
        clearSelection
    };
}
