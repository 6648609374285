import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ResourceType, SearchModel } from "types/models";
import { faFolder } from "@fortawesome/pro-solid-svg-icons";
import DateTimeCell from "./DateTimeCell";
import { fullname } from "utils/utils";
import RuleIcon from "Components/ERPIcon/RuleIcon";
import CollectionIcon from "Components/ERPIcon/CollectionIcon";
import { capitalize } from "lodash";

interface SearchResultRowProps {
    result: SearchModel;
    onResultNavigation: (result: SearchModel) => void;
}

const SearchResultRow = ({ result, onResultNavigation }: SearchResultRowProps) => {
    const handleResultNavigation = () => {
        onResultNavigation(result);
    };

    return (
        <tr onClick={handleResultNavigation} className="folder-row">
            <td style={{ maxWidth: 50 }}>
                {result.type === ResourceType.FOLDER && <FontAwesomeIcon icon={faFolder} />}
                {result.type === ResourceType.RULE && <RuleIcon />}
                {result.type === ResourceType.COLLECTION && <CollectionIcon />}
            </td>

            <td>{result.name}</td>

            <td className="centered-cell status-cell">{capitalize(result.type)}</td>

            <td className="centered-cell status-cell">{fullname(result.createdBy)}</td>

            <DateTimeCell dateTime={result.updatedAt} />

            <td className="centered-cell status-cell">{result.breadcrumbs}</td>
        </tr>
    );
};

export default SearchResultRow;
