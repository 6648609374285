import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { PluginFieldProps, PluginForm } from "./types";
import Label from "Components/Label";
import PluginParameterVariableSeasonChart from "./PluginParameterVariableSeasonChart";
import PluginParameterVariableSeasonRow from "./PluginParameterVariableSeasonRow";

interface PluginParameterVariableSeasonProps extends PluginFieldProps {
    onChange?: (field: string, value: number[][]) => void;
}

const PluginParameterVariableSeason = ({
    id,
    definition,
    field,
    control,
    disabled,
    onChange
}: PluginParameterVariableSeasonProps) => {
    const { getValues } = useFormContext<PluginForm>();

    const handleValueChanged = (rowIndex: number, index: number, value: number) => {
        const values: number[][] = getValues(`${definition.id}.${field.id}`);

        const next = [...(values ?? [])];

        next[rowIndex][index] = value;

        onChange(field.id, next);
    };

    return (
        <Controller
            control={control}
            name={`${definition.id}.${field.id}`}
            render={({ field: _field }) => {
                const rows: number[][] = _field.value ?? [];

                return (
                    <div className="curve-parameter-field-container">
                        <div className="curve-parameter-field">
                            <Label className="plugin-definition-field-label">{field.name}</Label>

                            <table className="curve-parameter-field--table">
                                <thead>
                                    <tr>
                                        <td>{"Month"}</td>

                                        <td>{"Success (%)"}</td>
                                    </tr>
                                </thead>

                                <tbody>
                                    {rows.map((row, idx) => {
                                        return (
                                            <PluginParameterVariableSeasonRow
                                                key={`${id}-curve-row-${idx}`}
                                                id={id}
                                                values={row}
                                                disabled={disabled}
                                                onChanged={(index, val) => handleValueChanged(idx, index, val)}
                                            />
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>

                        <PluginParameterVariableSeasonChart values={rows} />
                    </div>
                );
            }}
        />
    );
};

export default PluginParameterVariableSeason;
