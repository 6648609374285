import Select, { SelectOption } from "Components/Select/Select";
import React, { useMemo } from "react";
import { RuleIntermediateResultType } from "types/models";
import Humanize from "humanize-plus";

interface IntermediateResultTypeSelectOption extends SelectOption {
    intermediateResultType: RuleIntermediateResultType;
}

interface RuleExploreIntermediateTypeSelectProps {
    selectedIntermediateResultType: RuleIntermediateResultType;
    avaliableIntermediateResultTypes: RuleIntermediateResultType[];
    onIntermediateResultTypeSelected: (nextType: RuleIntermediateResultType) => void;
}

const RuleExploreIntermediateTypeSelect = ({
    selectedIntermediateResultType,
    avaliableIntermediateResultTypes,
    onIntermediateResultTypeSelected
}: RuleExploreIntermediateTypeSelectProps) => {
    const intermediateResultTypeOptions = useMemo(() => {
        return avaliableIntermediateResultTypes?.map(r => {
            const option: IntermediateResultTypeSelectOption = {
                value: r.id,
                label: `${r.label} (${Humanize.capitalize(r.scale)})`,
                intermediateResultType: r
            };

            return option;
        });
    }, [avaliableIntermediateResultTypes]);

    const handleIntermediateResultTypeSelected = (nextType: RuleIntermediateResultType) => {
        if (nextType.id === selectedIntermediateResultType.id) {
            return;
        }

        onIntermediateResultTypeSelected(nextType);
    };

    return (
        <div>
            <Select
                labelText="Select intermediate results to explore"
                menuClassName="intermediate-type-select"
                values={intermediateResultTypeOptions}
                value={intermediateResultTypeOptions.find(o => o.value === selectedIntermediateResultType?.id)}
                onSelected={(option: IntermediateResultTypeSelectOption) => {
                    handleIntermediateResultTypeSelected(option.intermediateResultType);
                }}
            />
        </div>
    );
};

export default RuleExploreIntermediateTypeSelect;
