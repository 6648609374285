import { faCheckCircle, faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import Select from "Components/Select/Select";
import TooltipIcon from "Components/TooltipIcon";
import clsx from "clsx";
import moment from "moment";
import React, { useMemo } from "react";
import { RuleRunModel } from "types/models";
import { useRule } from "./RuleContext";

interface RunSelectOption {
    value: string;
    label: string;
    run: RuleRunModel;
    outdatedWarning: OutdatedWarningModel;
}

interface OutdatedWarningModel {
    isOutdated: boolean;
    outdatedMessage?: string;
}

interface RuleExploreRunSelectProps {
    runs: RuleRunModel[];
    selectedRun: RuleRunModel;
    onRunSelected: (nextRun: RuleRunModel) => void;
}

const RuleExploreRunSelect = ({ runs, selectedRun, onRunSelected }: RuleExploreRunSelectProps) => {
    const { rule, dataPoints } = useRule();

    const getRunOutdatedWarning = (run: RuleRunModel): OutdatedWarningModel => {
        const isRunOutOfDateFromRule = run.ruleVersion !== rule.version;
        const isRunOutOfDateFromData = run.dataPointVersions.some(dprv => {
            const dataPoint = dataPoints.find(dp => dp.id === dprv.data_point_id);

            return dataPoint?.version !== dprv.data_point_version;
        });

        let outdatedMessage = "";
        if (isRunOutOfDateFromRule && isRunOutOfDateFromData) {
            outdatedMessage = "The rule settings and data have changed since the last run";
        } else if (isRunOutOfDateFromRule) {
            outdatedMessage = "The rule settings have changed since the last run";
        } else if (isRunOutOfDateFromData) {
            outdatedMessage = "The rule data has changed since the last run";
        }

        return {
            isOutdated: isRunOutOfDateFromRule || isRunOutOfDateFromData,
            outdatedMessage: outdatedMessage
        };
    };

    const runSelectOptions: RunSelectOption[] = useMemo(() => {
        return runs.map(r => {
            return {
                value: r.id,
                label: `Run @ ${moment(r.createdAt).format("DD MMM, YYYY hh:mm A")}`,
                run: r,
                outdatedWarning: getRunOutdatedWarning(r)
            };
        });
    }, [runs]);

    const handleRunSelected = (nextRun: RuleRunModel) => {
        if (nextRun.id === selectedRun.id) {
            return;
        }

        onRunSelected(nextRun);
    };

    const selectedRunOption = useMemo(() => {
        return runSelectOptions.find(o => o.run.id === selectedRun.id);
    }, [selectedRun]);

    return (
        <div className="run-select">
            <div className="selected-run">
                {!selectedRunOption.outdatedWarning.isOutdated && (
                    <TooltipIcon
                        icon={faCheckCircle}
                        tooltip="This run is up to date"
                        className={clsx("icon", "check-icon")}
                        size="2x"
                    />
                )}

                {selectedRunOption.outdatedWarning.isOutdated && (
                    <TooltipIcon
                        icon={faExclamationTriangle}
                        tooltip={selectedRunOption.outdatedWarning.outdatedMessage}
                        className={clsx("icon", "warn-icon")}
                        size="2x"
                    />
                )}

                <Select
                    labelText="Select run to explore"
                    menuClassName="select"
                    values={runSelectOptions}
                    value={runSelectOptions.find(s => s.value === selectedRun.id)}
                    onSelected={v => {
                        handleRunSelected((v as RunSelectOption).run);
                    }}
                />
            </div>
        </div>
    );
};

export default RuleExploreRunSelect;
