import React from "react";
import { LocationModel } from "types/models";
import Switch from "Components/Switch";
import Label from "Components/Label";
import Button from "Components/Button";
import { CollectionAddFilterSettings } from "./CollectionAdd";
import { PluginId } from "types/plugin";
import CollectionAddFilterMap from "./CollectionAddFilterMap";

interface CollectionAddFilterProps {
    filterSettings: CollectionAddFilterSettings;
    onFilterSettingsChange: (nextSettings: CollectionAddFilterSettings) => void;
}

interface PluginSelectOption {
    label: string;
    value: PluginId;
}

const PLUGIN_TYPES: PluginSelectOption[] = [
    { label: "Lowflow", value: PluginId.LOWFLOW },
    { label: "Freshes", value: PluginId.FRESHES },
    { label: "Oversupply", value: PluginId.OVERSUPPLY },
    { label: "Multiyear Freshes", value: PluginId.MULTIYEAR_FRESHES }
];

const CollectionAddFilter = ({ filterSettings, onFilterSettingsChange }: CollectionAddFilterProps) => {
    const handleLocationFilter = (nextlocations: LocationModel[]) => {
        onFilterSettingsChange({ ...filterSettings, locations: nextlocations });
    };

    const handlePluginFilter = (pluginId: PluginId) => {
        const exists = filterSettings.plugins.some(p => p === pluginId);

        if (exists) {
            const next = filterSettings.plugins.filter(p => p !== pluginId);
            onFilterSettingsChange({ ...filterSettings, plugins: next });
            return;
        }

        onFilterSettingsChange({ ...filterSettings, plugins: [...filterSettings.plugins, pluginId] });
    };

    const handleEnableAll = () => {
        onFilterSettingsChange({ ...filterSettings, plugins: PLUGIN_TYPES.map(p => p.value) });
    };

    const handleDisableAll = () => {
        onFilterSettingsChange({ ...filterSettings, plugins: [] });
    };

    const allEnabled = filterSettings.plugins.length === PLUGIN_TYPES.length;

    return (
        <div className="collection-add-filter">
            <div className="filter-label">
                <Label>Filter by model type</Label>

                <Button onClick={allEnabled ? handleDisableAll : handleEnableAll} className="modify-all-button">
                    {allEnabled ? "Disable All" : "Enable All"}
                </Button>
            </div>

            <div className="filter-plugin-types">
                <div className="filter-plugin-types-col">
                    {PLUGIN_TYPES.slice(0, 4).map(p => {
                        return (
                            <div key={p.value} className="filter-plugin-type-switch-container">
                                <Switch
                                    checked={filterSettings.plugins.some(pp => pp === p.value)}
                                    onChecked={() => handlePluginFilter(p.value)}
                                    title={p.label}
                                />
                                <span>{p.label}</span>
                            </div>
                        );
                    })}
                </div>
            </div>

            <CollectionAddFilterMap
                selectedLocations={filterSettings.locations}
                onLocationsSelectChanged={handleLocationFilter}
            />
        </div>
    );
};

export default CollectionAddFilter;
