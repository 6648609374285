import React from "react";
import { TaskModel, TaskStatus } from "../../types/models";
import LoadingIndicator from "../../Components/LoadingIndicator";
import { capitalize, isNil, round } from "lodash";
import clsx from "clsx";
import moment from "moment";

import "./TaskStatusDisplay.scss";

interface TaskStatusDisplayProps {
    task: TaskModel;
    label: string;
    showProgress: boolean;
    className?: string;
}

const TaskStatusDisplay = ({ task, label, showProgress, className }: TaskStatusDisplayProps) => {
    const isActive = task.status === TaskStatus.RUNNING || task.status === TaskStatus.PENDING;

    return (
        <div className={clsx("task-status", className)}>
            <div className="status-container">
                {label} status -
                <div className="status">
                    <div className={clsx("status-label", task.status)}>
                        {capitalize(task.status)}
                        {task.status === TaskStatus.RUNNING && showProgress && ` - ${round(task.progress, 0)}%`}
                    </div>
                    {!isActive && moment(task.updatedAt).format("DD MMM, YYYY hh:mm A")}

                    {isActive && <LoadingIndicator />}
                </div>
            </div>

            {!isNil(task.error) && (
                <div className="task-error">
                    <b>{task.error.message}</b>
                </div>
            )}
        </div>
    );
};

export default TaskStatusDisplay;
