import React from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faDatabase, faProjectDiagram } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import Transition from "Components/Transition";

interface HeaderLinkProps {
    to: string;
    label: string;
    isActive: boolean;
    transitionType: "slide-right" | "slide-left";
    icon: IconProp;
}

const HeaderLink = ({ to, label, isActive, transitionType, icon }: HeaderLinkProps) => {
    return (
        <NavLink to={to} className="link">
            <FontAwesomeIcon icon={icon} size="lg" />

            <span>{label}</span>

            <Transition isShown={isActive} transitionType={transitionType} key={label}>
                <div className="link-indicator"></div>
            </Transition>
        </NavLink>
    );
};

interface HeaderLinksProps {
    pathname: string;
}

const HeaderLinks = ({ pathname }: HeaderLinksProps) => {
    return (
        <div className="links">
            <HeaderLink
                to="/explorer"
                label="Explorer"
                isActive={pathname === "/explorer"}
                transitionType="slide-left"
                icon={faProjectDiagram}
            />
            <HeaderLink
                to="/data-library"
                label="Data Library"
                isActive={pathname === "/data-library"}
                transitionType="slide-right"
                icon={faDatabase}
            />
        </div>
    );
};

export default HeaderLinks;
