import clsx from "clsx";
import React, { useMemo } from "react";
import { SuccessLevel } from "types/models";
import { mapSuccessToLabel } from "utils/explorer-charts.utils";

interface ChartLegendItemProps {
    label: SuccessLevel;
    isSelected: boolean;
    onSelected: (label: string, active: boolean) => void;
}

const ChartLegendItem = ({ label, isSelected, onSelected }: ChartLegendItemProps) => {
    const handleSelected = () => {
        onSelected(label, !isSelected);
    };

    return (
        <div className="chart-legend-item" onClick={handleSelected}>
            <div className={clsx("legend-colour", label)}></div>

            <span className={clsx("legend-text", { "not-active": !isSelected })}>{mapSuccessToLabel(label)}</span>
        </div>
    );
};

interface RuleExploreYearlyChartLegendProps {
    selectedSuccess: SuccessLevel[];
    onSuccessChanged: (success: SuccessLevel[]) => void;
}

const RuleExploreYearlyChartLegend = ({ selectedSuccess, onSuccessChanged }: RuleExploreYearlyChartLegendProps) => {
    const successes = useMemo(() => {
        return [
            SuccessLevel.NONE,
            SuccessLevel.VERY_LOW,
            SuccessLevel.LOW,
            SuccessLevel.MODERATE,
            SuccessLevel.HIGH,
            SuccessLevel.VERY_HIGH
        ].map(success => {
            return {
                label: success,
                isSelected: selectedSuccess.includes(success)
            };
        });
    }, [selectedSuccess.length]);

    const handleSuccessSelected = (success: SuccessLevel, active: boolean) => {
        if (!active) {
            onSuccessChanged(selectedSuccess.filter(s => s !== success));
        } else {
            onSuccessChanged([...selectedSuccess, success]);
        }
    };

    return (
        <div className="chart-legend">
            {successes.map(success => {
                return (
                    <ChartLegendItem
                        key={`rule_explore_yearly_chart_legend_${success.label}`}
                        label={success.label}
                        isSelected={success.isSelected}
                        onSelected={handleSuccessSelected}
                    />
                );
            })}
        </div>
    );
};

export default RuleExploreYearlyChartLegend;
