import LoadingRow from "Scenes/Explorer/LoadingRow";
import React, { useMemo } from "react";
import { DataPointModel, User } from "types/models";
import SortableHeader, { SortFunction } from "Components/SortableHeader";
import { HeaderSortContext } from "Components/SortableHeader/types";
import Checkbox from "Components/Checkbox";
import { fullname, isNilOrEmpty } from "utils/utils";
import { SelectionState } from "./types";
import DataLibraryTableBulkActions from "./DataLibraryTableBulkActions";
import DataPointRow from "./DataPointRow";
import { isNil } from "lodash";
import EmptyRow from "Components/EmptyRow";

interface DataLibraryTableProps {
    user: User;
    isLoading: boolean;
    dataPoints: DataPointModel[];
    selection: SelectionState;
    sortContext: HeaderSortContext<DataPointModel>;
    allowDataActions: boolean;
    onDataPointClicked: (dataPoint: DataPointModel) => void;
    onDataPointSelected: (dataPoint: DataPointModel, selected: boolean) => void;
    onDataPointDelete: (dataPoint: DataPointModel) => void;
    onDataPointCopy: (dataPoint: DataPointModel) => void;
    onBulkDelete: (selection: SelectionState) => void;
    onBulkCopy: (selection: SelectionState) => void;
    onAllSelected: (selected: boolean) => void;
    onSort: SortFunction;
}

const DataLibraryTable = ({
    user,
    isLoading,
    dataPoints,
    selection,
    sortContext,
    allowDataActions,
    onDataPointClicked,
    onDataPointSelected,
    onDataPointDelete,
    onDataPointCopy,
    onBulkDelete,
    onBulkCopy,
    onAllSelected,
    onSort
}: DataLibraryTableProps) => {
    const allSelected = useMemo(() => {
        if (isNilOrEmpty(dataPoints)) {
            return false;
        }

        if (dataPoints.every(r => selection.dataPoints.has(r.id))) {
            return true;
        }

        return false;
    }, [dataPoints.length, selection]);

    return (
        <div className="data-library-table">
            {allowDataActions && (
                <DataLibraryTableBulkActions selection={selection} onDelete={onBulkDelete} onCopy={onBulkCopy} />
            )}

            <table>
                <thead>
                    <tr>
                        <th style={{ width: 50 }} className="selection-cell">
                            {allowDataActions && <Checkbox checked={allSelected} onChecked={onAllSelected} />}
                        </th>

                        <th style={{ width: 50 }}>{/* Row Icon */}</th>

                        <SortableHeader
                            label="Name"
                            sortKey="name"
                            context={sortContext}
                            style={{ width: "30%", textAlign: "left" }}
                            sorter={(e: DataPointModel) => e.name?.toLowerCase() ?? ""}
                            onSort={onSort}
                        />

                        <SortableHeader
                            label="Location"
                            sortKey="location"
                            context={sortContext}
                            style={{ width: 200 }}
                            sorter={(e: DataPointModel) => e?.location?.name ?? ""}
                            onSort={onSort}
                        />

                        <SortableHeader
                            label="Type"
                            sortKey="type"
                            context={sortContext}
                            style={{ width: 50 }}
                            sorter={(e: DataPointModel) => e.type?.toLowerCase() ?? ""}
                            onSort={onSort}
                        />

                        <SortableHeader
                            label="Created by"
                            sortKey="createdBy"
                            context={sortContext}
                            style={{ width: 200 }}
                            sorter={(e: DataPointModel) => fullname(e.createdBy)}
                            onSort={onSort}
                            centered
                        />

                        <SortableHeader
                            label="Last modified"
                            sortKey="updatedAt"
                            context={sortContext}
                            style={{ width: 250 }}
                            sorter={(e: DataPointModel) => new Date(e.updatedAt).getTime()}
                            onSort={onSort}
                            centered
                        />

                        <th style={{ minWidth: 100, maxWidth: 100 }}>{/* Hover Actions */}</th>
                    </tr>
                </thead>

                <tbody>
                    {isLoading && <LoadingRow collSpan={8} />}

                    {!isLoading &&
                        !isNilOrEmpty(dataPoints) &&
                        dataPoints.map(dataPoint => {
                            const isLocked = !isNil(dataPoint.lock) && dataPoint.lock.owner?.id !== user.id;

                            return (
                                <DataPointRow
                                    key={dataPoint.id}
                                    dataPoint={dataPoint}
                                    isSelected={selection.dataPoints.has(dataPoint.id)}
                                    isLocked={isLocked}
                                    allowDataActions={allowDataActions}
                                    onDataPointClicked={onDataPointClicked}
                                    onSelected={onDataPointSelected}
                                    onDataPointDelete={onDataPointDelete}
                                    onDataPointCopy={onDataPointCopy}
                                />
                            );
                        })}

                    {!isLoading && isNilOrEmpty(dataPoints) && (
                        <EmptyRow message="No data points match the filter provided" colSpan={8} />
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default DataLibraryTable;
