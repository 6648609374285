import { faSquareMinus, faSquarePlus } from "@fortawesome/pro-solid-svg-icons";
import ERPIcon from "Components/ERPIcon";
import Label from "Components/Label";
import { isNil } from "lodash";
import React, { useMemo, useState } from "react";
import { getValuesMean } from "utils/collection-explore.utils";
import { CollectionExploreResultModel } from "./CollectionExploreSingleYear";
import CollectionExploreTableRowScore from "./CollectionExploreTableRowScore";

interface CollectionExploreTableRowProps {
    result: CollectionExploreResultModel;
}

const CollectionExploreTableRow = ({ result }: CollectionExploreTableRowProps) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const combinedScore = useMemo(() => {
        const magnitudeScores = result.results.map(r => r?.magnitude_score).filter(s => !isNil(s));
        const durationScores = result.results.map(r => r?.duration_score).filter(s => !isNil(s));
        const countScores = result.results.map(r => r?.count_score).filter(s => !isNil(s));
        const independenceScores = result.results.map(r => r?.independence_score).filter(s => !isNil(s));
        const summaryScores = result.results.map(r => r?.summary_score).filter(s => !isNil(s));

        return {
            magnitude_score: getValuesMean(magnitudeScores),
            duration_score: getValuesMean(durationScores),
            count_score: getValuesMean(countScores),
            independence_score: getValuesMean(independenceScores),
            summary_score: getValuesMean(summaryScores)
        };
    }, [result]);

    return (
        <>
            <tr className="location-cell">
                <td style={{ width: "30%" }}>
                    <div>
                        <ERPIcon
                            icon={!isExpanded ? faSquarePlus : faSquareMinus}
                            className="location-icon"
                            onClick={() => setIsExpanded(!isExpanded)}
                        />
                        <Label className="location-label" onClick={() => setIsExpanded(!isExpanded)}>
                            {result.location.name}
                        </Label>
                    </div>
                </td>
                <CollectionExploreTableRowScore value={combinedScore.magnitude_score} />
                <CollectionExploreTableRowScore value={combinedScore.duration_score} />
                <CollectionExploreTableRowScore value={combinedScore.count_score} />
                <CollectionExploreTableRowScore value={combinedScore.independence_score} />
                <CollectionExploreTableRowScore value={combinedScore.summary_score} />
            </tr>
            {isExpanded &&
                result.results.map(r => {
                    return (
                        <tr key={r.rule}>
                            <td className="centered-cell" style={{ width: "30%" }}>
                                {r.rule}
                            </td>
                            <CollectionExploreTableRowScore value={r?.magnitude_score} />
                            <CollectionExploreTableRowScore value={r?.duration_score} />
                            <CollectionExploreTableRowScore value={r?.count_score} />
                            <CollectionExploreTableRowScore value={r?.independence_score} />
                            <CollectionExploreTableRowScore value={r?.summary_score} />
                        </tr>
                    );
                })}
        </>
    );
};

export default CollectionExploreTableRow;
