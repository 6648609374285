import React from "react";
import ERPIcon, { ERPIconProps } from ".";
import { faFileChartLine } from "@fortawesome/pro-solid-svg-icons";

type CollectionIconProps = Omit<ERPIconProps, "icon">;

const CollectionIcon = (props: CollectionIconProps) => {
    return <ERPIcon className="collection-icon" icon={faFileChartLine} {...props} />;
};

export default CollectionIcon;
