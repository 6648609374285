import LoadingIndicator from "Components/LoadingIndicator";
import { Toast } from "Components/Toast";
import { useInterval } from "Hooks/useInterval";
import CollectionService from "Services/collection.service";
import RuleService from "Services/rule.service";
import { isNil } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { CollectionModel, RuleModel, TaskModel, TaskStatus } from "types/models";

interface CollectionContextProps {
    collection: CollectionModel;
    collectionRules: RuleModel[];
    collecitonTasks: TaskModel[];
    readOnly: boolean;
    isSaving: boolean;
    setSaving: (saving: boolean) => void;
    updateCollection: (update: CollectionModel) => void;
    updateCollectionRules: (update: RuleModel[]) => void;
    updateCollectionTasks: (update: TaskModel[]) => void;
}

interface CollectionContextOptions {
    collectionId: string;
    readOnly: boolean;
    children: React.ReactNode;
}

const POLLING_TIME_MS = 2500;

export const CollectionContext = React.createContext<CollectionContextProps | null>(null);

export const useCollection = () => useContext(CollectionContext);

const CollectionContextProvider = ({ collectionId, readOnly, children }: CollectionContextOptions) => {
    const [collection, setCollection] = useState<CollectionModel>(null);
    const [collectionRules, setCollectionRules] = useState<RuleModel[]>([]);
    const [collectionTasks, setCollectionTasks] = useState<TaskModel[]>([]);
    const [isSaving, setSaving] = useState<boolean>(false);

    useEffect(() => {
        const getCollection = async (id: string) => {
            const getCollection = CollectionService.getCollection(id);
            const getCollectionRules = CollectionService.getCollectionRules(id);
            const getCollectionTasks = CollectionService.getCollectionTasks(id);

            const [_collection, _collectionRules, _collectionTasks] = await Promise.all([
                getCollection,
                getCollectionRules,
                getCollectionTasks
            ]);

            setCollection(_collection);
            setCollectionRules(_collectionRules);
            setCollectionTasks(_collectionTasks);
        };

        getCollection(collectionId);
    }, [collectionId]);

    useInterval(
        async () => {
            const nextTasks = await CollectionService.getCollectionTasks(collection.id);

            const changedTasks = nextTasks.filter(t => {
                const oldTask = collectionTasks.find(ct => ct.id === t.id);

                if (isNil(oldTask)) {
                    return true;
                }

                if (oldTask.status !== t.status) {
                    return true;
                }

                return false;
            });

            changedTasks.forEach(async t => {
                const taskRule = collectionRules.find(r => r.id === t.resourceId);

                switch (t.status) {
                    case TaskStatus.COMPLETED: {
                        const nextRuns = await RuleService.getRuleRuns(taskRule.id);
                        const nextRule: RuleModel = { ...taskRule, runs: nextRuns };
                        const nextRules = collectionRules.map(r => (r.id !== nextRule.id ? r : nextRule));
                        setCollectionRules([...nextRules]);
                        break;
                    }
                    case TaskStatus.FAILED: {
                        Toast.error(`Error when computing ${taskRule.name}: ${t.error.message}`);
                        break;
                    }
                }
            });

            if (changedTasks.some(t => t.status === TaskStatus.COMPLETED)) {
                Toast.success(
                    `Successfully computed ${
                        changedTasks.filter(t => t.status === TaskStatus.COMPLETED).length
                    } rule(s)`
                );
            }

            setCollectionTasks(nextTasks);
        },
        collectionTasks.some(t => t.status === TaskStatus.RUNNING || t.status === TaskStatus.PENDING)
            ? POLLING_TIME_MS
            : null
    );

    const updateCollection = (nextCollection: CollectionModel) => {
        setCollection({ ...nextCollection });
    };

    const updateCollectionRules = (nextCollectionRules: RuleModel[]) => {
        setCollectionRules([...nextCollectionRules]);
    };

    const updateTasks = (nextCollectionTasks: TaskModel[]) => {
        setCollectionTasks([...nextCollectionTasks]);
    };

    return (
        <CollectionContext.Provider
            value={{
                collection: collection,
                collectionRules: collectionRules,
                collecitonTasks: collectionTasks,
                readOnly: readOnly,
                isSaving: isSaving,
                setSaving: setSaving,
                updateCollection: updateCollection,
                updateCollectionRules: updateCollectionRules,
                updateCollectionTasks: updateTasks
            }}
        >
            {isNil(collection) && <LoadingIndicator className="collection-loading" centered />}

            {!isNil(collection) && children}
        </CollectionContext.Provider>
    );
};

export default CollectionContextProvider;
