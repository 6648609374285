import React from "react";
import Humanize from "humanize-plus";

interface PluginParameterVariableSeasonMonthCellProps {
    value: string;
}

const PluginParameterVariableSeasonMonthCell = ({ value }: PluginParameterVariableSeasonMonthCellProps) => {
    return (
        <td className="curve-parameter-field--table-value">
            <span>{Humanize.capitalize(value)}</span>
        </td>
    );
};

export default PluginParameterVariableSeasonMonthCell;
