import React, { useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { debounce, isNil } from "lodash";
import { ResetDataPointRequest, UpdateDataPointRequest } from "types/requests";
import { useDataPoint } from "./DataPointContext";
import { sleep } from "utils/utils";
import { DataPointModel, DataPointType, LocationModel } from "types/models";
import DataPointService from "Services/data-point.service";
import Label from "Components/Label";
import Input from "Components/Input";
import RadioButton from "Components/RadioButton";
import { LatLng } from "leaflet";
import DataPointManualDetails from "./DataPointManualDetails";
import DataPointAutomaticDetails from "./DataPointAutomaticDetails";
import { useConfirmation } from "Components/ConfirmDialog/ConfirmationContext";

const schema = yup.object().shape({
    id: yup.string().required("Id is required"),
    name: yup.string().required("Name is required"),
    type: yup.string().required("Type is required"),
    station: yup.string().optional().nullable(),
    position: yup.object().optional().nullable(),
    location: yup.object().optional().nullable()
});

export interface DataPointForm {
    id: string;
    name: string;
    type: DataPointType;
    station: string;
    position: LatLng;
    location: LocationModel;
}

const DEBOUCE_TRIGGER_SAVE = 500;

const DataPointSettings = () => {
    const { dataPoint, readOnly, setSaving, updateDataPoint, updateDataPointData } = useDataPoint();

    const confirm = useConfirmation();

    const { register, watch, setValue, getValues, control } = useForm<DataPointForm>({
        resolver: yupResolver(schema),
        mode: "onBlur",
        reValidateMode: "onSubmit",
        defaultValues: {
            id: dataPoint.id,
            name: dataPoint.name,
            type: dataPoint.type,
            station: dataPoint.station,
            position:
                !isNil(dataPoint.latitude) && !isNil(dataPoint.longitude)
                    ? new LatLng(dataPoint.latitude, dataPoint.longitude)
                    : null,

            location: dataPoint.location
        }
    });

    useEffect(() => {
        initDataPointForm(dataPoint);
    }, [dataPoint.id]);

    const initDataPointForm = (nextDataPoint: DataPointModel) => {
        setValue("id", nextDataPoint.id);
        setValue("name", nextDataPoint.name);
        setValue("type", nextDataPoint.type);
        setValue("station", nextDataPoint.station);
        setValue(
            "position",
            !isNil(nextDataPoint.latitude) && !isNil(nextDataPoint.longitude)
                ? new LatLng(nextDataPoint.latitude, nextDataPoint.longitude)
                : null
        );
        setValue("location", nextDataPoint.location);
    };

    const saveDataPoint = async (form: DataPointForm) => {
        try {
            const isValid = await schema.isValid(form);

            if (!isValid) {
                return;
            }

            setSaving(true);

            const request: UpdateDataPointRequest = {
                name: form.name,
                station: form.station,
                type: form.type,
                latitude: form?.position?.lat ?? null,
                longitude: form?.position?.lng ?? null
            };

            const updatedDataPoint = await DataPointService.updateDataPoint(form.id, request);

            updateDataPoint(updatedDataPoint);
        } finally {
            await sleep(250);
            setSaving(false);
        }
    };

    useEffect(() => {
        if (readOnly) {
            return;
        }

        const subscription = watch(debounce(saveDataPoint, DEBOUCE_TRIGGER_SAVE));

        return () => subscription.unsubscribe();
    }, [watch, readOnly]);

    const handleTypeChange = async (nextType: DataPointType) => {
        if (!isNil(dataPoint.station) || !isNil(dataPoint.latitude) || !isNil(dataPoint.longitude)) {
            const result = await confirm({
                title: "Switch Type",
                description: `Are you sure you want to change the data point type? This will reset the data point (including all data).`
            });

            if (!result.success) {
                return;
            }

            const request: ResetDataPointRequest = {
                name: dataPoint.name,
                type: nextType
            };

            const nextDataPoint = await DataPointService.resetDataPoint(dataPoint.id, request);
            updateDataPoint(nextDataPoint);
            updateDataPointData([]);
            initDataPointForm(nextDataPoint);
            return;
        }

        setValue("type", nextType);
    };

    const dataPointType = watch("type");
    const enableAutomaticComponents = dataPointType === DataPointType.AUTOMATIC;

    return (
        <form>
            <div className="data-point-settings">
                <div className="data-point-name">
                    <Label>Name</Label>
                    <Input register={register("name")} id="name" disabled={readOnly} />
                </div>

                <div className="data-point-type">
                    <Label>Define data point location</Label>
                    <Controller
                        name={`type`}
                        control={control}
                        render={({ field: _field }) => {
                            return (
                                <div className="data-point-type-radio-group">
                                    <RadioButton
                                        containerClassName="data-point-type-radio-button"
                                        label="Automatic (fetch by station)"
                                        checked={_field.value === DataPointType.AUTOMATIC}
                                        onChecked={() => handleTypeChange(DataPointType.AUTOMATIC)}
                                    />

                                    <RadioButton
                                        containerClassName="data-point-type-radio-button"
                                        label="Manual (add location and data)"
                                        checked={_field.value === DataPointType.MANUAL}
                                        onChecked={() => handleTypeChange(DataPointType.MANUAL)}
                                    />
                                </div>
                            );
                        }}
                    />
                </div>
            </div>

            <div className="data-point-details-container">
                {!enableAutomaticComponents && (
                    <DataPointManualDetails control={control} onGetValue={getValues} onSetValue={setValue} />
                )}

                {enableAutomaticComponents && (
                    <DataPointAutomaticDetails onSetValue={setValue} onGetValue={getValues} />
                )}
            </div>
        </form>
    );
};

export default DataPointSettings;
