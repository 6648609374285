import React, { useState } from "react";
import DataPointMap from "./DataPointMap";
import Label from "Components/Label";
import Input from "Components/Input";
import { isNil } from "lodash";
import DataPointStationSearch from "./DataPointStationSearch";
import { StationModel } from "types/models";
import DataPointObservedDataModal from "./DataPointObservedDataModal";
import DataPointAutomaticData from "./DataPointAutomaticData";

interface DataPointAutomaticDetailsProps {
    onSetValue: (field: string, value: any) => void;
    onGetValue: (field: string) => any;
}

const DataPointAutomaticDetails = ({ onSetValue, onGetValue }: DataPointAutomaticDetailsProps) => {
    const [showDataModal, setShowDataModal] = useState<boolean>(false);

    const handleUpdateStation = (station: StationModel) => {
        onSetValue("station", station.identifier);
        onSetValue("name", station.name);
        onSetValue("position.lat", station.latitude);
        onSetValue("position.lng", station.longitude);
    };

    return (
        <>
            <DataPointStationSearch gauge={onGetValue("station")} onStationChanged={handleUpdateStation} />

            {!isNil(onGetValue("station")) && (
                <div className="data-point-details">
                    <div className="data-point-map">
                        <DataPointMap
                            name={onGetValue("name")}
                            latitude={onGetValue("position.lat")}
                            longitude={onGetValue("position.lng")}
                            allowPositionChange={false}
                        />
                    </div>

                    <div className="data-point-details-settings">
                        <div className="data-point-locations">
                            <div className="data-point-location">
                                <Label>Latitude</Label>
                                <Input disabled={true} value={onGetValue("position.lat")} />
                            </div>

                            <div className="data-point-location">
                                <Label>Longitude</Label>
                                <Input disabled={true} value={onGetValue("position.lng")} />
                            </div>
                        </div>

                        <DataPointAutomaticData onDataEdit={() => setShowDataModal(true)} />
                    </div>

                    {showDataModal && (
                        <DataPointObservedDataModal
                            stationId={onGetValue("station")}
                            show={showDataModal}
                            onClose={() => setShowDataModal(false)}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default DataPointAutomaticDetails;
