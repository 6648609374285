import React from "react";
import HeaderSortIndicator from "./HeaderSortIndicator";
import { HeaderSortContext } from "./types";
import clsx from "clsx";

import "./SortableHeader.scss";

export type SortFunction = (sortKey: string, sorter?: (val: any) => any) => void;

interface SortableHeaderProps {
    label: string;
    sortKey: string;
    context: HeaderSortContext<any>;
    style?: React.CSSProperties;
    className?: string;
    centered?: boolean;
    sorter?: (val: any) => any;
    onSort: SortFunction;
}

const SortableHeader = ({
    label,
    sortKey,
    context,
    centered = false,
    style,
    className,
    sorter,
    onSort
}: SortableHeaderProps) => {
    const handleSort = () => {
        onSort(sortKey, sorter);
    };

    const sortContext = context?.header === sortKey ? context : null;

    return (
        <th style={style} className={clsx("sort-header", { centered: centered === true }, className)}>
            <div onClick={handleSort}>
                {label} <HeaderSortIndicator context={sortContext} />
            </div>
        </th>
    );
};

export default SortableHeader;
