import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { isNil } from "lodash";
import FileProgress from "./FileProgress";
import { sleep } from "utils/utils";
import { DataPointDataModel } from "types/models";
import DataPointService from "Services/data-point.service";

import "./FileUploader.scss";

export interface ProgressContext {
    progress: number;
    label: string;
}

export interface DataPointFileUploaderModalOptions {
    dataPointId: string;
    files: File[];
}

interface DataPointFileUploaderModalProps extends DataPointFileUploaderModalOptions {
    show: boolean;
    onClose: () => void;
    onFileUploaded: (data: DataPointDataModel[]) => void;
}

const DataPointFileUploaderModal = ({ dataPointId, files, onFileUploaded }: DataPointFileUploaderModalProps) => {
    const [context, setContext] = useState<ProgressContext>({ progress: 0, label: "Uploading File" });

    useEffect(() => {
        const doUpload = async () => {
            const dataPointData: DataPointDataModel[] = [];

            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const label = `Uploading File - ${i + 1}/${files.length}`;

                setContext({ progress: 0, label: label });

                await sleep(500);

                const result = await DataPointService.uploadDataPointData(
                    dataPointId,
                    file,
                    null,
                    false,
                    null,
                    progress => {
                        setContext({ progress: progress, label: label });
                    }
                );

                setContext({ progress: 100, label: label });

                dataPointData.push(result.data);

                await sleep(500);
            }

            onFileUploaded(dataPointData);
        };

        doUpload();
    }, []);

    return (
        <Modal show={open} centered backdrop="static">
            <Modal.Body>
                {!isNil(context) && <FileProgress progress={context?.progress ?? 0} label={context?.label} />}
            </Modal.Body>
        </Modal>
    );
};

export default DataPointFileUploaderModal;
