import React, { useState } from "react";
import { faDownload } from "@fortawesome/pro-regular-svg-icons";
import TooltipIcon from "Components/TooltipIcon";
import { BOMConnectionSettings, DataPointDataModel, ObservedDataSourceTypes } from "types/models";
import DataTypeSelect from "Components/DataTypeSelect";
import AzureBlobService from "Services/azure.blob.service";
import { faCheckCircle, faSync } from "@fortawesome/pro-solid-svg-icons";
import DataPointService from "Services/data-point.service";
import { useDataPoint } from "./DataPointContext";
import moment from "moment";
import EDSService from "Services/eds.service";
import { isNilOrEmpty } from "utils/utils";
import { Toast } from "Components/Toast";
import PreviewDataService from "Services/preview.data.service";
import Humanize from "humanize-plus";

interface DataPointAutomaticDataFileProps {
    data: DataPointDataModel;
    isLoadingAll: boolean;
}

const DataPointAutomaticDataFile = ({ data, isLoadingAll }: DataPointAutomaticDataFileProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { dataPoint, dataPointData, readOnly, updateDataPointData } = useDataPoint();

    const handleDownload = async () => {
        await AzureBlobService.downloadBlob(data.blobId, data.name);
    };

    const handleRefresh = async () => {
        try {
            setIsLoading(true);

            const [_data, _error] = await EDSService.getBOMData(
                data.dataType,
                (data.observationSettings.settings as BOMConnectionSettings).gauge
            );

            if (!isNilOrEmpty(_error)) {
                Toast.error(_error);
                return;
            }

            const file = PreviewDataService.formatTimeseriesAsFile(
                _data.data,
                `${_data.name}_${ObservedDataSourceTypes.BOM.toUpperCase()}_${Humanize.capitalize(data.dataType)}.csv`,
                `${_data.name}_${Humanize.capitalize(data.dataType)}_${_data.unit}`
            );

            const dataResult = await DataPointService.updateDataPointDataFile(
                dataPoint.id,
                data.id,
                file,
                data.dataType,
                data.isObserved,
                data.observationSettings
            );

            const currentIndex = dataPointData.findIndex(d => d.id === data.id);

            const next = [...dataPointData];
            next[currentIndex] = dataResult.data;

            updateDataPointData(next);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="data-point-file-section-data">
            <div className="data-point-file-section-info">
                <DataTypeSelect
                    allowedDataTypes={[data.dataType]}
                    dataType={data.dataType}
                    disabled={true}
                    onChanged={null}
                    isClearable
                />

                <span className="data-point-file-section-data-point-name">{data.name}</span>
            </div>

            <div className="data-point-file-section-icons">
                <TooltipIcon
                    className="data-download-icon"
                    icon={faDownload}
                    tooltip="Download file"
                    onClick={handleDownload}
                    size="lg"
                />

                <TooltipIcon
                    className="data-refresh-icon"
                    icon={faSync}
                    tooltip="Fetch latest data"
                    onClick={handleRefresh}
                    size="lg"
                    disabled={isLoading || isLoadingAll || readOnly}
                    spin={isLoading || isLoadingAll}
                />

                <TooltipIcon
                    icon={faCheckCircle}
                    tooltip={`Last refreshed - ${moment(data.updatedAt).format("DD MMM, YYYY hh:mm A")}`}
                    size="lg"
                    className="data-last-updated-icon"
                />
            </div>
        </div>
    );
};

export default DataPointAutomaticDataFile;
