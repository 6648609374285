import { ChartDataset } from "chart.js";
import { PluginId, PluginParameters, PluginSchema, ReportingTiers } from "./plugin";
import { CSV } from "./global";
import { Geometry } from "./geometry";

export enum GroupPrivilege {
    /**
     * Root Admin Privilege
     * Can only manage group users. Cannot see data
     */
    CAN_MANAGE_USERS = 1,

    /**
     * Group User.
     * Can only user data not manage user in group
     */
    CAN_USE = 6,

    /**
     * Group Admin.
     * Can user data and manage user in group
     */
    CAN_BE_GROUP_ADMIN = 7
}

export enum UserRole {
    ROOT = "root",
    APP_ADMIN = "app_admin",
    USER = "user"
}

export enum DataType {
    FLOW = "flow",
    DEPTH = "depth",
    TEMPERATURE = "temperature",
    RAINFALL = "rainfall",
    CLIMATE = "climate",
    EVAPORATION = "evaporation",
    SALINITY = "salinity",
    PH = "ph",
    TURBIDITY = "turbidity",
    STORAGE_VOLUME = "storage_volume",
    STORAGE_DEPTH = "storage_depth"
}

export enum ResourceType {
    DATA_POINT = "data_point",
    COLLECTION = "collection",
    RULE = "rule",
    FOLDER = "folder"
}

export enum RuleResultType {
    YEARLY_RESULTS = "yearly_results",
    DAILY_INTERMEDIATE = "daily_intermediate_results",
    YEARLY_INTERMEDIATE = "yearly_intermediate_results",
    SUMMARY_INTERMEDIATE = "summary_intermediate_results",
    EVENTS_INTERMEDIATE = "events_intermediate_results",
    RUN_SETTINGS_LOG = "run_settings_log",
    PARAMETER_RESULTS = "parameter_results",
    CUSTOM_RESULTS = "custom_results"
}

export enum TaskStatus {
    PENDING = "pending",
    RUNNING = "running",
    COMPLETED = "completed",
    FAILED = "failed"
}

export enum TaskType {
    COMPUTATION = "computation"
}

export enum SuccessLevel {
    VERY_LOW = "very_low",
    LOW = "low",
    MODERATE = "moderate",
    HIGH = "high",
    VERY_HIGH = "very_high",
    NONE = "none"
}

export interface BaseEntity {
    id: string;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface FolderModel extends BaseEntity {
    name: string;
    parentId: string;
    isRoot: boolean;
    createdBy: CreatedByUserModel;
}

export interface RuleModel extends BaseEntity {
    name: string;
    folderId: string;
    description: string;
    reportingTiers: ReportingTiers[];
    plugin: PluginModel;
    dataPointId: string;
    latitude: number;
    longitude: number;
    parameters: PluginParameters;
    location: LocationModel;
    createdBy: CreatedByUserModel;
    lock: LockModel;
    version: string;
    runPeriod: RunPeriodModel;
    dataInfillSettings: DataInfillSettingsModel;
    runs: RuleRunModel[];
}

export interface DataPointModel extends BaseEntity {
    name: string;
    type: DataPointType;
    station: string;
    latitude: number;
    longitude: number;
    location: LocationModel;
    dataTypes: DataType[];
    version: string;
    createdBy: CreatedByUserModel;
    lock: LockModel;
}

export interface LocationModel extends BaseEntity {
    id: string;
    code: string;
    parent: string;
    name: string;
    kind: LocationType;
    geometry: Geometry;
}

export enum LocationType {
    REGION = "region",
    CATCHMENT = "catchment",
    SUB_CATCHMENT = "sub_catchment"
}

export interface CollectionModel extends BaseEntity {
    name: string;
    folderId: string;
    description: string;
    createdBy: CreatedByUserModel;
    lock: LockModel;
}

export interface SearchModel extends BaseEntity {
    name: string;
    type: ResourceType;
    createdBy: CreatedByUserModel;
    breadcrumbs: string;
    ancestorIds: string[];
}

export interface GroupModel extends BaseEntity {
    name: string;
    rootFolderId: string;
    privilege: GroupPrivilege;
}

export interface User extends BaseEntity {
    id: string;
    auth0Id?: string;
    currentGroupId: string;
    email: string;
    normalisedEmail: string;
    firstName: string;
    lastName: string;
    role: UserRole;
    verified: boolean;
    groups: GroupModel[];
}

export interface GroupMember {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    username: string;
    privilege: GroupPrivilege;
    verified: boolean;
    role: UserRole;
}

export interface BaseDataModel extends BaseEntity {
    name: string;
    blobId: string;
    dataType: DataType;
    extension: string;
    size: number;
    isObserved: boolean;
    observationSettings: ObservedDataSettings;
}

export interface DataPointDataModel extends BaseDataModel {
    dataPointId: string;
    updatedAt: Date;
}

export interface Attachment extends BaseEntity {
    name: string;
    extension: string;
    blobId: string;
}

export interface RuleVersionModel {
    version: string;
}

export interface RuleRunModel {
    id: string;
    ruleVersion: string;
    createdAt: Date;
    dataPointVersions: RuleRunDataPointVersionModel[];
    results: RuleResultModel[];
}

export interface RuleRunDataPointVersionModel {
    data_point_id: string;
    data_point_version: string;
}

export interface RuleResultModel {
    id: string;
    blobId: string;
    name: string;
    sizeInBytes: number;
    extension: string;
    resultType: RuleResultType;
    createdAt: Date;
}

export interface TaskError {
    code: string;
    message: string;
}

export interface TaskModel {
    id: string;
    resourceId: string;
    status: TaskStatus;
    type: TaskType;
    error?: TaskError;
    progress: number;
    updatedAt: Date;
}

export interface PluginModel extends BaseEntity {
    name: string;
    identifier: PluginId;
    schema: PluginSchema;
    version: string;
    isCurrent: boolean;
}

export interface CreatedByUserModel extends BaseEntity {
    id: string;
    firstName: string;
    lastName: string;
}

export interface ResourceLockModel {
    id: string;
    resourceId: string;
    resourceType: ResourceType;
    owner: ResourceLockOwnerModel;
    createdAt: Date;
}

export enum ResourceLockErrors {
    ENTITY_NOT_FOUND = "ENTITY_NOT_FOUND"
}

export interface ResourceLockOwnerModel {
    id: string;
    firstName: string;
    lastName: string;
}

export interface ResourceLockResultModel {
    lock: ResourceLockModel;
    error?: ResourceLockError;
    success: boolean;
}

interface ResourceLockError {
    code: string;
    message: string;
}

export interface ResourceUnLockResultModel {
    error?: ResourceLockError;
    success: boolean;
}

export interface LockModel {
    id: string;
    owner: ResourceLockOwnerModel;
}

export interface RunPeriodModel {
    start: PeriodModel;
    end: PeriodModel;
}

export interface PeriodModel {
    day: number;
    month: string;
    year: number;
}

export interface ExplorePeriodModel {
    minYear: number;
    maxYear: number;
    startYear: number;
    endYear: number;
    years: number[];
    sliderMarks: number[];
}

export interface ExploreDailyPeriodModel {
    minDay: number;
    maxDay: number;
    startDay: number;
    endDay: number;
    days: number[];
    sliderMarks: number[];
}

export type RunResultData = CSV<string, string>;

export interface EflowChartData {
    labels: Array<string | string[] | number | number[] | Date | Date[]>;
    data: Array<ChartDataset>;
}

export type EflowTimerseriesChartData = Array<ChartDataset<"line", TimeseriesDay[]>>;

export type EflowPointChartData = Array<ChartDataset<"line", { x: number; y: number }[]>>;

export interface TimeseriesDay {
    date: Date;
    value: number;
}

export enum DataInfillType {
    EMPTY = "empty",
    PREVIOUS_VALUE = "previous_value"
}

export interface DataInfillSettingsModel {
    should_infill_data: boolean;
    infill_method: DataInfillType;
}

export enum DataPointType {
    MANUAL = "manual",
    AUTOMATIC = "automatic"
}

export interface DataPointDataPreviewData {
    dataType: DataType;
    data: TimeseriesDay[];
}

export interface StationModel {
    name: string;
    identifier: string;
    latitude: number;
    longitude: number;
}

export interface DataAvailabilityModel {
    name: string;
    identifier: string;
    from: string;
    to: string;
    dataType: DataType;
}

export interface CollectionExploreRuleResultModel {
    rule: RuleModel;
    hasResult: boolean;
    result?: RunResultData;
}

export interface RuleExploreResultsModel {
    hasSummary: boolean;
    hasIntermediate: boolean;
    summary: RuleExploreSummaryResultModel;
    intermediate: RuleExploreIntermediateResultModel;
}

export interface RuleExploreSummaryResultModel {
    yearly: RunResultData;
}

export interface RuleExploreIntermediateResultModel {
    daily: RunResultData;
    yearly: RunResultData;
}

export interface RuleIntermediateResultType {
    id: string;
    value: string;
    label: string;
    scale: RuleIntermediateResultTypeScale;
}

export enum RuleIntermediateResultTypeScale {
    DAILY = "daily",
    YEARLY = "yearly"
}

export interface RuleIntermediateResults {
    result: RuleIntermediateResultDailyTimeseries | RuleIntermediateResultYearlyTimeseries;
    yearlyResultPeriod: ExplorePeriodModel;
    dailyResultPeriod?: ExploreDailyPeriodModel;
    minValue?: number;
    maxValue?: number;
    resultsType: RuleIntermediateResultType;
}

export type RuleIntermediateResultDailyTimeseries = Record<number, TimeseriesDay[]>;

export type RuleIntermediateResultYearlyTimeseries = TimeseriesDay[];

export enum ObservedDataSourceTypes {
    SILO = "silo",
    BOM = "bom"
}

export interface ObservedDataSettings {
    source: ObservedDataSourceTypes;
    dataType?: DataType;
    period?: ObservedDataPeriodSettings;
    settings?: ObservedDataConnectionSettings;
}

export interface ObservedDataPeriodSettings {
    startYear: number;
    endYear: number;
}

export type ObservedDataConnectionSettings = SILOConnectionSettings | BOMConnectionSettings;

export interface SILOConnectionSettings {
    latitude: number;
    longitude: number;
}

export interface BOMConnectionSettings {
    gauge: string;
}
