import LinkCell from "Scenes/Explorer/LinkCell";
import clsx from "clsx";
import { isNil } from "lodash";
import React, { useMemo } from "react";

interface DataPointCellProps {
    children: React.ReactNode;
    id: string;
    onDataPointClicked: () => void;
    className?: string;
}

const DataPointCell = ({ id, onDataPointClicked, className, children }: DataPointCellProps) => {
    const to = useMemo(() => {
        return `/data-point/${id}`;
    }, [id]);

    return (
        <>
            {isNil(onDataPointClicked) && (
                <LinkCell to={to} className={className}>
                    {children}
                </LinkCell>
            )}

            {!isNil(onDataPointClicked) && (
                <td className={clsx(className, "clickable-cell")} onClick={onDataPointClicked}>
                    {children}
                </td>
            )}
        </>
    );
};

export default DataPointCell;
