import ERPModal from "Components/Modals/ERPModal";
import { ModalProps } from "Components/Modals/types";
import DataLibraryContainer from "Scenes/DataLibrary/DataLibraryContainer";
import React from "react";
import { DataPointModel, RuleModel } from "types/models";

interface RuleDataPointSelectModalProps extends ModalProps {
    rule: RuleModel;
    onDataPointSelected: (dataPoint: DataPointModel) => void;
}

const RuleDataPointSelectModal = ({ rule, show, onClose, onDataPointSelected }: RuleDataPointSelectModalProps) => {
    const handleDataPointSelected = (dataPoint: DataPointModel) => {
        onDataPointSelected(dataPoint);
    };

    return (
        <ERPModal
            show={show}
            title={`Select data point - ${rule.name}`}
            onClose={onClose}
            size="xl"
            scrollable
            backdrop={true}
            bodyClassName="rule-data-point-select-modal-body"
            className="rule-data-point-select-modal"
            dialogClassName="rule-data-point-select-modal-dialog"
            contentClassName="rule-data-point-select-modal-content"
        >
            <DataLibraryContainer
                allowNewData={false}
                allowDataActions={false}
                onDataPointClicked={handleDataPointSelected}
            />
        </ERPModal>
    );
};

export default RuleDataPointSelectModal;
