import { KNOWN_HTTP_STATUS_CODES } from "api/base.api";
import { Attachment, TaskModel, RuleModel, RuleVersionModel, RuleRunModel, DataPointModel } from "types/models";
import { ParameterUpdateResult, PluginParameters } from "types/plugin";
import {
    BulkCopyRuleRequest,
    BulkRuleDeleteRequest,
    BulkMoveRuleRequest,
    CopyRuleRequest,
    MoveRuleRequest,
    SetRulePluginRequest,
    SetRulePluginResponse,
    UpdateRuleNameRequest,
    UpdateRulePluginResponse,
    UpdateRuleRequest,
    UpdateRuleRunSettingsRequest,
    UpdateParametersRequest,
    UpdateParametersResponse
} from "types/requests";
import API from "../api/data.api";
import { URL } from "../Constants/api";

export default class RuleService {
    public static async getRule(id: string): Promise<RuleModel> {
        const rule = await API.get<RuleModel>(`${URL.RULES}/${id}`);
        return rule;
    }

    public static async getRules(): Promise<RuleModel[]> {
        const rules = await API.get<RuleModel[]>(`${URL.RULES}`);
        return rules;
    }

    public static async getRuleDataPoints(id: string): Promise<DataPointModel[]> {
        const dataPoints = await API.get<DataPointModel[]>(`${URL.RULES}/${id}/data-points`);
        return dataPoints;
    }

    public static async getAttachments(id: string): Promise<Attachment[]> {
        const attachments = await API.get<Attachment[]>(`${URL.RULES}/${id}/attachments`);
        return attachments;
    }

    public static async updateRule(id: string, request: UpdateRuleRequest): Promise<RuleModel> {
        const rule = await API.patch<RuleModel>(`${URL.RULES}/${id}`, request);
        return rule;
    }

    public static async updateRuleName(id: string, request: UpdateRuleNameRequest): Promise<RuleModel> {
        const rule = await API.patch<RuleModel>(`${URL.RULES}/${id}/name`, request);
        return rule;
    }

    public static async updateRuleRunSettings(id: string, request: UpdateRuleRunSettingsRequest): Promise<RuleModel> {
        const rule = await API.patch<RuleModel>(`${URL.RULES}/${id}/run-settings`, request);
        return rule;
    }

    public static async setPlugin(id: string, pluginId: string): Promise<SetRulePluginResponse> {
        const request: SetRulePluginRequest = {
            pluginId: pluginId
        };

        const response = await API.post<SetRulePluginResponse>(`${URL.RULES}/${id}/plugin`, request);

        return response;
    }

    public static async updatePlugin(id: string): Promise<UpdateRulePluginResponse> {
        const response = await API.patch<UpdateRulePluginResponse>(`${URL.RULES}/${id}/plugin/update`);

        return response;
    }

    public static async updateParameters(id: string, parameters: PluginParameters): Promise<ParameterUpdateResult> {
        try {
            const request: UpdateParametersRequest = {
                parameters: parameters
            };

            const response = await API.patch<UpdateParametersResponse>(`${URL.RULES}/${id}/parameters`, request);

            return {
                parameters: response.parameters,
                version: response.version,
                success: true,
                failure: null
            };
        } catch (error) {
            if (API.isAxiosError(error)) {
                if (error.response.status === KNOWN_HTTP_STATUS_CODES.BAD_REQUEST) {
                    const apiError = error.response.data.error;

                    return {
                        parameters: null,
                        version: null,
                        success: false,
                        failure: { code: apiError.code, message: apiError.message }
                    };
                }
            } else {
                throw error;
            }
        }
    }

    public static async deleteRule(id: string): Promise<void> {
        await API.delete<void>(`${URL.RULES}/${id}`);
    }

    public static async deleteRules(ids: string[]): Promise<void> {
        const request: BulkRuleDeleteRequest = {
            rules: ids ?? []
        };

        await API.delete<void>(`${URL.RULES}`, request);
    }

    public static async copyRule(id: string, destinationFolderId: string): Promise<RuleModel> {
        const request: CopyRuleRequest = {
            ruleId: id,
            toFolder: destinationFolderId
        };

        const copiedRule = await API.post<RuleModel>(`${URL.RULES}/copy`, request);

        return copiedRule;
    }

    public static async copyRules(ids: string[], destinationFolderId: string): Promise<RuleModel[]> {
        const request: BulkCopyRuleRequest = {
            ruleIds: ids,
            toFolder: destinationFolderId
        };

        const copiedRules = await API.post<RuleModel[]>(`${URL.RULES}/copy/bulk`, request);

        return copiedRules;
    }

    public static async moveRule(id: string, destinationFolderId: string): Promise<RuleModel> {
        const request: MoveRuleRequest = {
            ruleId: id,
            toFolder: destinationFolderId
        };

        const movedRule = await API.post<RuleModel>(`${URL.RULES}/move`, request);

        return movedRule;
    }

    public static async moveRules(ids: string[], destinationFolderId: string): Promise<RuleModel[]> {
        const request: BulkMoveRuleRequest = {
            ruleIds: ids,
            toFolder: destinationFolderId
        };

        const movedRules = await API.post<RuleModel[]>(`${URL.RULES}/move/bulk`, request);

        return movedRules;
    }

    public static async uploadAttachment(
        ruleId: string,
        file: File,
        onProgress: (progress: number) => void
    ): Promise<Attachment> {
        const formData = new FormData();

        formData.append("file", file);

        const data = await API.uploadFormData<Attachment>(
            `${URL.RULES}/${ruleId}/attachments`,
            formData,
            {
                "Content-Type": "multipart/form-data"
            },
            onProgress
        );

        return data;
    }

    public static async deleteAttachment(ruleId: string, attachmentId: string): Promise<void> {
        await API.delete<void>(`${URL.RULES}/${ruleId}/attachments/${attachmentId}`);
    }

    public static async getVersion(id: string): Promise<RuleVersionModel> {
        const version = await API.get<RuleVersionModel>(`${URL.RULES}/${id}/version`);
        return version;
    }

    public static async getComputationStatus(id: string): Promise<TaskModel> {
        const status = await API.get<TaskModel>(`${URL.RULES}/${id}/computation/status`);
        return status;
    }

    public static async getRuleRuns(id: string): Promise<RuleRunModel[]> {
        const runs = await API.get<RuleRunModel[]>(`${URL.RULES}/${id}/computation/runs`);
        return runs;
    }
}
