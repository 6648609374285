import React from "react";
import NumericInput from "Components/NumericInput";
import { Controller, useFormContext } from "react-hook-form";
import { CheckboxInputOptions as CheckboxInputOptions } from "types/plugin";
import { PluginFieldProps, PluginForm } from "./types";
import Checkbox from "Components/Checkbox";

interface PluginParameterCheckboxInputProps extends PluginFieldProps {
    onChange?: (field: string, value: Record<string, string | number | boolean>) => void;
}

const PluginParameterCheckboxInput = ({
    id,
    definition,
    field,
    control,
    disabled,
    onChange
}: PluginParameterCheckboxInputProps) => {
    const { watch } = useFormContext<PluginForm>();

    const [checkboxInput] = watch([`${definition.id}.${field.id}`]);

    const handleCheckboxInputValueChanged = (value: number) => {
        const currentIsChecked = checkboxInput?.isChecked;

        onChange(field.id, { isChecked: currentIsChecked, value: value });
    };

    const handleCheckboxInputCheckedChanged = (isChecked: boolean) => {
        onChange(field.id, { isChecked: isChecked, value: null });
    };

    const options: CheckboxInputOptions = field.control.options as CheckboxInputOptions;

    return (
        <div className="checkbox-value-parameter-field">
            <Controller
                control={control}
                name={`${definition.id}.${field.id}.isChecked`}
                render={({ field: _field }) => {
                    return (
                        <Checkbox
                            id={id}
                            containerClassName="checkbox-value-checkbox"
                            label={options.checkbox_label}
                            checked={_field.value}
                            disabled={disabled}
                            onChecked={handleCheckboxInputCheckedChanged}
                        />
                    );
                }}
            />

            <Controller
                control={control}
                name={`${definition.id}.${field.id}.value`}
                render={({ field: _field }) => {
                    return (
                        <NumericInput
                            id={id}
                            containerClassName="checkbox-value-input"
                            unit={field.control?.unit}
                            min={field.control?.range?.min}
                            max={field.control?.range?.max}
                            value={_field.value}
                            disabled={disabled || !checkboxInput?.isChecked}
                            onChange={handleCheckboxInputValueChanged}
                        />
                    );
                }}
            />
        </div>
    );
};

export default PluginParameterCheckboxInput;
