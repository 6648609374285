import React, { useContext, useEffect, useState } from "react";
import { LocationModel } from "../../types/models";
import LocationService from "Services/location.service";

interface LocationContextProps {
    locations: LocationModel[];
}

interface LocationContextOptions {
    children: React.ReactNode | React.ReactNode[];
}

export const LocationContext = React.createContext<LocationContextProps | null>(null);

export const useLocations = () => useContext(LocationContext);

const LocationProvider = ({ children }: LocationContextOptions) => {
    const [locations, setLocations] = useState<LocationModel[]>([]);

    useEffect(() => {
        const getLocations = async () => {
            const [_locations] = await Promise.all([LocationService.getLocations()]);

            setLocations(_locations);
        };

        getLocations();
    }, []);

    return (
        <LocationContext.Provider
            value={{
                locations: locations
            }}
        >
            {children}
        </LocationContext.Provider>
    );
};

export default LocationProvider;
