import React, { useMemo } from "react";
import LinkCell, { LinkCellProps } from "./LinkCell";

type CollectionLinkCellProps = Omit<LinkCellProps, "to"> & { id: string };

const CollectionLinkCell = ({ id, ...rest }: CollectionLinkCellProps) => {
    const to = useMemo(() => {
        return `/collection/${id}`;
    }, [id]);

    return <LinkCell to={to} {...rest}></LinkCell>;
};

export default CollectionLinkCell;
