import { csvFormat } from "d3-dsv";
import moment from "moment";
import { DataType, TimeseriesDay } from "types/models";

const CHART_LEGEND_COLOUR_OPTIONS = [
    "#FF671B",
    "#08BFDD",
    "#E24999",
    "#FDCD06",
    "#77BC1F",
    "#D2272E",
    "#0076BB",
    "#8547AD",
    "#FFA400",
    "#00994D"
];

interface ChartDataset {
    label: string;
    data: TimeseriesDay[];
    backgroundColor: string;
    pointRadius: number;
    borderWidth: number;
    borderColor: string;
    pointHitRadius: number;
}

interface ParsedData {
    name: string;
    data: TimeseriesDay[];
}

export default class PreviewDataService {
    static getDataTypeLabel = (dataType: DataType): string => {
        switch (dataType) {
            case DataType.FLOW:
                return "Flow";
            case DataType.DEPTH:
                return "Depth";
            case DataType.TEMPERATURE:
                return "Temperature";
            case DataType.RAINFALL:
                return "Rainfall";
            case DataType.EVAPORATION:
                return "Evaporation";
            case DataType.SALINITY:
                return "Salinity";
            case DataType.PH:
                return "pH";
            case DataType.TURBIDITY:
                return "Turbidity";
            case DataType.STORAGE_VOLUME:
                return "Storage Volume";
            case DataType.STORAGE_DEPTH:
                return "Storage Depth";

            default:
                return "Value";
        }
    };

    static formatChartDataObject(label: string, data: TimeseriesDay[], colour: string): ChartDataset {
        return {
            label: label,
            data: data,
            backgroundColor: colour,
            pointRadius: 0,
            borderWidth: 2,
            borderColor: colour,
            pointHitRadius: 5
        };
    }

    static buildChartDataSet(data: ParsedData[]): ChartDataset[] {
        return data.map((d, i) => {
            return this.formatChartDataObject(
                d.name,
                d.data,
                CHART_LEGEND_COLOUR_OPTIONS[i % CHART_LEGEND_COLOUR_OPTIONS.length]
            );
        });
    }

    static formatTimeseriesAsFile(data: TimeseriesDay[], name: string, valueHeader: string): File {
        const formattedData = csvFormat(
            data.map(d => {
                const dayRecord = {};

                dayRecord["date"] = moment(d.date).format("DD/MM/YYYY");
                dayRecord[valueHeader] = d.value;

                return dayRecord;
            })
        );

        return new File([formattedData], name);
    }
}
