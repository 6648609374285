import ERPChart from "Components/Charts/ERPChart";
import { ChartDataset, ChartOptions } from "chart.js";
import { isNil, merge } from "lodash";
import Humanize from "humanize-plus";
import React, { useMemo, useState } from "react";
import { EflowChartData, RunResultData, SuccessLevel } from "types/models";
import { formatYearlyBarChartData, mapSuccessToChartColour, mapSuccessToLabel } from "utils/explorer-charts.utils";
import RuleExploreYearlyChartLegend from "./RuleExploreYearlyBarChartLegend";

interface RuleExploreYearlyBarChartProps {
    yearlyResult: RunResultData;
    startYear: number;
    endYear: number;
    title: string;
}

const STEP_SIZE = 1;

const CHART_OPTIONS: ChartOptions = {
    maintainAspectRatio: false,
    interaction: {
        mode: "index"
    },
    indexAxis: "y",
    scales: {
        x: {
            grid: { display: false, drawBorder: false },
            stacked: true
        },
        y: {
            grid: { display: false, drawBorder: false },
            ticks: {
                display: false,
                maxTicksLimit: 6
            },
            stacked: true
        }
    },
    layout: {
        padding: { bottom: 5, left: 5, right: 5, top: 20 }
    },
    plugins: {
        tooltip: {
            enabled: true,
            intersect: true,
            mode: "point",
            position: "stackedBarPositioner",
            callbacks: {
                label: function (tooltipItem) {
                    const numberOfYears = parseInt(tooltipItem.formattedValue);
                    const risk = mapSuccessToLabel(tooltipItem.dataset.label as SuccessLevel);

                    const _label = `${numberOfYears} ${Humanize.pluralize(numberOfYears, "year", "years")} - ${risk}`;

                    return _label;
                },
                footer: function (tooltipItems) {
                    const toolTipItem = tooltipItems[0].dataset as any;
                    if (toolTipItem.context.fromYear === toolTipItem.context.toYear) {
                        return `${toolTipItem.context.fromYear}`;
                    } else {
                        return `${toolTipItem.context.fromYear} to ${toolTipItem.context.toYear}`;
                    }
                }
            }
        },
        legend: {
            display: false
        },
        title: {
            display: true,
            position: "top",
            align: "start",
            text: "Default Title",
            font: {
                size: 18
            }
        }
    },
    hover: {
        mode: "index",
        intersect: false
    },
    animation: false
};

const RuleExploreYearlyBarChart = ({ yearlyResult, startYear, endYear, title }: RuleExploreYearlyBarChartProps) => {
    const [selectedSuccess, setSelectedSuccess] = useState<SuccessLevel[]>([
        SuccessLevel.NONE,
        SuccessLevel.VERY_LOW,
        SuccessLevel.LOW,
        SuccessLevel.MODERATE,
        SuccessLevel.HIGH,
        SuccessLevel.VERY_HIGH
    ]);

    const options = useMemo(() => {
        return merge({}, CHART_OPTIONS, {
            plugins: { title: { text: title } },
            scales: {
                x: {
                    min: 0,
                    max: endYear - startYear + 1,
                    ticks: {
                        stepSize: STEP_SIZE,
                        callback: function (_, index) {
                            const year = startYear + STEP_SIZE * index;
                            return year;
                        }
                    }
                }
            }
        });
    }, [title, startYear, endYear]);

    const chartData: EflowChartData = useMemo(() => {
        const yearlyResultData = formatYearlyBarChartData(yearlyResult, startYear, endYear);

        const _labels = ["Yearly"];

        const yearlyData: ChartDataset[] = yearlyResultData.map(r => {
            return {
                label: r.success,
                data: [r.count, 0],
                backgroundColor: mapSuccessToChartColour(r.success),
                hoverBackgroundColor: mapSuccessToChartColour(r.success),
                context: { fromYear: r.fromYear, toYear: r.toYear }
            };
        });

        const _chartData = [...yearlyData];

        return { labels: _labels, data: _chartData };
    }, [yearlyResult, startYear, endYear]);

    const filteredChartData: EflowChartData = useMemo(() => {
        const data = chartData.data.map(d => {
            const isSelected = !isNil(d.label) ? selectedSuccess.includes(d.label as SuccessLevel) : true;

            return {
                ...d,
                backgroundColor: isSelected ? d.backgroundColor : "#efefef",
                barThickness: 50
            } as ChartDataset;
        });
        return { ...chartData, data: data };
    }, [selectedSuccess.length, chartData]);

    return (
        <div className="chart">
            <RuleExploreYearlyChartLegend selectedSuccess={selectedSuccess} onSuccessChanged={setSelectedSuccess} />

            <ERPChart
                id="summary_yearly_chart"
                type="bar"
                labels={filteredChartData.labels}
                data={filteredChartData.data}
                options={options}
                height={300}
            />
        </div>
    );
};

export default RuleExploreYearlyBarChart;
