import { useState } from "react";
import { SelectionState } from "./types";
import { DataPointModel } from "types/models";

export function useSelection(dataPoints: DataPointModel[]) {
    const [avaliableDataPoints, setAvaliableDataPoints] = useState<DataPointModel[]>(dataPoints);
    const [selection, setSelection] = useState<SelectionState>({
        dataPoints: new Set<string>()
    });

    const selectAll = (selected: boolean) => {
        const state: SelectionState = {
            dataPoints: new Set<string>()
        };

        if (selected) {
            for (let i = 0; i < avaliableDataPoints.length; i++) {
                const dataPoint = avaliableDataPoints[i];

                state.dataPoints.add(dataPoint.id);
            }
        } else {
            state.dataPoints.clear();
        }

        setSelection(state);
    };

    const selectDataPoint = (dataPoint: DataPointModel, selected: boolean) => {
        const state: SelectionState = { ...selection };

        if (selected) {
            state.dataPoints.add(dataPoint.id);
        } else {
            state.dataPoints.delete(dataPoint.id);
        }

        setSelection(state);
    };

    const deselectDataPoint = (dataPoint: DataPointModel) => {
        if (!selection.dataPoints.has(dataPoint.id)) {
            return;
        }

        selectDataPoint(dataPoint, false);
    };

    const clearSelection = () => {
        setSelection({ dataPoints: new Set<string>() });
    };

    return {
        selection,
        selectAll,
        selectDataPoint,
        deselectDataPoint,
        clearSelection,
        setAvaliableDataPoints
    };
}
