export const URL = {
    ME: "/me",
    FOLDERS: "/folders",
    COLLECTIONS: "/collections",
    RULES: "/rules",
    DATA_POINTS: "/data-points",
    PLUGINS: "/plugins",
    GROUPS: "/groups",
    REGISTER: "/register",
    COMPUTATION: "/computation",
    LOCATIONS: "/locations"
};
