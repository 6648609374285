import React, { useMemo } from "react";
import { faExclamationTriangle, faPlay } from "@fortawesome/pro-solid-svg-icons";
import Button from "Components/Button";
import LoadingIndicator from "Components/LoadingIndicator";
import { useRule } from "./RuleContext";
import PageTitle from "Components/PageTitle";
import RuleRunModal from "./RuleRunModal";
import { useInterval } from "Hooks/useInterval";
import RuleService from "Services/rule.service";
import { Toast } from "Components/Toast";
import { TaskStatus } from "types/models";
import { isNil } from "lodash";
import TaskStatusDisplay from "Components/TaskStatusDisplay/TaskStatusDisplay";
import TooltipIcon from "Components/TooltipIcon";
import { isNilOrEmpty } from "utils/utils";

const POLLING_TIME_MS = 2500;

interface OutdatedWarningModel {
    isOutdated: boolean;
    outdatedMessage?: string;
}

const RuleHeader = () => {
    const { rule, dataPoints, isSaving, readOnly, task, runs, showRunModal, updateTask, setRuns, setShowRunModal } =
        useRule();

    useInterval(
        async () => {
            const nextTask = await RuleService.getComputationStatus(rule.id);

            if (isNil(task) || task.status === nextTask.status) {
                return;
            }

            switch (nextTask.status) {
                case TaskStatus.COMPLETED: {
                    Toast.success(`${rule.name} successfully computed`);
                    const nextRuns = await RuleService.getRuleRuns(rule.id);
                    setRuns(nextRuns);
                    break;
                }

                case TaskStatus.FAILED:
                    Toast.error(`${rule.name}: ${nextTask.error.message}`);
                    break;
            }

            updateTask(nextTask);
        },
        task?.status === TaskStatus.RUNNING || task?.status === TaskStatus.PENDING ? POLLING_TIME_MS : null
    );

    const outdatedWarning: OutdatedWarningModel = useMemo(() => {
        if (!isNilOrEmpty(runs)) {
            //The runs are sorted by created at, newest first from the API
            const latestRun = runs[0];

            const isRunOutOfDateFromRule = latestRun.ruleVersion !== rule.version;
            const isRunOutOfDateFromData = latestRun.dataPointVersions.some(dprv => {
                const dataPoint = dataPoints.find(dp => dp.id === dprv.data_point_id);

                return dataPoint?.version !== dprv.data_point_version;
            });

            let outdatedMessage = "";
            if (isRunOutOfDateFromRule && isRunOutOfDateFromData) {
                outdatedMessage = "The rule settings and data have changed since the last run";
            } else if (isRunOutOfDateFromRule) {
                outdatedMessage = "The rule settings have changed since the last run";
            } else if (isRunOutOfDateFromData) {
                outdatedMessage = "The rule data has changed since the last run";
            }

            return {
                isOutdated: isRunOutOfDateFromRule || isRunOutOfDateFromData,
                outdatedMessage: outdatedMessage
            };
        }

        return {
            isOutdated: false
        };
    }, [runs.length, rule.version]);

    return (
        <div className="rule-header">
            <PageTitle title={rule.name} />

            <div className="rule-header-title">
                <h5>{rule.name}</h5>

                {outdatedWarning.isOutdated && (
                    <TooltipIcon
                        icon={faExclamationTriangle}
                        tooltip={outdatedWarning.outdatedMessage}
                        placement="right"
                        className="outdated-icon"
                        size="2x"
                    />
                )}
            </div>

            <div className="rule-header-actions">
                {!isNil(task) && <TaskStatusDisplay task={task} label="Computation" showProgress={true} />}

                {isSaving && <LoadingIndicator size="lg" className="saving-indicator" />}

                <Button variant="secondary" icon={faPlay} disabled={isSaving} onClick={() => setShowRunModal(true)}>
                    Run this configuration
                </Button>
            </div>

            {showRunModal && (
                <RuleRunModal show rule={rule} readOnly={readOnly} onClose={() => setShowRunModal(false)} />
            )}
        </div>
    );
};

export default RuleHeader;
