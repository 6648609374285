import Checkbox from "Components/Checkbox";
import Label from "Components/Label";
import NumericInput from "Components/NumericInput";
import RadioButton from "Components/RadioButton";
import React from "react";
import { ARITypes, ARIThresholdSettings } from "types/plugin";

interface PluginParameterARIThresholdProps {
    thresholdSettings: ARIThresholdSettings;
    label?: string;
    disabled?: boolean;
    onChange: (thresholdSettings: ARIThresholdSettings) => void;
}

const PluginParameterARIThreshold = ({
    thresholdSettings,
    label,
    disabled,
    onChange
}: PluginParameterARIThresholdProps) => {
    const isFlowTypeSelected = thresholdSettings.type === ARITypes.FLOW;

    return (
        <div className="threshold-parameter-field">
            <Label className="threshold-label">{label}</Label>
            <div className="threshold-ari-parameter-field">
                <div className="threshold-ari-radio-button-group">
                    <RadioButton
                        containerClassName="threshold-ari-radio-button"
                        label="Use ARI flow"
                        checked={thresholdSettings.type === ARITypes.FLOW}
                        disabled={disabled}
                        onChecked={() => onChange({ ...thresholdSettings, type: ARITypes.FLOW })}
                    />

                    <RadioButton
                        containerClassName="threshold-ari-radio-button"
                        label="Use threshold"
                        checked={thresholdSettings.type === ARITypes.THRESHOLD}
                        disabled={disabled}
                        onChecked={() => onChange({ ...thresholdSettings, type: ARITypes.THRESHOLD })}
                    />
                </div>

                {isFlowTypeSelected && (
                    <div className="threshold-ari-group">
                        <NumericInput
                            containerClassName="threshold-ari-input"
                            value={thresholdSettings.value}
                            min={0}
                            disabled={disabled}
                            onChange={value => onChange({ ...thresholdSettings, value: value })}
                            unit="years"
                        />

                        <Checkbox
                            containerClassName="threshold-ari-data-checkbox"
                            label="Calculate from default data"
                            checked={thresholdSettings.use_default_data}
                            onChecked={checked => onChange({ ...thresholdSettings, use_default_data: checked })}
                            disabled={disabled}
                        />
                    </div>
                )}

                {!isFlowTypeSelected && (
                    <NumericInput
                        containerClassName="threshold-threshold-input"
                        value={thresholdSettings.value}
                        min={0}
                        disabled={disabled}
                        onChange={value => onChange({ ...thresholdSettings, value: value })}
                    />
                )}
            </div>
        </div>
    );
};

export default PluginParameterARIThreshold;
