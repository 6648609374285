import { useConfirmation } from "Components/ConfirmDialog/ConfirmationContext";
import LoadingIndicator from "Components/LoadingIndicator";
import PluginParameters from "Components/Plugin/PluginParameters";
import Select, { SelectOption } from "Components/Select/Select";
import { Toast } from "Components/Toast";
import { isNil } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import RuleService from "Services/rule.service";
import { PluginModel } from "types/models";
import { PluginParameters as PluginFieldParameters, PluginScopes } from "types/plugin";
import { isNilOrEmpty, sleep } from "utils/utils";
import { useRule } from "./RuleContext";
import PluginUpdate from "./PluginUpdate";

interface PluginOption extends SelectOption {
    plugin: PluginModel;
}

const RuleSettings = () => {
    const { rule, readOnly, plugins, setPlugin, setSaving, updateParameters } = useRule();

    const confirm = useConfirmation();

    const [isSettingPlugin, setIsSettingPlugin] = useState<boolean>(false);
    const [selectedPlugin, setSelectedPlugin] = useState<PluginOption>(null);

    useEffect(() => {
        if (isNilOrEmpty(rule?.plugin?.id) || isNilOrEmpty(plugins)) {
            return;
        }

        if (!isNil(rule.plugin)) {
            setSelectedPlugin({ value: rule.plugin.id, label: rule.plugin.name, plugin: rule.plugin });
        }
    }, [rule?.plugin?.id]);

    const handlePluginSelected = async (option: PluginOption) => {
        try {
            if (rule.plugin?.id === option.value) {
                return;
            }

            if (!isNil(rule.plugin)) {
                const result = await confirm({
                    title: "Warning",
                    description: "All modified parameters and computation results will be lost. Do you wish to proceed?"
                });

                if (!result.success) {
                    return;
                }
            }

            setIsSettingPlugin(true);

            const result = await RuleService.setPlugin(rule.id, option.value);

            setPlugin(option.plugin, result.parameters, true);

            setSelectedPlugin(option);
        } finally {
            await sleep(250);

            setIsSettingPlugin(false);
        }
    };

    const handleParametersChanged = async (parameters: PluginFieldParameters) => {
        try {
            setSaving(true);

            const result = await RuleService.updateParameters(rule.id, parameters);

            if (!result.success) {
                Toast.error(result.failure.message);
                return;
            }

            updateParameters(result.parameters, result.version);
        } finally {
            await sleep(250);

            setSaving(false);
        }
    };

    const handlePluginUpdate = async () => {
        try {
            setIsSettingPlugin(true);

            const result = await RuleService.updatePlugin(rule.id);

            const currentPlugin = plugins.find(p => p.identifier === rule.plugin.identifier);

            setPlugin(currentPlugin, result.parameters);

            setSelectedPlugin({ label: currentPlugin.name, value: currentPlugin.id, plugin: currentPlugin });
        } finally {
            setIsSettingPlugin(false);
        }
    };

    const pluginOptions = useMemo(() => {
        return plugins?.map(s => {
            const option: PluginOption = {
                value: s.id,
                label: s.name,
                plugin: s
            };

            return option;
        });
    }, [plugins.length]);

    const hasPlugin = !isNil(selectedPlugin);
    const canShowPlugin = !isSettingPlugin && hasPlugin;

    return (
        <div className="rule-section-content rule-settings">
            <Select
                labelText="Select model"
                placeholder="Select a model"
                menuClassName="plugin-select"
                value={selectedPlugin}
                values={pluginOptions}
                disabled={readOnly}
                onSelected={handlePluginSelected}
            />

            {isSettingPlugin && <LoadingIndicator centered className="plugin-loader" />}

            {!isSettingPlugin && (
                <>
                    {hasPlugin && !selectedPlugin.plugin.isCurrent && <PluginUpdate onUpdate={handlePluginUpdate} />}

                    {canShowPlugin && (
                        <PluginParameters
                            id={rule.id}
                            parameters={rule?.parameters}
                            schema={selectedPlugin.plugin.schema}
                            disabled={readOnly}
                            scope={PluginScopes.RULE}
                            onParametersChanged={handleParametersChanged}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default RuleSettings;
