import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faUserCircle as MemberIcon } from "@fortawesome/pro-solid-svg-icons";
import { faUserCircle as PendingInviteIcon } from "@fortawesome/pro-regular-svg-icons/faUserCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { GroupMember, GroupPrivilege } from "types/models";
import { fullname } from "utils/utils";
import Tooltip from "Components/Tooltip";

import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";

interface GroupRowUserProps {
    member: GroupMember;
    isRemovable: boolean;
    onRemoved: (member: GroupMember) => void;
}

const GroupRowUser = ({ member, isRemovable, onRemoved }: GroupRowUserProps) => {
    const getIcon = (member: GroupMember): IconProp => {
        if (!member.verified) {
            return PendingInviteIcon;
        }

        return MemberIcon;
    };

    const getTooltip = (member: GroupMember): string => {
        switch (member.privilege) {
            case GroupPrivilege.CAN_USE:
                return "Group user";
            case GroupPrivilege.CAN_BE_GROUP_ADMIN:
                return "Group admin";
            case GroupPrivilege.CAN_MANAGE_USERS:
                return "Can manage users";
        }
    };

    const handleRemove = () => {
        onRemoved(member);
    };

    const displayedName = member.verified ? fullname(member) : member.email;
    const icon = getIcon(member);
    const tooltip = getTooltip(member);
    const isAdmin = member.privilege !== GroupPrivilege.CAN_USE;

    return (
        <div className={`${"group-user"} ${isAdmin ? "group-admin" : ""}`}>
            <Tooltip placement="bottom" tooltipContent={tooltip}>
                <FontAwesomeIcon icon={icon} />
            </Tooltip>

            <span>{displayedName}</span>

            {isRemovable && <FontAwesomeIcon icon={faTrashAlt} className="group-user-remove" onClick={handleRemove} />}
        </div>
    );
};

export default GroupRowUser;
