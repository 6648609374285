import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { PluginFieldProps, PluginForm } from "./types";
import Label from "Components/Label";
import { DataPointModel, DataType } from "types/models";
import DataPoint from "Components/Plugin/DataPoint";
import { DataPointOptions, PluginFieldArrayValue } from "types/plugin";
import { isNil } from "lodash";
import { Toast } from "Components/Toast";
import { useRule } from "Scenes/Rule/RuleContext";

interface PluginParameterDataPointMultiProps extends PluginFieldProps {
    onChange?: (field: string, value: PluginFieldArrayValue) => void;
}

const PluginParameterDataPointMulti = ({
    definition,
    field,
    control,
    disabled,
    onChange
}: PluginParameterDataPointMultiProps) => {
    const { dataPoints, updateDataPoints } = useRule();
    const { getValues } = useFormContext<PluginForm>();

    const options = field.control.options as DataPointOptions;

    const setDataPoint = async (_dataPoint: DataPointModel, _existingDataPoint?: DataPointModel) => {
        if (!isNil(options?.required_data_type) && !_dataPoint.dataTypes.some(d => d === options.required_data_type)) {
            Toast.error(`This datapoint has no ${options.required_data_type} data`);
            return;
        }

        let dataType = null;
        if (!isNil(options?.required_data_type)) {
            dataType = options.required_data_type;
        } else {
            dataType = _dataPoint.dataTypes.some(d => d === options.default_data_type)
                ? options.default_data_type
                : _dataPoint.dataTypes[0];
        }

        const currentParameterDataPoints = getValues(`${definition.id}.${field.id}`);

        if (currentParameterDataPoints.some(d => d.data_point_id === _dataPoint.id)) {
            Toast.error(`This datapoint is already selected`);
            return;
        }

        if (!dataPoints.some(d => d.id === _dataPoint.id)) {
            updateDataPoints([...dataPoints, _dataPoint]);
        }

        if (!isNil(_existingDataPoint)) {
            onChange(field.id, [
                ...currentParameterDataPoints.filter(d => d.data_point_id !== _existingDataPoint.id),
                { data_point_id: _dataPoint.id, data_type: dataType, lag: 0 }
            ]);
        } else {
            onChange(field.id, [
                ...currentParameterDataPoints,
                { data_point_id: _dataPoint.id, data_type: dataType, lag: 0 }
            ]);
        }
    };

    const updateDataPointDataType = async (_dataType: DataType, _existingDataPoint: DataPointModel) => {
        if (!isNil(options?.required_data_type) && _dataType !== options.required_data_type) {
            Toast.error(`This datapoint must use ${options.required_data_type} data`);
            return;
        }

        const currentParameterDataPoints = getValues(`${definition.id}.${field.id}`);
        const existingParameterDataPointIndex = currentParameterDataPoints.findIndex(
            d => d.data_point_id === _existingDataPoint.id
        );

        const nextParameterDataPoints = [...currentParameterDataPoints];
        nextParameterDataPoints[existingParameterDataPointIndex] = {
            ...nextParameterDataPoints[existingParameterDataPointIndex],
            data_type: _dataType
        };

        onChange(field.id, nextParameterDataPoints);
    };

    const updateRuleDataPointLag = async (_lag: number, _existingDataPoint: DataPointModel) => {
        const currentParameterDataPoints = getValues(`${definition.id}.${field.id}`);
        const existingParameterDataPointIndex = currentParameterDataPoints.findIndex(
            d => d.data_point_id === _existingDataPoint.id
        );

        const nextParameterDataPoints = [...currentParameterDataPoints];
        nextParameterDataPoints[existingParameterDataPointIndex] = {
            ...nextParameterDataPoints[existingParameterDataPointIndex],
            lag: _lag
        };

        onChange(field.id, nextParameterDataPoints);
    };

    const removeRuleDataPoint = async (_existingDataPoint: DataPointModel) => {
        const currentParameterDataPoints = getValues(`${definition.id}.${field.id}`);
        onChange(
            field.id,
            currentParameterDataPoints.filter(d => d.data_point_id !== _existingDataPoint.id)
        );
    };

    return (
        <div className="data-point-parameter-field">
            <Label className="plugin-definition-field-label">{field.name}</Label>

            <Controller
                control={control}
                name={`${definition.id}.${field.id}`}
                render={({ field: _field }) => {
                    return (
                        _field.value?.map(f => {
                            const dataPoint = dataPoints.find(d => d.id === f.data_point_id);

                            return (
                                <DataPoint
                                    key={f.data_point_id}
                                    dataPoint={dataPoint}
                                    dataType={f.data_type}
                                    lag={f.lag}
                                    disabled={disabled}
                                    showLag={options.allow_lag}
                                    onDataPointSelected={setDataPoint}
                                    onDataPointDataTypeChanged={updateDataPointDataType}
                                    onDataPointRemoved={removeRuleDataPoint}
                                    onDataPointLagChanged={updateRuleDataPointLag}
                                />
                            );
                        }) ?? null
                    );
                }}
            />

            <DataPoint
                dataPoint={null}
                dataType={null}
                lag={null}
                disabled={disabled}
                showLag={options.allow_lag}
                onDataPointSelected={setDataPoint}
                onDataPointDataTypeChanged={updateDataPointDataType}
                onDataPointRemoved={removeRuleDataPoint}
                onDataPointLagChanged={updateRuleDataPointLag}
            />
        </div>
    );
};

export default PluginParameterDataPointMulti;
