import { useLocations } from "Components/LocationContext";
import AppMap from "Components/Map";
import L, { DivIcon, LatLngBoundsExpression, LatLngExpression, Map } from "leaflet";
import { isNil, round } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Feature, FeatureCollection } from "types/geometry";
import { isNilOrEmpty } from "utils/utils";
import { GeoJSON, Marker, Popup } from "react-leaflet";
import { LocationModel } from "types/models";
import Button from "Components/Button";
import { CollectionExploreResultModel, CollectionExploreResultRow } from "./CollectionExploreSingleYear";
import { getValuesMean, mapResultsToColour, mapResultToColour } from "utils/collection-explore.utils";
import MarkerClusterGroup from "react-leaflet-cluster";
import ERPIcon from "Components/ERPIcon";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import ReactDOMServer from "react-dom/server";
import { generateUuid } from "@azure/core-http";
import Switch from "Components/Switch";

interface CollectionExploreMapProps {
    results: CollectionExploreResultModel[];
    selectedLocations: LocationModel[];
    onLocationsSelectChanged: (nextLocations: LocationModel[]) => void;
}

const MAP_CENTER: LatLngExpression = [-25.2744, 133.7751];
const MAP_BOUNDS: LatLngBoundsExpression = [
    [-44.85, 100.0],
    [-7.1422, 165.0]
];
const MAP_MAX_ZOOM = 15;
const MAP_MIN_ZOOM = 4;
const MAP_ZOOM = 4;
const GREY_COLOUR = "#acacae";

const DEFAULT_LAYER_STYLE = {
    fillColor: GREY_COLOUR,
    weight: 1,
    opacity: 0.65,
    color: "white",
    dashArray: "3",
    fillOpacity: 0.8
};

const SELECTED_LAYER_STYLE = {
    color: "black",
    dashArray: "0",
    weight: 3
};

const UNSELECTED_LAYER_STYLE = {
    color: "white",
    dashArray: "3",
    weight: 1
};

const CollectionExploreMap = ({ results, selectedLocations, onLocationsSelectChanged }: CollectionExploreMapProps) => {
    const { locations } = useLocations();
    const [map, setMap] = useState<Map>(null);
    const [showRules, setShowRules] = useState<boolean>(false);

    const geojsonLayerRef = useRef<L.GeoJSON>();

    const resultsLocations = results.map(r => r.location);

    useEffect(() => {
        if (!isNil(map)) {
            map.invalidateSize();
        }
    }, [map]);

    const geojsonLayer: FeatureCollection = useMemo(() => {
        if (isNilOrEmpty(locations)) {
            return null;
        }

        const features: Feature[] = locations
            .filter(l => resultsLocations.some(rl => rl.id === l.id))
            .map(l => {
                return {
                    type: "Feature",
                    geometry: l.geometry,
                    properties: {
                        location: l
                    }
                };
            });

        return {
            type: "FeatureCollection",
            features: features
        };
    }, [locations, resultsLocations]);

    const initFeature = (feature, layer) => {
        layer.bindTooltip(feature.properties.location.name, { sticky: true });

        if (selectedLocations.find(l => l.id === feature.properties.location.id)) {
            layer.setStyle(SELECTED_LAYER_STYLE);
        }

        const locationResult = results.find(r => r.location.id === feature.properties.location.id);
        const resultColour = mapResultsToColour(locationResult.results.map(r => r.summary_score));
        layer.setStyle({
            fillColor: resultColour
        });
    };

    const handleLayerClicked = (e: L.LeafletEvent) => {
        const layer = e.layer;

        const location = layer.feature.properties.location;

        if (selectedLocations.find(l => l.id === location.id)) {
            onLocationsSelectChanged([...selectedLocations.filter(l => l.id !== location.id)]);
            layer.setStyle(UNSELECTED_LAYER_STYLE);
            return;
        }

        layer.setStyle(SELECTED_LAYER_STYLE);
        onLocationsSelectChanged([...selectedLocations, location]);
    };

    const allEnabled = selectedLocations?.length === resultsLocations.length;

    const handleDisableAll = () => {
        geojsonLayerRef.current.setStyle(UNSELECTED_LAYER_STYLE);
        onLocationsSelectChanged([]);
    };

    const handleEnableAll = () => {
        geojsonLayerRef.current.setStyle(SELECTED_LAYER_STYLE);
        onLocationsSelectChanged([...resultsLocations]);
    };

    useEffect(() => {
        if (!isNil(geojsonLayerRef?.current) && !isNil(map) && geojsonLayerRef.current.getBounds().isValid()) {
            map.fitBounds(geojsonLayerRef.current.getBounds());
        }
    }, [map, geojsonLayer]);

    const groupedRulePoints = useMemo(() => {
        const flatResults = results.flatMap(r => r.results);

        const groupedByPosition: Record<string, CollectionExploreResultRow[]> = flatResults.reduce(
            (resultAccumulator, obj) => {
                const position = `${round(obj.latitude, 6)} ${round(obj.longitude, 6)}`;

                if (!resultAccumulator[position]) {
                    resultAccumulator[position] = [];
                }

                resultAccumulator[position].push(obj);
                return resultAccumulator;
            },
            {}
        );

        return Object.keys(groupedByPosition).map(k => {
            return {
                isSingle: groupedByPosition[k].length === 1,
                latitude: groupedByPosition[k][0].latitude,
                longitude: groupedByPosition[k][0].longitude,
                rules: groupedByPosition[k]
            };
        });
    }, [results]);

    return (
        <div className="results-map-container">
            <AppMap
                containerClassName="filter-map"
                center={MAP_CENTER}
                maxBounds={MAP_BOUNDS}
                maxZoom={MAP_MAX_ZOOM}
                minZoom={MAP_MIN_ZOOM}
                zoom={MAP_ZOOM}
                onMapCreated={map => setMap(map)}
            >
                {!isNil(geojsonLayer) && !isNil(map) && (
                    <GeoJSON
                        key={results.map(r => r.results.map(rr => rr.summary_score)).join("-")}
                        data={geojsonLayer}
                        style={DEFAULT_LAYER_STYLE}
                        onEachFeature={initFeature}
                        ref={geojsonLayerRef}
                        eventHandlers={{
                            click: e => {
                                handleLayerClicked(e);
                            }
                        }}
                    ></GeoJSON>
                )}

                {showRules &&
                    groupedRulePoints.map(group => {
                        if (group.isSingle) {
                            const rule = group.rules[0];
                            const ruleScoreColour = mapResultToColour(rule.summary_score);

                            const iconHTML = ReactDOMServer.renderToString(
                                <ERPIcon icon={faCircle} style={{ color: ruleScoreColour }} />
                            );
                            const customMarkerIcon = new DivIcon({
                                html: iconHTML
                            });

                            return (
                                <Marker
                                    key={generateUuid()}
                                    position={[group.latitude, group.longitude]}
                                    icon={customMarkerIcon}
                                >
                                    <Popup>
                                        {rule.rule}
                                        <br />
                                        <br />
                                        Lat: {round(rule.latitude, 5)}
                                        <br />
                                        Lng: {round(rule.longitude, 5)}
                                        <br />
                                        <br />
                                        {`Summary: ${round(rule.summary_score, 0)}%`}
                                    </Popup>
                                </Marker>
                            );
                        }
                        {
                            const generateClusteredIcon = () => {
                                const values = group.rules.map(r => r.summary_score);
                                const meanValue = getValuesMean(values);
                                const ruleScoreColour = mapResultToColour(meanValue);

                                const iconHTML = ReactDOMServer.renderToString(
                                    <span className="fa-layers fa-fw fa-lg">
                                        <span style={{ zIndex: 999, position: "relative" }}>{group.rules.length}</span>
                                        <ERPIcon icon={faCircle} size="lg" style={{ color: ruleScoreColour }} />
                                    </span>
                                );
                                const customMarkerIcon = new DivIcon({
                                    html: iconHTML
                                });

                                return customMarkerIcon;
                            };

                            return (
                                <MarkerClusterGroup key={generateUuid()} iconCreateFunction={generateClusteredIcon}>
                                    {group.rules.map(rule => {
                                        const ruleScoreColour = mapResultToColour(rule.summary_score);

                                        const iconHTML = ReactDOMServer.renderToString(
                                            <ERPIcon icon={faCircle} style={{ color: ruleScoreColour }} />
                                        );
                                        const customMarkerIcon = new DivIcon({
                                            html: iconHTML
                                        });

                                        return (
                                            <Marker
                                                key={generateUuid()}
                                                position={[group.latitude, group.longitude]}
                                                icon={customMarkerIcon}
                                            >
                                                <Popup>
                                                    {rule.rule}
                                                    <br />
                                                    <br />
                                                    Lat: {round(rule.latitude, 5)}
                                                    <br />
                                                    Lng: {round(rule.longitude, 5)}
                                                    <br />
                                                    <br />
                                                    {`Summary: ${round(rule.summary_score, 0)}%`}
                                                </Popup>
                                            </Marker>
                                        );
                                    })}
                                </MarkerClusterGroup>
                            );
                        }
                    })}

                <Button onClick={allEnabled ? handleDisableAll : handleEnableAll} className="modify-all-button">
                    {allEnabled ? "Deselect all" : "Select all"}
                </Button>
            </AppMap>

            <div className="rule-toggle-switch-container">
                <Switch checked={showRules} onChecked={() => setShowRules(!showRules)} />
                <span>Display individual rules</span>
            </div>
        </div>
    );
};

export default CollectionExploreMap;
