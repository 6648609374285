import React from "react";
import { ChartOptions } from "chart.js";
import ERPChart from "./ERPChart";
import { EflowTimerseriesChartData, TimeseriesDay } from "types/models";

interface ERPLineTimeseriesChartProps {
    id?: string;
    data: EflowTimerseriesChartData;
    height?: number | string;
    width?: number | string;
    options?: ChartOptions<"line">;
    disableAnimationAfterLoad?: boolean;
    canZoom?: boolean;
    persistLegendState?: boolean;
    persistZoomState?: boolean;
}

export const ERPLineTimeseriesChart = ({
    id,
    data,
    height = "auto",
    width = "auto",
    options = {} as ChartOptions<"line">,
    disableAnimationAfterLoad,
    canZoom = false,
    persistLegendState = false,
    persistZoomState = false
}: ERPLineTimeseriesChartProps) => {
    return (
        <ERPChart<"line", TimeseriesDay>
            id={id}
            type="line"
            data={data}
            options={options}
            height={height}
            width={width}
            canZoom={canZoom}
            persistLegendState={persistLegendState}
            persistZoomState={persistZoomState}
            disableAnimationAfterLoad={disableAnimationAfterLoad}
        />
    );
};

export default ERPLineTimeseriesChart;
