import React from "react";

interface SearchResultsHeaderProps {
    resultsCount: number;
}

const SearchResultsHeader = ({ resultsCount }: SearchResultsHeaderProps) => {
    return (
        <div className="filter">
            <h5>Search Results: {resultsCount} results</h5>
        </div>
    );
};

export default SearchResultsHeader;
