import LoadingIndicator from "Components/LoadingIndicator";
import { isNil } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { DataPointDataModel, DataPointModel } from "types/models";
import DataPointService from "Services/data-point.service";

interface DataPointContextProps {
    dataPoint: DataPointModel;
    dataPointData: DataPointDataModel[];
    readOnly: boolean;
    isSaving: boolean;
    setSaving: (saving: boolean) => void;
    updateDataPoint: (update: DataPointModel) => void;
    updateDataPointData: (update: DataPointDataModel[]) => void;
}

interface DataPointContextOptions {
    dataPointId: string;
    readOnly: boolean;
    children: React.ReactNode;
}

export const DataPointContext = React.createContext<DataPointContextProps | null>(null);

export const useDataPoint = () => useContext(DataPointContext);

const DataPointContextProvider = ({ dataPointId, readOnly, children }: DataPointContextOptions) => {
    const [dataPoint, setDataPoint] = useState<DataPointModel>(null);
    const [dataPointData, setDataPointData] = useState<DataPointDataModel[]>([]);
    const [isSaving, setSaving] = useState<boolean>(false);

    useEffect(() => {
        const getDataPoint = async (id: string) => {
            const getDataPoint = DataPointService.getDataPoint(id);
            const getDataPointData = DataPointService.getDataPointData(id);

            const [_dataPoint, _dataPointData] = await Promise.all([getDataPoint, getDataPointData]);

            setDataPoint(_dataPoint);
            setDataPointData(_dataPointData);
        };

        getDataPoint(dataPointId);
    }, [dataPointId]);

    const updateDataPoint = (nextDataPoint: DataPointModel) => {
        setDataPoint({ ...nextDataPoint });
    };

    const updateDataPointData = (nextDataPointData: DataPointDataModel[]) => {
        setDataPointData([...nextDataPointData]);
    };

    return (
        <DataPointContext.Provider
            value={{
                dataPoint: dataPoint,
                dataPointData: dataPointData,
                readOnly: readOnly,
                isSaving: isSaving,
                setSaving: setSaving,
                updateDataPoint: updateDataPoint,
                updateDataPointData: updateDataPointData
            }}
        >
            {isNil(dataPoint) && <LoadingIndicator className="data-point-loading" centered />}

            {!isNil(dataPoint) && children}
        </DataPointContext.Provider>
    );
};

export default DataPointContextProvider;
