import React from "react";
import { faCopy, faPencilAlt, faTrashAlt, faFileExport } from "@fortawesome/pro-regular-svg-icons";
import TooltipIcon from "Components/TooltipIcon";

interface CollectionRowActionsProps {
    canEdit: boolean;
    canDelete: boolean;
    onMove: () => void;
    onDelete: () => void;
    onCopy: () => void;
    onEdit: () => void;
}

const CollectionRowActions = ({ canEdit, canDelete, onMove, onDelete, onCopy, onEdit }: CollectionRowActionsProps) => {
    return (
        <div className="action-container">
            <TooltipIcon icon={faPencilAlt} tooltip="Edit collection" disabled={!canEdit} onClick={onEdit} />
            <TooltipIcon icon={faTrashAlt} tooltip="Delete collection" disabled={!canDelete} onClick={onDelete} />
            <TooltipIcon icon={faCopy} tooltip="Copy collection" onClick={onCopy} />
            <TooltipIcon icon={faFileExport} tooltip="Move collection" onClick={onMove} />
        </div>
    );
};

export default CollectionRowActions;
