import React, { useMemo } from "react";
import { DataPointModel, DataType, LocationModel } from "types/models";
import DataLibraryFilterMap from "./DataLibraryFilterMap";
import { isNil } from "lodash";
import { DataTypeSelectOption } from "Components/DataTypeSelect";
import Switch from "Components/Switch";
import Label from "Components/Label";
import Button from "Components/Button";
import { DataLibraryFilterSettings } from "./DataLibraryContainer";

interface DataLibraryFilterProps {
    filterSettings: DataLibraryFilterSettings;
    filteredDataPoints: DataPointModel[];
    onFilterSettingsChange: (nextSettings: DataLibraryFilterSettings) => void;
}

const DATA_TYPES: DataTypeSelectOption[] = [
    { label: "Flow", value: DataType.FLOW },
    { label: "Depth", value: DataType.DEPTH },
    { label: "Temperature", value: DataType.TEMPERATURE },
    { label: "Rainfall", value: DataType.RAINFALL },
    { label: "Evaporation", value: DataType.EVAPORATION },
    { label: "Salinity", value: DataType.SALINITY },
    { label: "Climate", value: DataType.CLIMATE },
    { label: "pH", value: DataType.PH },
    { label: "Turbidity", value: DataType.TURBIDITY },
    { label: "Storage Volume", value: DataType.STORAGE_VOLUME },
    { label: "Storage Depth", value: DataType.STORAGE_DEPTH },
    { label: "No Data", value: null }
];

const DataLibraryFilter = ({ filterSettings, filteredDataPoints, onFilterSettingsChange }: DataLibraryFilterProps) => {
    const handleLocationFilter = (location: LocationModel) => {
        onFilterSettingsChange({ ...filterSettings, location: location });
    };

    const handleDataTypeFilter = (dataType: DataType) => {
        const exists = filterSettings.dataTypes.some(d => d === dataType);

        if (exists) {
            const next = filterSettings.dataTypes.filter(d => d !== dataType);
            onFilterSettingsChange({ ...filterSettings, dataTypes: next });
            return;
        }

        onFilterSettingsChange({ ...filterSettings, dataTypes: [...filterSettings.dataTypes, dataType] });
    };

    const handleEnableAll = () => {
        onFilterSettingsChange({ ...filterSettings, dataTypes: DATA_TYPES.map(d => d.value) });
    };

    const handleDisableAll = () => {
        onFilterSettingsChange({ ...filterSettings, dataTypes: [] });
    };

    const cleanedDataPoints = useMemo(() => {
        if (isNil(filterSettings.location)) {
            return [];
        }

        return filteredDataPoints;
    }, [filteredDataPoints, filterSettings.location]);

    const allEnabled = filterSettings.dataTypes.length === DATA_TYPES.length;

    return (
        <div className="data-library-filter">
            <div className="filter-label">
                <Label>Filter by data types</Label>

                <Button onClick={allEnabled ? handleDisableAll : handleEnableAll} className="modify-all-button">
                    {allEnabled ? "Disable All" : "Enable All"}
                </Button>
            </div>

            <div className="filter-data-types">
                <div className="filter-data-types-col">
                    {DATA_TYPES.slice(0, 4).map(d => {
                        return (
                            <div key={d.value} className="filter-data-type-switch-container">
                                <Switch
                                    checked={filterSettings.dataTypes.some(dd => dd === d.value)}
                                    onChecked={() => handleDataTypeFilter(d.value)}
                                    title={d.label}
                                />
                                <span>{d.label}</span>
                            </div>
                        );
                    })}
                </div>

                <div className="filter-data-types-col">
                    {DATA_TYPES.slice(4, 8).map(d => {
                        return (
                            <div key={d.value} className="filter-data-type-switch-container">
                                <Switch
                                    checked={filterSettings.dataTypes.some(dd => dd === d.value)}
                                    onChecked={() => handleDataTypeFilter(d.value)}
                                    title={d.label}
                                />
                                <span>{d.label}</span>
                            </div>
                        );
                    })}
                </div>

                <div className="filter-data-types-col">
                    {DATA_TYPES.slice(8, 12).map(d => {
                        return (
                            <div key={d.value} className="filter-data-type-switch-container">
                                <Switch
                                    checked={filterSettings.dataTypes.some(dd => dd === d.value)}
                                    onChecked={() => handleDataTypeFilter(d.value)}
                                    title={d.label}
                                />
                                <span>{d.label}</span>
                            </div>
                        );
                    })}
                </div>
            </div>

            <DataLibraryFilterMap
                selectedLocation={filterSettings.location}
                dataPoints={cleanedDataPoints}
                onLocationSelect={handleLocationFilter}
            />
        </div>
    );
};

export default DataLibraryFilter;
