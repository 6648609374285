import React from "react";
import { RuleModel } from "types/models";
import clsx from "clsx";
import RuleIcon from "Components/ERPIcon/RuleIcon";

import "./FileSystem.scss";

interface FileSystemModalRuleProps {
    rule: RuleModel;
    disabled: boolean;
}

const FileSystemModalRule = ({ rule, disabled }: FileSystemModalRuleProps) => {
    return (
        <div className={clsx("fs-modal-rule", { disabled: disabled === true })}>
            <RuleIcon />

            <span>{rule.name}</span>
        </div>
    );
};

export default FileSystemModalRule;
