import Button from "Components/Button";
import React from "react";

interface PluginUpdateProps {
    onUpdate: () => void;
}

const PluginUpdate = ({ onUpdate }: PluginUpdateProps) => {
    return (
        <div className="plugin-update">
            There is a new version available for this plugin.
            <Button variant="secondary" onClick={onUpdate}>
                Update
            </Button>
        </div>
    );
};

export default PluginUpdate;
