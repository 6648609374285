import React, { useMemo, useState } from "react";
import PageTitle from "Components/PageTitle";
import ExplorerHeader from "./ExplorerHeader";
import ExplorerTable from "./ExplorerTable";
import { FolderModel, RuleModel, CollectionModel } from "types/models";
import { useFileSystem } from "Components/FileSystem/useFileSystem";
import FileSystemBreadcrumbs from "Components/FileSystem/FileSystemBreadcrumbs";
import { Breadcrumb } from "Components/FileSystem/types";
import { useSelection } from "./useSelection";
import { Toast } from "Components/Toast";
import { useFileSystemModal } from "Components/FileSystem/FileSystemModalContext";
import FolderModal, { FolderModalContext } from "./FolderModal";
import { isNil } from "lodash";
import { useConfirmation } from "Components/ConfirmDialog/ConfirmationContext";
import RuleService from "Services/rule.service";
import { ExplorerEntity, ExplorerItem, SelectionState } from "./types";
import { isNilOrEmpty } from "utils/utils";
import CollectionService from "Services/collection.service";
import FolderService from "Services/folder.service";
import { useAuth0 } from "auth/react-auth0-spa";
import SearchView from "./SearchView";
import RuleModal, { RuleModalContext } from "./RuleModal";
import CollectionModal, { CollectionModalContext } from "./CollectionModal";
import { useSort } from "Components/SortableHeader/useSort";

import "./Explorer.scss";

const Explorer = () => {
    const { eflowUser } = useAuth0();

    const {
        folders,
        stack,
        view,
        breadcrumbs,
        navigateToFolder,
        navigateBack,
        navigateBackToFolder,
        addFolder,
        addFolderToView,
        addFolders,
        addFoldersToView,
        updateFolder,
        updateFolders,
        removeFolder,
        removeFolderFromView,
        removeFolderByIds,
        removeFolderFromViewByIds,
        addRule,
        addRules,
        updateRule,
        removeRule,
        removeRuleByIds,
        addCollection,
        addCollections,
        updateCollection,
        removeCollection,
        removeCollectionByIds,
        buildNewStackToFolder,
        isLoading
    } = useFileSystem();

    const {
        selection,
        selectAll,
        deselectAll,
        selectFolder,
        deselectFolder,
        selectCollection,
        deselectCollection,
        selectRule,
        deselectRule,
        clearSelection
    } = useSelection(view.subFolders, view.collections, view.rules);

    const confirm = useConfirmation();
    const fs = useFileSystemModal();

    const [folderModalContext, setFolderModalContext] = useState<FolderModalContext>(null);
    const [ruleModalContext, setRuleModalContext] = useState<RuleModalContext>(null);
    const [collectionModalContext, setCollectionModalContext] = useState<CollectionModalContext>(null);
    const [search, setSearch] = useState<string>("");

    const items = useMemo(() => {
        if (isNil(view)) {
            return [];
        }

        const items: ExplorerItem<ExplorerEntity>[] = [
            ...view.subFolders.map(folder => {
                const item: ExplorerItem<FolderModel> = {
                    item: folder,
                    type: "folder"
                };
                return item;
            }),
            ...view.collections.map(collection => {
                const item: ExplorerItem<CollectionModel> = {
                    item: collection,
                    type: "collection"
                };
                return item;
            }),
            ...view.rules.map(rule => {
                const item: ExplorerItem<RuleModel> = {
                    item: rule,
                    type: "rule"
                };
                return item;
            })
        ];

        return items;
    }, [view]);

    const { sortedItems: displayedItems, handleSort, sortContext } = useSort(items);

    const handleNewFolder = () => {
        setFolderModalContext({ folder: null, parentFolder: view.folder });
    };

    const handleFolderEdit = (folder: FolderModel) => {
        setFolderModalContext({ folder: folder, parentFolder: view.folder });
    };

    const handleSearch = (newSearch: string) => {
        setSearch(newSearch);
    };

    const clearSearch = () => {
        setSearch("");
    };

    const isSearching = !isNilOrEmpty(search);

    const handleFolderMove = async (folder: FolderModel) => {
        const copiedStack = stack.copy();

        const result = await fs({
            title: `Move ${folder.name} to`,
            folders: folders,
            fromStack: copiedStack,
            disabledFolders: new Set<string>([folder.id])
        });

        if (!result.success) {
            return;
        }

        if (result.folder.id === folder.parentId) {
            return;
        }

        const movedFolder = await FolderService.moveFolder(folder.id, result.folder.id);

        removeFolderFromView(folder);

        updateFolder(movedFolder);

        deselectFolder(folder);

        Toast.success(`Successfully moved folder ${folder.name}`);
    };

    const handleFolderDeleted = async (folder: FolderModel) => {
        const result = await confirm({
            title: "Folder delete",
            description: "You are about to delete a folder and all its content. Do you wish to continue ?"
        });

        if (!result.success) {
            return;
        }

        removeFolder(folder);
        removeFolderFromView(folder);
        deselectFolder(folder);

        const deleteResult = await FolderService.deleteFolder(folder.id);

        if (!deleteResult?.success) {
            Toast.error(deleteResult.failure.message);
        } else {
            Toast.success("Successfully deleted folder");
        }
    };

    const handleFolderCopy = async (folder: FolderModel) => {
        const copiedStack = stack.copy();

        const result = await fs({
            title: `Copy ${folder.name} to`,
            folders: folders,
            fromStack: copiedStack,
            disabledFolders: new Set<string>([folder.id])
        });

        if (!result.success) {
            return;
        }

        const copiedFolders = await FolderService.copyFolder(folder.id, result.folder.id);

        if (folder.parentId === result.folder.id) {
            const sourceFolder = copiedFolders.find(f => f.parentId === result.folder.id);
            addFolderToView(sourceFolder);
        }

        addFolders(copiedFolders);

        Toast.success(`Successfully copied folder ${folder.name}`);
    };

    const handleFolderCreated = (folder: FolderModel) => {
        clearSearch();
        addFolder(folder);
        addFolderToView(folder);
    };

    const handleFolderUpdated = (folder: FolderModel) => {
        updateFolder(folder);
    };

    const handleFolderNavgiation = (folder: FolderModel) => {
        navigateToFolder(folder);
        deselectAll();
    };

    const handleBreadcrumbSelected = (breadcrumb: Breadcrumb): void => {
        navigateBackToFolder(breadcrumb.index);
    };

    const handleBackNavigation = () => {
        navigateBack();
    };

    const handleCollectionMove = async (collection: CollectionModel) => {
        const result = await fs({ title: `Move ${collection.name} to`, folders: folders, fromStack: stack });

        if (!result.success) {
            return;
        }

        if (result.folder.id === collection.folderId) {
            return;
        }

        await CollectionService.moveCollection(collection.id, result.folder.id);

        removeCollection(collection);

        deselectCollection(collection);

        Toast.success(`Successfully moved collection ${collection.name}`);
    };

    const handleCollectionDelete = async (collection: CollectionModel) => {
        const result = await confirm({
            title: "Delete collection",
            description: `You are about to delete a collection. This cannot be undone, do you wish to continue?`
        });

        if (!result.success) {
            return;
        }

        removeCollection(collection);
        deselectCollection(collection);

        await CollectionService.deleteCollection(collection.id);

        Toast.success("Successfully deleted collection");
    };

    const handleCollectionCopy = async (collection: CollectionModel) => {
        const result = await fs({ title: `Copy ${collection.name} to`, folders: folders, fromStack: stack });

        if (!result.success) {
            return;
        }

        const copiedCollection = await CollectionService.copyCollection(collection.id, result.folder.id);

        if (result.folder.id === view.folder.id) {
            addCollection(copiedCollection);
        }

        Toast.success(`Successfully copied collection ${collection.name}`);
    };

    const handleCollectionEdit = (collection: CollectionModel) => {
        setCollectionModalContext({ collection: collection });
    };

    const handleCollectionUpdated = (collection: CollectionModel) => {
        updateCollection(collection);
    };

    const handleRuleMove = async (rule: RuleModel) => {
        const result = await fs({ title: `Move ${rule.name} to`, folders: folders, fromStack: stack });

        if (!result.success) {
            return;
        }

        if (result.folder.id === rule.folderId) {
            return;
        }

        await RuleService.moveRule(rule.id, result.folder.id);

        removeRule(rule);

        deselectRule(rule);

        Toast.success(`Successfully moved rule ${rule.name}`);
    };

    const handleRuleDelete = async (rule: RuleModel) => {
        const result = await confirm({
            title: "Delete Rule",
            description: `You are about to delete a rule. This cannot be undone, do you wish to continue?`
        });

        if (!result.success) {
            return;
        }

        removeRule(rule);
        deselectRule(rule);

        await RuleService.deleteRule(rule.id);

        Toast.success("Successfully deleted rule");
    };

    const handleRuleCopy = async (rule: RuleModel) => {
        const result = await fs({ title: `Copy ${rule.name} to`, folders: folders, fromStack: stack });

        if (!result.success) {
            return;
        }

        const copiedRule = await RuleService.copyRule(rule.id, result.folder.id);

        if (result.folder.id === view.folder.id) {
            addRule(copiedRule);
        }

        Toast.success(`Successfully copied rule ${rule.name}`);
    };

    const handleRuleEdit = (rule: RuleModel) => {
        setRuleModalContext({ rule: rule });
    };

    const handleRuleUpdated = (rule: RuleModel) => {
        updateRule(rule);
    };

    const handleBulkDelete = async (state: SelectionState) => {
        const result = await confirm({
            title: "Delete selection",
            description: `You are about to delete the selected items. This cannot be undone, do you wish to continue?`
        });

        if (!result.success) {
            return;
        }

        let folders = Array.from(state.folders.keys());
        const rules: string[] = [];
        const collections: string[] = [];

        const selected_rules = Array.from(state.rules.keys());
        const selected_collections = Array.from(state.collections.keys());

        for (let i = 0; i < selected_rules.length; i++) {
            const selected_rule = selected_rules[i];

            const matching = view.rules.find(m => m.id === selected_rule);

            if (isNil(matching.lock) || matching?.lock?.owner?.id === eflowUser.id) {
                rules.push(selected_rule);
            }
        }

        for (let i = 0; i < selected_collections.length; i++) {
            const selected_collection = selected_collections[i];

            const matching = view.collections.find(s => s.id === selected_collection);

            if (isNil(matching.lock) || matching?.lock?.owner?.id === eflowUser.id) {
                collections.push(selected_collection);
            }
        }

        removeFolderByIds(folders);
        removeFolderFromViewByIds(folders);
        removeRuleByIds(rules);
        removeCollectionByIds(collections);

        if (!isNilOrEmpty(folders)) {
            const result = await FolderService.deleteFolders(folders);

            if (!result.success) {
                Toast.error(result.failure.message);
                folders = [];
            }
        }

        if (!isNilOrEmpty(rules)) {
            await RuleService.deleteRules(rules);
        }

        if (!isNilOrEmpty(collections)) {
            await CollectionService.deleteCollections(collections);
        }

        clearSelection();

        const total = folders.length + rules.length + collections.length;

        if (total > 0) {
            Toast.success(`Successfully deleted ${folders.length + rules.length + collections.length} item(s)`);
        }
    };

    const handleBulkCopy = async (state: SelectionState) => {
        const folders_to_copy = Array.from(state.folders.keys());
        const rules_to_copy = Array.from(state.rules.keys());
        const collections_to_copy = Array.from(state.collections.keys());

        const totalSelected = folders_to_copy.length + collections_to_copy.length + rules_to_copy.length;

        const copiedStack = stack.copy();

        const result = await fs({
            title: `Copy ${totalSelected} selected item(s) to`,
            folders: folders,
            fromStack: copiedStack,
            disabledFolders: new Set<string>(folders_to_copy)
        });

        if (!result.success) {
            return;
        }

        if (!isNilOrEmpty(folders_to_copy)) {
            const copiedFolders = await FolderService.copyFolders(folders_to_copy, result.folder.id);

            if (view.folder.id === result.folder.id) {
                const sourceFolders = copiedFolders.filter(f => f.parentId === result.folder.id);

                addFoldersToView(sourceFolders);
            }

            addFolders(copiedFolders);
        }

        if (!isNilOrEmpty(rules_to_copy)) {
            const copiedRules = await RuleService.copyRules(rules_to_copy, result.folder.id);

            if (result.folder.id === view.folder.id) {
                addRules(copiedRules);
            }
        }

        if (!isNilOrEmpty(collections_to_copy)) {
            const copiedCollections = await CollectionService.copyCollections(collections_to_copy, result.folder.id);

            if (result.folder.id === view.folder.id) {
                addCollections(copiedCollections);
            }
        }

        clearSelection();

        Toast.success(`Successfully copied ${totalSelected} items(s)`);
    };

    const handleBulkMove = async (state: SelectionState) => {
        const folders_to_move = Array.from(state.folders.keys());
        const rules_to_move = Array.from(state.rules.keys());
        const collections_to_move = Array.from(state.collections.keys());

        const totalSelected = folders_to_move.length + collections_to_move.length + rules_to_move.length;

        const copiedStack = stack.copy();

        const result = await fs({
            title: `Move ${totalSelected} selected item(s) to`,
            folders: folders,
            fromStack: copiedStack,
            disabledFolders: new Set<string>(folders_to_move)
        });

        if (!result.success) {
            return;
        }

        if (result.folder.id === view.folder.id) {
            return;
        }

        if (!isNilOrEmpty(folders_to_move)) {
            const movedFolders = await FolderService.moveFolders(folders_to_move, result.folder.id);
            removeFolderFromViewByIds(folders_to_move);
            updateFolders(movedFolders);
        }

        if (!isNilOrEmpty(rules_to_move)) {
            await RuleService.moveRules(rules_to_move, result.folder.id);
            removeRuleByIds(rules_to_move);
        }

        if (!isNilOrEmpty(collections_to_move)) {
            await CollectionService.moveCollections(collections_to_move, result.folder.id);
            removeCollectionByIds(collections_to_move);
        }

        clearSelection();

        Toast.success(`Successfully moved ${totalSelected} items(s)`);
    };

    const handleSearchFolderClick = (folderId: string, ancestorIds: string[]) => {
        if (view.folder.id !== folderId) {
            buildNewStackToFolder(folderId, ancestorIds);
        }

        clearSearch();
    };

    return (
        <div className="explorer-container">
            <PageTitle title="Explorer" />

            <ExplorerHeader
                folderId={view?.folder?.id}
                onNewFolder={handleNewFolder}
                onSearch={handleSearch}
                search={search}
            />

            {!isSearching && (
                <>
                    <FileSystemBreadcrumbs
                        currentViewIndex={view.index}
                        breadcrumbs={breadcrumbs}
                        containerClassName="explorer--breadcrumbs"
                        onBack={handleBackNavigation}
                        onBreadcrumbSelected={handleBreadcrumbSelected}
                    />

                    <ExplorerTable
                        items={displayedItems}
                        onSort={handleSort}
                        sortContext={sortContext}
                        selection={selection}
                        user={eflowUser}
                        isLoading={isLoading}
                        onFolderNavigation={handleFolderNavgiation}
                        onFolderSelected={selectFolder}
                        onFolderMove={handleFolderMove}
                        onFolderEdit={handleFolderEdit}
                        onFolderDelete={handleFolderDeleted}
                        onFolderCopy={handleFolderCopy}
                        onCollectionSelected={selectCollection}
                        onCollectionMove={handleCollectionMove}
                        onCollectionDelete={handleCollectionDelete}
                        onCollectionCopy={handleCollectionCopy}
                        onCollectionEdit={handleCollectionEdit}
                        onRuleSelected={selectRule}
                        onRuleMove={handleRuleMove}
                        onRuleDelete={handleRuleDelete}
                        onRuleCopy={handleRuleCopy}
                        onRuleEdit={handleRuleEdit}
                        onAllSelected={selectAll}
                        onBulkDelete={handleBulkDelete}
                        onBulkCopy={handleBulkCopy}
                        onBulkMove={handleBulkMove}
                    />
                </>
            )}

            {isSearching && <SearchView search={search} onFolderClick={handleSearchFolderClick} />}

            {!isNil(folderModalContext) && (
                <FolderModal
                    context={folderModalContext}
                    show
                    onClose={() => setFolderModalContext(null)}
                    onFolderCreated={handleFolderCreated}
                    onFolderUpdated={handleFolderUpdated}
                />
            )}

            {!isNil(ruleModalContext) && (
                <RuleModal
                    context={ruleModalContext}
                    show
                    onClose={() => setRuleModalContext(null)}
                    onRuleUpdated={handleRuleUpdated}
                />
            )}

            {!isNil(collectionModalContext) && (
                <CollectionModal
                    context={collectionModalContext}
                    show
                    onClose={() => setCollectionModalContext(null)}
                    onCollectionUpdated={handleCollectionUpdated}
                />
            )}
        </div>
    );
};

export default Explorer;
