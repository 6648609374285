import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";

interface GroupMenuItemProps {
    label: string;
    selected?: boolean;
    onSelected: () => void;
}

const GroupMenuItem = ({ label, selected, onSelected }: GroupMenuItemProps) => {
    const handleClick = () => {
        if (selected) {
            return;
        }

        onSelected();
    };

    return (
        <Dropdown.Item as="div" className={clsx("group", { selected: selected === true })} onClick={handleClick}>
            <span>{label}</span>

            {selected && <FontAwesomeIcon icon={faCheck} />}
        </Dropdown.Item>
    );
};

export default GroupMenuItem;
