import React from "react";
import FormActions from "Components/FormActions";
import Button from "Components/Button";
import { faPlay } from "@fortawesome/pro-solid-svg-icons";
import LoadingButton from "Components/LoadingButton";

interface RuleRunModalFooterProps {
    canRun: boolean;
    isRunning: boolean;
    onRun: () => void;
    onCancel: () => void;
}

const RuleRunModalFooter = ({ canRun, isRunning, onRun, onCancel }: RuleRunModalFooterProps) => {
    return (
        <FormActions>
            <Button variant="grey" disabled={isRunning} onClick={onCancel}>
                Cancel
            </Button>

            <LoadingButton isLoading={isRunning} disabled={!canRun} variant="secondary" icon={faPlay} onClick={onRun}>
                Run
            </LoadingButton>
        </FormActions>
    );
};

export default RuleRunModalFooter;
