import { FolderModel, GroupModel, User } from "types/models";
import { ChangePasswordRequest, RegisterRequest } from "types/requests";
import API from "../api/data.api";
import { URL } from "../Constants/api";

export default class UserService {
    public static async me(): Promise<User> {
        const me = await API.get<User>(URL.ME);
        return me;
    }

    static async getMyGroups(): Promise<GroupModel[]> {
        const groups = await API.get<GroupModel[]>(`${URL.ME}/groups`);
        return groups;
    }

    static async setActiveGroup(groupId: string): Promise<void> {
        await API.patch<void>(`${URL.ME}/groups/${groupId}/active`);
    }

    static async getFolders(): Promise<FolderModel[]> {
        const folders = await API.get<FolderModel[]>(`${URL.ME}/groups/active/folders`);

        return folders;
    }

    static async register(request: RegisterRequest): Promise<User> {
        const user = await API.post<User>(URL.REGISTER, request);

        return user;
    }

    public static async changePassword(request: ChangePasswordRequest): Promise<void> {
        await API.patch<void>(`${URL.ME}/change-password`, request);
    }
}
