import React from "react";
import AppProgressBar from "Components/ProgressBar";

interface ProgressProps {
    progress: number;
    label: string;
}

const FileProgress = ({ progress, label }: ProgressProps) => {
    return (
        <div className="progress-modal--body">
            <div className="progress-modal--label">
                <span>{label}</span>
            </div>

            <AppProgressBar progress={progress} />
        </div>
    );
};

export default FileProgress;
