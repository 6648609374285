import Label from "Components/Label";
import { useLocations } from "Components/LocationContext";
import AppMap from "Components/Map";
import L, { LatLngBoundsExpression, LatLngExpression, Map, Icon } from "leaflet";
import { isNil, round } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Feature, FeatureCollection } from "types/geometry";
import { isNilOrEmpty } from "utils/utils";
import { GeoJSON, Marker, Popup } from "react-leaflet";
import { DataPointModel, LocationModel } from "types/models";
import locationPinIcon from "Assets/images/location-pin-icon.svg";
import Button from "Components/Button";
import { useHistory } from "react-router-dom";
import DataLibraryFilterMapGroupMarker from "./DataLibraryFilterMapGroupMarker";

interface DataLibraryFilterMapProps {
    selectedLocation: LocationModel;
    dataPoints: DataPointModel[];
    onLocationSelect: (location: LocationModel) => void;
}

const MAP_CENTER: LatLngExpression = [-25.2744, 133.7751];
const MAP_BOUNDS: LatLngBoundsExpression = [
    [-44.85, 100.0],
    [-7.1422, 165.0]
];
const MAP_MAX_ZOOM = 15;
const MAP_MIN_ZOOM = 4;
const MAP_ZOOM = 4;
const DEFAULT_COLOUR = "#ff671b";
const HIGHLIGHT_COLOUR = "#8547AD";

const LAYER_STYLE = {
    fillColor: DEFAULT_COLOUR,
    weight: 1,
    opacity: 0.65,
    color: "white",
    dashArray: "3",
    fillOpacity: 0.8
};

const DataLibraryFilterMap = ({ selectedLocation, dataPoints, onLocationSelect }: DataLibraryFilterMapProps) => {
    const { locations } = useLocations();
    const [map, setMap] = useState<Map>(null);
    const history = useHistory();

    const geojsonLayerRef = useRef<L.GeoJSON>();

    useEffect(() => {
        if (!isNil(map)) {
            map.invalidateSize();
        }
    }, [map]);

    const geojsonLayer: FeatureCollection = useMemo(() => {
        if (isNilOrEmpty(locations)) {
            return null;
        }

        const features: Feature[] = locations.map(l => {
            return {
                type: "Feature",
                geometry: l.geometry,
                properties: {
                    location: l
                }
            };
        });

        return {
            type: "FeatureCollection",
            features: features
        };
    }, [locations]);

    const initFeature = (feature, layer) => {
        layer.bindTooltip(feature.properties.location.name, { sticky: true });

        if (selectedLocation?.id === feature.properties.location.id) {
            layer.setStyle({
                fillColor: HIGHLIGHT_COLOUR
            });
            map.fitBounds(layer.getBounds());
        }
    };

    const handleLayerClicked = (e: L.LeafletEvent) => {
        const layer = e.layer;

        geojsonLayerRef.current.resetStyle();
        const location = layer.feature.properties.location;

        if (location.id === selectedLocation?.id) {
            onLocationSelect(null);
            map.setZoom(MAP_ZOOM);
            return;
        }

        layer.setStyle({
            fillColor: HIGHLIGHT_COLOUR
        });
        map.fitBounds(layer.getBounds());
        onLocationSelect(location);
    };

    const groupedDataPoints = useMemo(() => {
        const groupedByPosition: Record<string, DataPointModel[]> = dataPoints.reduce((result, obj) => {
            const position = `${round(obj.latitude, 6)} ${round(obj.longitude, 6)}`;

            if (!result[position]) {
                result[position] = [];
            }

            result[position].push(obj);
            return result;
        }, {});

        return Object.keys(groupedByPosition).map(k => {
            return {
                isSingle: groupedByPosition[k].length === 1,
                latitude: groupedByPosition[k][0].latitude,
                longitude: groupedByPosition[k][0].longitude,
                dataPoints: groupedByPosition[k],
                id: groupedByPosition[k][0].id
            };
        });
    }, [dataPoints]);

    return (
        <div className="filter-map-container">
            <Label>Filter by location</Label>

            <AppMap
                containerClassName="filter-map"
                center={MAP_CENTER}
                maxBounds={MAP_BOUNDS}
                maxZoom={MAP_MAX_ZOOM}
                minZoom={MAP_MIN_ZOOM}
                zoom={MAP_ZOOM}
                onMapCreated={map => setMap(map)}
            >
                {!isNil(geojsonLayer) && !isNil(map) && (
                    <GeoJSON
                        data={geojsonLayer}
                        style={LAYER_STYLE}
                        onEachFeature={initFeature}
                        ref={geojsonLayerRef}
                        eventHandlers={{
                            click: e => {
                                handleLayerClicked(e);
                            }
                        }}
                    ></GeoJSON>
                )}
                {groupedDataPoints.map(group => {
                    if (group.isSingle) {
                        const dataPoint = group.dataPoints[0];

                        return (
                            <Marker
                                key={group.id}
                                position={[group.latitude, group.longitude]}
                                icon={
                                    new Icon({
                                        iconUrl: locationPinIcon,
                                        iconSize: [20, 30],
                                        iconAnchor: [10, 15],
                                        tooltipAnchor: [0, 0]
                                    })
                                }
                            >
                                <Popup>
                                    {dataPoint.name}
                                    <br />
                                    <br />
                                    Lat: {round(dataPoint.latitude, 5)}
                                    <br />
                                    Lng: {round(dataPoint.longitude, 5)}
                                    <br />
                                    <br />
                                    <Button onClick={() => history.push(`/data-point/${dataPoint.id}`)}>Open</Button>
                                </Popup>
                            </Marker>
                        );
                    }

                    if (!group.isSingle) {
                        return (
                            <DataLibraryFilterMapGroupMarker
                                id={group.id}
                                latitude={group.latitude}
                                longitude={group.longitude}
                                dataPoints={group.dataPoints}
                            />
                        );
                    }
                })}
            </AppMap>
        </div>
    );
};

export default DataLibraryFilterMap;
