import LoadingIndicator from "Components/LoadingIndicator";
import { isNil } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import RuleService from "Services/rule.service";
import PluginService from "Services/plugin.service";
import { TaskModel, RuleModel, PluginModel, DataPointModel, RuleRunModel } from "types/models";
import { PluginParameters } from "types/plugin";

interface RuleContextProps {
    rule: RuleModel;
    dataPoints: DataPointModel[];
    readOnly: boolean;
    plugins: PluginModel[];
    isSaving: boolean;
    task: TaskModel;
    runs: RuleRunModel[];
    showRunModal: boolean;
    setSaving: (saving: boolean) => void;
    updateRule: (update: RuleModel) => void;
    updateDataPoints: (nextDataPoints: DataPointModel[]) => void;
    updateParameters: (parameters: PluginParameters, nextVersion: string) => void;
    setPlugin: (plugin: PluginModel, parameters: PluginParameters, shouldResetRule?: boolean) => void;
    updateTask: (task: TaskModel) => void;
    setRuns: (nextRuns: RuleRunModel[]) => void;
    setShowRunModal: (boolean) => void;
}

interface RuleContextOptions {
    ruleId: string;
    readOnly: boolean;
    children: React.ReactNode;
}

export const RuleContext = React.createContext<RuleContextProps | null>(null);

export const useRule = () => useContext(RuleContext);

const RuleContextProvider = ({ ruleId, readOnly, children }: RuleContextOptions) => {
    const [rule, setRule] = useState<RuleModel>(null);
    const [dataPoints, setDataPoints] = useState<DataPointModel[]>([]);
    const [plugins, setPlugins] = useState<PluginModel[]>([]);
    const [isSaving, setSaving] = useState<boolean>(false);
    const [task, setTask] = useState<TaskModel>(null);
    const [runs, setRuns] = useState<RuleRunModel[]>([]);
    const [showRunModal, setShowRunModal] = useState<boolean>(false);

    useEffect(() => {
        const getRule = async (id: string) => {
            const getRule = RuleService.getRule(id);
            const getDataPoints = RuleService.getRuleDataPoints(id);
            const getPlugins = PluginService.getPlugins();
            const getStatus = RuleService.getComputationStatus(id);

            const [_rule, _dataPoints, _plugins, _task] = await Promise.all([
                getRule,
                getDataPoints,
                getPlugins,
                getStatus
            ]);

            setPlugins(_plugins);
            setTask(_task);
            setRule(_rule);
            setDataPoints(_dataPoints);
            setRuns(_rule.runs);
        };

        getRule(ruleId);
    }, [ruleId]);

    const updateRule = (rule: RuleModel) => {
        setRule({ ...rule });
    };

    const setPlugin = (plugin: PluginModel, parameters: PluginParameters, shouldResetRule = false) => {
        setRule({ ...rule, plugin: { ...plugin }, parameters: parameters });
        if (shouldResetRule) {
            resetRule();
        }
    };

    const updateParameters = (parameters: PluginParameters, nextVersion: string) => {
        setRule({ ...rule, parameters: { ...parameters }, version: nextVersion });
    };

    const updateTask = (task: TaskModel) => {
        setTask({ ...task });
    };

    const resetRule = () => {
        setTask(null);
        setDataPoints([]);
        setRuns([]);
    };

    return (
        <RuleContext.Provider
            value={{
                rule: rule,
                dataPoints: dataPoints,
                readOnly: readOnly,
                plugins: plugins,
                isSaving: isSaving,
                task: task,
                runs: runs,
                showRunModal: showRunModal,
                setSaving: setSaving,
                updateRule: updateRule,
                updateDataPoints: setDataPoints,
                setPlugin: setPlugin,
                updateParameters: updateParameters,
                updateTask: updateTask,
                setRuns: setRuns,
                setShowRunModal: setShowRunModal
            }}
        >
            {isNil(rule) && <LoadingIndicator className="rule-loading" centered />}

            {!isNil(rule) && children}
        </RuleContext.Provider>
    );
};

export default RuleContextProvider;
