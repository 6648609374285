import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import FileSelectorButton from "Components/FileSelector/FileSelectorButton";
import { useAttachmentFileUploader } from "Components/FileUploader/AttachmentFileUploaderContext";
import React, { useEffect, useState } from "react";
import RuleService from "Services/rule.service";
import AzureBlobService from "Services/azure.blob.service";
import { Attachment, RuleModel } from "types/models";
import { isNilOrEmpty } from "utils/utils";
import RuleAttachment from "./RuleAttachment";
import RuleAttachmentEmpty from "./RuleAttachmentEmpty";
import { useConfirmation } from "Components/ConfirmDialog/ConfirmationContext";

interface RuleAttachmentProps {
    rule: RuleModel;
    readOnly: boolean;
}

const RuleAttachments = ({ rule, readOnly }: RuleAttachmentProps) => {
    const [attatchments, setAttatchments] = useState<Attachment[]>([]);
    const confirm = useConfirmation();
    const uploader = useAttachmentFileUploader();

    const handleFileUpload = async (file: File) => {
        const result = await uploader({
            ruleId: rule.id,
            file: file
        });

        if (!result.success) {
            return;
        }

        const next: Attachment[] = [...attatchments, result.data];

        setAttatchments(next);
    };

    const handleAttachmentDeleted = async (attatchment: Attachment) => {
        const result = await confirm({
            title: "Delete Attachment",
            description: "You are about to delete an Attachment. Do you wish to continue?"
        });

        if (!result.success) {
            return;
        }

        await RuleService.deleteAttachment(rule.id, attatchment.id);

        const next = attatchments.filter(a => a.id !== attatchment.id);

        setAttatchments(next);
    };

    const handleAttatchmentDownload = async (attatchment: Attachment) => {
        await AzureBlobService.downloadBlob(attatchment.blobId, attatchment.name);
    };

    useEffect(() => {
        const getAttachments = async () => {
            const attachments = await RuleService.getAttachments(rule.id);

            setAttatchments(attachments);
        };

        if (!isNilOrEmpty(rule?.id)) {
            getAttachments();
        }
    }, [rule?.id]);

    return (
        <div className="rule-attachments">
            <div className="attachments-header">
                <h5>Attachments</h5>

                <FileSelectorButton
                    className="button"
                    label="New Attatchment"
                    icon={faPlus}
                    disabled={readOnly}
                    onFileSelected={handleFileUpload}
                />
            </div>

            {isNilOrEmpty(attatchments) && <RuleAttachmentEmpty />}

            {attatchments.map(attachment => {
                return (
                    <RuleAttachment
                        key={attachment.id}
                        attachment={attachment}
                        readOnly={readOnly}
                        onAttatchmentDelete={handleAttachmentDeleted}
                        onAttatchmentDownload={handleAttatchmentDownload}
                    />
                );
            })}
        </div>
    );
};

export default RuleAttachments;
