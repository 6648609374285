import React from "react";
import MonthSelect from "Components/MonthSelect";
import DaySelect from "Components/DaySelect";
import YearSelect from "Components/YearSelect";
import { PeriodModel } from "types/models";
import Label from "Components/Label";
import { isNil } from "lodash";

interface RunPeriodSelectProps {
    id: string;
    field: string;
    period: PeriodModel;
    disabled?: boolean;
    onChange?: (period: PeriodModel) => void;
}

const RunPeriodSelect = ({ id, field, period, disabled, onChange }: RunPeriodSelectProps) => {
    const handleYearChanged = (year: number) => {
        const next: PeriodModel = { ...period, year: year };

        onChange(next);
    };

    const handleMonthChanged = (month: string) => {
        const next: PeriodModel = { ...period, month: !isNil(month) ? month.toLowerCase() : null };

        onChange(next);
    };

    const handleDayChanged = (day: number) => {
        const next: PeriodModel = { ...period, day: day };

        onChange(next);
    };

    return (
        <div className="run-period-select">
            <Label>{field}</Label>

            <div className="run-period-select--inputs">
                <DaySelect
                    id={`${id}.day`}
                    value={period.day}
                    className="run-period-select-day"
                    isClearable
                    disabled={disabled}
                    onDayChanged={handleDayChanged}
                />

                <MonthSelect
                    id={`${id}.month`}
                    value={period.month}
                    className="run-period-select-month"
                    isClearable
                    disabled={disabled}
                    onMonthChanged={handleMonthChanged}
                />

                <YearSelect
                    id={`${id}.year`}
                    value={period.year}
                    className="run-period-select-year"
                    isClearable
                    disabled={disabled}
                    onYearChanged={handleYearChanged}
                />
            </div>
        </div>
    );
};

export default RunPeriodSelect;
