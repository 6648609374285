import React from "react";
import EflowLogo from "Assets/images/eflow-projector-logo.svg";
import { Link } from "react-router-dom";

interface HeaderIconProps {
    link: string;
}

const HeaderIcon = ({ link }: HeaderIconProps) => {
    return (
        <Link to={link} className="header-icon">
            <img src={EflowLogo} id="eflow-logo" alt="Eflow Projector" />
        </Link>
    );
};

export default HeaderIcon;
