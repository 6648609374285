import Label from "Components/Label";
import { useLocations } from "Components/LocationContext";
import AppMap from "Components/Map";
import L, { LatLngBoundsExpression, LatLngExpression, Map } from "leaflet";
import { isNil } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Feature, FeatureCollection } from "types/geometry";
import { isNilOrEmpty } from "utils/utils";
import { GeoJSON } from "react-leaflet";
import { LocationModel } from "types/models";
import Button from "Components/Button";

interface CollectionAddFilterMapProps {
    selectedLocations: LocationModel[];
    onLocationsSelectChanged: (nextLocations: LocationModel[]) => void;
}

const MAP_CENTER: LatLngExpression = [-25.2744, 133.7751];
const MAP_BOUNDS: LatLngBoundsExpression = [
    [-44.85, 100.0],
    [-7.1422, 165.0]
];
const MAP_MAX_ZOOM = 15;
const MAP_MIN_ZOOM = 4;
const MAP_ZOOM = 4;
const DEFAULT_COLOUR = "#ff671b";
const HIGHLIGHT_COLOUR = "#8547AD";

const LAYER_STYLE = {
    fillColor: DEFAULT_COLOUR,
    weight: 1,
    opacity: 0.65,
    color: "white",
    dashArray: "3",
    fillOpacity: 0.8
};

const CollectionAddFilterMap = ({ selectedLocations, onLocationsSelectChanged }: CollectionAddFilterMapProps) => {
    const { locations } = useLocations();
    const [map, setMap] = useState<Map>(null);

    const geojsonLayerRef = useRef<L.GeoJSON>();

    useEffect(() => {
        if (!isNil(map)) {
            map.invalidateSize();
        }
    }, [map]);

    const geojsonLayer: FeatureCollection = useMemo(() => {
        if (isNilOrEmpty(locations)) {
            return null;
        }

        const features: Feature[] = locations.map(l => {
            return {
                type: "Feature",
                geometry: l.geometry,
                properties: {
                    location: l
                }
            };
        });

        return {
            type: "FeatureCollection",
            features: features
        };
    }, [locations]);

    const initFeature = (feature, layer) => {
        layer.bindTooltip(feature.properties.location.name, { sticky: true });

        if (selectedLocations.find(l => l.id === feature.properties.location.id)) {
            layer.setStyle({
                fillColor: HIGHLIGHT_COLOUR
            });
        }
    };

    const handleLayerClicked = (e: L.LeafletEvent) => {
        const layer = e.layer;

        const location = layer.feature.properties.location;

        if (selectedLocations.find(l => l.id === location.id)) {
            onLocationsSelectChanged([...selectedLocations.filter(l => l.id !== location.id)]);
            layer.setStyle({
                fillColor: DEFAULT_COLOUR
            });
            return;
        }

        layer.setStyle({
            fillColor: HIGHLIGHT_COLOUR
        });
        onLocationsSelectChanged([...selectedLocations, location]);
    };

    const allEnabled = selectedLocations.length === locations.length;

    const handleDisableAll = () => {
        geojsonLayerRef.current.setStyle({
            fillColor: DEFAULT_COLOUR
        });
        onLocationsSelectChanged([]);
    };

    const handleEnableAll = () => {
        geojsonLayerRef.current.setStyle({
            fillColor: HIGHLIGHT_COLOUR
        });
        onLocationsSelectChanged(...[locations]);
    };

    return (
        <div className="filter-map-container">
            <Label>Filter by locations</Label>

            <AppMap
                containerClassName="filter-map"
                center={MAP_CENTER}
                maxBounds={MAP_BOUNDS}
                maxZoom={MAP_MAX_ZOOM}
                minZoom={MAP_MIN_ZOOM}
                zoom={MAP_ZOOM}
                onMapCreated={map => setMap(map)}
            >
                {!isNil(geojsonLayer) && !isNil(map) && (
                    <GeoJSON
                        data={geojsonLayer}
                        style={LAYER_STYLE}
                        onEachFeature={initFeature}
                        ref={geojsonLayerRef}
                        eventHandlers={{
                            click: e => {
                                handleLayerClicked(e);
                            }
                        }}
                    ></GeoJSON>
                )}

                <Button onClick={allEnabled ? handleDisableAll : handleEnableAll} className="modify-all-button">
                    {allEnabled ? "Disable all" : "Enable all"}
                </Button>
            </AppMap>
        </div>
    );
};

export default CollectionAddFilterMap;
