import { forEach, isNil, round } from "lodash";
import {
    ExplorePeriodModel,
    RuleIntermediateResults,
    RuleIntermediateResultType,
    RuleIntermediateResultTypeScale,
    RunResultData,
    SuccessLevel,
    TimeseriesDay
} from "types/models";
import { isNilOrEmpty } from "./utils";
import Humanize from "humanize-plus";
import moment from "moment";

interface PercentBarChartResult {
    key: string;
    percent: number;
}

interface YearlyBarChartResult {
    success: SuccessLevel;
    count: number;
    fromYear: number;
    toYear: number;
}

interface YearDataResult {
    year: number;
    success: SuccessLevel;
}

const UNSELECTABLE_INTERMEDIATE_RESULT_TYPES = [
    "rule",
    "date",
    "year",
    "flow",
    "depth",
    "rainfall",
    "evaporation",
    "salinity",
    "temperature",
    "risk",
    "recruitment",
    "failure_reason",
    "failure_reasons",
    "prevailing_climate",
    "start_date",
    "end_date",
    "measure"
];

const HEATMAP_MIN_COLOUR = { r: 255, g: 83, b: 73 };
const HEATMAP_MID_COLOUR = { r: 253, g: 198, b: 7 };
const HEATMAP_MAX_COLOUR = { r: 8, g: 191, b: 221 };

export const formatPercentBarChartData = (
    data: RunResultData,
    startYear: number,
    endYear: number
): PercentBarChartResult[] => {
    const result: YearDataResult[] = data.rows
        .map(r => {
            return { ...r, year: parseInt(r.year), success: formatSuccess(parseFloat(r.success)) };
        })
        .filter(r => r.year >= startYear && r.year <= endYear);

    return [
        {
            key: SuccessLevel.NONE,
            percent: getSuccessPercent(SuccessLevel.NONE, result)
        },
        {
            key: SuccessLevel.VERY_LOW,
            percent: getSuccessPercent(SuccessLevel.VERY_LOW, result)
        },
        {
            key: SuccessLevel.LOW,
            percent: getSuccessPercent(SuccessLevel.LOW, result)
        },
        {
            key: SuccessLevel.MODERATE,
            percent: getSuccessPercent(SuccessLevel.MODERATE, result)
        },
        {
            key: SuccessLevel.HIGH,
            percent: getSuccessPercent(SuccessLevel.HIGH, result)
        },
        {
            key: SuccessLevel.VERY_HIGH,
            percent: getSuccessPercent(SuccessLevel.VERY_HIGH, result)
        }
    ];
};

export const formatYearlyBarChartData = (
    data: RunResultData,
    startYear: number,
    endYear: number
): YearlyBarChartResult[] => {
    const result: YearDataResult[] = data.rows
        .map(r => {
            return { ...r, year: parseInt(r.year), success: formatSuccess(parseFloat(r.success)) };
        })
        .filter(r => r.year >= startYear && r.year <= endYear);

    const groupedResult = [];

    forEach(result, year => {
        const latestSection = groupedResult.length > 0 ? groupedResult[groupedResult.length - 1] : null;

        if (isNil(latestSection)) {
            groupedResult.push({ success: year.success, count: 1, fromYear: year.year, toYear: year.year });
            return;
        }

        if (latestSection.success === year.success) {
            groupedResult[groupedResult.length - 1] = {
                ...latestSection,
                count: latestSection.count + 1,
                toYear: year.year
            };
            return;
        }

        groupedResult.push({ success: year.success, count: 1, fromYear: year.year, toYear: year.year });
    });

    return groupedResult;
};

export const getResultPeriod = (data: RunResultData): ExplorePeriodModel => {
    const resultYears = data.rows.map(d => parseInt(d.year));

    const startYear = resultYears[0];
    const endYear = resultYears[resultYears.length - 1];

    return {
        minYear: startYear,
        maxYear: endYear,
        startYear: startYear,
        endYear: endYear,
        years: resultYears,
        sliderMarks: resultYears.filter(y => y % 10 === 0)
    };
};

const getSuccessPercent = (successType: SuccessLevel, data: YearDataResult[]): number => {
    const filteredData = data.filter(d => d.success === successType);

    if (isNilOrEmpty(filteredData)) {
        return 0;
    }

    return round((filteredData.length / data.length) * 100, 2);
};

const formatSuccess = (success: number) => {
    if (0 <= success && success <= 20) {
        return SuccessLevel.VERY_LOW;
    }

    if (20 < success && success <= 40) {
        return SuccessLevel.LOW;
    }

    if (40 < success && success <= 60) {
        return SuccessLevel.MODERATE;
    }

    if (60 < success && success <= 80) {
        return SuccessLevel.HIGH;
    }

    if (80 < success && success <= 100) {
        return SuccessLevel.VERY_HIGH;
    }

    return SuccessLevel.NONE;
};

export const mapSuccessToChartColour = (key: SuccessLevel): string => {
    switch (key) {
        case SuccessLevel.VERY_LOW:
            return "#FF5349";
        case SuccessLevel.LOW:
            return "#FF9125";
        case SuccessLevel.MODERATE:
            return "#FDCD06";
        case SuccessLevel.HIGH:
            return "#43D98E";
        case SuccessLevel.VERY_HIGH:
            return "#08BFDD";
        default:
            return "#acacae";
    }
};

export const mapSuccessToLabel = (success: SuccessLevel): string => {
    switch (success) {
        case SuccessLevel.VERY_LOW:
            return "0-20%";
        case SuccessLevel.LOW:
            return "21-40%";
        case SuccessLevel.MODERATE:
            return "41-60%";
        case SuccessLevel.HIGH:
            return "61-80%";
        case SuccessLevel.VERY_HIGH:
            return "81-100%";
        default:
            return "No Data";
    }
};

export const getAvaliableIntermediateResultTypes = (
    dailyData: RunResultData,
    yearlyData: RunResultData
): RuleIntermediateResultType[] => {
    const dailyTypes = dailyData.headers
        .filter(h => !UNSELECTABLE_INTERMEDIATE_RESULT_TYPES.includes(h))
        .map(h => {
            return {
                id: `${h}_daily`,
                value: h,
                label: Humanize.capitalize(h.replace(/_/g, " ")),
                scale: RuleIntermediateResultTypeScale.DAILY
            };
        });

    const yearlyTypes = yearlyData.headers
        .filter(h => !UNSELECTABLE_INTERMEDIATE_RESULT_TYPES.includes(h))
        .map(h => {
            return {
                id: `${h}_yearly`,
                value: h,
                label: Humanize.capitalize(h.replace(/_/g, " ")),
                scale: RuleIntermediateResultTypeScale.YEARLY
            };
        });

    const allTypes = [...dailyTypes, ...yearlyTypes].reduce(
        (typeAccumulator: RuleIntermediateResultType[], checkType: RuleIntermediateResultType) => {
            if (!typeAccumulator.some(t => t.id === checkType.id)) {
                typeAccumulator.push(checkType);
            }
            return typeAccumulator;
        },
        []
    );

    return allTypes;
};

export const formatDailyIntermediateResults = (
    data: RunResultData,
    intermediateResultType: RuleIntermediateResultType
): RuleIntermediateResults => {
    const yearlyResults = {};
    const dates = buildYearTimeseriesDates();
    let minValue = null;
    let maxValue = null;
    const yearsSet = new Set<number>();

    forEach(data.rows, day => {
        // Get all required params
        const date = moment(day.date, "DD/MM/YYYY").toDate();
        const value = +day[intermediateResultType.value];
        const year = date.getFullYear();

        //Update min/max values
        minValue = isNil(minValue) || value < minValue ? value : minValue;
        maxValue = isNil(maxValue) || value > maxValue ? value : maxValue;

        //Add year
        yearsSet.add(year);

        // Build data structure
        if (isNil(yearlyResults[year])) {
            yearlyResults[year] = [...dates];
        }

        // Get matching date
        const dateIndex = yearlyResults[year].findIndex(d => areDatesEqual(date, d.date));

        // Push result
        yearlyResults[year][dateIndex] = { date: date, value: value };
    });

    // Create results period
    const allYears = [...yearsSet].sort();
    const startYear = allYears[0];
    const endYear = allYears[allYears.length - 1];

    const allDays = Array.from({ length: 366 }, (_, i) => i + 1);

    if (0 <= minValue && minValue <= 100 && 0 <= maxValue && maxValue <= 100) {
        minValue = 0;
        maxValue = 100;
    }

    return {
        result: yearlyResults,
        minValue: minValue,
        maxValue: maxValue,
        yearlyResultPeriod: {
            minYear: startYear,
            maxYear: endYear,
            startYear: startYear,
            endYear: endYear,
            years: allYears,
            sliderMarks: allYears.filter(y => y % 10 === 0)
        },
        dailyResultPeriod: {
            minDay: 1,
            maxDay: 366,
            startDay: 1,
            endDay: 366,
            days: allDays,
            sliderMarks: allDays.filter(d => d % 30 === 0)
        },
        resultsType: intermediateResultType
    };
};

export const formatYearlyIntermediateResults = (
    data: RunResultData,
    intermediateResultType: RuleIntermediateResultType
): RuleIntermediateResults => {
    const yearlyResults = [];
    const yearsSet = new Set<number>();

    forEach(data.rows, yearRow => {
        // Get all required params
        const date = moment(yearRow.year, "YYYY").toDate();
        const value = +yearRow[intermediateResultType.value];

        //Add year
        yearsSet.add(date.getFullYear());

        // Build data structure
        yearlyResults.push({ date: date, value: value });
    });

    // Create results period
    const allYears = [...yearsSet].sort();
    const startYear = allYears[0];
    const endYear = allYears[allYears.length - 1];

    return {
        result: yearlyResults,
        yearlyResultPeriod: {
            minYear: startYear,
            maxYear: endYear,
            startYear: startYear,
            endYear: endYear,
            years: allYears,
            sliderMarks: allYears.filter(y => y % 10 === 0)
        },
        resultsType: intermediateResultType
    };
};

export const buildYearTimeseriesDates = (): TimeseriesDay[] => {
    const year = 2020; // We don't care about the year for display purposes, but it must be a leap year to allow all dates to be created
    const dates = [];

    for (let month = 0; month < 12; month++) {
        for (let day = 1; day <= 31; day++) {
            const date = new Date(year, month, day);
            if (date.getFullYear() === year && date.getMonth() === month) {
                dates.push({ date: date, value: null });
            }
        }
    }

    return dates;
};

const areDatesEqual = (date1: Date, date2: Date) => {
    return date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
};

export const generateHeatmapColours = (valuesArray: TimeseriesDay[], minValue: number, maxValue: number) => {
    return valuesArray.map(d => {
        return !isNil(d?.value) ? getHeatmapColour(+d?.value, minValue, maxValue) : "#acacae";
    });
};

const getHeatmapColour = (value: number, min: number, max: number) => {
    const range = max - min;
    const positionInRange = range > 0 ? (value - min) / range : 1;

    if (positionInRange < 0.5) {
        const adjustedPositionInRange = positionInRange / 0.5;

        const r = round(HEATMAP_MIN_COLOUR.r + adjustedPositionInRange * (HEATMAP_MID_COLOUR.r - HEATMAP_MIN_COLOUR.r));
        const g = round(HEATMAP_MIN_COLOUR.g + adjustedPositionInRange * (HEATMAP_MID_COLOUR.g - HEATMAP_MIN_COLOUR.g));
        const b = round(HEATMAP_MIN_COLOUR.b + adjustedPositionInRange * (HEATMAP_MID_COLOUR.b - HEATMAP_MIN_COLOUR.b));
        return `rgb(${r}, ${g}, ${b})`;
    }

    if (positionInRange > 0.5) {
        const adjustedPositionInRange = (positionInRange - 0.5) / 0.5;

        const r = round(HEATMAP_MID_COLOUR.r + adjustedPositionInRange * (HEATMAP_MAX_COLOUR.r - HEATMAP_MID_COLOUR.r));
        const g = round(HEATMAP_MID_COLOUR.g + adjustedPositionInRange * (HEATMAP_MAX_COLOUR.g - HEATMAP_MID_COLOUR.g));
        const b = round(HEATMAP_MID_COLOUR.b + adjustedPositionInRange * (HEATMAP_MAX_COLOUR.b - HEATMAP_MID_COLOUR.b));
        return `rgb(${r}, ${g}, ${b})`;
    }

    return `rgb(${HEATMAP_MID_COLOUR.r}, ${HEATMAP_MID_COLOUR.g}, ${HEATMAP_MID_COLOUR.b})`;
};
