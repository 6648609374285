import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import RuleHeader from "./RuleHeader";
import RuleTabs, { RuleTabType, TabSelection } from "./RuleTabs";
import RuleGeneralInfo from "./RuleGeneralInfo";
import RuleSettings from "./RuleSettings";
import RuleContextProvider from "./RuleContext";
import { useResourceLocker } from "Hooks/useResourceLocker";
import { ResourceLockErrors, ResourceLockModel, ResourceType } from "types/models";
import { isNil } from "lodash";
import LoadingIndicator from "Components/LoadingIndicator";
import { useAuth0 } from "auth/react-auth0-spa";
import ResourceLockedWarning from "Components/ResourceLockedWarning";
import { AttachmentFileUploaderModalProvider } from "Components/FileUploader/AttachmentFileUploaderContext";
import { Toast } from "Components/Toast";

import "./Rule.scss";
import RuleExplore from "./RuleExplore";

const Rule = () => {
    const { id } = useParams<{ id: string }>();
    const { eflowUser } = useAuth0();
    const [activeTab, setActiveTab] = useState<TabSelection>({ index: 0, type: RuleTabType.GENERAL });
    const [resourceLock, setResourceLock] = useState<ResourceLockModel>(null);
    const { lock, unlock } = useResourceLocker();
    const history = useHistory();

    useEffect(() => {
        const doInit = async (ruleId: string) => {
            const lock_result = await lock(ruleId, ResourceType.RULE);

            if (!lock_result.success) {
                if (lock_result.error?.code === ResourceLockErrors.ENTITY_NOT_FOUND) {
                    Toast.error("Rule could not be found");
                    history.push("/");
                }

                return;
            }

            setResourceLock(lock_result.lock);
        };

        doInit(id);

        return () => {
            unlock(id);
        };
    }, [id]);

    const isLockOwner = useMemo(() => {
        if (isNil(resourceLock?.owner)) {
            return false;
        }

        return resourceLock.owner.id === eflowUser.id;
    }, [resourceLock]);

    if (isNil(resourceLock)) {
        return <LoadingIndicator className="rule-loading" centered />;
    }

    return (
        <AttachmentFileUploaderModalProvider>
            <RuleContextProvider ruleId={id} readOnly={!isLockOwner}>
                <div className="rule-container">
                    <RuleHeader />

                    {!isLockOwner && <ResourceLockedWarning resourceType="rule" owner={resourceLock.owner} />}

                    <RuleTabs selectedTab={activeTab} onTabSelected={setActiveTab} />

                    {activeTab.type === RuleTabType.GENERAL && <RuleGeneralInfo />}

                    {activeTab.type === RuleTabType.SETTINGS && <RuleSettings />}

                    {activeTab.type === RuleTabType.EXPLORE && <RuleExplore />}
                </div>
            </RuleContextProvider>
        </AttachmentFileUploaderModalProvider>
    );
};

export default Rule;
