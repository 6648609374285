import React from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import clsx from "clsx";

import "./TextArea.scss";

export type TextAreaProps = {
    register?: UseFormRegisterReturn;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

const TextArea = (props: TextAreaProps) => {
    const { className, disabled, ...rest } = props;

    return (
        <textarea
            className={clsx("form-control", "erp-text-area", className, { disabled: disabled === true })}
            disabled={disabled}
            {...props.register}
            {...rest}
        ></textarea>
    );
};

export default TextArea;
