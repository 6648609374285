import { CollectionModel, RuleModel, TaskModel } from "types/models";
import {
    BulkCopyCollectionRequest,
    BulkMoveCollectionRequest,
    BulkCollectionDeleteRequest,
    CopyCollectionRequest,
    MoveCollectionRequest,
    UpdateCollectionNameRequest,
    UpdateCollectionRequest,
    AddCollectionRuleRequest,
    BulkAddCollectionRuleRequest,
    RemoveCollectionRuleRequest,
    BulkRemoveCollectionRuleRequest
} from "types/requests";
import API from "../api/data.api";
import { URL } from "../Constants/api";

export default class CollectionService {
    public static async getCollection(id: string): Promise<CollectionModel> {
        const collection = await API.get<CollectionModel>(`${URL.COLLECTIONS}/${id}`);
        return collection;
    }

    public static async getCollections(): Promise<CollectionModel[]> {
        const collections = await API.get<CollectionModel[]>(`${URL.COLLECTIONS}`);
        return collections;
    }

    public static async getCollectionRules(id: string): Promise<RuleModel[]> {
        const collectionRules = await API.get<RuleModel[]>(`${URL.COLLECTIONS}/${id}/rules`);
        return collectionRules;
    }

    public static async getCollectionTasks(id: string): Promise<TaskModel[]> {
        const collectionTasks = await API.get<TaskModel[]>(`${URL.COLLECTIONS}/${id}/tasks`);
        return collectionTasks;
    }

    public static async updateCollection(id: string, request: UpdateCollectionRequest): Promise<CollectionModel> {
        const collection = await API.patch<CollectionModel>(`${URL.COLLECTIONS}/${id}`, request);
        return collection;
    }

    public static async updateCollectionName(
        id: string,
        request: UpdateCollectionNameRequest
    ): Promise<CollectionModel> {
        const collection = await API.patch<CollectionModel>(`${URL.COLLECTIONS}/${id}/name`, request);
        return collection;
    }

    public static async deleteCollection(id: string): Promise<void> {
        await API.delete<void>(`${URL.COLLECTIONS}/${id}`);
    }

    public static async deleteCollections(ids: string[]): Promise<void> {
        const request: BulkCollectionDeleteRequest = {
            collections: ids ?? []
        };

        await API.delete<void>(`${URL.COLLECTIONS}`, request);
    }

    public static async copyCollection(id: string, destinationFolderId: string): Promise<CollectionModel> {
        const request: CopyCollectionRequest = {
            collectionId: id,
            toFolder: destinationFolderId
        };

        const copiedCollection = await API.post<CollectionModel>(`${URL.COLLECTIONS}/copy`, request);

        return copiedCollection;
    }

    public static async copyCollections(ids: string[], destinationFolderId: string): Promise<CollectionModel[]> {
        const request: BulkCopyCollectionRequest = {
            collectionIds: ids,
            toFolder: destinationFolderId
        };

        const copiedCollections = await API.post<CollectionModel[]>(`${URL.COLLECTIONS}/copy/bulk`, request);

        return copiedCollections;
    }

    public static async moveCollection(id: string, destinationFolderId: string): Promise<CollectionModel> {
        const request: MoveCollectionRequest = {
            collectionId: id,
            toFolder: destinationFolderId
        };

        const movedCollection = await API.post<CollectionModel>(`${URL.COLLECTIONS}/move`, request);

        return movedCollection;
    }

    public static async moveCollections(ids: string[], destinationFolderId: string): Promise<CollectionModel[]> {
        const request: BulkMoveCollectionRequest = {
            collectionIds: ids,
            toFolder: destinationFolderId
        };

        const movedCollections = await API.post<CollectionModel[]>(`${URL.COLLECTIONS}/move/bulk`, request);

        return movedCollections;
    }

    public static async addCollectionRule(id: string, ruleId: string): Promise<RuleModel[]> {
        const request: AddCollectionRuleRequest = {
            ruleId: ruleId
        };

        const collectionRules = await API.post<RuleModel[]>(`${URL.COLLECTIONS}/${id}/rules`, request);

        return collectionRules;
    }

    public static async addCollectionRules(id: string, ruleIds: string[]): Promise<RuleModel[]> {
        const request: BulkAddCollectionRuleRequest = {
            ruleIds: ruleIds
        };

        const collectionRules = await API.post<RuleModel[]>(`${URL.COLLECTIONS}/${id}/rules/bulk`, request);

        return collectionRules;
    }

    public static async removeCollectionRule(id: string, ruleId: string): Promise<RuleModel[]> {
        const request: RemoveCollectionRuleRequest = {
            ruleId: ruleId
        };

        const collectionRules = await API.delete<RuleModel[]>(`${URL.COLLECTIONS}/${id}/rules`, request);

        return collectionRules;
    }

    public static async removeCollectionRules(id: string, ruleIds: string[]): Promise<RuleModel[]> {
        const request: BulkRemoveCollectionRuleRequest = {
            ruleIds: ruleIds
        };

        const collectionRules = await API.delete<RuleModel[]>(`${URL.COLLECTIONS}/${id}/rules/bulk`, request);

        return collectionRules;
    }
}
