import React from "react";
import { RuleModel, TaskStatus } from "types/models";
import { isNilOrEmpty } from "utils/utils";
import { useCollection } from "./CollectionContext";
import { useSort } from "Components/SortableHeader/useSort";
import { useSelection } from "./useSelection";
import CollectionRulesTable from "./CollectionRulesTable";
import { SelectionState } from "./types";
import CollectionService from "Services/collection.service";
import { Toast } from "Components/Toast";
import { BaseComputationRequest } from "types/requests";
import ComputationService from "Services/computation.service";
import { isNil } from "lodash";

const CollectionReview = () => {
    const { collection, collectionRules, collecitonTasks, updateCollectionRules, updateCollectionTasks } =
        useCollection();

    const { selection, selectAll, selectRule, clearSelection, setAvaliableRules } = useSelection(collectionRules);

    const { sortedItems, handleSort, sortContext } = useSort(collectionRules);

    const handleRuleRemoved = async (rule: RuleModel) => {
        if (!collectionRules.some(r => r.id === rule.id)) {
            Toast.error("This rule isn't in the collection");
            return;
        }

        const nextCollectionRules = await CollectionService.removeCollectionRule(collection.id, rule.id);

        Toast.success(`Sucessfully removed ${rule.name}`);

        updateCollectionRules(nextCollectionRules);
        setAvaliableRules(nextCollectionRules);
    };

    const handleBulkRemove = async (state: SelectionState) => {
        const ruleIds = Array.from(state.rules.keys());

        if (isNilOrEmpty(ruleIds)) {
            return;
        }

        const filteredIds = ruleIds.filter(id => collectionRules.some(r => r.id === id));

        if (isNilOrEmpty(filteredIds)) {
            Toast.error("All selected rules are already not in the collection");
            return;
        }

        const nextCollectionRules = await CollectionService.removeCollectionRules(collection.id, filteredIds);

        const total = filteredIds.length;
        if (total > 0) {
            Toast.success(`Successfully removed ${total} item(s)`);
        }

        updateCollectionRules(nextCollectionRules);
        setAvaliableRules(nextCollectionRules);
        clearSelection();
    };

    const handleRuleRun = async (rule: RuleModel) => {
        if (collecitonTasks.find(t => t.resourceId === rule.id)?.status === TaskStatus.RUNNING) {
            return;
        }

        const request: BaseComputationRequest = {
            ruleId: rule.id,
            runPeriod: rule.runPeriod,
            dataInfillSettings: rule.dataInfillSettings
        };

        const result = await ComputationService.compute(request);

        if (isNil(result)) {
            return;
        }

        if (!result.success) {
            Toast.error(result.failure.message);
            return;
        }

        const nextTasks = collecitonTasks.filter(t => t.resourceId !== rule.id);
        updateCollectionTasks([...nextTasks, result.task]);
    };

    const handleBulkRun = async (state: SelectionState) => {
        const ruleIds = Array.from(state.rules.keys());

        if (isNilOrEmpty(ruleIds)) {
            return;
        }

        const filteredIds = ruleIds.filter(
            id => !collecitonTasks.some(t => t.resourceId === id && t.status === TaskStatus.RUNNING)
        );

        if (isNilOrEmpty(filteredIds)) {
            Toast.error("All selected rules are already running");
            return;
        }

        const newTasks = await Promise.all(
            filteredIds.map(async id => {
                const ruleToRun = collectionRules.find(r => r.id === id);

                const request: BaseComputationRequest = {
                    ruleId: ruleToRun.id,
                    runPeriod: ruleToRun.runPeriod,
                    dataInfillSettings: ruleToRun.dataInfillSettings
                };

                const result = await ComputationService.compute(request);

                if (isNil(result)) {
                    return;
                }

                if (!result.success) {
                    Toast.error(`Error when computing ${ruleToRun.name}: ${result.failure.message}`);
                    return;
                }

                return result.task;
            })
        );

        const cleanTasks = newTasks.filter(t => !isNil(t));
        const nextTasks = collecitonTasks.filter(t => !cleanTasks.some(ct => ct.id === t.id));

        updateCollectionTasks([...nextTasks, ...cleanTasks]);

        clearSelection();
    };

    return (
        <div className="collection-review">
            <CollectionRulesTable
                rules={sortedItems}
                collectionRules={collectionRules}
                selection={selection}
                sortContext={sortContext}
                allowAdd={false}
                allowRun={true}
                noRulesMessage="No rules in the collection"
                onRuleSelected={selectRule}
                onRuleRemove={handleRuleRemoved}
                onBulkRemove={handleBulkRemove}
                onAllSelected={selectAll}
                onSort={handleSort}
                onRuleRun={handleRuleRun}
                onBulkRun={handleBulkRun}
            />
        </div>
    );
};

export default CollectionReview;
