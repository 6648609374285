import axios from "axios";
import Config from "Config";
import { isNil } from "lodash";
import { isNilOrEmpty } from "utils/utils";

export default class AzureBlobService {
    static formatFileDownloadUrl(filename: string): string {
        return `${Config.azureStorageUrl}/${filename}`;
    }

    static async downloadBlob(resultAzureFileId: string, name: string): Promise<void> {
        try {
            const downloadUrl = this.formatFileDownloadUrl(resultAzureFileId);

            const response = await axios({
                url: downloadUrl,
                method: "GET",
                responseType: "arraybuffer"
            });

            this.downloadToDisk(new Blob([response.data]), name);
        } catch (error) {
            console.error(error.response.data);
        }
    }

    static async downloadBlobAsString(resultAzureFileId: string): Promise<string> {
        try {
            const downloadUrl = this.formatFileDownloadUrl(resultAzureFileId);

            const response = await axios.get(downloadUrl);

            return response.data;
        } catch (error) {
            console.error(error.response.data);
        }
    }

    static downloadToDisk(file: Blob, name: string): void {
        if (isNil(file)) {
            return;
        }

        const url = window.URL.createObjectURL(file);
        const link = document.createElement("a");

        link.href = url;
        link.setAttribute("download", name);

        document.body.appendChild(link);

        link.click();
    }

    static downloadToDiskFromBase64String(file: string, name: string): void {
        if (isNilOrEmpty(file)) {
            return;
        }

        const url = file;
        const link = document.createElement("a");

        link.href = url;
        link.setAttribute("download", name);

        document.body.appendChild(link);

        link.click();
    }

    static async downloadBlobAsFile(
        resultAzureFileId: string,
        name: string,
        onProgress?: (progress: number) => void
    ): Promise<File> {
        try {
            const downloadUrl = this.formatFileDownloadUrl(resultAzureFileId);

            const response = await axios({
                url: downloadUrl,
                method: "GET",
                responseType: "arraybuffer",
                onDownloadProgress: evt => {
                    if (onProgress && evt) {
                        const progress = Math.floor((evt.loaded / evt.total) * 100);
                        onProgress(progress);
                    }
                }
            });

            return new File([response.data], name);
        } catch (error) {
            console.error(error.response);
        }
    }
}
