import React, { useEffect, useState } from "react";
import EDSService from "../../Services/eds.service";
import Select, { SelectOption } from "Components/Select/Select";
import { DataType, StationModel } from "types/models";
import Label from "Components/Label";

interface DataPointStationSearchProps {
    gauge: string;
    onStationChanged: (nextStation: StationModel) => void;
}

const SITES_LIMIT = 100;

const DataPointStationSearch = ({ gauge, onStationChanged }: DataPointStationSearchProps) => {
    const [sites, setSites] = useState<SelectOption[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleGaugeChanged = (nextStation: StationModel) => {
        if (nextStation.identifier !== gauge) {
            onStationChanged(nextStation);
        }
    };

    useEffect(() => {
        const getSites = async () => {
            try {
                setIsLoading(true);

                const _sites = await EDSService.getBomSites(DataType.FLOW);

                const _siteOptions = _sites.map(s => {
                    return {
                        label: `${s.name} (${s.identifier})`,
                        value: s.identifier,
                        station: s
                    };
                });

                setSites(_siteOptions);
            } finally {
                setIsLoading(false);
            }
        };

        getSites();
    }, []);

    const filterSites = (search: string): SelectOption[] => {
        const matchingSites = sites.filter(s => {
            const normalisedSearch = search.toLowerCase();

            const normalisedIdentifier = s.station.identifier.toLowerCase();

            if (normalisedIdentifier.includes(normalisedSearch)) {
                return true;
            }

            const normalisedName = s.station.name.toLowerCase();

            if (normalisedName.includes(normalisedSearch)) {
                return true;
            }

            return false;
        });

        return matchingSites.length > SITES_LIMIT ? matchingSites.slice(0, 100) : matchingSites;
    };

    const loadOptions = (inputValue: string, callback: (options: SelectOption[]) => void) => {
        callback(filterSites(inputValue));
    };

    return (
        <div className="data-point-station">
            <Label>Station</Label>

            <Select
                isAsync={true}
                loadValues={loadOptions}
                value={sites.find(s => s.value === gauge) ?? null}
                placeholder="Search by gauge name or identifier"
                onSelected={(o: SelectOption) => handleGaugeChanged(o.station)}
                isLoading={isLoading}
            />
        </div>
    );
};

export default DataPointStationSearch;
