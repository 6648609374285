import { useAuth0 } from "auth/react-auth0-spa";
import React, { useEffect, useMemo, useState } from "react";
import { DataPointModel, DataType, LocationModel } from "types/models";
import { useSelection } from "./useSelection";
import { useSort } from "Components/SortableHeader/useSort";
import { useConfirmation } from "Components/ConfirmDialog/ConfirmationContext";
import DataPointService from "Services/data-point.service";
import { Toast } from "Components/Toast";
import { SelectionState } from "./types";
import { isNilOrEmpty } from "utils/utils";
import { isNil } from "lodash";
import DataLibraryHeader from "./DataLibraryHeader";
import DataLibraryFilter from "./DataLibraryFilter";
import DataLibraryTable from "./DataLibraryTable";
import SearchView from "./SearchView";
import Transition from "Components/Transition";

interface DataLibraryContainerProps {
    allowNewData: boolean;
    allowDataActions: boolean;
    onDataPointClicked?: (dataPoint: DataPointModel) => void;
}

export interface DataLibraryFilterSettings {
    location: LocationModel;
    dataTypes: DataType[];
}

const DEFAULT_DATA_TYPES_FILTER = [
    DataType.FLOW,
    DataType.DEPTH,
    DataType.TEMPERATURE,
    DataType.RAINFALL,
    DataType.CLIMATE,
    DataType.EVAPORATION,
    DataType.SALINITY,
    DataType.PH,
    DataType.TURBIDITY,
    DataType.STORAGE_VOLUME,
    DataType.STORAGE_DEPTH,
    null
];

const DataLibraryContainer = ({ allowNewData, allowDataActions, onDataPointClicked }: DataLibraryContainerProps) => {
    const { eflowUser } = useAuth0();
    const [dataPoints, setDataPoints] = useState<DataPointModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [filterSettings, setFilterSettings] = useState<DataLibraryFilterSettings>({
        location: null,
        dataTypes: DEFAULT_DATA_TYPES_FILTER
    });
    const [search, setSearch] = useState<string>("");

    const { selection, selectAll, selectDataPoint, deselectDataPoint, clearSelection, setAvaliableDataPoints } =
        useSelection(dataPoints);

    const { sortedItems, handleSort, sortContext } = useSort(dataPoints);

    const confirm = useConfirmation();

    useEffect(() => {
        const fetchDataPoints = async () => {
            try {
                setIsLoading(true);

                const _dataPoints = await DataPointService.getDataPoints();

                setDataPoints(_dataPoints);
            } finally {
                setIsLoading(false);
            }
        };

        fetchDataPoints();
    }, []);

    const handleDataPointDelete = async (dataPoint: DataPointModel) => {
        const result = await confirm({
            title: "Delete data point",
            description: `You are about to delete a data point. This cannot be undone, do you wish to continue?`
        });

        if (!result.success) {
            return;
        }

        const nextDataPoints = dataPoints.filter(d => d.id !== dataPoint.id);
        setDataPoints(nextDataPoints);
        deselectDataPoint(dataPoint);

        await DataPointService.deleteDataPoint(dataPoint.id);

        Toast.success("Successfully deleted data point");
    };

    const handleDataPointCopy = async (dataPoint: DataPointModel) => {
        const copiedDataPoint = await DataPointService.copyDataPoint(dataPoint.id);

        setDataPoints([...dataPoints, copiedDataPoint]);

        Toast.success(`Successfully copied data point ${dataPoint.name}`);
    };

    const handleBulkDelete = async (state: SelectionState) => {
        const result = await confirm({
            title: "Delete selection",
            description: `You are about to delete the selected items. This cannot be undone, do you wish to continue?`
        });

        if (!result.success) {
            return;
        }

        const dataPointsToRemove = Array.from(state.dataPoints.keys());

        if (!isNilOrEmpty(dataPointsToRemove)) {
            await DataPointService.deleteDataPoints(dataPointsToRemove);
            const nextDataPoints = dataPoints.filter(d => !dataPointsToRemove.includes(d.id));
            setDataPoints(nextDataPoints);
        }

        clearSelection();

        const total = dataPointsToRemove.length;

        if (total > 0) {
            Toast.success(`Successfully deleted ${dataPointsToRemove.length} item(s)`);
        }
    };

    const handleBulkCopy = async (state: SelectionState) => {
        const dataPointsToCopy = Array.from(state.dataPoints.keys());

        const totalSelected = dataPointsToCopy.length;

        if (!isNilOrEmpty(dataPointsToCopy)) {
            const copiedDataPoints = await DataPointService.copyDataPoints(dataPointsToCopy);

            setDataPoints([...dataPoints, ...copiedDataPoints]);
        }

        clearSelection();

        Toast.success(`Successfully copied ${totalSelected} items(s)`);
    };

    const isSearching = !isNilOrEmpty(search);

    const itemsToShow = useMemo(() => {
        const _filteredItems = sortedItems.filter(d => {
            if (!isNil(filterSettings.location)) {
                if (d?.location?.id !== filterSettings.location.id) {
                    return false;
                }
            }

            if (isNilOrEmpty(d.dataTypes) && filterSettings.dataTypes.includes(null)) {
                return true;
            }

            if (!d.dataTypes.some(dataType => filterSettings.dataTypes.includes(dataType))) {
                return false;
            }

            return true;
        });

        setAvaliableDataPoints(_filteredItems);

        return _filteredItems;
    }, [sortedItems, filterSettings]);

    return (
        <div>
            <DataLibraryHeader showNewDataButton={allowNewData} search={search} onSearch={setSearch} />

            <Transition isShown={!isSearching} transitionType="fade">
                <div className="data-view-container">
                    <DataLibraryFilter
                        filterSettings={filterSettings}
                        filteredDataPoints={itemsToShow}
                        onFilterSettingsChange={setFilterSettings}
                    />

                    <DataLibraryTable
                        user={eflowUser}
                        isLoading={isLoading}
                        dataPoints={itemsToShow}
                        selection={selection}
                        sortContext={sortContext}
                        allowDataActions={allowDataActions}
                        onDataPointClicked={onDataPointClicked}
                        onDataPointSelected={selectDataPoint}
                        onDataPointCopy={handleDataPointCopy}
                        onDataPointDelete={handleDataPointDelete}
                        onBulkDelete={handleBulkDelete}
                        onBulkCopy={handleBulkCopy}
                        onAllSelected={selectAll}
                        onSort={handleSort}
                    />
                </div>
            </Transition>

            <Transition isShown={isSearching} transitionType="fade">
                <SearchView search={search} onDataPointClicked={onDataPointClicked} />
            </Transition>
        </div>
    );
};

export default DataLibraryContainer;
