import React from "react";
import { Controller } from "react-hook-form";
import { PluginFieldProps } from "./types";
import FormInput from "Components/FormInput/FormInput";

interface PluginInputProps extends PluginFieldProps {
    onChange: (value: string) => void;
}

const PluginInput = ({ id, definition, field, control, disabled, onChange }: PluginInputProps) => {
    return (
        <Controller
            control={control}
            name={`${definition.id}.${field.id}`}
            render={({ field: _field }) => {
                return (
                    <FormInput
                        id={id}
                        labelText={field.name}
                        labelClass="plugin-definition-field-label"
                        defaultValue={_field.value}
                        onBlur={e => {
                            const value = e.target?.value ?? null;

                            if (value !== _field.value) {
                                onChange(e.target?.value ?? null);
                            }
                        }}
                        disabled={disabled}
                        maxLength={100}
                    />
                );
            }}
        />
    );
};

export default PluginInput;
