import React from "react";
import { CollectionModel } from "types/models";
import ERPModal from "Components/Modals/ERPModal";
import { ModalProps } from "Components/Modals/types";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import FormActions from "Components/FormActions";
import Button from "Components/Button";
import LoadingButton from "Components/LoadingButton";
import FormInput from "Components/FormInput/FormInput";
import { UpdateCollectionNameRequest } from "types/requests";
import { Toast } from "Components/Toast";
import CollectionService from "Services/collection.service";

interface CollectionModalProps extends ModalProps {
    context: CollectionModalContext;
    onCollectionUpdated: (collection: CollectionModel) => void;
}

export interface CollectionModalContext {
    collection: CollectionModel;
}

const schema = yup.object().shape({
    name: yup.string().required("Name is required")
});

interface CollectionForm {
    name: string;
}

const CollectionModal = ({ context, show, onClose, onCollectionUpdated }: CollectionModalProps) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isDirty, isSubmitting }
    } = useForm<CollectionForm>({
        resolver: yupResolver(schema),
        defaultValues: {
            name: context.collection.name ?? ""
        }
    });

    const handleFormSubmit = async (data: CollectionForm) => {
        const request: UpdateCollectionNameRequest = {
            name: data.name
        };

        const updatedCollection = await CollectionService.updateCollectionName(context.collection.id, request);

        Toast.success(`Successfully updated collection ${data.name}`);

        onCollectionUpdated(updatedCollection);

        onClose();
    };

    return (
        <ERPModal title="Edit collection" show={show} onClose={onClose}>
            <div>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <FormInput
                        labelText="Name"
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Folder name"
                        register={register("name")}
                        error={errors?.name}
                        disabled={isSubmitting}
                    />

                    <FormActions>
                        <Button variant="secondary" disabled={isSubmitting} onClick={onClose}>
                            Cancel
                        </Button>

                        <LoadingButton disabled={!isDirty} isLoading={isSubmitting} variant="primary" type="submit">
                            Update
                        </LoadingButton>
                    </FormActions>
                </form>
            </div>
        </ERPModal>
    );
};

export default CollectionModal;
