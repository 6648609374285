import React from "react";
import { faMinusCircle, faPlusCircle } from "@fortawesome/pro-regular-svg-icons";
import TooltipIcon from "Components/TooltipIcon";
import { SelectionState } from "./types";
import { isNil } from "lodash";
import { faPlayCircle } from "@fortawesome/pro-solid-svg-icons";

interface CollectionRulesTableBulkActionsProps {
    selection: SelectionState;
    allowAdd: boolean;
    allowRun: boolean;
    onAdd?: (selection: SelectionState) => void;
    onRemove: (selection: SelectionState) => void;
    onRun?: (selection: SelectionState) => void;
}

const CollectionRulesTableBulkActions = ({
    selection,
    allowAdd,
    allowRun,
    onAdd,
    onRemove,
    onRun
}: CollectionRulesTableBulkActionsProps) => {
    const hasSelection = selection.rules.size > 0;

    const handleAdd = () => {
        if (!isNil(onAdd)) {
            onAdd(selection);
        }
    };

    const handleRun = () => {
        if (!isNil(onRun)) {
            onRun(selection);
        }
    };

    const handleRemove = () => {
        onRemove(selection);
    };

    return (
        <div className="table--bulk-actions">
            <span>Bulk actions: </span>

            <div className="action-icons">
                {allowAdd && (
                    <TooltipIcon
                        icon={faPlusCircle}
                        size="lg"
                        placement="bottom"
                        className={hasSelection ? "add-icon" : "disabled-icon"}
                        tooltip="Add all selected"
                        disabled={!hasSelection}
                        onClick={handleAdd}
                    />
                )}

                <TooltipIcon
                    icon={faMinusCircle}
                    size="lg"
                    tooltip="Remove all selected"
                    placement="bottom"
                    className={hasSelection ? "remove-icon" : "disabled-icon"}
                    disabled={!hasSelection}
                    onClick={handleRemove}
                />

                {allowRun && (
                    <TooltipIcon
                        icon={faPlayCircle}
                        size="lg"
                        placement="bottom"
                        className={hasSelection ? "run-icon" : "disabled-icon"}
                        tooltip="Run all selected"
                        disabled={!hasSelection}
                        onClick={handleRun}
                    />
                )}
            </div>
        </div>
    );
};

export default CollectionRulesTableBulkActions;
