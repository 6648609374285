import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import { ChartOptions } from "chart.js";
import ERPLineTimeseriesChart from "Components/Charts/ERPLineTimeseriesChart";
import TooltipIcon from "Components/TooltipIcon";
import { merge } from "lodash";
import React, { useMemo } from "react";
import {
    EflowTimerseriesChartData,
    ExplorePeriodModel,
    RuleIntermediateResultType,
    RuleIntermediateResultYearlyTimeseries
} from "types/models";

interface RuleExploreIntermediateYearlyChartProps {
    result: RuleIntermediateResultYearlyTimeseries;
    title: string;
    yearlyPeriod: ExplorePeriodModel;
    resultType: RuleIntermediateResultType;
}

const CHART_HEIGHT = 500;

const CHART_OPTIONS: ChartOptions<"line"> = {
    maintainAspectRatio: false,
    normalized: true,
    interaction: {
        intersect: true,
        mode: "index"
    },
    layout: {
        padding: 5
    },
    plugins: {
        tooltip: {
            enabled: true,
            callbacks: {
                label: function (tooltipItem) {
                    return `${tooltipItem.dataset.label}: ${tooltipItem.formattedValue}%`;
                }
            }
        },
        legend: {
            display: true,
            position: "top"
        },
        decimation: {
            enabled: true
        },
        title: {
            display: true,
            position: "top",
            align: "start",
            font: {
                size: 20
            },
            padding: {
                bottom: 50
            }
        }
    },
    animation: false,
    scales: {
        x: {
            title: {
                display: true,
                text: "Year"
            },
            type: "timeseries",
            time: {
                unit: "year",
                displayFormats: {
                    year: "YYYY"
                },
                tooltipFormat: "YYYY"
            },
            grid: {
                display: false
            }
        },
        y: {
            type: "linear",
            min: 0,
            max: 102,
            ticks: {
                stepSize: 10,
                includeBounds: false
            },
            display: true,
            position: "left",
            grid: {
                display: false
            },
            title: {
                display: true,
                text: "Score (%)"
            }
        }
    },
    parsing: {
        xAxisKey: "date",
        yAxisKey: "value"
    }
};

const RuleExploreIntermediateYearlyChart = ({
    result,
    title,
    yearlyPeriod,
    resultType
}: RuleExploreIntermediateYearlyChartProps) => {
    const chartOpts: ChartOptions<"line"> = useMemo(() => {
        return merge<ChartOptions<"line">, ChartOptions<"line">, ChartOptions<"line">>({}, CHART_OPTIONS, {
            plugins: {
                title: {
                    text: title
                }
            }
        });
    }, [title]);

    const chartData: EflowTimerseriesChartData = useMemo(() => {
        return [
            {
                label: resultType.label,
                data: result.filter(
                    d => d.date.getFullYear() >= yearlyPeriod.startYear && d.date.getFullYear() <= yearlyPeriod.endYear
                ),
                backgroundColor: "#FF671B",
                pointRadius: 0,
                borderWidth: 2,
                borderColor: "#FF671B",
                pointHitRadius: 5
            }
        ];
    }, [result, yearlyPeriod]);

    return (
        <div className="chart">
            <div className="chart-tools">
                <div className="chart-options">
                    <TooltipIcon
                        tooltip="Use shift+drag to zoom
                Double click to reset zoom
                Pan with the mouse"
                        icon={faQuestionCircle}
                        size={"2x"}
                        className="chart-tooltip"
                    />
                </div>
            </div>

            <ERPLineTimeseriesChart
                id={title}
                data={chartData}
                options={chartOpts}
                height={CHART_HEIGHT}
                canZoom={true}
                persistLegendState={true}
            />
        </div>
    );
};

export default RuleExploreIntermediateYearlyChart;
