import { isNil } from "lodash";
import { RuleExploreResultsModel, RuleResultModel, RuleResultType, RuleRunModel, RunResultData } from "types/models";
import { isNilOrEmpty } from "utils/utils";
import AzureBlobService from "./azure.blob.service";
import { CSVParser } from "./csv-parser";
import ZipGenerator, { ZipFileInfo } from "./zip-generator";

export default class RuleResultService {
    private static readonly generator = new ZipGenerator();
    private static readonly csvParser = new CSVParser();

    static async getRunResults(run: RuleRunModel): Promise<RuleExploreResultsModel> {
        const [summaryYearly, intermediateYearly, intermediateDaily] = [
            run.results.find(r => r.resultType === RuleResultType.YEARLY_RESULTS),
            run.results.find(r => r.resultType === RuleResultType.YEARLY_INTERMEDIATE),
            run.results.find(r => r.resultType === RuleResultType.DAILY_INTERMEDIATE)
        ];

        const hasSummary = !isNil(summaryYearly);
        const hasIntermediate = !isNil(intermediateYearly) || !isNil(intermediateDaily);

        return {
            hasSummary: hasSummary,
            hasIntermediate: hasIntermediate,
            summary: {
                yearly: await this.getRunResultData(run, summaryYearly)
            },
            intermediate: {
                daily: await this.getRunResultData(run, intermediateDaily),
                yearly: await this.getRunResultData(run, intermediateYearly)
            }
        };
    }

    static async getRunResultData(run: RuleRunModel, result: RuleResultModel): Promise<RunResultData> {
        if (isNil(result)) {
            return { rows: [], headers: [] };
        }

        const resultData = await AzureBlobService.downloadBlobAsString(`${run.id}/${result.blobId}`);

        if (isNilOrEmpty(resultData)) {
            return { rows: [], headers: [] };
        }

        const parsedData = this.csvParser.parse<string, string>(resultData);

        return parsedData;
    }

    static async zipFiles(files: ZipFileInfo[]): Promise<Blob> {
        return this.generator.generate(files);
    }

    static combineDataToBlob(data: RunResultData[]): Blob {
        const combinedData = this.csvParser.combine<string, string>(data);
        const dataRaw = this.csvParser.formatToString(combinedData);

        const blob = new Blob([...dataRaw]);
        return blob;
    }
}
