import React from "react";
import FormActions from "Components/FormActions";
import Button from "Components/Button";

interface FileSystemModalFooterProps {
    canSelect: boolean;
    onSelect: () => void;
    onCancel: () => void;
}

const FileSystemModalFooter = ({ canSelect, onSelect, onCancel }: FileSystemModalFooterProps) => {
    return (
        <FormActions>
            <Button variant="grey" onClick={onCancel}>
                Cancel
            </Button>

            <Button disabled={!canSelect} variant="primary" onClick={onSelect}>
                Select
            </Button>
        </FormActions>
    );
};

export default FileSystemModalFooter;
