import AppMap from "Components/Map";
import L, { Icon, LatLng, LatLngBoundsExpression, LatLngExpression, Map } from "leaflet";
import { isNil, round } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { LayersControl, Marker, Popup, useMapEvents } from "react-leaflet";
import locationPinIcon from "Assets/images/location-pin-icon.svg";
import { useConfirmation } from "Components/ConfirmDialog/ConfirmationContext";
import { useLocations } from "Components/LocationContext";
import { Feature, FeatureCollection } from "types/geometry";
import { isNilOrEmpty } from "utils/utils";
import { GeoJSON } from "react-leaflet";

interface DataPointMapProps {
    name: string;
    latitude: number;
    longitude: number;
    allowPositionChange: boolean;
    onPositionChange?: (position: LatLng) => void;
}

interface DataPointMapLocationMarkerProps {
    name: string;
    position: LatLng;
    map: Map;
    onPositionChange?: (position: LatLng) => void;
}

const MAP_CENTER: LatLngExpression = [-25.2744, 133.7751];
const MAP_BOUNDS: LatLngBoundsExpression = [
    [-44.85, 100.0],
    [-7.1422, 165.0]
];
const MAP_MAX_ZOOM = 15;
const MAP_MIN_ZOOM = 4;
const MAP_ZOOM = 4;
const DEFAULT_COLOUR = "#ff671b";
const HIGHLIGHT_COLOUR = "#8547AD";

const LAYER_STYLE = {
    fillColor: DEFAULT_COLOUR,
    weight: 1,
    opacity: 0.65,
    color: "white",
    dashArray: "3",
    fillOpacity: 0.8
};

const LocationMarker = ({ name, position, map, onPositionChange }: DataPointMapLocationMarkerProps) => {
    useEffect(() => {
        if (!isNil(position) && !isNil(map)) {
            map.panTo(position);
        }
    }, [position, map]);

    useMapEvents({
        click(e) {
            onPositionChange(new LatLng(round(e.latlng.lat, 6), round(e.latlng.lng, 6)));
        }
    });

    return isNil(position) ? null : (
        <Marker
            position={position}
            icon={
                new Icon({
                    iconUrl: locationPinIcon,
                    iconSize: [20, 30],
                    iconAnchor: [10, 15],
                    tooltipAnchor: [0, 0]
                })
            }
        >
            <Popup>
                {name}
                <br />
                Lat: {round(position.lat, 6)}
                <br />
                Lng: {round(position.lng, 6)}
            </Popup>
        </Marker>
    );
};

const DataPointMap = ({ name, latitude, longitude, allowPositionChange, onPositionChange }: DataPointMapProps) => {
    const { locations } = useLocations();
    const confirm = useConfirmation();

    const [map, setMap] = useState<Map>(null);
    const geojsonLayerRef = useRef<L.GeoJSON>();

    const position = useMemo(() => {
        if (isNil(latitude) && isNil(longitude)) {
            return null;
        }

        return new LatLng(latitude, longitude);
    }, [latitude, longitude]);

    const handlePositionChange = async (nextPosition: LatLng) => {
        if (!allowPositionChange) {
            return;
        }

        if (!isNil(position)) {
            const result = await confirm({
                title: "Change Location",
                description: `Are you sure you want to change the location of this data point?`
            });

            if (!result.success) {
                return;
            }
        }

        if (!isNil(onPositionChange)) {
            onPositionChange(nextPosition);
        }
    };

    const geojsonLayer: FeatureCollection = useMemo(() => {
        if (isNilOrEmpty(locations)) {
            return null;
        }

        const features: Feature[] = locations.map(l => {
            return {
                type: "Feature",
                geometry: l.geometry,
                properties: {
                    location: l
                }
            };
        });

        return {
            type: "FeatureCollection",
            features: features
        };
    }, [locations]);

    const initFeature = (feature, layer) => {
        layer.bindTooltip(feature.properties.location.name, { sticky: true });
        layer.on("mouseover", function () {
            this.setStyle({
                fillColor: HIGHLIGHT_COLOUR
            });
        });
        layer.on("mouseout", function () {
            this.setStyle({
                fillColor: DEFAULT_COLOUR
            });
        });
    };

    return (
        <AppMap
            center={MAP_CENTER}
            maxBounds={MAP_BOUNDS}
            maxZoom={MAP_MAX_ZOOM}
            minZoom={MAP_MIN_ZOOM}
            zoom={MAP_ZOOM}
            onMapCreated={map => setMap(map)}
        >
            <LocationMarker name={name} position={position} map={map} onPositionChange={handlePositionChange} />

            {!isNil(geojsonLayer) && !isNil(map) && (
                <LayersControl position="topright">
                    <LayersControl.Overlay checked name="Basins">
                        <GeoJSON
                            data={geojsonLayer}
                            style={LAYER_STYLE}
                            onEachFeature={initFeature}
                            ref={geojsonLayerRef}
                        />
                    </LayersControl.Overlay>
                </LayersControl>
            )}
        </AppMap>
    );
};

export default DataPointMap;
