import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import { ChartOptions } from "chart.js";
import Label from "Components/Label";
import TooltipIcon from "Components/TooltipIcon";
import { merge } from "lodash";
import React, { useMemo, useState } from "react";
import { DataType, EflowPointChartData, EflowTimerseriesChartData } from "types/models";
import ERPChartAxisTabs, { ERPChartAxisTabType, TabSelection } from "Components/Charts/ERPChartAxisTabs";
import ERPChart from "Components/Charts/ERPChart";
import PreviewDataService from "Services/preview.data.service";

interface SystemDataPreviewModalMagnitudeDurationChartProps {
    id: string;
    chartData: EflowTimerseriesChartData;
    dataType: DataType;
}

const CHART_OPTIONS: ChartOptions<"line"> = {
    maintainAspectRatio: false,
    normalized: true,
    interaction: {
        intersect: true,
        mode: "index"
    },
    layout: {
        padding: 5
    },
    plugins: {
        tooltip: {
            enabled: true
        },
        legend: {
            display: true,
            position: "top"
        },
        decimation: {
            enabled: true
        }
    },
    animation: false,
    scales: {
        x: {
            title: {
                display: true,
                text: "Percentage Exceedence (%)"
            },
            type: "linear",
            grid: {
                display: false
            },
            min: 0,
            max: 100
        },
        y: {
            type: "linear",
            display: true,
            position: "left",
            grid: {
                display: false
            }
        }
    },
    parsing: {
        xAxisKey: "x",
        yAxisKey: "y"
    }
};

const getDataAxisLabelOptions = (dataType: DataType): ChartOptions<"line"> => {
    const nameString = PreviewDataService.getDataTypeLabel(dataType);

    return {
        scales: {
            y: {
                title: {
                    display: true,
                    text: nameString
                }
            }
        }
    };
};

const getDataAxisTypeOptions = (axisType: ERPChartAxisTabType): ChartOptions<"line"> => {
    return {
        scales: {
            y: {
                type: axisType as "linear" | "logarithmic",
                min: axisType === ERPChartAxisTabType.LOGARITHMIC ? 0.01 : 0
            }
        }
    };
};

const SystemDataPreviewModalMagnitudeDurationChart = ({
    id,
    chartData,
    dataType
}: SystemDataPreviewModalMagnitudeDurationChartProps) => {
    const [axisTab, setAxisTab] = useState<TabSelection>({ index: 0, type: ERPChartAxisTabType.LOGARITHMIC });

    const chartOpts: ChartOptions<"line"> = useMemo(() => {
        return merge<ChartOptions<"line">, ChartOptions<"line">, ChartOptions<"line">, ChartOptions<"line">>(
            {},
            CHART_OPTIONS,
            getDataAxisLabelOptions(dataType),
            getDataAxisTypeOptions(axisTab.type)
        );
    }, [dataType, axisTab]);

    const data: EflowPointChartData = useMemo(() => {
        return chartData.map(d => {
            const values = d.data.map(data => data.value).sort((a, b) => b - a);

            const points = values.map((v, i) => {
                const probability = (i + 1) / (values.length + 1);
                return { x: probability * 100, y: v };
            });

            return {
                ...d,
                data: points
            };
        });
    }, [chartData]);

    return (
        <div className="chart">
            <div className="chart-tools">
                <Label className="chart-title">Magnitude duration</Label>

                <div className="chart-options">
                    <ERPChartAxisTabs selectedTab={axisTab} onTabSelected={setAxisTab} />

                    <TooltipIcon
                        tooltip="Use shift+drag to zoom
                Double click to reset zoom
                Pan with the mouse"
                        icon={faQuestionCircle}
                        size={"2x"}
                        className="chart-tooltip"
                    />
                </div>
            </div>
            <ERPChart<"line", { x: number; y: number }>
                id={id}
                type="line"
                data={data}
                options={chartOpts}
                height={700}
                canZoom={true}
                persistLegendState={true}
                persistZoomState={true}
            />
        </div>
    );
};

export default SystemDataPreviewModalMagnitudeDurationChart;
