import React from "react";
import FormError, { ErrorMessage } from "Components/FormError/FormError";
import Label from "Components/Label";
import clsx from "clsx";
import NumericInput, { NumericInputProps } from "Components/NumericInput";

export type FormNumeriInputProps = {
    labelText?: string;
    labelClass?: string;
    error?: ErrorMessage;
    errorClassName?: string;
    containerClassName?: string;
} & NumericInputProps;

const FormNumericInput = (props: FormNumeriInputProps) => {
    const { labelText, labelClass, error, errorClassName, name, containerClassName, ...rest } = props;

    return (
        <div className={clsx("form-group", containerClassName)}>
            {labelText && (
                <Label className={labelClass} htmlFor={name}>
                    {labelText}
                </Label>
            )}

            <NumericInput name={name} {...rest}></NumericInput>

            <FormError className={errorClassName} error={error} />
        </div>
    );
};

export default FormNumericInput;
