import React, { useEffect, useState } from "react";
import { useRule } from "./RuleContext";
import { RuleExploreResultsModel, RuleRunModel } from "types/models";
import { isNilOrEmpty } from "utils/utils";
import ExploreTabs, { ExploreTabType, TabSelection } from "./ExploreTabs";
import RuleExploreRunSelect from "./RuleExploreRunSelect";
import LoadingIndicator from "Components/LoadingIndicator";
import RuleExploreBody from "./RuleExploreBody";
import { isNil } from "lodash";
import RuleResultService from "Services/rule-result.service";
import ResultsInfo from "./ResultsInfo";
import { faPlay } from "@fortawesome/pro-solid-svg-icons";

const RuleExplore = () => {
    const { runs, setShowRunModal } = useRule();

    const [activeTab, setActiveTab] = useState<TabSelection>({ index: 0, type: ExploreTabType.SUMMARY });
    const [selectedRun, setSelectedRun] = useState<RuleRunModel>(!isNilOrEmpty(runs) ? runs[0] : null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [results, setResults] = useState<RuleExploreResultsModel>(null);

    useEffect(() => {
        if (!isNilOrEmpty(runs)) {
            if (selectedRun?.id !== runs[0].id) {
                setSelectedRun(runs[0]); // Latest run
            }
        }
    }, [runs]);

    useEffect(() => {
        const fetchRunResultsData = async (_run: RuleRunModel) => {
            setIsLoading(true);

            try {
                const runResults = await RuleResultService.getRunResults(_run);
                setResults(runResults);
            } finally {
                setIsLoading(false);
            }
        };

        if (!isNil(selectedRun)) {
            fetchRunResultsData(selectedRun);
        }
    }, [selectedRun]);

    const hasRuns = !isNilOrEmpty(runs) && !isNil(selectedRun);

    const handleRerun = () => {
        setShowRunModal(true);
    };

    return (
        <div className="rule-section-content rule-explore">
            {!hasRuns && (
                <ResultsInfo
                    showButton={true}
                    buttonText="Run this configuration"
                    icon={faPlay}
                    message="This model has no results yet"
                    onClick={handleRerun}
                />
            )}

            {hasRuns && (
                <>
                    <RuleExploreRunSelect runs={runs} selectedRun={selectedRun} onRunSelected={setSelectedRun} />

                    <ExploreTabs selectedTab={activeTab} onTabSelected={setActiveTab} />

                    {isLoading && <LoadingIndicator centered={true} />}

                    {!isLoading && <RuleExploreBody results={results} run={selectedRun} activeTab={activeTab.type} />}
                </>
            )}
        </div>
    );
};

export default RuleExplore;
