import React, { useMemo } from "react";
import LoadingIndicator from "Components/LoadingIndicator";
import moment from "moment";
import { isNil } from "lodash";
import TooltipIcon from "Components/TooltipIcon";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { useDataPoint } from "./DataPointContext";
import PageTitle from "Components/PageTitle";

const DataPointHeader = () => {
    const { dataPoint, isSaving } = useDataPoint();

    const lastSaved = useMemo(() => {
        return moment(dataPoint.updatedAt).format("DD MMM, YYYY hh:mm A");
    }, [dataPoint.updatedAt]);

    return (
        <div className="data-point-section data-point-header">
            <PageTitle title={dataPoint.name} />

            <h5>{dataPoint.name}</h5>

            {isSaving && <LoadingIndicator size="lg" className="saving-indicator" />}

            {!isSaving && !isNil(dataPoint.updatedAt) && (
                <TooltipIcon
                    icon={faCheckCircle}
                    className="saved-status-icon"
                    tooltip={`Last saved - ${lastSaved}`}
                    size="lg"
                />
            )}
        </div>
    );
};

export default DataPointHeader;
