import { DataPointDataModel, DataPointModel, DataType, ObservedDataSettings } from "types/models";
import {
    BulkCopyDataPointRequest,
    BulkDataPointDeleteRequest,
    CopyDataPointRequest,
    CreateDataPointDataResponse,
    CreateDataPointRequest,
    DataPointDataDeleteResponse,
    ResetDataPointRequest,
    UpdateDataFileRequest,
    UpdateDataPointDataResponse,
    UpdateDataPointRequest
} from "types/requests";
import API from "../api/data.api";
import { URL } from "../Constants/api";
import { isNil } from "lodash";

export default class DataPointService {
    public static async createDataPoint(request: CreateDataPointRequest): Promise<DataPointModel> {
        const dataPoint = await API.post<DataPointModel>(`${URL.DATA_POINTS}`, request);

        return dataPoint;
    }

    public static async getDataPoint(id: string): Promise<DataPointModel> {
        const dataPoint = await API.get<DataPointModel>(`${URL.DATA_POINTS}/${id}`);
        return dataPoint;
    }

    public static async getDataPoints(): Promise<DataPointModel[]> {
        const dataPoints = await API.get<DataPointModel[]>(`${URL.DATA_POINTS}`);
        return dataPoints;
    }

    public static async updateDataPoint(id: string, request: UpdateDataPointRequest): Promise<DataPointModel> {
        const dataPoint = await API.patch<DataPointModel>(`${URL.DATA_POINTS}/${id}`, request);
        return dataPoint;
    }

    public static async deleteDataPoint(id: string): Promise<void> {
        await API.delete<void>(`${URL.DATA_POINTS}/${id}`);
    }

    public static async deleteDataPoints(ids: string[]): Promise<void> {
        const request: BulkDataPointDeleteRequest = {
            dataPoints: ids ?? []
        };

        await API.delete<void>(`${URL.DATA_POINTS}`, request);
    }

    public static async copyDataPoint(id: string): Promise<DataPointModel> {
        const request: CopyDataPointRequest = {
            dataPointId: id
        };

        const copiedDataPoint = await API.post<DataPointModel>(`${URL.DATA_POINTS}/copy`, request);

        return copiedDataPoint;
    }

    public static async copyDataPoints(ids: string[]): Promise<DataPointModel[]> {
        const request: BulkCopyDataPointRequest = {
            dataPointIds: ids
        };

        const copiedDataPoints = await API.post<DataPointModel[]>(`${URL.DATA_POINTS}/copy/bulk`, request);

        return copiedDataPoints;
    }

    public static async getDataPointData(dataPointId: string): Promise<DataPointDataModel[]> {
        const data = await API.get<DataPointDataModel[]>(`${URL.DATA_POINTS}/${dataPointId}/data`);
        return data;
    }

    public static async uploadDataPointData(
        dataPointId: string,
        file: File,
        dataType?: DataType,
        isObserved?: boolean,
        observationSettings?: ObservedDataSettings,
        onProgress?: (progress: number) => void
    ): Promise<CreateDataPointDataResponse> {
        const formData = new FormData();

        formData.append("file", file);

        if (!isNil(dataType)) {
            formData.append("dataType", dataType);
        }

        if (!isNil(isObserved)) {
            formData.append("isObserved", String(isObserved));
        }

        if (!isNil(observationSettings)) {
            formData.append("observationSettings", JSON.stringify(observationSettings));
        }

        const data = await API.uploadFormData<CreateDataPointDataResponse>(
            `${URL.DATA_POINTS}/${dataPointId}/data`,
            formData,
            {
                "Content-Type": "multipart/form-data"
            },
            onProgress
        );

        return data;
    }

    public static async updateDataPointDataFile(
        dataPointId: string,
        dataFileId: string,
        file: File,
        dataType?: DataType,
        isObserved?: boolean,
        observationSettings?: ObservedDataSettings,
        onProgress?: (progress: number) => void
    ): Promise<UpdateDataPointDataResponse> {
        const formData = new FormData();

        formData.append("file", file);

        if (!isNil(dataType)) {
            formData.append("dataType", dataType);
        }

        if (!isNil(isObserved)) {
            formData.append("isObserved", String(isObserved));
        }

        if (!isNil(observationSettings)) {
            formData.append("observationSettings", JSON.stringify(observationSettings));
        }

        const data = await API.patchFormData<UpdateDataPointDataResponse>(
            `${URL.DATA_POINTS}/${dataPointId}/data/${dataFileId}/file`,
            formData,
            {
                "Content-Type": "multipart/form-data"
            },
            onProgress
        );

        return data;
    }

    public static async updateDataPointDataOptions(
        dataPointId: string,
        dataFileId: string,
        dataType: DataType
    ): Promise<UpdateDataPointDataResponse> {
        const request: UpdateDataFileRequest = {
            dataType: dataType
        };

        const data = await API.patch<UpdateDataPointDataResponse>(
            `${URL.DATA_POINTS}/${dataPointId}/data/${dataFileId}/options`,
            request
        );

        return data;
    }

    public static async deleteDataPointData(
        dataPointId: string,
        dataFileId: string
    ): Promise<DataPointDataDeleteResponse> {
        const data = await API.delete<DataPointDataDeleteResponse>(
            `${URL.DATA_POINTS}/${dataPointId}/data/${dataFileId}`
        );
        return data;
    }

    public static async resetDataPoint(id: string, request: ResetDataPointRequest): Promise<DataPointModel> {
        const dataPoint = await API.patch<DataPointModel>(`${URL.DATA_POINTS}/${id}/reset`, request);
        return dataPoint;
    }

    public static async searchDataPoints(searchString: string): Promise<DataPointModel[]> {
        const dataPoints = await API.get<DataPointModel[]>(
            `${URL.DATA_POINTS}/search`,
            {},
            new URLSearchParams([["name", searchString]])
        );
        return dataPoints;
    }
}
