import React from "react";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import TooltipIcon from "Components/TooltipIcon";

export const PluginOutOfDateIcon = () => {
    return (
        <TooltipIcon className="plugin-outofdate-icon" icon={faExclamationTriangle} tooltip="Plugin is out of date" />
    );
};

export default PluginOutOfDateIcon;
