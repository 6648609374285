import React from "react";
import ActivableButton from "Components/Button/ActivableButton";
import { guid } from "utils/utils";

export enum ExploreTabType {
    SUMMARY = "summary",
    INTERMEDIATE = "intermediate",
    DOWNLOADS = "downloads"
}

interface ExploreTabProps {
    type: ExploreTabType;
    isActive: boolean;
    label: string;
    onSelected: (type: ExploreTabType) => void;
}

interface ExploreTabsProps {
    selectedTab: TabSelection;
    onTabSelected: (selection: TabSelection) => void;
}

interface TabInfo {
    type: ExploreTabType;
    label: string;
}

export interface TabSelection {
    type: ExploreTabType;
    index: number;
}

const TAB_INFO: TabInfo[] = [
    {
        label: "Summary",
        type: ExploreTabType.SUMMARY
    },
    {
        label: "Intermediate",
        type: ExploreTabType.INTERMEDIATE
    },
    {
        label: "Downloads",
        type: ExploreTabType.DOWNLOADS
    }
];

const ExploreTab = ({ type, isActive, label, onSelected }: ExploreTabProps) => {
    const handleSelected = () => {
        onSelected(type);
    };

    return (
        <div className="explorer--tab">
            <ActivableButton onClick={handleSelected} isActive={isActive}>
                {label}
            </ActivableButton>
        </div>
    );
};

const ExploreTabs = ({ selectedTab, onTabSelected }: ExploreTabsProps) => {
    const handleTabSelected = (selection: TabSelection) => {
        if (selection.type === selectedTab.type) {
            return;
        }

        onTabSelected(selection);
    };

    return (
        <div className="explorer--navigation">
            <div className="explorer--tabs">
                {TAB_INFO.map((info, index) => {
                    return (
                        <ExploreTab
                            key={guid()}
                            type={info.type}
                            label={info.label}
                            isActive={selectedTab.type === info.type}
                            onSelected={type => handleTabSelected({ type: type, index: index })}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default ExploreTabs;
