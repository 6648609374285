import ERPChart from "Components/Charts/ERPChart";
import { ChartDataset, ChartOptions } from "chart.js";
import { merge } from "lodash";
import React, { useMemo } from "react";
import { EflowChartData, RunResultData, SuccessLevel } from "types/models";
import { formatPercentBarChartData } from "utils/explorer-charts.utils";

interface RuleExplorePercentBarChartProps {
    yearlyResult: RunResultData;
    startYear: number;
    endYear: number;
    title: string;
}

const CHART_OPTIONS: ChartOptions = {
    maintainAspectRatio: false,
    interaction: {
        mode: "index"
    },
    indexAxis: "y",
    scales: {
        x: {
            grid: { display: false, drawBorder: false },
            ticks: {
                stepSize: 10,
                callback(tickValue) {
                    return tickValue;
                }
            },
            min: 0,
            max: 100,
            stacked: true
        },
        y: {
            grid: { display: false, drawBorder: false },
            ticks: {
                display: false,
                maxTicksLimit: 6
            },
            stacked: true
        }
    },
    layout: {
        padding: 5
    },
    plugins: {
        tooltip: {
            enabled: true,
            intersect: true,
            mode: "point",
            position: "stackedBarPositioner",
            callbacks: {
                label: function (tooltipItem) {
                    return `${tooltipItem.dataset.label}: ${tooltipItem.formattedValue}%`;
                }
            }
        },
        legend: {
            position: "top"
        },
        title: {
            display: true,
            position: "top",
            align: "start",
            text: "Default title",
            font: {
                size: 18
            }
        }
    },
    hover: {
        mode: "index",
        intersect: false
    },
    animation: false
};

const RuleExplorePercentBarChart = ({ yearlyResult, startYear, endYear, title }: RuleExplorePercentBarChartProps) => {
    const options = useMemo(() => {
        return merge({}, CHART_OPTIONS, { plugins: { title: { text: title } } });
    }, [title]);

    const chartData: EflowChartData = useMemo(() => {
        const yearlyResultData = formatPercentBarChartData(yearlyResult, startYear, endYear);

        const _labels = ["Summary"];

        const _chartData: ChartDataset[] = [
            {
                label: "No Data",
                data: [yearlyResultData.find(d => d.key === SuccessLevel.NONE).percent],
                backgroundColor: "#acacae",
                hoverBackgroundColor: "#acacae",
                barThickness: 50
            },
            {
                label: "0-20%",
                data: [yearlyResultData.find(d => d.key === SuccessLevel.VERY_LOW).percent],
                backgroundColor: "#FF5349",
                hoverBackgroundColor: "#FF5349",
                barThickness: 50
            },
            {
                label: "21-40%",
                data: [yearlyResultData.find(d => d.key === SuccessLevel.LOW).percent],
                backgroundColor: "#FF9125",
                hoverBackgroundColor: "#FF9125",
                barThickness: 50
            },
            {
                label: "41-60%",
                data: [yearlyResultData.find(d => d.key === SuccessLevel.MODERATE).percent],
                backgroundColor: "#FDCD06",
                hoverBackgroundColor: "#FDCD06",
                barThickness: 50
            },
            {
                label: "61-80%",
                data: [yearlyResultData.find(d => d.key === SuccessLevel.HIGH).percent],
                backgroundColor: "#43D98E",
                hoverBackgroundColor: "#43D98E",
                barThickness: 50
            },
            {
                label: "81-100%",
                data: [yearlyResultData.find(d => d.key === SuccessLevel.VERY_HIGH).percent],
                backgroundColor: "#08BFDD",
                hoverBackgroundColor: "#08BFDD",
                barThickness: 50
            }
        ];

        return { labels: _labels, data: _chartData };
    }, [yearlyResult, startYear, endYear]);

    return (
        <div className="chart">
            <ERPChart
                id="summary_percentage_chart"
                type="bar"
                labels={chartData.labels}
                data={chartData.data}
                options={options}
                height={300}
            />
        </div>
    );
};

export default RuleExplorePercentBarChart;
