import React, { useEffect, useState } from "react";
import { useCollection } from "./CollectionContext";
import { CollectionExploreRuleResultModel, RuleResultType } from "types/models";
import { isNilOrEmpty } from "utils/utils";
import { isNil } from "lodash";
import RuleResultService from "Services/rule-result.service";
import LoadingIndicator from "Components/LoadingIndicator";
import ExploreTabs, { ExploreTabType, TabSelection } from "./ExploreTabs";
import CollectionExploreBody from "./CollectionExploreBody";

const CollectionExplore = () => {
    const { collectionRules } = useCollection();
    const [ruleResults, setRuleResults] = useState<CollectionExploreRuleResultModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [activeTab, setActiveTab] = useState<TabSelection>({ index: 0, type: ExploreTabType.SINGLE_YEAR });

    const fetchAndFormatResults = async () => {
        setIsLoading(true);

        try {
            const nextResults: CollectionExploreRuleResultModel[] = await Promise.all(
                collectionRules.map(async rule => {
                    if (isNilOrEmpty(rule.runs)) {
                        return {
                            rule: rule,
                            hasResult: false
                        };
                    }

                    const latestRun = rule.runs[0];
                    const yearlyResult = latestRun.results.find(
                        result => result.resultType === RuleResultType.YEARLY_INTERMEDIATE
                    );

                    if (isNil(yearlyResult)) {
                        return {
                            rule: rule,
                            hasResult: false
                        };
                    }

                    return {
                        rule: rule,
                        hasResult: true,
                        result: await RuleResultService.getRunResultData(latestRun, yearlyResult)
                    };
                })
            );

            setRuleResults(nextResults);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchAndFormatResults();
    }, [collectionRules]);

    return (
        <div className="collection-explore">
            <ExploreTabs selectedTab={activeTab} onTabSelected={setActiveTab} />

            {isLoading && <LoadingIndicator centered={true} />}

            {!isLoading && <CollectionExploreBody results={ruleResults} activeTab={activeTab.type} />}
        </div>
    );
};

export default CollectionExplore;
