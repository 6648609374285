import React, { useState } from "react";
import Checkbox from "Components/Checkbox";
import { DataPointModel } from "types/models";
import { fullname } from "utils/utils";
import DateTimeCell from "Scenes/Explorer/DateTimeCell";
import LockedIcon from "Scenes/Explorer/LockedIcon";
import DataPointRowActions from "./DataPointRowActions";
import DataPointIcon from "Components/ERPIcon/DataPointIcon";
import Humanize from "humanize-plus";
import DataPointCell from "./DataPointCell";
import { isNil } from "lodash";

interface DataPointRowProps {
    dataPoint: DataPointModel;
    isSelected: boolean;
    isLocked: boolean;
    allowDataActions: boolean;
    onDataPointClicked: (dataPoint: DataPointModel) => void;
    onSelected: (dataPoint: DataPointModel, selected: boolean) => void;
    onDataPointDelete: (dataPoint: DataPointModel) => void;
    onDataPointCopy: (dataPoint: DataPointModel) => void;
}

const DataPointRow = ({
    dataPoint,
    isSelected,
    isLocked,
    allowDataActions,
    onDataPointClicked,
    onSelected,
    onDataPointDelete,
    onDataPointCopy
}: DataPointRowProps) => {
    const [isHovering, setIsHovering] = useState<boolean>(false);

    const handleSelected = (selected: boolean) => {
        onSelected(dataPoint, selected);
    };

    const handleDelete = () => {
        onDataPointDelete(dataPoint);
    };

    const handleCopy = () => {
        onDataPointCopy(dataPoint);
    };

    const handleDataPointClicked = () => {
        onDataPointClicked(dataPoint);
    };

    return (
        <tr
            className="data-point-row"
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
        >
            <td style={{ width: 50 }} className="selection-cell">
                {allowDataActions && <Checkbox checked={isSelected} onChecked={handleSelected} />}
            </td>

            <DataPointCell
                id={dataPoint.id}
                onDataPointClicked={!isNil(onDataPointClicked) ? handleDataPointClicked : null}
            >
                <DataPointIcon />
            </DataPointCell>

            <DataPointCell
                id={dataPoint.id}
                onDataPointClicked={!isNil(onDataPointClicked) ? handleDataPointClicked : null}
            >
                <div>
                    {dataPoint.name}

                    {isLocked && <LockedIcon type="Data Point" owner={dataPoint.lock.owner} />}
                </div>
            </DataPointCell>

            <DataPointCell
                id={dataPoint.id}
                className="status-cell"
                onDataPointClicked={!isNil(onDataPointClicked) ? handleDataPointClicked : null}
            >
                <div>{Humanize.capitalize(dataPoint?.location?.name ?? "")}</div>
            </DataPointCell>

            <DataPointCell
                id={dataPoint.id}
                className="status-cell"
                onDataPointClicked={!isNil(onDataPointClicked) ? handleDataPointClicked : null}
            >
                <div>{Humanize.capitalize(dataPoint.type)}</div>
            </DataPointCell>

            <DataPointCell
                id={dataPoint.id}
                className="centered-cell status-cell"
                onDataPointClicked={!isNil(onDataPointClicked) ? handleDataPointClicked : null}
            >
                {fullname(dataPoint.createdBy)}
            </DataPointCell>

            <DateTimeCell dateTime={dataPoint.updatedAt} />

            <td className="action-cell">
                {isHovering && allowDataActions && (
                    <DataPointRowActions canDelete={!isLocked} onDelete={handleDelete} onCopy={handleCopy} />
                )}
            </td>
        </tr>
    );
};

export default DataPointRow;
