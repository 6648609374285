import { HeaderSortContext } from "Components/SortableHeader/types";
import { isNil, orderBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { isNilOrEmpty } from "utils/utils";

export function applySort<T>(items: T[], context: HeaderSortContext) {
    if (isNil(context)) {
        return items;
    }

    if (!isNil(context.sorter)) {
        return orderBy(items, context.sorter, context.order);
    }

    return orderBy(items, context.header, context.order);
}

export function useSort<T>(items: T[], parentContext: HeaderSortContext<T> = null) {
    const [sortContext, setSortContext] = useState<HeaderSortContext<T>>(parentContext);

    useEffect(() => {
        setSortContext(parentContext);
    }, [parentContext]);

    useEffect(() => {
        if (isNilOrEmpty(items)) {
            setSortContext(sortContext);
        }
    }, [items]);

    const handleSort = (sortKey: string, sorter?: (val: T) => any) => {
        let context = sortContext;

        if (isNil(context) || context.header !== sortKey) {
            context = {
                header: sortKey,
                order: "asc",
                sorter: sorter
            };
        } else {
            context = {
                header: sortKey,
                order: sortContext.order === "asc" ? "desc" : "asc",
                sorter: sorter
            };
        }

        setSortContext(context);
    };

    const sortedItems: T[] = useMemo(() => {
        if (isNilOrEmpty(items)) {
            return [];
        }

        return applySort(items, sortContext);
    }, [items, sortContext]);

    return { sortedItems, handleSort, sortContext };
}
