import { EFLOWEnvironment } from "./types";

class Config {
    public readonly apiUrl: string;
    public readonly environment: EFLOWEnvironment;
    public readonly auth0: Auth0Config;
    public readonly ws: WSConfig;
    public readonly azureStorageUrl: string;
    public readonly ai: ApplicationInsightsConfig;
    public readonly map: MapConfig;
    public readonly edsUrl: string;

    constructor() {
        this.environment = process.env.REACT_APP_EFLOW_ENVIRONMENT as EFLOWEnvironment;
        this.azureStorageUrl = process.env.REACT_APP_EFLOW_AZURE_STORAGE_URL;
        this.apiUrl = process.env.REACT_APP_API_BACKEND_URI;
        this.edsUrl = process.env.REACT_APP_DATA_EDS_URL;

        this.auth0 = new Auth0Config();
        this.ws = new WSConfig();
        this.ai = new ApplicationInsightsConfig();
        this.map = new MapConfig();
    }
}

class Auth0Config {
    public readonly audience: string;
    public readonly clientId: string;
    public readonly redirectUri: string;

    constructor() {
        this.audience = process.env.REACT_APP_AUTH0_AUDENCE_API;
        this.clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
        this.redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URI;
    }
}

class WSConfig {
    public readonly url: string;
    public readonly path: string;

    constructor() {
        this.url = process.env.REACT_APP_WS_ENDPOINT;
        this.path = process.env.REACT_APP_WS_PATH;
    }
}

class ApplicationInsightsConfig {
    public readonly connectionString: string;

    constructor() {
        this.connectionString = process.env.REACT_APP_AI_CONNECTION_STRING;
    }
}

class MapConfig {
    public readonly mapboxToken: string;

    constructor() {
        this.mapboxToken = process.env.REACT_APP_MAP_BOX_TOKEN;
    }
}

export default new Config();
