import { useWebsocket } from "Components/Websocket";
import { ResourceType, ResourceLockResultModel } from "types/models";
import { ResourceLockRequest, ResourceUnLockRequest } from "types/requests";

export function useResourceLocker() {
    const { socket } = useWebsocket();

    const lock = async (id: string, type: ResourceType): Promise<ResourceLockResultModel> => {
        return new Promise(resolve => {
            console.log(`resource_locker | ${type} | lock:${id}`);

            const request: ResourceLockRequest = {
                id: id,
                type: type
            };

            socket?.once("lock.result", (data: ResourceLockResultModel) => {
                resolve(data);
            });

            socket?.emit("lock", request);
        });
    };

    const unlock = (id: string): void => {
        console.log(`resource_locker | unlock:${id}`);

        const request: ResourceUnLockRequest = {
            id: id
        };

        socket?.emit("unlock", request);
    };

    return { lock, unlock };
}
