import React, { useEffect, useMemo, useState } from "react";
import { ResourceLockErrors, ResourceLockModel, ResourceType } from "types/models";
import { isNil } from "lodash";
import { useHistory, useParams } from "react-router-dom";
import { useAuth0 } from "auth/react-auth0-spa";
import { useResourceLocker } from "Hooks/useResourceLocker";
import { Toast } from "Components/Toast";
import LoadingIndicator from "Components/LoadingIndicator";
import ResourceLockedWarning from "Components/ResourceLockedWarning";
import CollectionContextProvider from "./CollectionContext";
import CollectionHeader from "./CollectionHeader";
import CollectionTabs, { CollectionTabType, TabSelection } from "./CollectionTabs";
import CollectionGeneralInfo from "./CollectionGeneralInfo";
import CollectionAdd from "./CollectionAdd";
import CollectionReview from "./CollectionReview";
import CollectionExplore from "./CollectionExplore";

import "./Collection.scss";

const Collection = () => {
    const { id } = useParams<{ id: string }>();
    const { eflowUser } = useAuth0();
    const [activeTab, setActiveTab] = useState<TabSelection>({ index: 0, type: CollectionTabType.GENERAL });
    const { lock, unlock } = useResourceLocker();
    const history = useHistory();
    const [resourceLock, setResourceLock] = useState<ResourceLockModel>(null);

    useEffect(() => {
        const doInit = async (dataPointId: string) => {
            const lock_result = await lock(dataPointId, ResourceType.DATA_POINT);

            if (!lock_result.success) {
                if (lock_result.error?.code === ResourceLockErrors.ENTITY_NOT_FOUND) {
                    Toast.error("Collection could not be found");
                    history.push("/");
                }

                return;
            }

            setResourceLock(lock_result.lock);
        };

        doInit(id);

        return () => {
            unlock(id);
        };
    }, [id]);

    const isLockOwner = useMemo(() => {
        if (isNil(resourceLock?.owner)) {
            return false;
        }

        return resourceLock.owner.id === eflowUser.id;
    }, [resourceLock]);

    if (isNil(resourceLock)) {
        return <LoadingIndicator className="rule-loading" centered />;
    }

    return (
        <CollectionContextProvider collectionId={id} readOnly={!isLockOwner}>
            <div className="collection-container">
                <CollectionHeader />

                {!isLockOwner && <ResourceLockedWarning resourceType="Collection" owner={resourceLock.owner} />}

                <CollectionTabs selectedTab={activeTab} onTabSelected={setActiveTab} />

                {activeTab.type === CollectionTabType.GENERAL && <CollectionGeneralInfo />}
                {activeTab.type === CollectionTabType.ADD_RULES && <CollectionAdd />}
                {activeTab.type === CollectionTabType.REVIEW_RULES && <CollectionReview />}
                {activeTab.type === CollectionTabType.EXPLORE && <CollectionExplore />}
            </div>
        </CollectionContextProvider>
    );
};

export default Collection;
