import React, { useMemo } from "react";
import { DataInfillSettingsModel, DataInfillType, RunPeriodModel } from "types/models";
import Checkbox from "Components/Checkbox";
import Divider from "Components/Divider";
import RunPeriod from "Components/RunPeriod";
import Tooltip from "Components/Tooltip";
import Select from "Components/Select/Select";
import { isNil } from "lodash";

interface RuleRunModalRunSettingsProps {
    runPeriod: RunPeriodModel;
    dataInfillSettings: DataInfillSettingsModel;
    readOnly: boolean;
    handleSetRunPeriod: (runPeriod: RunPeriodModel) => void;
    handleSetInfillSettings: (dataInfillSettings: DataInfillSettingsModel) => void;
}

type InfillSelectOption = {
    value: DataInfillType;
    label: string;
};

const RuleRunModalRunSettings = ({
    runPeriod,
    dataInfillSettings,
    readOnly,
    handleSetRunPeriod,
    handleSetInfillSettings
}: RuleRunModalRunSettingsProps) => {
    const infillMethods: InfillSelectOption[] = useMemo(() => {
        return [
            {
                value: DataInfillType.EMPTY,
                label: "Infill missing days with no value"
            },
            {
                value: DataInfillType.PREVIOUS_VALUE,
                label: "Infill missing days with previous value"
            }
        ];
    }, []);

    const infillMethod = useMemo(() => {
        if (!isNil(dataInfillSettings.infill_method)) {
            return infillMethods.find(m => m.value === dataInfillSettings.infill_method);
        }

        return infillMethods.find(m => m.value === DataInfillType.EMPTY);
    }, [dataInfillSettings]);

    const handleShouldInfillChecked = (isChecked: boolean) => {
        handleSetInfillSettings({ ...dataInfillSettings, should_infill_data: isChecked });
    };

    const handleInfillMethodChanged = (infillMethod: DataInfillType) => {
        handleSetInfillSettings({ ...dataInfillSettings, infill_method: infillMethod });
    };

    return (
        <div>
            <RunPeriod
                title="Run Period"
                runPeriod={runPeriod}
                onRunPeriodChanged={handleSetRunPeriod}
                disabled={readOnly}
            />
            <Divider />
            <Tooltip
                tooltipContent="The computation will return an error if days are missing from the data.               
                Check this to infill the missing days and still attempt the run."
                placement="left"
            >
                <div className="infill-settings">
                    <Checkbox
                        label="Attempt to run with incomplete data"
                        checked={dataInfillSettings.should_infill_data}
                        onChecked={(checked: boolean) => {
                            if (checked !== dataInfillSettings.should_infill_data) {
                                handleShouldInfillChecked(checked);
                            }
                        }}
                    />
                    <Select
                        id="infill-select"
                        menuClassName="infill-type-select"
                        value={infillMethod}
                        values={infillMethods}
                        onSelected={(option: InfillSelectOption) => {
                            if (option.value !== dataInfillSettings.infill_method) {
                                handleInfillMethodChanged(option.value);
                            }
                        }}
                        disabled={readOnly || !dataInfillSettings.should_infill_data}
                    />
                </div>
            </Tooltip>
        </div>
    );
};

export default RuleRunModalRunSettings;
