import React from "react";
import Input from "Components/Input";
import NewButton from "./NewButton";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";

interface ExplorerHeaderProps {
    folderId: string;
    search: string;
    onNewFolder: () => void;
    onSearch: (value: string) => void;
}

const ExplorerHeader = ({ folderId, search, onNewFolder, onSearch }: ExplorerHeaderProps) => {
    const handleSearch = e => {
        onSearch(e.target.value);
    };

    return (
        <div className="explorer--header">
            <NewButton folderId={folderId} onNewFolder={onNewFolder} />

            <Input
                className="search-bar"
                placeholder="Search eflow projector"
                icon={faSearch}
                onChange={handleSearch}
                value={search}
            />

            <div></div>
        </div>
    );
};

export default ExplorerHeader;
