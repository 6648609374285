import Label from "Components/Label";
import { useLocations } from "Components/LocationContext";
import { isNil } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { CollectionExploreRuleResultModel, LocationModel } from "types/models";
import { isNilOrEmpty } from "utils/utils";
import { CollectionExploreFilterModel, CollectionExplorePeriodModel } from "./CollectionExploreBody";
import CollectionExploreMap from "./CollectionExploreMap";
import CollectionExploreTable from "./CollectionExploreTable";

interface CollectionExploreSingleYearProps {
    results: CollectionExploreRuleResultModel[];
    filterSettings: CollectionExploreFilterModel;
    resultsPeriod: CollectionExplorePeriodModel;
}

export interface CollectionExploreResultRow {
    year: string;
    rule: string;
    latitude: number;
    longitude: number;
    summary_score: number;
    magnitude_score?: number;
    duration_score?: number;
    count_score?: number;
    independence_score?: number;
}

export interface CollectionExploreResultModel {
    location: LocationModel;
    results: CollectionExploreResultRow[];
}

const CollectionExploreSingleYear = ({ results, filterSettings, resultsPeriod }: CollectionExploreSingleYearProps) => {
    const { locations } = useLocations();

    const [selectedLocations, setSelectedLocations] = useState<LocationModel[]>(null);

    const filteredLocationResults: CollectionExploreResultModel[] = useMemo(() => {
        const locationResultsWithData = results
            .filter(r => r.hasResult)
            .filter(r => r.rule.reportingTiers.some(t => filterSettings.reportingTiers.includes(t)))
            .filter(r => filterSettings.plugins.some(p => p === r.rule.plugin.identifier))
            .reduce((locationAccumulator, result) => {
                if (!locationAccumulator[result.rule.location.id]) {
                    locationAccumulator[result.rule.location.id] = [];
                }
                locationAccumulator[result.rule.location.id].push(result);
                return locationAccumulator;
            }, {});

        return Object.keys(locationResultsWithData).map(locationId => {
            return {
                location: locations.find(l => l.id === locationId),
                results: locationResultsWithData[locationId]
                    .map(r => {
                        const resultRow = r.result.rows.find(row => +row.year === resultsPeriod.singleYear) ?? null;

                        if (isNil(resultRow)) {
                            return null;
                        }

                        return {
                            year: +resultRow.year,
                            rule: r.rule.name,
                            latitude: r.rule.latitude,
                            longitude: r.rule.longitude,
                            summary_score: +resultRow.summary_score,
                            magnitude_score: !isNilOrEmpty(resultRow?.magnitude_score)
                                ? +resultRow.magnitude_score
                                : null,
                            duration_score: !isNilOrEmpty(resultRow?.duration_score) ? +resultRow.duration_score : null,
                            count_score: !isNilOrEmpty(resultRow?.count_score) ? +resultRow.count_score : null,
                            independence_score: !isNilOrEmpty(resultRow?.independence_score)
                                ? +resultRow.independence_score
                                : null
                        };
                    })
                    .filter(r => !isNil(r))
            };
        });
    }, [results, filterSettings, resultsPeriod.singleYear]);

    useEffect(() => {
        if (!isNilOrEmpty(filteredLocationResults) && isNil(selectedLocations)) {
            const initialLocations = filteredLocationResults.map(r => r.location);
            setSelectedLocations([...initialLocations]);
        }
    }, [filteredLocationResults]);

    return (
        <div className="collection-explore-single-year">
            <div className="gradient-bar-holder">
                <Label className="gradient-label">0%</Label>
                <div className="gradient-bar"></div>
                <Label className="gradient-label">100%</Label>
            </div>

            <div className="results-visualisations">
                <CollectionExploreMap
                    results={filteredLocationResults}
                    selectedLocations={selectedLocations}
                    onLocationsSelectChanged={setSelectedLocations}
                />

                <CollectionExploreTable results={filteredLocationResults} selectedLocations={selectedLocations} />
            </div>
        </div>
    );
};

export default CollectionExploreSingleYear;
