import React from "react";
import { DataPointModel } from "types/models";
import { fullname } from "utils/utils";
import { capitalize } from "lodash";
import DataPointIcon from "Components/ERPIcon/DataPointIcon";
import DateTimeCell from "Scenes/Explorer/DateTimeCell";
import Humanize from "humanize-plus";

interface SearchResultRowProps {
    result: DataPointModel;
    onResultNavigation: (result: DataPointModel) => void;
}

const SearchResultRow = ({ result, onResultNavigation }: SearchResultRowProps) => {
    const handleResultNavigation = () => {
        onResultNavigation(result);
    };

    return (
        <tr onClick={handleResultNavigation} className="data-row">
            <td style={{ maxWidth: 50 }}>
                <DataPointIcon />
            </td>

            <td>{result.name}</td>

            <td className="status-cell">{capitalize(result.type)}</td>

            <td className="centered-cell status-cell">{fullname(result.createdBy)}</td>

            <DateTimeCell dateTime={result.updatedAt} />

            <td className="centered-cell status-cell">{result?.location?.name}</td>

            <td className="centered-cell status-cell">
                {result.dataTypes.map(d => Humanize.capitalize(d)).join(", ")}
            </td>
        </tr>
    );
};

export default SearchResultRow;
