import React from "react";
import { DataAvailabilityModel, DataType } from "types/models";
import { isNilOrEmpty } from "utils/utils";
import Humanize from "humanize-plus";
import Switch from "Components/Switch";
import moment from "moment";

interface DataPointObservedDataModalBodyProps {
    dataAvailabilities: DataAvailabilityModel[];
    enabledDataTypes: DataType[];
    setEnabledDataTypes: (next: DataType[]) => void;
}

const DataPointObservedDataModalBody = ({
    dataAvailabilities,
    enabledDataTypes,
    setEnabledDataTypes
}: DataPointObservedDataModalBodyProps) => {
    const handleDataTypeChecked = (dataType: DataType) => {
        const exists = enabledDataTypes.some(d => d === dataType);

        if (exists) {
            const next = enabledDataTypes.filter(d => d !== dataType);
            setEnabledDataTypes(next);
            return;
        }

        setEnabledDataTypes([...enabledDataTypes, dataType]);
    };

    const formatEDSDate = (dateString: string) => {
        if (isNilOrEmpty(dateString)) {
            return null;
        }

        return moment(dateString, "YYYYMMDD").format("DD/MM/YYYY");
    };

    return (
        <div className="data-point-observed-data-table">
            <table>
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>From</th>
                        <th>To</th>
                        <th>Source</th>
                        <th>Enabled</th>
                    </tr>
                </thead>

                <tbody>
                    {dataAvailabilities.map(d => {
                        return (
                            <tr key={d.dataType}>
                                <td>{Humanize.capitalizeAll(d.dataType.split("_").join(" "))}</td>
                                <td>{formatEDSDate(d.from)}</td>
                                <td>{formatEDSDate(d.to)}</td>
                                <td>BOM</td>
                                <td>
                                    <Switch
                                        checked={enabledDataTypes.some(e => e === d.dataType)}
                                        onChecked={() => handleDataTypeChecked(d.dataType)}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default DataPointObservedDataModalBody;
