import Label from "Components/Label";
import Select, { SelectOption } from "Components/Select/Select";
import React from "react";
import Switch from "Components/Switch";
import { PluginId, ReportingTiers } from "types/plugin";
import { CollectionExploreFilterModel, CollectionExplorePeriodModel } from "./CollectionExploreBody";

interface CollectionExploreFilterProps {
    filterSettings: CollectionExploreFilterModel;
    selectedResultPeriod: CollectionExplorePeriodModel;
    showSingleYear: boolean;
    onFilterSettingsChange: (nextSettings: CollectionExploreFilterModel) => void;
    onSelectedResultsPeriodChange: (nextPeriod: CollectionExplorePeriodModel) => void;
}

interface PluginSelectOption {
    label: string;
    value: PluginId;
}

interface ReportingTierSelectOption {
    label: string;
    value: ReportingTiers;
}

interface YearSelectOption extends SelectOption {
    year: number;
}

const PLUGIN_TYPES: PluginSelectOption[] = [
    { label: "Lowflow", value: PluginId.LOWFLOW },
    { label: "Freshes", value: PluginId.FRESHES },
    { label: "Oversupply", value: PluginId.OVERSUPPLY },
    { label: "Multiyear Freshes", value: PluginId.MULTIYEAR_FRESHES }
];

const REPORTING_TIERS_TYPES: ReportingTierSelectOption[] = [
    { label: "Level 1", value: ReportingTiers.LEVEL_1 },
    { label: "Level 2", value: ReportingTiers.LEVEL_2 },
    { label: "Level 3", value: ReportingTiers.LEVEL_3 },
    { label: "Level 4", value: ReportingTiers.LEVEL_4 }
];

const CollectionExploreFilter = ({
    filterSettings,
    selectedResultPeriod,
    showSingleYear,
    onFilterSettingsChange,
    onSelectedResultsPeriodChange
}: CollectionExploreFilterProps) => {
    const handleSingleYearPeriodChange = (newYear: number) => {
        onSelectedResultsPeriodChange({ ...selectedResultPeriod, singleYear: newYear });
    };

    const handlePluginFilter = (pluginId: PluginId) => {
        const exists = filterSettings.plugins.some(p => p === pluginId);

        if (exists) {
            const next = filterSettings.plugins.filter(p => p !== pluginId);
            onFilterSettingsChange({ ...filterSettings, plugins: next });
            return;
        }

        onFilterSettingsChange({ ...filterSettings, plugins: [...filterSettings.plugins, pluginId] });
    };

    const handleReportingTierFilter = (reportingTier: ReportingTiers) => {
        const exists = filterSettings.reportingTiers.some(t => t === reportingTier);

        if (exists) {
            const next = filterSettings.reportingTiers.filter(t => t !== reportingTier);
            onFilterSettingsChange({ ...filterSettings, reportingTiers: next });
            return;
        }

        onFilterSettingsChange({
            ...filterSettings,
            reportingTiers: [...filterSettings.reportingTiers, reportingTier]
        });
    };

    const yearSelectOptions: YearSelectOption[] = selectedResultPeriod.years
        .map(y => {
            return {
                label: `${y.toString()} - ${(y + 1).toString()}`,
                value: y.toString(),
                year: y
            };
        })
        .reverse();

    return (
        <div className="collection-explore-filter">
            {showSingleYear && (
                <div className="year-select">
                    <Label>Select reporting year to explore</Label>
                    <Select
                        values={yearSelectOptions}
                        value={yearSelectOptions.find(o => o.year === selectedResultPeriod.singleYear)}
                        onSelected={(nextOption: YearSelectOption) => handleSingleYearPeriodChange(nextOption.year)}
                    />
                </div>
            )}

            <div className="rule-types-group">
                <Label>Display rule types</Label>
                <div className="filter-plugin-types">
                    <div className="filter-plugin-types-col">
                        {PLUGIN_TYPES.slice(0, 2).map(p => {
                            return (
                                <div key={p.value} className="filter-plugin-type-switch-container">
                                    <Switch
                                        checked={filterSettings.plugins.some(pp => pp === p.value)}
                                        onChecked={() => handlePluginFilter(p.value)}
                                        title={p.label}
                                    />
                                    <span>{p.label}</span>
                                </div>
                            );
                        })}
                    </div>
                    <div className="filter-plugin-types-col">
                        {PLUGIN_TYPES.slice(2, 4).map(p => {
                            return (
                                <div key={p.value} className="filter-plugin-type-switch-container">
                                    <Switch
                                        checked={filterSettings.plugins.some(pp => pp === p.value)}
                                        onChecked={() => handlePluginFilter(p.value)}
                                        title={p.label}
                                    />
                                    <span>{p.label}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>

            <div className="reporting-tiers-group">
                <Label>Display reporting tiers</Label>
                <div className="filter-plugin-types">
                    <div className="filter-plugin-types-col">
                        {REPORTING_TIERS_TYPES.slice(0, 2).map(r => {
                            return (
                                <div key={r.value} className="filter-plugin-type-switch-container">
                                    <Switch
                                        checked={filterSettings.reportingTiers.some(rr => rr === r.value)}
                                        onChecked={() => handleReportingTierFilter(r.value)}
                                        title={r.label}
                                    />
                                    <span>{r.label}</span>
                                </div>
                            );
                        })}
                    </div>
                    <div className="filter-plugin-types-col">
                        {REPORTING_TIERS_TYPES.slice(2, 4).map(r => {
                            return (
                                <div key={r.value} className="filter-plugin-type-switch-container">
                                    <Switch
                                        checked={filterSettings.reportingTiers.some(rr => rr === r.value)}
                                        onChecked={() => handleReportingTierFilter(r.value)}
                                        title={r.label}
                                    />
                                    <span>{r.label}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CollectionExploreFilter;
