import React from "react";
import NumericInput from "Components/NumericInput";
import { Controller, useFormContext } from "react-hook-form";
import { PluginFieldProps, PluginForm } from "./types";
import Checkbox from "Components/Checkbox";
import Label from "Components/Label";
import Tooltip from "Components/Tooltip";

interface PluginParameterFreshesRuleProps extends PluginFieldProps {
    onChange?: (field: string, value: Record<string, string | number | boolean>) => void;
}

const PluginParameterFreshesRule = ({
    id,
    definition,
    field,
    control,
    disabled,
    onChange
}: PluginParameterFreshesRuleProps) => {
    const { watch } = useFormContext<PluginForm>();

    const [watchedValues] = watch([`${definition.id}.${field.id}`]);
    const isEnabled = watchedValues?.is_enabled;

    const handleIsEnabledChanged = (_isEnabled: boolean) => {
        const currentMagnitude = watchedValues?.magnitude;
        const currentDuration = watchedValues?.duration;
        const currentCount = watchedValues?.count;
        const currentIndependence = watchedValues?.independence;

        onChange(field.id, {
            is_enabled: _isEnabled,
            magnitude: currentMagnitude,
            duration: currentDuration,
            count: currentCount,
            independence: currentIndependence
        });
    };

    const handleMagnitudeChanged = (_magnitude: number) => {
        const currentIsEnabled = watchedValues?.is_enabled;
        const currentDuration = watchedValues?.duration;
        const currentCount = watchedValues?.count;
        const currentIndependence = watchedValues?.independence;

        onChange(field.id, {
            is_enabled: currentIsEnabled,
            magnitude: _magnitude,
            duration: currentDuration,
            count: currentCount,
            independence: currentIndependence
        });
    };

    const handleDurationChanged = (_duration: number) => {
        const currentIsEnabled = watchedValues?.is_enabled;
        const currentMagnitude = watchedValues?.magnitude;
        const currentCount = watchedValues?.count;
        const currentIndependence = watchedValues?.independence;

        onChange(field.id, {
            is_enabled: currentIsEnabled,
            magnitude: currentMagnitude,
            duration: _duration,
            count: currentCount,
            independence: currentIndependence
        });
    };

    const handleCountChanged = (_count: number) => {
        const currentIsEnabled = watchedValues?.is_enabled;
        const currentMagnitude = watchedValues?.magnitude;
        const currentDuration = watchedValues?.duration;
        const currentIndependence = watchedValues?.independence;

        onChange(field.id, {
            is_enabled: currentIsEnabled,
            magnitude: currentMagnitude,
            duration: currentDuration,
            count: _count,
            independence: currentIndependence
        });
    };

    const handleIndependenceChanged = (_independence: number) => {
        const currentIsEnabled = watchedValues?.is_enabled;
        const currentMagnitude = watchedValues?.magnitude;
        const currentDuration = watchedValues?.duration;
        const currentCount = watchedValues?.count;

        onChange(field.id, {
            is_enabled: currentIsEnabled,
            magnitude: currentMagnitude,
            duration: currentDuration,
            count: currentCount,
            independence: _independence
        });
    };

    return (
        <div className="freshes-rule-field">
            <Controller
                control={control}
                name={`${definition.id}.${field.id}.is_enabled`}
                render={({ field: _field }) => {
                    return (
                        <Tooltip tooltipContent="Uncheck to disable the analysis of this climate" placement="left">
                            <div>
                                <Checkbox
                                    id={id}
                                    label={field.name}
                                    checked={_field.value}
                                    disabled={disabled}
                                    onChecked={handleIsEnabledChanged}
                                />
                            </div>
                        </Tooltip>
                    );
                }}
            />

            <div className="input-group">
                <Controller
                    control={control}
                    name={`${definition.id}.${field.id}.magnitude`}
                    render={({ field: _field }) => {
                        return (
                            <Tooltip tooltipContent="The unit of this input should match the unit of the flow data being used (likely ML)">
                                <div className="input-container">
                                    <Label className="label">Magnitude</Label>
                                    <NumericInput
                                        id={id}
                                        value={isEnabled ? _field.value : null}
                                        disabled={disabled || !isEnabled}
                                        onChange={handleMagnitudeChanged}
                                        className="input"
                                    />
                                </div>
                            </Tooltip>
                        );
                    }}
                />

                <Controller
                    control={control}
                    name={`${definition.id}.${field.id}.duration`}
                    render={({ field: _field }) => {
                        return (
                            <div className="input-container">
                                <Label className="label">Duration</Label>
                                <NumericInput
                                    id={id}
                                    unit="Day(s)"
                                    min={1}
                                    value={isEnabled ? _field.value : null}
                                    disabled={disabled || !isEnabled}
                                    onChange={handleDurationChanged}
                                    className="input"
                                />
                            </div>
                        );
                    }}
                />

                <Controller
                    control={control}
                    name={`${definition.id}.${field.id}.count`}
                    render={({ field: _field }) => {
                        return (
                            <div className="input-container">
                                <Label className="label">Count</Label>
                                <NumericInput
                                    id={id}
                                    unit="Event(s)"
                                    min={1}
                                    value={isEnabled ? _field.value : null}
                                    disabled={disabled || !isEnabled}
                                    onChange={handleCountChanged}
                                    className="input"
                                />
                            </div>
                        );
                    }}
                />

                <Controller
                    control={control}
                    name={`${definition.id}.${field.id}.independence`}
                    render={({ field: _field }) => {
                        return (
                            <div className="input-container">
                                <Label className="label">Independence</Label>
                                <NumericInput
                                    id={id}
                                    unit="Day(s)"
                                    min={1}
                                    value={isEnabled ? _field.value : null}
                                    disabled={disabled || !isEnabled}
                                    onChange={handleIndependenceChanged}
                                    className="input"
                                />
                            </div>
                        );
                    }}
                />
            </div>
        </div>
    );
};

export default PluginParameterFreshesRule;
