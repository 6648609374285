import React, { useState } from "react";
import Checkbox from "Components/Checkbox";
import { RuleModel } from "types/models";
import DateTimeCell from "./DateTimeCell";
import RuleRowActions from "./RuleRowActions";
import RuleIcon from "Components/ERPIcon/RuleIcon";
import RuleLinkCell from "./RuleLinkCell";
import { fullname } from "utils/utils";
import LockedIcon from "./LockedIcon";
import PluginOutOfDateIcon from "Components/ERPIcon/PluginOutOfDateIcon";

interface RuleRowProps {
    rule: RuleModel;
    isSelected: boolean;
    isLocked: boolean;
    onSelected: (rule: RuleModel, selected: boolean) => void;
    onRuleMove: (rule: RuleModel) => void;
    onRuleDelete: (rule: RuleModel) => void;
    onRuleCopy: (rule: RuleModel) => void;
    onRuleEdit: (rule: RuleModel) => void;
}

const RuleRow = ({
    rule,
    isSelected,
    isLocked,
    onSelected,
    onRuleMove,
    onRuleDelete,
    onRuleCopy,
    onRuleEdit
}: RuleRowProps) => {
    const [isHovering, setIsHovering] = useState<boolean>(false);

    const handleSelected = (selected: boolean) => {
        onSelected(rule, selected);
    };

    const handleMove = () => {
        onRuleMove(rule);
    };

    const handleDelete = () => {
        onRuleDelete(rule);
    };

    const handleCopy = () => {
        onRuleCopy(rule);
    };

    const handleEdit = () => {
        onRuleEdit(rule);
    };

    return (
        <tr className="rule-row" onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
            <td style={{ width: 50 }} className="selection-cell">
                <Checkbox checked={isSelected} onChecked={handleSelected} />
            </td>

            <RuleLinkCell id={rule.id} style={{ maxWidth: 50 }}>
                <RuleIcon />
            </RuleLinkCell>

            <RuleLinkCell id={rule.id}>
                {rule.name}

                {isLocked && <LockedIcon type="Rule" owner={rule.lock.owner} />}

                {rule.plugin?.isCurrent === false && <PluginOutOfDateIcon />}
            </RuleLinkCell>

            <RuleLinkCell id={rule.id} className="centered-cell status-cell">
                Rule
            </RuleLinkCell>

            <RuleLinkCell id={rule.id} className="centered-cell status-cell">
                {fullname(rule.createdBy)}
            </RuleLinkCell>

            <DateTimeCell dateTime={rule.updatedAt} />

            <td className="action-cell">
                {isHovering && (
                    <RuleRowActions
                        canEdit={!isLocked}
                        canDelete={!isLocked}
                        onMove={handleMove}
                        onDelete={handleDelete}
                        onCopy={handleCopy}
                        onEdit={handleEdit}
                    />
                )}
            </td>
        </tr>
    );
};

export default RuleRow;
