import Label from "Components/Label";
import LoadingIndicator from "Components/LoadingIndicator";
import ERPSlider from "Components/Slider/Slider";
import { SliderValues } from "Components/Slider/types";
import { isNil, round } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import {
    ExploreDailyPeriodModel,
    ExplorePeriodModel,
    RuleExploreResultsModel,
    RuleIntermediateResultDailyTimeseries,
    RuleIntermediateResults,
    RuleIntermediateResultType,
    RuleIntermediateResultTypeScale,
    RuleIntermediateResultYearlyTimeseries
} from "types/models";
import {
    formatDailyIntermediateResults,
    formatYearlyIntermediateResults,
    getAvaliableIntermediateResultTypes
} from "utils/explorer-charts.utils";
import { isNilOrEmpty } from "utils/utils";
import { useRule } from "./RuleContext";
import RuleExploreIntermediateDailyChart from "./RuleExploreIntermediateDailyChart";
import RuleExploreIntermediateTypeSelect from "./RuleExploreIntermediateTypeSelect";
import RuleExploreIntermediateYearlyChart from "./RuleExploreIntermediateYearlyChart";

interface RuleExploreIntermediateProps {
    results: RuleExploreResultsModel;
}

const DEFAULT_YEAR_LENGTH = 20;

const getIntermediateData = (
    results: RuleExploreResultsModel,
    intermediateResultType: RuleIntermediateResultType
): RuleIntermediateResults => {
    switch (intermediateResultType.scale) {
        case RuleIntermediateResultTypeScale.DAILY:
            return formatDailyIntermediateResults(results.intermediate.daily, intermediateResultType);

        case RuleIntermediateResultTypeScale.YEARLY:
            return formatYearlyIntermediateResults(results.intermediate.yearly, intermediateResultType);

        default:
            return null;
    }
};

const RuleExploreIntermediate = ({ results }: RuleExploreIntermediateProps) => {
    const { rule } = useRule();

    const [selectedYearlyResultPeriod, setSelectedYearlyResultPeriod] = useState<ExplorePeriodModel>(null);
    const [selectedDailyResultPeriod, setSelectedDailyResultPeriod] = useState<ExploreDailyPeriodModel>(null);

    const [selectedIntermediateResultType, setSelectedIntermediateResultType] =
        useState<RuleIntermediateResultType>(null);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [intermediateData, setIntermediateData] = useState<RuleIntermediateResults>(null);

    const intermediateResultTypes = useMemo(() => {
        const allTypes = getAvaliableIntermediateResultTypes(results.intermediate.daily, results.intermediate.yearly);

        return allTypes;
    }, [results]);

    useEffect(() => {
        if (isNilOrEmpty(intermediateResultTypes)) {
            return;
        }

        const defaultType =
            intermediateResultTypes.find(d => d.value === "summary_score") ?? intermediateResultTypes[0];

        setSelectedIntermediateResultType(defaultType);
    }, [intermediateResultTypes]);

    const getData = async () => {
        setIsLoading(true);
        const data: RuleIntermediateResults = await new Promise(resolve => {
            const data = getIntermediateData(results, selectedIntermediateResultType);
            resolve(data);
        });

        if (isNil(selectedDailyResultPeriod)) {
            setSelectedDailyResultPeriod(data.dailyResultPeriod);
        }

        if (isNil(selectedYearlyResultPeriod)) {
            // Default to show only the last DEFAULT_YEAR_LENGTH years of data
            const yearlyResultPeriod = {
                ...data.yearlyResultPeriod,
                startYear:
                    data.yearlyResultPeriod.endYear - data.yearlyResultPeriod.startYear <= DEFAULT_YEAR_LENGTH
                        ? data.yearlyResultPeriod.startYear
                        : data.yearlyResultPeriod.endYear - DEFAULT_YEAR_LENGTH
            };

            setSelectedYearlyResultPeriod(yearlyResultPeriod);
        }

        setIntermediateData(data);
        setIsLoading(false);
    };

    useEffect(() => {
        if (!isNil(selectedIntermediateResultType)) {
            getData();
        }
    }, [selectedIntermediateResultType]);

    const handleSelectedYearlyPeriodChange = (newValues: SliderValues) => {
        setSelectedYearlyResultPeriod({
            ...selectedYearlyResultPeriod,
            startYear: newValues.from,
            endYear: newValues.to
        });
    };

    const handleSelectedDailyPeriodChange = (newValues: SliderValues) => {
        setSelectedDailyResultPeriod({
            ...selectedDailyResultPeriod,
            startDay: newValues.from,
            endDay: newValues.to
        });
    };

    return (
        <div>
            {!results.hasIntermediate && <span>No intermediate results</span>}

            {results.hasIntermediate && (
                <>
                    <RuleExploreIntermediateTypeSelect
                        selectedIntermediateResultType={selectedIntermediateResultType}
                        avaliableIntermediateResultTypes={intermediateResultTypes}
                        onIntermediateResultTypeSelected={setSelectedIntermediateResultType}
                    />

                    {!isNil(selectedYearlyResultPeriod) && (
                        <ERPSlider
                            containerClassName="explore-slider"
                            values={{
                                from: selectedYearlyResultPeriod?.startYear,
                                to: selectedYearlyResultPeriod?.endYear
                            }}
                            min={selectedYearlyResultPeriod.minYear}
                            max={selectedYearlyResultPeriod.maxYear}
                            marks={selectedYearlyResultPeriod.sliderMarks}
                            title="Years"
                            onChange={handleSelectedYearlyPeriodChange}
                        />
                    )}

                    {!isNil(selectedDailyResultPeriod) &&
                        selectedIntermediateResultType?.scale === RuleIntermediateResultTypeScale.DAILY && (
                            <ERPSlider
                                containerClassName="explore-slider"
                                values={{
                                    from: selectedDailyResultPeriod?.startDay,
                                    to: selectedDailyResultPeriod?.endDay
                                }}
                                min={selectedDailyResultPeriod.minDay}
                                max={selectedDailyResultPeriod.maxDay}
                                marks={selectedDailyResultPeriod.sliderMarks}
                                title="Days"
                                onChange={handleSelectedDailyPeriodChange}
                            />
                        )}

                    {!isLoading && (
                        <>
                            {intermediateData?.resultsType.scale === RuleIntermediateResultTypeScale.DAILY && (
                                <>
                                    <div className="gradient-bar-holder">
                                        <Label className="gradient-label">{`${round(
                                            intermediateData.minValue,
                                            0
                                        )}%`}</Label>
                                        <div className="gradient-bar"></div>
                                        <Label className="gradient-label">{`${round(
                                            intermediateData.maxValue,
                                            0
                                        )}%`}</Label>
                                    </div>

                                    <RuleExploreIntermediateDailyChart
                                        result={intermediateData.result as RuleIntermediateResultDailyTimeseries}
                                        title={`${rule.name} - ${intermediateData.resultsType.label}`}
                                        minValue={intermediateData.minValue}
                                        maxValue={intermediateData.maxValue}
                                        yearlyPeriod={selectedYearlyResultPeriod}
                                        dailyPeriod={selectedDailyResultPeriod}
                                    />
                                </>
                            )}

                            {intermediateData?.resultsType.scale === RuleIntermediateResultTypeScale.YEARLY && (
                                <RuleExploreIntermediateYearlyChart
                                    result={intermediateData.result as RuleIntermediateResultYearlyTimeseries}
                                    title={`${rule.name} - ${intermediateData.resultsType.label}`}
                                    yearlyPeriod={selectedYearlyResultPeriod}
                                    resultType={intermediateData.resultsType}
                                />
                            )}
                        </>
                    )}

                    {isLoading && <LoadingIndicator centered={true} />}
                </>
            )}
        </div>
    );
};

export default RuleExploreIntermediate;
