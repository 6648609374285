import React, { useEffect, useState } from "react";
import { RuleModel } from "types/models";
import { debounce } from "lodash";
import CollectionAddSearchResultsHeader from "./CollectionAddSearchResultsHeader";
import { useCollection } from "./CollectionContext";
import CollectionRulesTable from "./CollectionRulesTable";
import { SelectionState } from "./types";
import { HeaderSortContext } from "Components/SortableHeader/types";
import { SortFunction } from "Components/SortableHeader";

interface CollectionAddSearchViewProps {
    search: string;
    rules: RuleModel[];
    selection: SelectionState;
    sortContext: HeaderSortContext<RuleModel>;
    onRuleSelected: (rule: RuleModel, selected: boolean) => void;
    onRuleAdd: (rule: RuleModel) => void;
    onRuleRemove: (rule: RuleModel) => void;
    onBulkAdd: (selection: SelectionState) => void;
    onBulkRemove: (selection: SelectionState) => void;
    onAllSelected: (selected: boolean) => void;
    onSort: SortFunction;
}

const DEBOUCE_SEARCH_WAIT_MS = 200;

const CollectionAddSearchView = ({
    search,
    rules,
    selection,
    sortContext,
    onRuleSelected,
    onRuleAdd,
    onRuleRemove,
    onBulkAdd,
    onBulkRemove,
    onAllSelected,
    onSort
}: CollectionAddSearchViewProps) => {
    const [searchResults, setSearchResults] = useState<RuleModel[]>([]);
    const [searching, setSearching] = useState<boolean>(false);
    const { collectionRules } = useCollection();

    const doSearch = async (searchText: string) => {
        try {
            const filteredRules = rules.filter(r => r.name.toLowerCase().includes(searchText));

            setSearchResults(filteredRules);
        } finally {
            setSearching(false);
        }
    };

    const [debouncedSearch] = useState(() => debounce(doSearch, DEBOUCE_SEARCH_WAIT_MS));

    useEffect(() => {
        setSearching(true);
        debouncedSearch(search);
    }, [search]);

    return (
        <div className="search-view">
            <CollectionAddSearchResultsHeader resultsCount={searchResults.length} />

            <CollectionRulesTable
                isLoading={searching}
                rules={searchResults}
                collectionRules={collectionRules}
                selection={selection}
                sortContext={sortContext}
                allowAdd={true}
                allowRun={false}
                noRulesMessage="No rules match the search"
                onRuleSelected={onRuleSelected}
                onRuleRemove={onRuleRemove}
                onRuleAdd={onRuleAdd}
                onBulkAdd={onBulkAdd}
                onBulkRemove={onBulkRemove}
                onAllSelected={onAllSelected}
                onSort={onSort}
            />
        </div>
    );
};

export default CollectionAddSearchView;
