import React, { useState } from "react";
import Button from "Components/Button";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { Dropdown } from "react-bootstrap";
import { faFolderPlus } from "@fortawesome/pro-light-svg-icons";
import CollectionIcon from "Components/ERPIcon/CollectionIcon";
import RuleIcon from "Components/ERPIcon/RuleIcon";
import { useHistory } from "react-router-dom";
import { CreateRuleRequest, CreateCollectionRequest } from "types/requests";
import FolderService from "Services/folder.service";
import ERPIcon from "Components/ERPIcon";
import { ReportingTiers } from "types/plugin";

interface NewButtonProps {
    folderId: string;
    onNewFolder: () => void;
}

const NewButton = ({ folderId, onNewFolder }: NewButtonProps) => {
    const history = useHistory();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleNewCollection = async () => {
        try {
            setIsLoading(true);

            const request: CreateCollectionRequest = {
                name: "New Compliance Report",
                description: null
            };

            const collection = await FolderService.createCollection(folderId, request);

            history.push(`/collection/${collection.id}`);
        } finally {
            setIsLoading(false);
        }
    };

    const handleNewModel = async () => {
        try {
            setIsLoading(true);

            const request: CreateRuleRequest = {
                name: "New Rule",
                description: null,
                reportingTiers: [ReportingTiers.LEVEL_1]
            };

            const rule = await FolderService.createRule(folderId, request);

            history.push(`/rule/${rule.id}`);
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <Dropdown>
            <Dropdown.Toggle className="new-button" disabled={isLoading}>
                New <ERPIcon className="new-button-icon" icon={faPlus} isLoading={isLoading} />
            </Dropdown.Toggle>

            <Dropdown.Menu align="right" className="new-button-menu">
                <Dropdown.Item as={Button} variant="none" eventKey="1" onClick={onNewFolder}>
                    <ERPIcon icon={faFolderPlus} />
                    Folder
                </Dropdown.Item>

                <Dropdown.Divider />

                <Dropdown.Item as={Button} variant="none" eventKey="2" onClick={handleNewCollection}>
                    <CollectionIcon />
                    Compliance Report
                </Dropdown.Item>

                <Dropdown.Item as={Button} variant="none" eventKey="3" onClick={handleNewModel}>
                    <RuleIcon />
                    Rule
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default NewButton;
