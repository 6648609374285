import React from "react";
import { Link } from "react-router-dom";

export type LinkCellProps = React.TdHTMLAttributes<HTMLTableDataCellElement> & {
    to: string;
    children: React.ReactNode;
};

const LinkCell = ({ to, children, ...rest }: LinkCellProps) => {
    return (
        <td {...rest}>
            <Link to={to}>{children}</Link>
        </td>
    );
};

export default LinkCell;
