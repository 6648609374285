import React, { useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import FormInput from "Components/FormInput/FormInput";
import FormTextArea from "Components/FormTextArea/FormTextArea";
import { debounce } from "lodash";
import RuleService from "Services/rule.service";
import { UpdateRuleRequest } from "types/requests";
import { useRule } from "./RuleContext";
import { sleep } from "utils/utils";
import RuleAttachments from "./RuleAttachments";
import { ReportingTiers } from "types/plugin";
import Label from "Components/Label";
import Select, { SelectOption } from "Components/Select/Select";

const schema = yup.object().shape({
    id: yup.string().required("Id is required"),
    name: yup.string().required("Name is required"),
    description: yup.string().optional().nullable(),
    reportingTiers: yup.array().optional()
});

interface RuleForm {
    id: string;
    name: string;
    description: string;
    reportingTiers: ReportingTiers[];
}

const DEBOUCE_TRIGGER_SAVE = 500;

const RuleGeneralInfo = () => {
    const { rule, readOnly, setSaving, updateRule } = useRule();

    const {
        register,
        watch,
        setValue,
        control,
        formState: { errors }
    } = useForm<RuleForm>({
        resolver: yupResolver(schema),
        mode: "onBlur",
        reValidateMode: "onSubmit",
        defaultValues: {
            id: rule.id,
            name: rule.name ?? "",
            description: rule.description ?? "",
            reportingTiers: rule.reportingTiers ?? []
        }
    });

    useEffect(() => {
        setValue("id", rule.id);
        setValue("name", rule.name);
        setValue("description", rule.description);
        setValue("reportingTiers", rule.reportingTiers);
    }, [rule.id]);

    const saveRule = async (form: RuleForm) => {
        try {
            const isValid = await schema.isValid(form);

            if (!isValid) {
                return;
            }

            setSaving(true);

            const request: UpdateRuleRequest = {
                name: form.name,
                description: form.description,
                reportingTiers: form.reportingTiers
            };

            const updatedRule = await RuleService.updateRule(form.id, request);

            updateRule(updatedRule);
        } finally {
            await sleep(250);
            setSaving(false);
        }
    };

    useEffect(() => {
        if (readOnly) {
            return;
        }

        const subscription = watch(debounce(saveRule, DEBOUCE_TRIGGER_SAVE));

        return () => subscription.unsubscribe();
    }, [watch, readOnly]);

    const reportingTiersOptions: SelectOption[] = [
        { value: ReportingTiers.LEVEL_1, label: "Level 1" },
        { value: ReportingTiers.LEVEL_2, label: "Level 2" },
        { value: ReportingTiers.LEVEL_3, label: "Level 3" },
        { value: ReportingTiers.LEVEL_4, label: "Level 4" }
    ];

    const handleReportingTiersChanged = (nextTiers: ReportingTiers[]) => {
        setValue("reportingTiers", nextTiers);
    };

    return (
        <div className="rule-section-content rule-general-info">
            <form>
                <FormInput
                    labelText="Name"
                    id="name"
                    name="name"
                    type="text"
                    register={register("name")}
                    error={errors?.name}
                    disabled={readOnly}
                />

                <FormTextArea
                    labelText="Description"
                    id="description"
                    name="description"
                    rows={6}
                    register={register("description")}
                    error={errors?.description}
                    disabled={readOnly}
                />

                <Controller
                    control={control}
                    name={`reportingTiers`}
                    render={({ field: _field }) => {
                        return (
                            <div className="reporting-tiers-group">
                                <Label>Reporting Tiers</Label>
                                <Select
                                    values={reportingTiersOptions}
                                    value={_field.value.map(v => {
                                        return reportingTiersOptions.find(o => o.value === v);
                                    })}
                                    onSelected={options => handleReportingTiersChanged(options.map(o => o.value))}
                                    isMulti={true}
                                />
                            </div>
                        );
                    }}
                />
            </form>

            <RuleAttachments rule={rule} readOnly={readOnly} />
        </div>
    );
};

export default RuleGeneralInfo;
