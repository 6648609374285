import axios from "axios";
import Config from "../Config";
import { DataType } from "../types/models";
import {
    BOMAvailabilitiesResult,
    BOMAvailability,
    BOMDataResult,
    BOMSite,
    BOMSitesResult,
    SILODataResult,
    EDSDataResult
} from "../types/eds";
import moment from "moment";
import { isNil } from "lodash";

export default class EDSService {
    static formatSiloUrl(dataType: DataType, params: Record<string, string | number>): string {
        const siloEndpoint = this.mapDataTypeToSiloRoute(dataType);

        return `${Config.edsUrl}/api/v1/silo/${siloEndpoint}${this.mapParams(params)}`;
    }

    static formatBomUrl(endpoint: string, params: Record<string, string | number>): string {
        return `${Config.edsUrl}/api/v1/bom/${endpoint}${this.mapParams(params)}`;
    }

    static mapParams(params: Record<string, string | number>): string {
        return Object.keys(params).reduce((acc, key) => {
            if (isNil(params[key])) {
                return acc;
            }

            return acc + `${key}=${params[key]}&`;
        }, "?");
    }

    static mapDataTypeToSiloRoute(dataType: DataType): string {
        switch (dataType) {
            case DataType.RAINFALL:
                return "daily-rainfall";
            case DataType.EVAPORATION:
                return "daily-evaporation";
            case DataType.TEMPERATURE:
                return "daily-max-temperature";

            default:
                return;
        }
    }

    static async getSiloData(
        dataType: DataType,
        latitude: number,
        longitude: number,
        startYear: number,
        endYear: number
    ): Promise<[EDSDataResult, string]> {
        try {
            const response = await axios.get(
                this.formatSiloUrl(dataType, { lat: latitude, lon: longitude, start: startYear, end: endYear })
            );

            const result: SILODataResult = response.data;

            const data = result.values.map(v => {
                return {
                    date: moment(v.date, "YYYY-MM-DD").toDate(),
                    value: v.value
                };
            });

            return [
                {
                    data: data,
                    unit: result?.unit?.displayName,
                    name: `${result.location.latitude} ${result.location.longitude}`
                },
                null
            ];
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return [{ data: [] }, error.response.data.message];
            }
        }
    }

    static async getBOMData(
        dataType: DataType,
        gauge: string,
        startYear?: number,
        endYear?: number
    ): Promise<[EDSDataResult, string]> {
        try {
            const response = await axios.get(
                this.formatBomUrl("data", { dataType: dataType, siteId: gauge, start: startYear, end: endYear })
            );

            const result: BOMDataResult = response.data;

            const data = result.values.map(v => {
                return {
                    date: moment(v.timestamp, "YYYYMMDD").toDate(),
                    value: v.value
                };
            });

            return [
                {
                    data: data,
                    unit: result.availability.unit,
                    name: `${result.availability.name} (${result.availability.identifier})`
                },
                null
            ];
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return [{ data: [] }, error.response.data.message];
            }
        }
    }

    static async getBomSites(dataType: DataType): Promise<BOMSite[]> {
        const response = await axios.get(this.formatBomUrl("sites", { dataType: dataType }));

        const result: BOMSitesResult = response.data;

        const data = result.sites;

        return data;
    }

    static async getBomAvailabilities(gauge: string): Promise<BOMAvailability[]> {
        const response = await axios.get(this.formatBomUrl("availabilities", { siteId: gauge }));

        const result: BOMAvailabilitiesResult = response.data;

        const data = result.availabilities;

        return data;
    }
}
