import React, { useState } from "react";
import { ExplorePeriodModel, RuleExploreResultsModel } from "types/models";
import RuleExplorePercentBarChart from "./RuleExplorePercentBarChart";
import { useRule } from "./RuleContext";
import RuleExploreYearlyBarChart from "./RuleExploreYearlyBarChart";
import ERPSlider from "Components/Slider/Slider";
import { getResultPeriod } from "utils/explorer-charts.utils";
import { SliderValues } from "Components/Slider/types";

interface RuleExploreSummaryProps {
    results: RuleExploreResultsModel;
}

const RuleExploreSummary = ({ results }: RuleExploreSummaryProps) => {
    const { rule } = useRule();
    const [selectedResultPeriod, setSelectedResultPeriod] = useState<ExplorePeriodModel>(
        results.hasSummary ? getResultPeriod(results.summary.yearly) : null
    );

    const handleSelectedPeriodChange = (newValues: SliderValues) => {
        setSelectedResultPeriod({ ...selectedResultPeriod, startYear: newValues.from, endYear: newValues.to });
    };

    return (
        <div>
            {!results.hasSummary && <span>No summary results</span>}

            {results.hasSummary && (
                <div>
                    <ERPSlider
                        containerClassName="explore-slider"
                        values={{ from: selectedResultPeriod.startYear, to: selectedResultPeriod.endYear }}
                        min={selectedResultPeriod.minYear}
                        max={selectedResultPeriod.maxYear}
                        marks={selectedResultPeriod.sliderMarks}
                        title="Years"
                        onChange={handleSelectedPeriodChange}
                    />

                    <RuleExplorePercentBarChart
                        yearlyResult={results.summary.yearly}
                        startYear={selectedResultPeriod.startYear}
                        endYear={selectedResultPeriod.endYear}
                        title={`${rule.name} - Percentage of year results`}
                    />

                    <RuleExploreYearlyBarChart
                        yearlyResult={results.summary.yearly}
                        startYear={selectedResultPeriod.startYear}
                        endYear={selectedResultPeriod.endYear}
                        title={`${rule.name} - Timeseries of year results`}
                    />
                </div>
            )}
        </div>
    );
};

export default RuleExploreSummary;
