import React from "react";
import { faCopy, faFileExport, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import TooltipIcon from "Components/TooltipIcon";
import { SelectionState } from "./types";

interface ExplorerTableBulkActionsProps {
    selection: SelectionState;
    onDelete: (selection: SelectionState) => void;
    onCopy: (selection: SelectionState) => void;
    onMove: (selection: SelectionState) => void;
}

const ExplorerTableBulkActions = ({ selection, onDelete, onCopy, onMove }: ExplorerTableBulkActionsProps) => {
    const hasSelection = selection.folders.size > 0 || selection.collections.size > 0 || selection.rules.size > 0;

    const handleDelete = () => {
        onDelete(selection);
    };

    const handleCopy = () => {
        onCopy(selection);
    };

    const handleMove = () => {
        onMove(selection);
    };

    return (
        <div className="table--bulk-actions">
            <span>Bulk actions: </span>

            <div className="action-icons">
                <TooltipIcon
                    icon={faTrashAlt}
                    tooltip="Delete selected"
                    placement="bottom"
                    disabled={!hasSelection}
                    onClick={handleDelete}
                />

                <TooltipIcon
                    icon={faCopy}
                    tooltip="Copy selected"
                    placement="bottom"
                    disabled={!hasSelection}
                    onClick={handleCopy}
                />

                <TooltipIcon
                    icon={faFileExport}
                    tooltip="Move selected"
                    placement="bottom"
                    disabled={!hasSelection}
                    onClick={handleMove}
                />
            </div>
        </div>
    );
};

export default ExplorerTableBulkActions;
