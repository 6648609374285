import React from "react";

interface CollectionAddSearchResultsHeaderProps {
    resultsCount: number;
}

const CollectionAddSearchResultsHeader = ({ resultsCount }: CollectionAddSearchResultsHeaderProps) => {
    return (
        <div className="filter">
            <h5>Search Results: {resultsCount} results</h5>
        </div>
    );
};

export default CollectionAddSearchResultsHeader;
