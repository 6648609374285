import React from "react";
import Dropzone from "react-dropzone";
import { isNil } from "lodash";

import "./FileSelector.scss";

interface FileSelectorProps {
    accept?: string | string[];
    children?: React.ReactNode;
    allowMultiple?: boolean;
    disabled?: boolean;
    className?: string;
    onFileSelected: (file: File | File[]) => void;
}

const FileSelectorDrag = ({
    accept,
    children,
    allowMultiple = false,
    disabled,
    className,
    onFileSelected
}: FileSelectorProps) => {
    const handleFileSelected = (files: File[]) => {
        if (files?.length === 0) {
            return;
        }

        if (allowMultiple) {
            onFileSelected(files);
        } else {
            onFileSelected(files[0]);
        }
    };

    return (
        <Dropzone disabled={disabled} noClick noKeyboard onDrop={handleFileSelected} accept={accept}>
            {({ getRootProps, getInputProps, open }) => (
                <div {...getRootProps()} className={className}>
                    {!isNil(children) && children}

                    {isNil(children) && (
                        <div className="dropzone">
                            <span className="dropzone-label">
                                Drag files here or{" "}
                                <span className="browse-label" onClick={open}>
                                    browse
                                </span>
                            </span>
                        </div>
                    )}

                    <input {...getInputProps()} />
                </div>
            )}
        </Dropzone>
    );
};

export default FileSelectorDrag;
