import React from "react";
import TooltipIcon from "Components/TooltipIcon";
import { faMinusCircle, faPlusCircle } from "@fortawesome/pro-regular-svg-icons";
import { faCircleNotch, faPlayCircle } from "@fortawesome/pro-solid-svg-icons";
import ERPIcon from "Components/ERPIcon";
import { TaskModel, TaskStatus } from "types/models";

interface CollectionRulesTableRowActionsProps {
    isEnabled: boolean;
    allowRun: boolean;
    task: TaskModel;
    onAdd: () => void;
    onRemove: () => void;
    onRun: () => void;
}

const CollectionRulesTableRowActions = ({
    isEnabled,
    allowRun,
    task,
    onAdd,
    onRemove,
    onRun
}: CollectionRulesTableRowActionsProps) => {
    const isRunning = task?.status === TaskStatus.PENDING || task?.status === TaskStatus.RUNNING;

    return (
        <div className="action-container">
            {!isEnabled && <TooltipIcon icon={faPlusCircle} className="add-icon" tooltip="Add rule" onClick={onAdd} />}

            {isEnabled && (
                <TooltipIcon icon={faMinusCircle} className="remove-icon" tooltip="Remove rule" onClick={onRemove} />
            )}

            {allowRun && !isRunning && (
                <TooltipIcon icon={faPlayCircle} className="run-icon" tooltip="Run rule" onClick={onRun} />
            )}
            {allowRun && isRunning && <ERPIcon icon={faCircleNotch} className="running-icon" spin={true} />}
        </div>
    );
};

export default CollectionRulesTableRowActions;
