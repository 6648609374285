import FormError from "../../Components/FormError/FormError";
import Label from "../../Components/Label";
import { isNil } from "lodash";
import React, { useState, useEffect, ComponentType, KeyboardEventHandler, FocusEventHandler } from "react";
import { FieldError, UseFormRegisterReturn } from "react-hook-form";
import Select, { ActionMeta, components, InputActionMeta, OptionProps, StylesConfig } from "react-select";
import AsyncSelect from "react-select/async";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import { guid, isNilOrEmpty } from "../../utils/utils";
import { ThemeConfig } from "react-select/dist/declarations/src/theme";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import TooltipIcon from "../TooltipIcon";

export type SelectOption = { value: string; label: string; [key: string]: any };

export type StatefulSelectOption = {
    selected: boolean;
} & SelectOption;

export interface SelectProps {
    id?: string;
    name?: string;
    labelText?: string;
    labelClassName?: string;
    initialValue?: SelectOption | SelectOption[];
    values?: Array<SelectOption>;
    loadValues?: (inputValue: string, callback: (options: SelectOption[]) => void) => void;
    isAsync?: boolean;
    onSelected?: (value: SelectOption | SelectOption[], action: ActionMeta<SelectOption | SelectOption[]>) => void;
    placeholder?: string;
    disabled?: boolean;
    menuClassName?: string;
    isClearable?: boolean;
    isSearchable?: boolean;
    closeMenuOnSelect?: boolean;
    register?: UseFormRegisterReturn;
    value?: SelectOption | SelectOption[];
    renderOption?: ComponentType<OptionProps<any, any, any>>;
    onKeyDown?: KeyboardEventHandler;
    inputValue?: string;
    onInputChange?: (newValue: string, action: InputActionMeta) => void;
    hideMenuIcon?: boolean;
    isLoading?: boolean;
    clearValueOnSelect?: boolean;
    isMulti?: boolean;
    error?: FieldError;
    filterOption?: (option: FilterOptionOption<SelectOption>, value: string) => boolean;
    tabSelectsValue?: boolean;
    menuIsOpen?: boolean;
    onFocus?: FocusEventHandler;
    onBlur?: FocusEventHandler;
    onMenuOpen?: () => void;
    onMenuClose?: () => void;
    openMenuOnClick?: boolean;
    icon?: IconProp;
    iconClassName?: string;
    iconTooltip?: string;
}

const ERROR_COLOR = "#d2272e";
const PRIMARY_COLOR = "#08bfdd";
const PRIMARY_COLOR_25 = "#08bfdd";
const WHITE_COLOR = "white";
const DISABLED_GREY = "#efefef";
const DISABLED_GREY_INDICATOR = "#d1d3d4";

const AppSelect = React.forwardRef((props: SelectProps, ref: any) => {
    const {
        id = guid(),
        name = `select-${guid()}`,
        labelText,
        labelClassName,
        initialValue,
        values,
        loadValues,
        isAsync,
        onSelected,
        placeholder,
        disabled,
        menuClassName,
        isClearable = false,
        isSearchable = true,
        register,
        value,
        closeMenuOnSelect,
        renderOption = components.Option,
        hideMenuIcon = false,
        clearValueOnSelect,
        error,
        filterOption,
        tabSelectsValue,
        menuIsOpen,
        onFocus,
        onBlur,
        onMenuOpen,
        onMenuClose,
        openMenuOnClick,
        icon,
        iconClassName,
        iconTooltip,
        ...rest
    } = props;

    const [selectedValue, setSelectedValue] = useState<SelectOption | SelectOption[]>(initialValue);

    useEffect(() => {
        setSelectedValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
        setSelectedValue(value);
    }, [value]);

    const handleSelectedValue = (
        value: SelectOption | SelectOption[],
        action: ActionMeta<SelectOption | SelectOption[]>
    ) => {
        setSelectedValue(!clearValueOnSelect ? value : null);

        if (onSelected) {
            onSelected(value, action);
        }
    };

    const hasError = !isNil(error);

    const styles: StylesConfig = {
        control: provided => {
            const borderColor = hasError ? ERROR_COLOR : disabled ? DISABLED_GREY_INDICATOR : PRIMARY_COLOR;
            const hoverBorderColor = hasError ? ERROR_COLOR : PRIMARY_COLOR;

            return {
                ...provided,
                borderWidth: "1px",
                borderColor: borderColor,
                borderRadius: 0,
                ":hover": { borderColor: hoverBorderColor }
            };
        },
        loadingIndicator: provided => {
            return {
                ...provided,
                backgroundColor: WHITE_COLOR
            };
        },
        dropdownIndicator: provided => {
            return {
                ...provided,
                color: !disabled ? WHITE_COLOR : DISABLED_GREY_INDICATOR,
                backgroundColor: !disabled ? PRIMARY_COLOR : DISABLED_GREY,
                height: "100%",
                alignItems: "center",
                ":hover": {
                    ...provided[":hover"],
                    color: WHITE_COLOR,
                    cursor: "pointer"
                }
            };
        },
        indicatorSeparator: provided => {
            return {
                ...provided,
                backgroundColor: "transparent"
            };
        },
        option: provided => {
            return {
                ...provided,
                ":active": {
                    backgroundColor: PRIMARY_COLOR,
                    color: WHITE_COLOR
                },
                ":hover": {
                    ...provided[":hover"],
                    color: WHITE_COLOR
                }
            };
        }
    };

    const theme: ThemeConfig = theme => {
        return {
            ...theme,
            colors: {
                ...theme.colors,
                primary: PRIMARY_COLOR,
                primary25: PRIMARY_COLOR_25
            }
        };
    };

    return (
        <div className={menuClassName}>
            <div>
                {labelText && (
                    <Label htmlFor={name} className={labelClassName}>
                        {labelText}
                    </Label>
                )}

                {!isNil(icon) && !isNilOrEmpty(iconTooltip) && (
                    <TooltipIcon
                        icon={icon}
                        tooltip={iconTooltip}
                        className={iconClassName}
                        style={{ marginLeft: 5 }}
                    />
                )}
            </div>

            {!isAsync && (
                <Select
                    ref={ref}
                    id={id}
                    name={name}
                    isSearchable={isSearchable}
                    isClearable={isClearable}
                    placeholder={placeholder}
                    value={value ?? selectedValue}
                    onChange={handleSelectedValue}
                    tabSelectsValue={tabSelectsValue}
                    options={values}
                    filterOption={filterOption}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onMenuOpen={onMenuOpen}
                    onMenuClose={onMenuClose}
                    openMenuOnClick={openMenuOnClick}
                    closeMenuOnSelect={closeMenuOnSelect}
                    menuIsOpen={menuIsOpen}
                    menuPlacement="auto"
                    menuPosition="fixed"
                    theme={theme}
                    isDisabled={disabled}
                    components={{
                        Option: renderOption,
                        IndicatorsContainer: hideMenuIcon ? () => null : components.IndicatorsContainer
                    }}
                    styles={styles}
                    {...register}
                    {...rest}
                />
            )}

            {isAsync && (
                <AsyncSelect
                    ref={ref}
                    id={id}
                    name={name}
                    isSearchable={isSearchable}
                    isClearable={isClearable}
                    placeholder={placeholder}
                    value={value ?? selectedValue}
                    onChange={handleSelectedValue}
                    tabSelectsValue={tabSelectsValue}
                    loadOptions={loadValues}
                    noOptionsMessage={({ inputValue }) =>
                        !inputValue ? "Begin searching to view options" : "No results found"
                    }
                    filterOption={filterOption}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onMenuOpen={onMenuOpen}
                    onMenuClose={onMenuClose}
                    openMenuOnClick={openMenuOnClick}
                    closeMenuOnSelect={closeMenuOnSelect}
                    menuIsOpen={menuIsOpen}
                    menuPlacement="auto"
                    menuPosition="fixed"
                    theme={theme}
                    isDisabled={disabled}
                    components={{
                        Option: renderOption,
                        IndicatorsContainer: hideMenuIcon ? () => null : components.IndicatorsContainer
                    }}
                    styles={styles}
                    {...register}
                    {...rest}
                />
            )}

            {hasError && <FormError error={error} />}
        </div>
    );
});

export default AppSelect;
