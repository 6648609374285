import { isNil, round } from "lodash";
import React from "react";
import { mapResultToColour } from "utils/collection-explore.utils";

interface CollectionExploreTableRowScoreProps {
    value: number;
}

const CollectionExploreTableRowScore = ({ value }: CollectionExploreTableRowScoreProps) => {
    const valueColour = !isNil(value) ? mapResultToColour(value) : "white";

    return (
        <td>
            <div className="score-cell" style={{ backgroundColor: valueColour }}>
                {round(value, 0)}
            </div>
        </td>
    );
};

export default CollectionExploreTableRowScore;
