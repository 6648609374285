import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { isNil } from "lodash";
import React, { useEffect, useState } from "react";

import "./Checkbox.scss";

export type CheckboxProps = {
    id?: string;
    checked?: boolean;
    label?: string | React.ReactNode;
    disabled?: boolean;
    containerClassName?: string;
    onChecked?: (checked: boolean) => void;
};

const Checkbox = ({ id, checked = false, label, disabled, containerClassName, onChecked }: CheckboxProps) => {
    const [isChecked, setIsChecked] = useState<boolean>(checked);

    useEffect(() => {
        setIsChecked(checked);
    }, [checked]);

    const handleChecked = e => {
        if (disabled) {
            return;
        }

        e.stopPropagation();

        const next = !isChecked;

        setIsChecked(next);

        if (onChecked) {
            onChecked(next);
        }
    };

    return (
        <div id={id} className={clsx("erp-checkbox-container", containerClassName, { disabled: disabled === true })}>
            <div className="erp-checkbox" onClick={handleChecked}>
                {isChecked && <FontAwesomeIcon icon={faCheck} />}
            </div>

            {!isNil(label) && <div className="erp-checkbox-label">{label}</div>}
        </div>
    );
};

export default Checkbox;
