import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSortUp } from "@fortawesome/pro-solid-svg-icons";
import { HeaderSortContext } from "./types";

import "./SortableHeader.scss";

interface HeaderSortIndicatorProps {
    context: HeaderSortContext;
}

const HeaderSortIndicator = ({ context }: HeaderSortIndicatorProps) => {
    return (
        <div className="header--sort">
            <FontAwesomeIcon icon={faSortUp} className={context?.order === "desc" ? "not-active" : ""} />
            <FontAwesomeIcon icon={faSortDown} className={context?.order === "asc" ? "not-active" : ""} />
        </div>
    );
};

export default HeaderSortIndicator;
