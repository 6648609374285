import { parseInt, round } from "lodash";
import { CollectionExplorePeriodModel } from "Scenes/Collection/CollectionExploreBody";
import { CollectionExploreRuleResultModel } from "types/models";

const GRADIENT_MIN_COLOUR = { r: 255, g: 83, b: 73 };
const GRADIENT_MID_COLOUR = { r: 253, g: 198, b: 7 };
const GRADIENT_MAX_COLOUR = { r: 8, g: 191, b: 221 };

export const getResultPeriod = (results: CollectionExploreRuleResultModel[]): CollectionExplorePeriodModel => {
    const uniqueYears = results.reduce((yearAccumulator: Set<number>, result: CollectionExploreRuleResultModel) => {
        const resultYears = result.hasResult ? result.result.rows.map(d => parseInt(d.year)) : [];

        resultYears.forEach(y => yearAccumulator.add(y));

        return yearAccumulator;
    }, new Set());

    const uniqueYearsList = Array.from(uniqueYears).sort();

    const startYear = uniqueYearsList[0];
    const endYear = uniqueYearsList[uniqueYearsList.length - 1];

    return {
        minYear: startYear,
        maxYear: endYear,
        startYear: startYear,
        endYear: endYear,
        singleYear: endYear,
        years: uniqueYearsList,
        sliderMarks: uniqueYearsList.filter(y => y % 10 === 0)
    };
};

export const getValuesMean = (values: number[], dp = 0): number => {
    if (values.length === 0) {
        return null;
    }

    const sum = values.reduce((acc, val) => acc + val, 0);
    return round(sum / values.length, dp);
};

export const mapResultsToColour = (values: number[]): string => {
    const meanValue = getValuesMean(values);
    return getHeatmapColour(meanValue, 0, 100);
};

export const mapResultToColour = (value: number): string => {
    return getHeatmapColour(value, 0, 100);
};

const getHeatmapColour = (value: number, min: number, max: number) => {
    const range = max - min;
    const positionInRange = range > 0 ? (value - min) / range : 1;

    if (positionInRange < 0.5) {
        const adjustedPositionInRange = positionInRange / 0.5;

        const r = round(
            GRADIENT_MIN_COLOUR.r + adjustedPositionInRange * (GRADIENT_MID_COLOUR.r - GRADIENT_MIN_COLOUR.r)
        );
        const g = round(
            GRADIENT_MIN_COLOUR.g + adjustedPositionInRange * (GRADIENT_MID_COLOUR.g - GRADIENT_MIN_COLOUR.g)
        );
        const b = round(
            GRADIENT_MIN_COLOUR.b + adjustedPositionInRange * (GRADIENT_MID_COLOUR.b - GRADIENT_MIN_COLOUR.b)
        );
        return `rgb(${r}, ${g}, ${b})`;
    }

    if (positionInRange > 0.5) {
        const adjustedPositionInRange = (positionInRange - 0.5) / 0.5;

        const r = round(
            GRADIENT_MID_COLOUR.r + adjustedPositionInRange * (GRADIENT_MAX_COLOUR.r - GRADIENT_MID_COLOUR.r)
        );
        const g = round(
            GRADIENT_MID_COLOUR.g + adjustedPositionInRange * (GRADIENT_MAX_COLOUR.g - GRADIENT_MID_COLOUR.g)
        );
        const b = round(
            GRADIENT_MID_COLOUR.b + adjustedPositionInRange * (GRADIENT_MAX_COLOUR.b - GRADIENT_MID_COLOUR.b)
        );
        return `rgb(${r}, ${g}, ${b})`;
    }

    return `rgb(${GRADIENT_MID_COLOUR.r}, ${GRADIENT_MID_COLOUR.g}, ${GRADIENT_MID_COLOUR.b})`;
};
