import { KNOWN_HTTP_STATUS_CODES } from "api/base.api";
import { TaskModel } from "types/models";
import { ComputationResult } from "types/plugin";
import API from "../api/data.api";
import { URL } from "../Constants/api";
import { BaseComputationRequest } from "types/requests";

export default class ComputationService {
    public static async compute(request: BaseComputationRequest): Promise<ComputationResult> {
        try {
            const task = await API.post<TaskModel>(URL.COMPUTATION, request);

            return { task: task, success: true, failure: null };
        } catch (error) {
            if (API.isAxiosError(error)) {
                if (error.response.status === KNOWN_HTTP_STATUS_CODES.BAD_REQUEST) {
                    const apiError = error.response.data.error;

                    return {
                        task: null,
                        success: false,
                        failure: { code: apiError.code, message: apiError.message }
                    };
                }
            } else {
                throw error;
            }
        }
    }
}
