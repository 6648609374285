import React, { useState } from "react";
import Checkbox from "Components/Checkbox";
import { CollectionModel } from "types/models";
import DateTimeCell from "./DateTimeCell";
import CollectionRowActions from "./CollectionRowActions";
import CollectionIcon from "Components/ERPIcon/CollectionIcon";
import CollectionLinkCell from "./CollectionLinkCell";
import LockedIcon from "./LockedIcon";
import { fullname } from "utils/utils";

interface CollectionRowProps {
    collection: CollectionModel;
    isSelected: boolean;
    isLocked: boolean;
    onSelected: (collection: CollectionModel, selected: boolean) => void;
    onCollectionMove: (collection: CollectionModel) => void;
    onCollectionDelete: (collection: CollectionModel) => void;
    onCollectionCopy: (collection: CollectionModel) => void;
    onCollectionEdit: (collection: CollectionModel) => void;
}

const CollectionRow = ({
    collection,
    isSelected,
    isLocked,
    onSelected,
    onCollectionMove,
    onCollectionDelete,
    onCollectionCopy,
    onCollectionEdit
}: CollectionRowProps) => {
    const [isHovering, setIsHovering] = useState<boolean>(false);

    const handleSelected = (selected: boolean) => {
        onSelected(collection, selected);
    };

    const handleMove = () => {
        onCollectionMove(collection);
    };

    const handleDelete = () => {
        onCollectionDelete(collection);
    };

    const handleCopy = () => {
        onCollectionCopy(collection);
    };

    const handleEdit = () => {
        onCollectionEdit(collection);
    };

    return (
        <tr
            className="collection-row"
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
        >
            <td style={{ width: 50 }} className="selection-cell">
                <Checkbox checked={isSelected} onChecked={handleSelected} />
            </td>

            <CollectionLinkCell id={collection.id}>
                <CollectionIcon />
            </CollectionLinkCell>

            <CollectionLinkCell id={collection.id}>
                <div>
                    {collection.name}

                    {isLocked && <LockedIcon type="Collection" owner={collection.lock.owner} />}
                </div>
            </CollectionLinkCell>

            <CollectionLinkCell id={collection.id} className="centered-cell status-cell">
                <div>Compliance Report</div>
            </CollectionLinkCell>

            <CollectionLinkCell id={collection.id} className="centered-cell status-cell">
                {fullname(collection.createdBy)}
            </CollectionLinkCell>

            <DateTimeCell dateTime={collection.updatedAt} />

            <td className="action-cell">
                {isHovering && (
                    <CollectionRowActions
                        canEdit={!isLocked}
                        canDelete={!isLocked}
                        onMove={handleMove}
                        onDelete={handleDelete}
                        onCopy={handleCopy}
                        onEdit={handleEdit}
                    />
                )}
            </td>
        </tr>
    );
};

export default CollectionRow;
