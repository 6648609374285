import React, { useMemo } from "react";
import Checkbox from "Components/Checkbox";
import { RuleModel } from "types/models";
import CollectionRulesTableRowActions from "./CollectionRulesTableRowActions";
import RuleIcon from "Components/ERPIcon/RuleIcon";
import { fullname, isNilOrEmpty } from "utils/utils";
import CollectionRulesTableLinkCell from "./CollectionRulesTableLinkCell";
import { isNil } from "lodash";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import TooltipIcon from "Components/TooltipIcon";
import { useCollection } from "./CollectionContext";
import DateTimeCell from "Scenes/Explorer/DateTimeCell";

interface CollectionRulesTableRowProps {
    rule: RuleModel;
    isSelected: boolean;
    isEnabled: boolean;
    allowRun: boolean;
    onSelected: (rule: RuleModel, selected: boolean) => void;
    onRuleAdd?: (rule: RuleModel) => void;
    onRuleRemove: (rule: RuleModel) => void;
    onRuleRun?: (rule: RuleModel) => void;
}

interface OutdatedWarningModel {
    hasResults: boolean;
    isOutdated?: boolean;
}

const CollectionRulesTableRow = ({
    rule,
    isSelected,
    isEnabled,
    allowRun,
    onSelected,
    onRuleAdd,
    onRuleRemove,
    onRuleRun
}: CollectionRulesTableRowProps) => {
    const { collecitonTasks } = useCollection();

    const handleSelected = (selected: boolean) => {
        onSelected(rule, selected);
    };

    const handleAdd = () => {
        if (!isNil(onRuleAdd)) {
            onRuleAdd(rule);
        }
    };

    const handleRemove = () => {
        onRuleRemove(rule);
    };

    const handleRun = () => {
        if (!isNil(onRuleRun)) {
            onRuleRun(rule);
        }
    };

    const outdatedWarning: OutdatedWarningModel = useMemo(() => {
        if (isNilOrEmpty(rule.runs)) {
            return {
                hasResults: false
            };
        }

        //The runs are sorted by created at, newest first from the API
        const latestRun = rule.runs[0];

        const isOutdated = latestRun.ruleVersion !== rule.version;

        return {
            hasResults: true,
            isOutdated: isOutdated
        };
    }, [rule.runs, rule.version]);

    const ruleTask = useMemo(() => {
        return collecitonTasks.find(t => t.resourceId === rule.id);
    }, [rule.id, collecitonTasks]);

    return (
        <tr className="rule-row">
            <td style={{ width: 50 }} className="selection-cell">
                <Checkbox checked={isSelected} onChecked={handleSelected} />
            </td>

            <td id={rule.id} style={{ maxWidth: 50 }}>
                <RuleIcon />
            </td>

            <CollectionRulesTableLinkCell id={rule.id}>
                {rule.name}

                {!outdatedWarning.hasResults && (
                    <TooltipIcon
                        icon={faExclamationTriangle}
                        className="no-results-icon"
                        tooltip="The rule has no results"
                    />
                )}

                {outdatedWarning.isOutdated && (
                    <TooltipIcon
                        icon={faExclamationTriangle}
                        className="outdated-results-icon"
                        tooltip="The rule settings have changed since the last run"
                    />
                )}
            </CollectionRulesTableLinkCell>

            <td id={rule.id} className="status-cell">
                {rule.location?.name}
            </td>

            <td id={rule.id} className="centered-cell status-cell">
                {fullname(rule.createdBy)}
            </td>

            <DateTimeCell dateTime={rule.updatedAt} />

            {allowRun && <DateTimeCell dateTime={!isNilOrEmpty(rule.runs) ? rule.runs[0].createdAt : null} />}

            <td className="action-cell">
                <CollectionRulesTableRowActions
                    isEnabled={isEnabled}
                    allowRun={allowRun}
                    task={ruleTask}
                    onAdd={handleAdd}
                    onRemove={handleRemove}
                    onRun={handleRun}
                />
            </td>
        </tr>
    );
};

export default CollectionRulesTableRow;
